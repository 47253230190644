import "./styles.scss";

import Check from "@mui/icons-material/Check";
import { Step, Typography } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import MuiStepper, { StepperProps as MuiStepperProps } from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

interface StepIconRootProps {
    ownerState: {
        active: boolean;
    };
}
const StyledStepIcon = styled("div")<StepIconRootProps>(({ theme, ownerState }) => ({
    "color": theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    "display": "flex",
    "height": 22,
    "alignItems": "center",
    ...(ownerState.active && {
        color: theme.palette.primary.main,
    }),
    "& .StepIcon-completedIcon": {
        color: theme.palette.primary.main,
        zIndex: 1,
        fontSize: 18,
    },
    "& .StepIcon-circle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
    },
}));

function StepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <StyledStepIcon ownerState={{ active: Boolean(active) }} className={className}>
            {completed ? (
                <Check className={`StepIcon-completedIcon ${active ? "ActiveStepIconPulseAnimation" : ""}`} />
            ) : (
                <div className={`StepIcon-circle ${active ? "ActiveStepIconPulseAnimation" : ""}`} />
            )}
        </StyledStepIcon>
    );
}

interface StepperProps {
    activeStep: number;
    steps: { label: string; sublabel?: string }[];
    muiStepperProps?: MuiStepperProps;
}
export function Stepper({ activeStep, steps, muiStepperProps }: StepperProps) {
    return (
        <MuiStepper
            sx={{ marginTop: 2 }}
            alternativeLabel
            activeStep={activeStep}
            connector={<StyledStepConnector />}
            {...muiStepperProps}
        >
            {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel
                        sx={{
                            fontSize: 12,
                            [`& .${stepLabelClasses.label}.${stepLabelClasses.alternativeLabel}`]: {
                                marginTop: 2,
                            },
                        }}
                        StepIconComponent={StepIcon}
                    >
                        <Typography className={`${activeStep === index ? "ActiveStepLabelPulseAnimation" : ""}`}>
                            {step.label}
                        </Typography>
                        {step.sublabel && (
                            <Typography
                                className={`${activeStep === index ? "ActiveStepLabelPulseAnimation" : ""}`}
                                variant={"caption"}
                                sx={{ color: "gray" }}
                            >
                                {step.sublabel}
                            </Typography>
                        )}
                    </StepLabel>
                </Step>
            ))}
        </MuiStepper>
    );
}
