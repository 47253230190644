import "./HelpCSS.css";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

export default function HelpImage(props) {
    return (
        <Paper variant={"outlined"} className={"HelpImageBox"}>
            <MobileView>
                <img src={`HelpImagesMobile/${props.Image}`} />
            </MobileView>
            <BrowserView>
                <img src={`HelpImages/${props.Image}`} />
            </BrowserView>

            <Typography variant={"caption"}>{props.Caption}</Typography>
        </Paper>
    );
}
