import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

import { LightTooltip } from "../../generic/LightTooltip/LightTooltip";

export function OperationModeIcon({ Target, showDetails, ...props }) {
    const { t } = useTranslation(["icons", "common"], { keyPrefix: "statusIcons.operationModeIcon" });
    let operationMode = "normal";
    if (Target?.saplingAutomationSettings?.automationEnabled) {
        operationMode = "automation";
    }
    const manualOverrideInfo = Target?.sproutNetworkInfo?.manualOverrideInfo;
    if (manualOverrideInfo) {
        let targetState = manualOverrideInfo.remoteOverrideSetting;
        if (
            new Date(manualOverrideInfo.deviceOverrideSettingLastUpdated?.valueOf()).valueOf() >
            new Date(manualOverrideInfo.remoteOverrideSettingLastUpdated?.valueOf()).valueOf()
        ) {
            targetState = manualOverrideInfo.deviceOverrideSetting;
        }
        const currentState = manualOverrideInfo.deviceOverrideSetting;
        if (currentState !== targetState && targetState === "open") {
            operationMode = "toManualOpen";
        } else if (currentState !== targetState && targetState === "closed") {
            operationMode = "toManualClosed";
        } else if (currentState !== targetState && targetState === "none") {
            operationMode = "toNormal";
        } else if (currentState !== targetState) {
            operationMode = "manualTransitioning";
        } else if (currentState === "open") {
            operationMode = "manualOpen";
        } else if (currentState === "closed") {
            operationMode = "manualClosed";
        }
    }
    const { PrimaryText, SecondaryText, Icon } = {
        normal: {
            PrimaryText: t("following"),
            SecondaryText: t("schedule"),
            Icon: CalendarTodayIcon,
        },
        automation: {
            PrimaryText: t("automation"),
            SecondaryText: t("enabled", { ns: "common", keyPrefix: "states" }),
            Icon: AutoAwesomeIcon,
        },
        manualOpen: {
            PrimaryText: t("override"),
            SecondaryText: t("open", { ns: "common", keyPrefix: "states" }),
            Icon: PlumbingIcon,
        },
        manualClosed: {
            PrimaryText: t("override"),
            SecondaryText: t("close", { ns: "common", keyPrefix: "states" }),
            Icon: PlumbingIcon,
        },
        toManualOpen: {
            PrimaryText: t("manually"),
            SecondaryText: t("opening"),
            Icon: ChangeCircleIcon,
        },
        toManualClosed: {
            PrimaryText: t("manually"),
            SecondaryText: t("closing"),
            Icon: ChangeCircleIcon,
        },
        toNormal: {
            PrimaryText: t("syncing"),
            SecondaryText: t("schedule"),
            Icon: ChangeCircleIcon,
        },
        manualTransitioning: {
            PrimaryText: t("changing"),
            SecondaryText: t("state"),
            Icon: ChangeCircleIcon,
        },
    }[operationMode];
    const passedProps = { ...props };
    delete passedProps.listItem;
    delete passedProps.Target;
    delete passedProps.showDetails;
    if (showDetails) {
        return (
            <div {...passedProps}>
                {/* <LightTooltip title={BattteryText}> */}
                <IconButton>
                    <Icon />
                </IconButton>
                {/* </LightTooltip> */}
                <Typography variant={"caption"}>{PrimaryText}</Typography>
                <Typography variant={"caption"}>{SecondaryText}</Typography>
            </div>
        );
    }
    return (
        <LightTooltip title={`${PrimaryText} ${SecondaryText}`}>
            <IconButton {...passedProps}>
                <Icon />
            </IconButton>
        </LightTooltip>
    );
}
