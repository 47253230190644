import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Checkbox, Chip, Stack, SxProps, TextField, TextFieldProps, Typography } from "@mui/material";
import pluralize from "pluralize";
import React, { useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize={"medium"} />;
const checkedIcon = <CheckBoxIcon fontSize={"medium"} />;

export interface ComboBoxOption {
    value: string;
    label: string;
    group?: string;
}

interface SharedComboBoxProps {
    options: ComboBoxOption[];
    renderInput?: (params: any, label?: string, placeholder?: string) => React.ReactNode;
    defaultRenderInputLabel?: string;
    sx?: SxProps;
    clearable?: boolean;
    groupBy?: (option: ComboBoxOption) => string;
    loading?: boolean;
    renderTags?: (value: ComboBoxOption[]) => React.ReactNode;
    placeholder?: string;
    groupLabels?: Record<string, string>;
}

interface SingleComboBoxProps extends SharedComboBoxProps {
    multiple: false;
    onChange: (value: ComboBoxOption | null) => void;
    defaultValue?: ComboBoxOption;
    value?: ComboBoxOption;
}

interface MultipleComboBoxProps extends SharedComboBoxProps {
    multiple: true;
    onChange: (value: ComboBoxOption[] | null) => void;
    defaultValue?: ComboBoxOption[];
    value?: ComboBoxOption[];
}

type ComboBoxProps = SingleComboBoxProps | MultipleComboBoxProps;

export function ComboBox({
    loading,
    value,
    groupBy,
    clearable = true,
    multiple,
    sx,
    options,
    renderInput = defaultRenderInputFn,
    onChange,
    defaultRenderInputLabel,
    defaultValue,
    renderTags,
    placeholder,
    groupLabels,
}: ComboBoxProps) {
    const [inputValue, setInputValue] = useState("");

    return (
        <Autocomplete
            componentsProps={{
                // Increase menu drop-shadow to differentiate from white background
                paper: {
                    elevation: 3,
                },
                popper: {
                    modifiers: [
                        {
                            // Add slight gap between menu and input
                            name: "offset",
                            options: {
                                offset: [0, 5],
                            },
                        },
                    ],
                },
            }}
            inputValue={multiple ? inputValue : undefined}
            loading={loading}
            disableCloseOnSelect={multiple}
            multiple={multiple}
            disablePortal
            options={options}
            defaultValue={defaultValue}
            disableClearable={!clearable}
            value={value}
            sx={{
                ...sx,
            }}
            isOptionEqualToValue={(option, newValue) => option.value === newValue.value || newValue.value === ""}
            getOptionLabel={(option: ComboBoxOption) => option.label}
            renderInput={(params: any) => renderInput(params, defaultRenderInputLabel, placeholder)}
            onChange={(_event: React.SyntheticEvent, newValue: ComboBoxOption | ComboBoxOption[] | null) => {
                if (multiple) {
                    onChange(newValue as ComboBoxOption[] | null);
                } else {
                    onChange(newValue as ComboBoxOption | null);
                }
            }}
            renderGroup={(params) => (
                <React.Fragment key={params.group}>
                    <Stack {...params} sx={{ justifyContent: "center", height: 40, py: 2, px: 4 }}>
                        <Typography variant={"subtitle2"} fontSize={12}>
                            {groupLabels?.[params.group] || params.group}
                        </Typography>
                    </Stack>
                    {params.children}
                </React.Fragment>
            )}
            renderOption={(props, option, { selected }) => (
                <li
                    style={{ minHeight: 40, display: "flex", alignItems: "center" }}
                    {...props}
                    key={option.value} // should be last to prevent override by spread
                >
                    {multiple && (
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 2 }} checked={selected} />
                    )}
                    {option.label}
                </li>
            )}
            groupBy={groupBy}
            // Avoid resetting the input on selection
            onInputChange={(_, newInputValue, reason) => reason !== "reset" && setInputValue(newInputValue)}
            renderTags={renderTags}
        />
    );
}

/**
 * Default renderInput for the ComboBox when none is specified
 */
function defaultRenderInputFn(params: any, label?: string, placeholder?: string) {
    return <ComboBoxInput placeholder={placeholder} label={label} {...params} />;
}

/*
 * Simple wrapper for the default text input component
 */
type ComboBoxInputProps = TextFieldProps & {
    label?: string;
    placeholder?: string;
};
export function ComboBoxInput({ label, placeholder, ...params }: ComboBoxInputProps) {
    return <TextField {...params} label={label} placeholder={placeholder} />;
}

/*
 * Component for rendering groups of tags (useful to render tags outside of the ComboBox component)
 */
export interface ComboBoxGroup {
    groupName: string;
    values: ComboBoxOption[];
}
interface ComboBoxTagGroupProps {
    tags: Record<string, ComboBoxGroup>;
    sx?: SxProps;
    onDelete: (value: ComboBoxOption) => void;
}
export function ComboBoxTagGroup({ tags, sx, onDelete }: ComboBoxTagGroupProps) {
    return (
        <Stack spacing={5} sx={{ ...sx }}>
            {Object.entries(tags).map(([key, { groupName, values }]) => (
                <Stack key={key}>
                    <Typography variant={"subtitle2"} fontSize={12}>
                        {groupName}
                    </Typography>
                    <Stack direction={"row"} flexWrap={"wrap"} gap={3} sx={{ mt: 3 }}>
                        {values.map((value) => (
                            <Chip
                                style={{
                                    borderRadius: 4,
                                }}
                                key={value.value}
                                label={value.label}
                                onDelete={() => onDelete(value)}
                                deleteIcon={<CloseIcon />}
                                sx={{
                                    "& .MuiChip-deleteIcon": {
                                        "margin": "0 4px 0 -6px",
                                        "color": "grey.600",
                                        "fontSize": "14px",
                                        "&:hover": {
                                            color: "grey.800",
                                        },
                                    },
                                }}
                            />
                        ))}
                    </Stack>
                </Stack>
            ))}
        </Stack>
    );
}

/*
 * Helper function to transform the options array into a ComboBoxGroups object,
 * based on the groupBy and groupName functions
 */
interface SeparateOptionsIntoGroupsParams {
    options: ComboBoxOption[];
    pluralizeGroupLabels?: boolean;
    groupBy?: (option: ComboBoxOption) => string;
    groupLabels?: Record<string, string>;
}
export function separateOptionsIntoGroups({
    options,
    pluralizeGroupLabels = false,
    groupBy = (option) => option.group || "selected",
    groupLabels,
}: SeparateOptionsIntoGroupsParams): Record<string, ComboBoxGroup> {
    const grouped = options.reduce((acc: Record<string, ComboBoxGroup>, value) => {
        const groupKey = groupBy(value);
        const groupLabel = groupLabels?.[groupKey] || groupKey;
        const name = groupKey ? `${pluralizeGroupLabels ? pluralize(groupLabel) : groupLabel}` : "Selected";
        acc[groupKey] = acc[groupKey] || { groupName: name, values: [] };
        acc[groupKey].values.push(value);
        return acc;
    }, {});
    return grouped;
}
