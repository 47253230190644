import "./GraphLegendChip.scss";

import { Typography } from "@mui/material";
import React from "react";

export function GraphLegendChip({ label, className, style, variant, colorSet, color }) {
    const legendIconStyle = {};

    if (color) {
        legendIconStyle["--legend-color"] = color;
    }

    return (
        <div className={`GraphLegendChip ${className}`} style={style || {}}>
            <div className={`LegendIcon ${variant || "Square"} ${colorSet || " "} `} style={legendIconStyle} />
            <Typography>{label}</Typography>
        </div>
    );
}
