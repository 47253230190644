import "./ZoneProfileEditor.css";

import AirIcon from "@mui/icons-material/Air";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import GrassIcon from "@mui/icons-material/Grass";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { getFirstDefined } from "gardenspadejs/dist/general";
import * as React from "react";
import { EventHandler, SessionHandler } from "verdiapi";

import { allVarietals } from "../../../utils/GetGrapeColorFromName";
import VarietalAvatar from "../../icons/VarietalAvatar";
import { LengthUnitTextField } from "../DeprecatedInputs/LengthUnitTextField";
import NumericTextField from "../DeprecatedInputs/NumericTextField";
import TabbedSoilLayerEditor from "../SoilLayerEditor/TabbedSoilLayerEditor";
import RelativeIrrigationDurationEditor from "./RelativeIrrigationDurationEditor";
import TotalZoneFlowRateCalculator from "./TotalZoneFlowRateCalculator";

export default class ZoneProfileEditor extends React.Component {
    changes = {};

    defaultValues = null;

    resizeListenerFunction;

    constructor(props) {
        super(props);
        this.state = {
            automaticallyUpdateIrrigationDuration: false, // FLAG: automatically update to recommended value
            totalFlowRateCalculatorOpen: false,
            windowWidth: window.innerWidth,
        };
        this.resetFlowRateEvent = new EventHandler();
    }

    componentDidMount() {
        this.resizeListenerFunction = () => {
            this.setState({
                windowWidth: window.innerWidth,
            });
        };
        window.addEventListener("resize", this.resizeListenerFunction, true);
    }

    componentWillUnmount() {
        this.defaultValues = null;
        this.changes = {};

        window.removeEventListener("resize", this.resizeListenerFunction);
    }

    render() {
        // FLAGS to display certain fields
        const displayRelativeIrrigationDuration = true;

        if (!this.props.open) {
            this.defaultValues = null;
            this.changes = {};
            return null;
        }
        if (this.props.open && !this.defaultValues) {
            let metadata = {};
            let soilLayers = [];
            if (this.props.zone && this.props.zone.userMetadata) {
                metadata = this.props.zone.userMetadata;
            }
            if (this.props.zone && this.props.zone.soilLayers) {
                soilLayers = this.props.zone.soilLayers;
            }
            const datePlanted = new Date(metadata.datePlanted);
            let defaultYear;
            if (!Number.isNaN(datePlanted.valueOf())) {
                defaultYear = datePlanted.getFullYear();
            }
            this.defaultValues = {
                connectedPump: metadata.connectedPump || "",
                varietal: metadata.varietal || undefined,
                yearPlanted: defaultYear,
                emitterSpacing: metadata.emitterSpacing || undefined,
                emitterFlowRate: metadata.emitterFlowRate || undefined,
                rootClone: metadata.rootClone || undefined,
                vineClone: metadata.vineClone || undefined,
                notes: metadata.notes || undefined,
                soilLayers: soilLayers || [],
                zonePressureHead: metadata.zonePressureHead || undefined,
                moistureAlertLowerThreshold: metadata.moistureAlertLowerThreshold || undefined,
                moistureAlertUpperThreshold: metadata.moistureAlertUpperThreshold || undefined,
                totalZoneFlowRate: metadata.totalZoneFlowRate || undefined,
                moistureAlertSettings: metadata.alertSettings?.moisture,
                relativeIrrigationDuration: this.props.zone.defaultRelativeIrrigationDuration, // raw value 0-2
                recommendedRelativeIrrigationDuration: getFirstDefined(
                    this.props.zone.recommendedRelativeIrrigationDuration,
                    1,
                ), // raw value 0-2
            };
            console.info("zone metadata", metadata);
        }

        const handleClose = async (save) => {
            if (save) {
                if (this.props.zone) {
                    const properDelta = { ...this.changes };
                    delete properDelta.yearPlanted;
                    delete properDelta.soilLayers;
                    let newSoilLayers;
                    if (
                        this.changes.yearPlanted &&
                        this.changes.yearPlanted > 1900 &&
                        this.changes.yearPlanted < 2100
                    ) {
                        properDelta.datePlanted = new Date(this.changes.yearPlanted, 2, 2);
                    }
                    if (this.changes.soilLayers) {
                        newSoilLayers = this.changes.soilLayers;
                    }
                    const editObj = {
                        userMetadata: properDelta,
                        soilLayers: newSoilLayers,
                    };
                    if (this.changes.relativeIrrigationDuration) {
                        editObj.defaultRelativeIrrigationDuration = this.changes.relativeIrrigationDuration;
                        delete this.changes.relativeIrrigationDuration;
                    }
                    await this.props.zone.argEdit(editObj);
                }
            }
            if (this.props.onClose) {
                this.props.onClose();
            }
        };

        const iconTitle = (Icon, title, alignmentCorrection = 0) => (
            <div className={"titleWithIcons"}>
                <Icon className={"iconStyle"} style={{ marginTop: alignmentCorrection }} />
                <Typography className={"zoneDetailsSubtitles"}>{title}</Typography>
            </div>
        );

        const calculatorButtonClickHandler = () => {
            const newState = !this.state.totalFlowRateCalculatorOpen;
            this.setState({
                totalFlowRateCalculatorOpen: newState,
            });
        };

        const expandedViewMinWidth = 1150;
        const isCompactView = this.state.windowWidth < expandedViewMinWidth;
        const mobileViewMinWidth = 515;
        const isMobileView = this.state.windowWidth < mobileViewMinWidth;

        return (
            <Dialog className={"ZoneProfileEditor"} open={this.props.open} onClose={handleClose}>
                <DialogTitle>Edit Zone Details - {this.props.zone.name}</DialogTitle>
                <DialogContent
                    className={"ZoneProfileEditorContent"}
                    sx={{ flexDirection: isCompactView ? "column" : "" }}
                >
                    <Box
                        className={"zoneProfileEditorFirstColumn"}
                        sx={{
                            "marginRight": isCompactView ? "0px" : "35px",
                            "& .MuiFormControl-root": { margin: "5px" },
                        }}
                    >
                        <Box className={"responsiveZoneEditorBasicInfoContainer"}>
                            <div className={"varietalAvatarContainer"}>
                                <VarietalAvatar className={"varietalAvatar"} varietal={this.defaultValues.varietal} />
                            </div>
                            <div
                                className={"varietalTextFieldContainer"}
                                style={{ order: isMobileView ? 3 : "", width: isMobileView ? "100%" : "" }}
                            >
                                <Autocomplete
                                    className={"varietalTextFieldAutocomplete"}
                                    size={"small"}
                                    onChange={(e, v) => {
                                        this.changes.varietal = v;
                                        this.forceUpdate();
                                    }}
                                    defaultValue={this.defaultValues.varietal}
                                    freeSolo
                                    options={allVarietals}
                                    renderInput={(params) => (
                                        <TextField
                                            className={"varietalTextField"}
                                            size={"small"}
                                            {...params}
                                            helperText={"e.g. Pinot noir"}
                                            label={"Varietal"}
                                        />
                                    )}
                                />
                            </div>
                            <div className={"ageTextFieldContainer"} style={{ order: isMobileView ? 2 : "" }}>
                                <NumericTextField
                                    className={"ageTextField"}
                                    size={"small"}
                                    defaultValue={this.defaultValues.yearPlanted}
                                    label={"Year"}
                                    helperText={"Planted"}
                                    onChange={(e) => {
                                        this.changes.yearPlanted = e;
                                    }}
                                />
                            </div>
                        </Box>

                        <Box className={"responsiveZoneEditorContainer"}>
                            <div className={"clonesTextFieldsContainer"}>
                                {iconTitle(GrassIcon, "Clones")}
                                <div className={"zoneProfileTextFieldRow"} style={{ justifyContent: "initial" }}>
                                    <TextField
                                        className={"vineCloneTextField"}
                                        size={"small"}
                                        label={"Vine"}
                                        helperText={"e.g. 5664"}
                                        defaultValue={this.defaultValues.vineClone}
                                        onChange={(e) => {
                                            this.changes.vineClone = e.target.value;
                                        }}
                                    />
                                    <TextField
                                        className={"rootCloneTextField"}
                                        size={"small"}
                                        label={"Root"}
                                        helperText={"e.g. 4832"}
                                        defaultValue={this.defaultValues.rootClone}
                                        onChange={(e) => {
                                            this.changes.rootClone = e.target.value;
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={"pumpSelectorContainer"}>
                                {iconTitle(ChairAltIcon, "Pump Selection")}
                                <FormControl className={"pumpSelectorDropdown"} size={"small"}>
                                    <InputLabel>Pump</InputLabel>
                                    <Select
                                        defaultValue={this.defaultValues.connectedPump || ""}
                                        label={"Pump"}
                                        onChange={(e) => {
                                            this.changes.connectedPump = e.target.value;
                                        }}
                                    >
                                        <MenuItem value={""}>
                                            <em>None</em>
                                        </MenuItem>
                                        {(SessionHandler.currentUserObject.pumps || []).map((p) => (
                                            <MenuItem value={p.name}>{p.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className={"pressureHeadTextFieldContainer"}>
                                {iconTitle(GasMeterIcon, "Block Pressure", -1)}
                                <LengthUnitTextField
                                    className={"pressureHeadTextField"}
                                    size={"small"}
                                    label={"Req. Length"}
                                    defaultValue={this.defaultValues.zonePressureHead}
                                    onChange={(e) => {
                                        this.changes.zonePressureHead = e;
                                    }}
                                    useCase={"ZonePressureHead"}
                                    unitCategory={"length"}
                                />
                            </div>

                            <div className={"totalZoneFlowRateTextFieldContainer"}>
                                {iconTitle(AirIcon, "Flow Rate")}
                                <Box className={"zoneProfileTextFieldRow"}>
                                    <LengthUnitTextField
                                        className={"totalZoneFlowRateTextField"}
                                        size={"small"}
                                        label={"Flow Rate"}
                                        defaultValue={this.defaultValues.totalZoneFlowRate}
                                        onChange={(e) => {
                                            this.changes.totalZoneFlowRate = e;
                                        }}
                                        resetEvent={this.resetFlowRateEvent}
                                        useCase={"TotalZoneFlowRate"}
                                        unitCategory={"flowRate"}
                                    />
                                    <Button
                                        className={"totalZoneFlowRateCalculatorButton"}
                                        variant={"contained"}
                                        onClick={calculatorButtonClickHandler}
                                        disableRipple={true}
                                    >
                                        Calc.
                                    </Button>
                                </Box>
                            </div>
                        </Box>

                        <div
                            className={"totalZoneFlowRateCalculatorPanel"}
                            style={{
                                maxHeight: this.state.totalFlowRateCalculatorOpen ? "200px" : "0px",
                                overflow: this.state.totalFlowRateCalculatorOpen ? "visible" : "hidden",
                            }}
                        >
                            <TotalZoneFlowRateCalculator
                                onChange={(e) => {
                                    this.changes.totalZoneFlowRate = e;
                                    this.resetFlowRateEvent.trigger({ newValue: e });
                                }}
                            />
                        </div>

                        <Divider className={"firstColumnDividerStyle"} />

                        {displayRelativeIrrigationDuration && (
                            <RelativeIrrigationDurationEditor
                                automaticallyUpdateIrrigationDuration={this.state.automaticallyUpdateIrrigationDuration}
                                recommendedIrrigationDuration={this.defaultValues.recommendedRelativeIrrigationDuration}
                                defaultValue={this.defaultValues.relativeIrrigationDuration}
                                onChange={(e) => {
                                    this.changes.relativeIrrigationDuration = e;
                                }}
                            />
                        )}
                    </Box>

                    {isCompactView && <Divider className={"firstColumnDividerStyle"} />}

                    <Box className={"zoneProfileEditorSecondColumn"}>
                        {isCompactView && <Divider className={"firstColumnDividerStyle"} />}

                        <Typography className={"soilLayerEditorTitle"}>Soil Layer Editor</Typography>
                        <TabbedSoilLayerEditor
                            defaultSoilProfiles={this.defaultValues.soilLayers}
                            onChange={(e) => {
                                this.changes.soilLayers = e;
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)}>Cancel</Button>
                    <Button onClick={() => handleClose(true)}>Save</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
