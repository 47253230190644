import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function SuccessOutlinedIcon(props) {
    return (
        <SvgIcon viewBox={"0 0 24 24"} {...props}>
            <path
                fill={"#4caf50"}
                d={
                    "M 20 12 A 8 8 0 0 1 12 20 A 8 8 0 0 1 4 12 A 8 8 0 0 1 12 4 C 12.76 4 13.5 4.11 14.2 4.31 L 15.77 2.74 C 14.61 2.26 13.34 2 12 2 A 10 10 0 0 0 2 12 A 10 10 0 0 0 12 22 A 10 10 0 0 0 22 12 M 7.91 10.08 L 6.5 11.5 L 11 16 L 21 6 L 19.59 4.58 L 11 13.17 L 7.91 10.08 Z"
                }
            />
        </SvgIcon>
    );
}
