import "./ManualModeToggle.scss";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import { ValveCloseDroplet, ValveOpenDroplet } from "../SproutVerification/VerificationIcons";
import { ManualModeState, ManualModeTransitionStatuses, ManualOverrideInfo } from "./types";

type ManualModeObject = {
    upperText: string;
    lowerText: string;
    icon: React.ReactNode;
    mode: ManualModeState;
};

interface ManualModeButtonGroupProps {
    onClick: (mode: string) => void;
    requestSending: boolean;
    manualOverrideInfo: ManualOverrideInfo;
    targetState: ManualModeState;
    manualModeTransitionStatus: ManualModeTransitionStatuses;
}
export function ManualModeButtonGroup({
    onClick,
    requestSending,
    manualOverrideInfo,
    manualModeTransitionStatus,
    targetState,
}: ManualModeButtonGroupProps) {
    const { t } = useTranslation(["specializedComponents"], { keyPrefix: "manualModeToggle" });

    const manualModeTransitionStrings: Record<ManualModeTransitionStatuses, string> = {
        sending: t("sending"),
        confirming: t("confirming"),
        complete: t("done"),
    } as const;

    const manualModeButtons: ManualModeObject[] = [
        {
            mode: ManualModeState.OPEN,
            upperText: t("force"),
            lowerText: t("open", { ns: "common", keyPrefix: "states" }),
            icon: <ValveOpenDroplet className={"ManualModeToggle__IndividualModeIcon"} />,
        },
        {
            mode: ManualModeState.NONE,
            upperText: t("sync"),
            lowerText: t("schedule"),
            icon: <CalendarTodayIcon className={"ManualModeToggle__IndividualModeIcon"} />,
        },
        {
            mode: ManualModeState.CLOSED,
            upperText: t("force"),
            lowerText: t("close", { ns: "common", keyPrefix: "states" }),
            icon: <ValveCloseDroplet className={"ManualModeToggle__IndividualModeIcon"} />,
        },
    ];

    return (
        <div
            className={`ManualModeToggle__ModesContainer ${
                requestSending ? "ManualModeToggle__ModesContainer--sending" : ""
            }`}
            style={{
                // This prevents a weird scroll bar from showing up. I don't really get why
                // but it solves the issue.
                overflow: "clip",
            }}
        >
            {manualModeButtons.map(({ mode, icon, upperText, lowerText }) => {
                const isCurrentDeviceMode = manualOverrideInfo.deviceOverrideSetting === mode;
                const isTransitioning = targetState === mode;

                const modeClassName = `ManualModeToggle__IndividualMode
                ${isCurrentDeviceMode ? "manualModeToggle__IndividualMode--currentMode" : ""} 
                ${isTransitioning ? "manualModeToggle__IndividualMode--transitioningTo" : ""}`;

                return (
                    <div key={mode} className={modeClassName} onClick={() => onClick(mode)}>
                        <Typography variant={"button"}>{upperText}</Typography>
                        {icon}
                        <Typography variant={"body1"}>{lowerText}</Typography>
                        {isCurrentDeviceMode && (
                            <div className={"ManualModeToggle__IndividualModeSubtext"}>
                                <Typography variant={"button"}>{t("currentMode")}</Typography>
                            </div>
                        )}
                        {!isCurrentDeviceMode && isTransitioning && (
                            <div className={"ManualModeToggle__IndividualModeSubtext"}>
                                <Typography variant={"button"}>
                                    {manualModeTransitionStrings[manualModeTransitionStatus]}
                                </Typography>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
