import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";

/**
 * IconButtonWithTooltip is a wrapper around the IconButton component that adds a tooltip to the IconButton.
 */
export function IconButtonWithTooltip({ title, icon, badgeSx = {}, badgeContent = undefined, ...props }) {
    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} position={"relative"}>
            {badgeContent && (
                <Box
                    sx={{
                        pointerEvents: "none",
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        top: 5,
                        right: -12,
                        borderRadius: 2,
                        zIndex: 2,
                        ...badgeSx,
                    }}
                >
                    {badgeContent}
                </Box>
            )}
            <Tooltip arrow placement={"top"} title={title}>
                <IconButton {...props}>{icon}</IconButton>
            </Tooltip>
        </Box>
    );
}
