import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function MoistureSensorIcon(props) {
    let viewBox = "0, 0,  8.467, 8.467";
    if (props.slim) {
        viewBox = "2, 0,  4.467, 8.467";
    }
    return (
        <SvgIcon viewBox={viewBox} {...props}>
            <path
                style={{
                    fillOpacity: 1,
                    fillRule: "evenodd",
                    strokeWidth: ".0193773px",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                }}
                d={
                    "M16.184 2.776c-1.236 0-2.21.427-2.21.614-.03.124-.056.825 0 1.04 0 .08.496.113.718.15l.968.723c-.006 1.194 0 5.191 0 5.191h.276V5.671l.248-.004.248.004v4.823h.276s.006-3.997 0-5.19l.968-.725c.222-.036.718-.069.718-.15.056-.214.03-.915 0-1.039 0-.187-.974-.614-2.21-.614z"
                }
                transform={"translate(-11.95 -2.42)"}
            />
        </SvgIcon>
    );
}
