import "../HelpCSS.css";

import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "../HelpBase";

export function WarningRTCSyncError(props) {
    return (
        <HelpBase
            Title={"RTC Sync Warning"}
            Target={props.children}
            className={props.className}
            style={props.style}
            component={props.component}
        >
            <Typography className={"StepDetails"}>
                <b>RTC Sync Errors</b> are indicators of potentially malfunctioning devices. This warning will appear if
                you magnet the device on a regular basis, or if the device is frequently offline.
            </Typography>
            <Typography className={"StepDetails"}>
                If you are getting this error and are not frequently magnetting the device, please contact us. This
                device may be malfunctioning.
            </Typography>
        </HelpBase>
    );
}
