const data = [
    {
        Variety: "Abrusco",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Adreuli Skelkana",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Affenthaler",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Ag Aldere",
        Color: "White",
        Use: "Wine, table grape",
    },
    {
        Variety: "Ag Kurdashi",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Agiorgitiko",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Aglianico",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Airén",
        Color: "white",
        Use: "Wine",
    },
    {
        Variety: "Akhmetis Tsiteli",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Albariño",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Albarossa",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Albillo Mayor",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Albillo Real",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Alden",
        Color: "Black",
        Use: "Raisin,Table,Wine",
    },
    {
        Variety: "Aleatico",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Alexandrouli",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Alfrocheiro",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Ali Shaitan",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Alicante Bouschet",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Aligoté",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Almeria",
        Color: "White",
        Use: "Raisin,Table,Wine",
    },
    {
        Variety: "Alvarelhão",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Alvarinho",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Ambulo Blanc",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Ansonica",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Apple Grape",
        Color: "Pink",
        Use: "Table",
    },
    {
        Variety: "Arabeuli Shavi",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Aramon",
        Color: "Black",
        Use: "Wine, table",
    },
    {
        Variety: "Arandell",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Arinarnoa",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Arinto",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Arneis",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Arnsburger",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Aromella",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Arvine",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Asgari",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Askeri",
        Color: "White",
        Use: "Raisin,Table,Wine",
    },
    {
        Variety: "Assýrtiko",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Athiri",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Aurore",
        Color: "Pink",
        Use: "Wine",
    },
    {
        Variety: "Autumn Black",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Autumn King",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Autumn Royal",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Autumn Seedless",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Auxerrois",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Avgoustiatis",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Babić",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Babica",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Baco blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Baco noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Barbaroux",
        Color: "Rose",
        Use: "Wine",
    },
    {
        Variety: "Barbera",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Barlinka",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Bazaleturi Tsolikouri",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Beauty Seedless",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Béclan",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Bez el-Anza",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Biancolella",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Biancu Gentile",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Bidaneh",
        Color: "White",
        Use: "Raisin,Table",
    },
    {
        Variety: "Bien Donne",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Big Pete",
        Color: "White",
        Use: "Raisin",
    },
    {
        Variety: "Black Beet",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Black Corinth",
        Color: "Black",
        Use: "Wine, table, raisin",
    },
    {
        Variety: "Black Emerald",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Black Seedless",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Black Spanish",
        Color: "Black",
        Use: "Wine, Jelly, Juice, Tabl...",
    },
    {
        Variety: "Blackrose",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Blanc du Bois",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Blauer Portugieser",
        Color: "Black",
        Use: "Wine, table",
    },
    {
        Variety: "Bluebell",
        Color: "Blue",
        Use: "Jelly,Juice,Table",
    },
    {
        Variety: "Blush Seedless",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Bolgar",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Bon noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Bonarda",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Bourboulenc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Brachetto",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Brianna",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Bronx Seedless",
        Color: "Red",
        Use: "Table, 8-raisin",
    },
    {
        Variety: "Bruce's Sport",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Budeshuri Tsiteli",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Burger",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Burgrave",
        Color: "Red",
        Use: "Table, raisin",
    },
    {
        Variety: "Cabernet Dorsa",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Cabernet franc",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Cabernet Sauvignon",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Cabernet Volos",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Caladoc",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Calmeria",
        Color: "White",
        Use: "Table, raisin",
    },
    {
        Variety: "Calzin",
        Color: "Black",
        Use: "Wine, table, raisin",
    },
    {
        Variety: "Caminante Blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Camminare Noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Canner",
        Color: "White",
        Use: "Canning",
    },
    {
        Variety: "Caprettone",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Cardinal",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Carignane",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Carina",
        Color: "Black",
        Use: "Raisin,Wine",
    },
    {
        Variety: "Carmenère",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Carmine",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Carnelian",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Casculho",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Casetta",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Castel 188-15",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Castelã",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Castelão",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Castets",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Catarratto",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Catawba",
        Color: "Red",
        Use: "Table, wine",
    },
    {
        Variety: "Cayuga White",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Centennial Seedless",
        Color: "White",
        Use: "Table, raisin",
    },
    {
        Variety: "Centurion",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Chambourcin",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Champanel",
        Color: "Black",
        Use: "Jelly,Rootstock",
    },
    {
        Variety: "Chancellor",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Charbono",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Chardonel",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Chardonnay",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Chardonnay 4x",
        Color: "",
        Use: "Wine",
    },
    {
        Variety: "Chasselas doré",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Chelois",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Chenin blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Cherta",
        Color: "white",
        Use: "Wine",
    },
    {
        Variety: "Chimenti Globe",
        Color: "red",
        Use: "Table",
    },
    {
        Variety: "Christmas Rose",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Ciliegiolo",
        Color: "Red",
        Use: "Table,Wine",
    },
    {
        Variety: "Cinsaut",
        Color: "Black",
        Use: "Raisin,Table,Wine",
    },
    {
        Variety: "Clairette blanche",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Colombard",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Concieira",
        Color: "Red",
        Use: "Wine",
    },
    {
        Variety: "Concord",
        Color: "Black",
        Use: "Canning,Juice,Table,Wine",
    },
    {
        Variety: "Concord Seedless",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Cornifesto",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Corot noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Cortese",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Corvina Veronese",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Cosmo 10",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Cosmo 2",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Cot",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Couderc 1202",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Couderc 1613",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Couderc 161-49",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Couderc 1616",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Couderc 1616E",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Couderc 241-123",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Couderc 3309",
        Color: "",
        Use: "Rootstock",
    },
    {
        Variety: "Counoise",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Courbu blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Crimson Pearl",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Crimson Seedless",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Croatina",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Cserszegi Fűszeres",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Danakharuli",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Dattier",
        Color: "White",
        Use: "Raisin,Table",
    },
    {
        Variety: "Dattier de St. Vallier",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Dawn Seedless",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Debit",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "DeChaunac",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Delaware",
        Color: "Red",
        Use: "Table, Wine",
    },
    {
        Variety: "Delight",
        Color: "White",
        Use: "Table, raisin",
    },
    {
        Variety: "Diamond",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Diamond Muscat",
        Color: "White",
        Use: "Raisin",
    },
    {
        Variety: "Dimyat",
        Color: "White",
        Use: "Wine, table, raisin",
    },
    {
        Variety: "Dizmar",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Dog Ridge",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Dolcetto",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Dondghlabi",
        Color: "White",
        Use: "Wine, juice, table",
    },
    {
        Variety: "Donzillinho",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Doradillo",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Dornfelder",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "DOVine",
        Color: "White",
        Use: "Raisin",
    },
    {
        Variety: "Dunkelfelder",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Dunstan #2",
        Color: "Red",
        Use: "Table,Wine",
    },
    {
        Variety: "Durif",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Dutchess",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Early Burgundy",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Early Muscat",
        Color: "White",
        Use: "Table, wine",
    },
    {
        Variety: "Edelweiss",
        Color: "White",
        Use: "Jelly,Juice,Table,Wine",
    },
    {
        Variety: "Ederena",
        Color: "Red",
        Use: "Wine",
    },
    {
        Variety: "Ehrenfelser",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Elbling",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Elvira",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Emerald Riesling",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Emerald Seedless",
        Color: "White",
        Use: "Table, raisin",
    },
    {
        Variety: "Emir",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Emperor",
        Color: "Red",
        Use: "Wine, table",
    },
    {
        Variety: "Erbaluce",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Errante Noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Esparte",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Etoile I",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Excelsior Guilly",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Exotic",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Falanghina",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Fantasy Seedless",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Fay Rouge",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Feher Szagos",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Fer",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Fercal",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Ferdinand de Lesseps",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Fernão Pires",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Feteasca alba",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Fiano",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Fiesta",
        Color: "White",
        Use: "Table, raisin",
    },
    {
        Variety: "Fileri",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Flame Seedless",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Flame Tokay",
        Color: "Red",
        Use: "Wine, table",
    },
    {
        Variety: "Flora",
        Color: "Pink",
        Use: "Wine",
    },
    {
        Variety: "Florilush",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Fokiano",
        Color: "Black",
        Use: "Wine, table",
    },
    {
        Variety: "Folle blanche",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Forastera",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Fredonia",
        Color: "Black",
        Use: "Jelly,Juice,Table, Wine",
    },
    {
        Variety: "Freedom",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Freisa",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Frontenac",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Frontenac blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Frontenac gris",
        Color: "Grey",
        Use: "Wine",
    },
    {
        Variety: "Fujiminori",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Furmint",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Gamaret",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Gamay noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Garanoir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Garganega",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Garnacha gris",
        Color: "Grey",
        Use: "Wine",
    },
    {
        Variety: "Garnacha Peluda",
        Color: "black",
        Use: "Wine",
    },
    {
        Variety: "Garnacha tinta",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Garonnet",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Gasconade",
        Color: "Black",
        Use: "Wine, table, juice",
    },
    {
        Variety: "Gechi Kyrlen",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Geneva Red",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Gewürztraminer",
        Color: "Pink",
        Use: "Wine",
    },
    {
        Variety: "Glavinuša",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Glennel",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Godello",
        Color: "white",
        Use: "Wine",
    },
    {
        Variety: "Gold",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Gold Finger",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Gorby",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Goron de Bovernier",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Gouais blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Gouveio",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Goyura",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Graciano",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Grand noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Greco di Tufo",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Green Hungarian",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Grenache noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Grignolino",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Grillo",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Gros Manseng",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Grüner Veltliner",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Gulabi",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Harmony",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Hárslevelű",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Helena",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Herbemont",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Himrod",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Hungarian Riesling",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Hunisa",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Husseine",
        Color: "White",
        Use: "Table grape",
    },
    {
        Variety: "Isabella",
        Color: "Black",
        Use: "Wine, table",
    },
    {
        Variety: "Italia",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Itchkimar",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Ives",
        Color: "Blue",
        Use: "Jelly,Juice,Wine",
    },
    {
        Variety: "Juan García",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "July Muscat",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Kadarka",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Kakhet",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Kamenina",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Kandhara",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Kartlis Tita",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Kerner",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Khatun Khardzhi",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Khindogni",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Kidonitsa",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "King of the North",
        Color: "Black",
        Use: "Jelly,Juice,Table,Wine",
    },
    {
        Variety: "Kishmish Vatkana",
        Color: "Black",
        Use: "Raisin,Table",
    },
    {
        Variety: "Kizil Sapak",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Kober 125AA",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Kober 5BB",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Korona",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Koshu",
        Color: "Rose",
        Use: "Table",
    },
    {
        Variety: "Kozma 55",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Kyoho",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "La Crescent",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Lacrima di Morro d'Alba",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Lagorthi",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Lagrein",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Lambrusca di Alessandria",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Landot noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Laurot",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Léon Milot",
        Color: "Black",
        Use: "Juice,Wine",
    },
    {
        Variety: "Limberger",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Limnio",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Limniona",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "LN33",
        Color: "White",
        Use: "Indicator plant for viru...",
    },
    {
        Variety: "Lomanto",
        Color: "Black",
        Use: "Jelly,Juice,Table,Wine",
    },
    {
        Variety: "Loose Perlette",
        Color: "White",
        Use: "Table, raisin",
    },
    {
        Variety: "Louise Swenson",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Loureiro",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Macabeo",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Malaga",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Malagouzia",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Malbec",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Malvasia bianca",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Malvasia Dubrovacka",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Malvasia fina",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Malvasia Istriana",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Malvasia Preta",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Manseng",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Mantuo de Pilas",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Maréchal Foch",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Marquette",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Marquis",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Mars",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Marsanne",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Marselan",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Marufo",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Marzemino",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Mavrodaphne",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Mavron",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Maxine Rouge",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Mazuela",
        Color: "black",
        Use: "Wine",
    },
    {
        Variety: "Mehdi",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Melody",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Melon",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Mencía",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Merlot",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Merlot Kanthus",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Meunier",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Millardet et de Grasset 101-14",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Millardet et de Grasset 219A",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Millardet et de Grasset 41B",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Misket Black",
        Color: "Black",
        Use: "Table, raisin",
    },
    {
        Variety: "Misket Plevenski",
        Color: "Black",
        Use: "Table, raisin",
    },
    {
        Variety: "MissBlanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Mission",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Molinara",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Molinera",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Mollard",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Mondeuse",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Montepulciano",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Monukka",
        Color: "Black",
        Use: "Table, raisin",
    },
    {
        Variety: "Morenillo",
        Color: "",
        Use: "",
    },
    {
        Variety: "Moreto",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Morrastel",
        Color: "",
        Use: "Wine",
    },
    {
        Variety: "Mortensen Hardy",
        Color: "Golden (light) ...",
        Use: "Table",
    },
    {
        Variety: "Moscato giallo",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Moschofilero",
        Color: "Red or deep pin...",
        Use: "Wine",
    },
    {
        Variety: "Mourisco de Semente",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Mourtaou",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Mourvèdre",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Muller Thurgau",
        Color: "White",
        Use: "Wine, juice",
    },
    {
        Variety: "Muscadelle",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Muscadelle du Bordelais",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Muscardin",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Muscat blanc",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Muscat Hamburg",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Muscat of Alexandria",
        Color: "White",
        Use: "Raisin,Table,Wine",
    },
    {
        Variety: "Muscat Ottonel",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Muscat St. Vallier",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Muxtari",
        Color: "Red",
        Use: "Table,Wine",
    },
    {
        Variety: "Nagano Purple",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Nail",
        Color: "Pink",
        Use: "Table",
    },
    {
        Variety: "Nakachevanin Gara Kishmishi",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Nakachevanin Sahibi",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Nascetta",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Nebbiolo",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Négrette",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Negro Amaro",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Nehelescol",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Nero d'Avola",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "New York Muscat",
        Color: "Black",
        Use: "Wine, table",
    },
    {
        Variety: "Niabell",
        Color: "Black",
        Use: "Wine, table, juice",
    },
    {
        Variety: "Niagara",
        Color: "White",
        Use: "Juice,Table,Wine",
    },
    {
        Variety: "Nielluccio",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Noiret",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Norton",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Odem",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Ojaleshi",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Ontario",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Orange Muscat",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Oraniensteiner",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Orion",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Palomino",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Parellada",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Paseante Noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Pedro Ximénez",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Pelaverga",
        Color: "Black",
        Use: "Wine, table grape",
    },
    {
        Variety: "Péloursin",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Perera",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Periquita",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Perlette",
        Color: "White",
        Use: "Raisin,Table",
    },
    {
        Variety: "Persan",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Petit Bouschet",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Petit Manseng",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Petit Verdot",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Peverella",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Phoenix",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Picardan",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Picolit",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Picpoul blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Piedirosso",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Pierce",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Pignolo",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Pinot blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Pinot gris",
        Color: "Grey",
        Use: "Wine",
    },
    {
        Variety: "Pinot Meunier",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Pinot noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Pinot noir Précoce",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Pinotage",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Pione",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Piquepoul blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Pishik Uzumu",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Pixie",
        Color: "Black",
        Use: "",
    },
    {
        Variety: "Plavac mali",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Plavina",
        Color: "Red",
        Use: "Wine",
    },
    {
        Variety: "Pošip bijeli",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Prieto Picudo",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Primitivo",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Princess",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Queen",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Raboso Piave",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Ramming C51-63",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Räuschling",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Red Veltliner",
        Color: "Red",
        Use: "Wine",
    },
    {
        Variety: "Redglobe",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Refosco",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Regent",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Ribier",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Ribolla gialla",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Richter 99",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Riesling",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Riesling Italico",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Riesling renano",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Rkatsiteli",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Rodítis",
        Color: "Red",
        Use: "Table,Wine",
    },
    {
        Variety: "Romulus",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Rondinella",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Rossese",
        Color: "Black",
        Use: "",
    },
    {
        Variety: "Rossese bianco",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Rossiola",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Rotberger",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Rotgipfler",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Rougett",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Roussanne",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Royalty",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Rubin Tairovskii",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Rubired",
        Color: "Black",
        Use: "Juice,Wine",
    },
    {
        Variety: "Ruby Cabernet",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Ruby Seedless",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Ruchè",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Rufete",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Rukatac",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Russian Seedless",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Sabal kanskoi",
        Color: "Red",
        Use: "Table,Wine",
    },
    {
        Variety: "Saint Émilion",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Saint Macaire",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Salt Creek",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Salvador",
        Color: "Red",
        Use: "Wine, juice",
    },
    {
        Variety: "San Marco",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Sangiovese",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Sansigot",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Saperavi",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Saperavi Budeshuriseburi",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Sauvignon blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Sauvignon gris",
        Color: "Grey",
        Use: "Wine",
    },
    {
        Variety: "Sauvignon Kretos",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Sauvignon Rytos",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Sauvignon vert",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Savagnin blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Savatiano",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Scarlet",
        Color: "Black",
        Use: "Wine, juice",
    },
    {
        Variety: "Scarlet Royal",
        Color: "Red",
        Use: "Table",
    },
    {
        Variety: "Schiava grossa",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Schioppettino",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Schuyler",
        Color: "Blue",
        Use: "Table",
    },
    {
        Variety: "Seedless Tokay",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Ségalin",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Selma Pete",
        Color: "White",
        Use: "Raisin",
    },
    {
        Variety: "Semebat",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Sémillon",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Seneca",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Seyval blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Shiraz",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Siegerrebe",
        Color: "Pink",
        Use: "Table,Wine",
    },
    {
        Variety: "Sklava",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Skrlet",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Slankamenka",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Souzão",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Sovereign Opal",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "St. Croix",
        Color: "Black",
        Use: "Juice,Table,Wine",
    },
    {
        Variety: "St. Pepin",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Steuben",
        Color: "Black",
        Use: "Juice,Table,Wine",
    },
    {
        Variety: "Suavis",
        Color: "Red",
        Use: "Table, wine",
    },
    {
        Variety: "Sultana moscata",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Sultanina",
        Color: "White",
        Use: "Table, wine and raisin",
    },
    {
        Variety: "Summer Muscat",
        Color: "White",
        Use: "Raisin",
    },
    {
        Variety: "Summer Royal",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Sunbelt",
        Color: "Black",
        Use: "Jelly,Juice",
    },
    {
        Variety: "Swenson Red",
        Color: "Red",
        Use: "Table,Wine",
    },
    {
        Variety: "Sylvaner",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Symphony",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Syrah",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Talibi",
        Color: "Rose",
        Use: "Table",
    },
    {
        Variety: "Taminga",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Tamnara",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Tampa",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Tannat",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tarrango",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Tauberschwarz",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Teleki 8B",
        Color: "Black",
        Use: "Rootstock",
    },
    {
        Variety: "Tempranillo",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Tempranillo blanco",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Teroldego",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Terret noir",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Thiakon",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Thomcord",
        Color: "Black",
        Use: "Table",
    },
    {
        Variety: "Thompson Seedless",
        Color: "White",
        Use: "Concentrate,Raisin,Table...",
    },
    {
        Variety: "Thomuscat",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Timorasso",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Tinta Amarella",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tinta Bairrada",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tinta Barroca",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tinta Caiada",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tinta Carvalha",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tinta Francisca",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tinta Madeira",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tinta Roriz",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Tinto Cão",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Tinto Sem Nome",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Tocai Friulano",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Torrontés Riojano",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Touriga Brasileira",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Touriga Franca",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Touriga Nacional",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Traminer",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Traminette",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Trebbiano Toscano",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Trentina",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Trincadeira",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Triplett 181-7A",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Triplett 30-47",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Triplett blanc",
        Color: "White",
        Use: "Concentrate,Wine",
    },
    {
        Variety: "Triplett F1-16",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Triplett F3-3",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Triplett F3-5",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Triplett T182-4",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Triplett T194-1",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Triplett T213-19",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Triplett T61-13",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Triplett T793-1",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Trousseau",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Trousseau gris",
        Color: "Grey",
        Use: "Wine",
    },
    {
        Variety: "Tulillah",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Ugni blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Vaccarèse",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Valdepeñas",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Valdiguié",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Valley Pearl",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Valvin Muscat",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Varousset",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Verdejo",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Verdelho",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Verdicchio",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Vermentino",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Vernaccia nera",
        Color: "Black",
        Use: "Table,Wine",
    },
    {
        Variety: "Verona",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Vertzami",
        Color: "Black",
        Use: "Wine and table",
    },
    {
        Variety: "Vespaiola",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Vespolina",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Victoria Red",
        Color: "Bright red",
        Use: "Table,Wine",
    },
    {
        Variety: "Vidal blanc",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Vidiano",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Vignoles",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Villard blanc",
        Color: "White",
        Use: "Table,Wine",
    },
    {
        Variety: "Vinhão",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Vintinto",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Viognier",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Viosinho",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Viura",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Voskeat",
        Color: "White",
        Use: "Wine, table",
    },
    {
        Variety: "Vranac",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Vugava",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Xarel.lo",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Xinomavro",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Xynisteri",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Zalema",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Zalxa",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Zeini",
        Color: "White",
        Use: "Table",
    },
    {
        Variety: "Zinfandel",
        Color: "Black",
        Use: "Wine",
    },
    {
        Variety: "Zlahtina",
        Color: "White",
        Use: "Wine",
    },
    {
        Variety: "Zweigelt",
        Color: "Black",
        Use: "Wine",
    },
];
export const allVarietals = data.map((d) => d.Variety);

const colorLookup = {
    white: "#debe6c",
    black: "#594e75",
    pink: "#ec89d5",
    blue: "#3a519f",
    red: "#9b3e39",
    grey: "#5d5e6c",
};
const cache = {};
export function getGrapeColorFromName(name) {
    if (cache[name]) {
        return cache[name];
    }

    const returnResult = (result) => {
        let color = colorLookup[result.Color.trim().toLowerCase()];
        if (!color) {
            color = colorLookup.black;
        }
        cache[name] = color;
        return cache[name];
    };
    let simpleName = name.trim().toLowerCase();
    for (let i = 0; i < data.length; i++) {
        if (data[i].Variety.trim().toLowerCase() === simpleName) {
            return returnResult(data[i]);
        }
    }

    simpleName = simpleName.replaceAll("[^A-Za-z]+", "");
    for (let i = 0; i < data.length; i++) {
        let varietyName = data[i].Variety.trim().toLowerCase();
        varietyName = varietyName.replaceAll("[^A-Za-z]+", "");

        if (data[i].Variety.trim().toLowerCase() === simpleName) {
            return returnResult(data[i]);
        }
    }

    cache[name] = colorLookup.black;
    return cache[name];
}
