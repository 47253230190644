import React from "react";
import { GenerateUID } from "verdiapi/dist/HelperFunctions";

/**
 * Deprecated because we are going to swtich to functional which makes this easier.
 * @deprecated
 */
export default class ReactComponentBase extends React.Component {
    _ownComponent;

    constructor(props) {
        super(props);
        this.state = {};
        this.uid = GenerateUID("ReactElement");
    }

    /**
     *
     * @return {HTMLElement}
     */
    // eslint-disable-next-line react/no-unused-class-component-methods
    get ownComponent() {
        if (this._ownComponent) {
            return this._ownComponent;
        }
        /**
         *
         * @type {HTMLElement}
         * @private
         */
        this._ownComponent = document.getElementById(this.uid);
        return this._ownComponent;
    }
}
