import "./SensoterraOverview.css";

import { Checkbox, FormControlLabel } from "@mui/material";
import { getFirstDefined } from "gardenspadejs/dist/general";
import { withSnackbar } from "notistack";
import React from "react";
import { RisingEdgeBufferedAction } from "verdiapi/dist/HelperFunctions";

import { GenerateUID } from "../../../utils/HelperFunctions";
import { LengthUnitTextField } from "../DeprecatedInputs/LengthUnitTextField";
import NumericTextField from "../DeprecatedInputs/NumericTextField";

export class DeviceStatus extends React.Component {
    /**
     * @param {{Device: Device, StartEditing: Boolean, OnZonesChanged: function(Zone, Zone), ShowValveState: Boolean}} props
     */
    constructor(props) {
        super(props);
        this.state = {
            doSMSAlerts: this.acceptableValueRangeDefined,
        };
        this.uid = GenerateUID("SensoterraOverview");

        this.changesToSave = {};
        this.tryAutoSave = new RisingEdgeBufferedAction(() => {
            // trying to autosave
            if (this.props.autoSave && this.props.Device) {
                const parsedChanges = { ...this.changesToSave };
                if (parsedChanges.doSMSAlerts !== undefined && parsedChanges.doSMSAlerts === false) {
                    parsedChanges.acceptableValueRange = [];
                } else if (parsedChanges.doSMSAlerts !== undefined && parsedChanges.doSMSAlerts === true) {
                    parsedChanges.acceptableValueRange = getFirstDefined(
                        parsedChanges.acceptableValueRange,
                        this.props.Device?.acceptableValueRange,
                        [0, 100],
                    );
                }
                delete parsedChanges.doSMSAlerts;
                // saving changes
                console.info("saving changes: ", parsedChanges);
                this.props.Device.edit(parsedChanges)
                    .then(() => {
                        if (this.props.enqueueSnackbar) {
                            this.props.enqueueSnackbar("auto-saved sensor preferences", {
                                variant: "success",
                            });
                        }
                    })
                    .catch(() => {
                        if (this.props.enqueueSnackbar) {
                            this.props.enqueueSnackbar("Could not save sensor preferences", {
                                variant: "error",
                            });
                        }
                    });
            }
        }, 3000);
        this.tryAutoSave.waitForQuiet = true;
        this.tryAutoSave.risingEdge = false;
    }

    componentDidMount() {}

    get acceptableValueRangeDefined() {
        return (
            this.props.Device?.acceptableValueRange &&
            Array.isArray(this.props.Device?.acceptableValueRange) &&
            this.props.Device?.acceptableValueRange.length > 1
        );
    }

    render() {
        return (
            <div className={"SensoterraDeviceOverviewCardRoot"} id={this.uid}>
                <LengthUnitTextField
                    label={"depth"}
                    useCase={"Depth"}
                    defaultValue={this.props.Device?.depth || 0}
                    onChange={(v) => {
                        this.changesToSave.depth = v;
                        this.tryAutoSave.trigger();
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.doSMSAlerts}
                            onChange={() => {
                                this.changesToSave.doSMSAlerts = !this.state.doSMSAlerts;
                                this.setState({ doSMSAlerts: !this.state.doSMSAlerts });
                                this.tryAutoSave.trigger();
                            }}
                        />
                    }
                    label={"Alert me when sensor reads outside the following range:"}
                />
                {/* <Typography variant={"subtitle1"}> </Typography> */}
                <div className={"SensoterraSMSAlertUpperLowerLimit"}>
                    <NumericTextField
                        disabled={!this.state.doSMSAlerts}
                        label={"Lower limit"}
                        defaultValue={
                            this.props.Device?.acceptableValueRange?.[0] ??
                            this.changesToSave?.acceptableValueRange?.[0] ??
                            "0.0"
                        }
                        onChange={(v) => {
                            if (!this.changesToSave.acceptableValueRange) {
                                this.changesToSave.acceptableValueRange = this.props.Device?.acceptableValueRange || [
                                    0, 100,
                                ];
                            }
                            this.changesToSave.acceptableValueRange[0] = v;
                            if (this.changesToSave.acceptableValueRange[1] === undefined) {
                                this.changesToSave.acceptableValueRange[1] = getFirstDefined(
                                    this.props.Device?.acceptableValueRange[1],
                                    100,
                                );
                            }
                            this.tryAutoSave.trigger();
                        }}
                    />
                    <NumericTextField
                        disabled={!this.state.doSMSAlerts}
                        label={"Upper limit"}
                        defaultValue={
                            this.props.Device?.acceptableValueRange?.[1] ??
                            this.changesToSave?.acceptableValueRange?.[1] ??
                            "100.0"
                        }
                        onChange={(v) => {
                            if (!this.changesToSave.acceptableValueRange) {
                                this.changesToSave.acceptableValueRange = this.props.Device?.acceptableValueRange || [
                                    0, 100,
                                ];
                            }
                            this.changesToSave.acceptableValueRange[1] = v;
                            if (this.changesToSave.acceptableValueRange[0] === undefined) {
                                this.changesToSave.acceptableValueRange[0] = getFirstDefined(
                                    this.props.Device?.acceptableValueRange[0],
                                    0,
                                );
                            }
                            this.tryAutoSave.trigger();
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withSnackbar(DeviceStatus);
