import "./VerificationTroubleshootText.scss";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

import InfoOutlineBlueIcon from "../../icons/InfoOutlineBlueIcon";
import SuccessOutlinedIcon from "../../icons/SuccessOutlinedIcon";

// All error codes and potential solutions below are just dummy examples for now.
const errorCodes = {
    10: {
        name: "Reported wrong state",
        overrides: [11, 12],
        isRelevant: (ver) =>
            ver.lastOpenReportedStateVerified === false || ver.lastCloseReportedStateVerified === false,
    },
    11: {
        name: "Incomplete open verification",
        overrides: [],
        isRelevant: (ver) => ver.lastOpenVerification === undefined,
    },
    12: {
        name: "Incomplete close verification",
        overrides: [],
        isRelevant: (ver) => ver.lastOpenVerification === undefined,
    },
    13: {
        name: "Flow unverified",
        overrides: [11, 12],
        isRelevant: (ver) => ver.lastOpenFlowVerified === false || ver.lastCloseFlowVerified === false,
    },
    14: {
        name: "Potentially outdated verification",
        overrides: [],
        isRelevant: (ver) =>
            Date.now() - new Date(ver.lastOpenVerification).getTime().valueOf() > 1000 * 60 * 60 * 24 * 100,
    },
};

const potentialSolutions = [
    {
        priority: 9,
        relevantErrors: [11, 13],
        title: "Unplug it then plug it in",
        details:
            "Please try to unplug it by slowly pulling all valves out of device, then plug all valves back in the device again.",
    },
    {
        priority: 2,
        relevantErrors: [10, 11, 12, 13, 14],
        title: "Contact support team",
        details: "Please contact the Verdi Team for further support.",
    },
    {
        priority: 10,
        relevantErrors: [10, 13, 14],
        title: "Turn it on and off",
        details:
            "Please try to turn it on and off again by carefully toggling the power switch on, wait for a few minutes, then toggle the power switch off.",
    },
    {
        priority: 3,
        relevantErrors: [12],
        title: "Check sensor conditions",
        details:
            "Please check the sensor conditions. If the sensor is broken, it may not be able to report any data despite there being flow.",
    },
    {
        priority: 5,
        relevantErrors: [10, 12, 14],
        title: "Check device connections",
        details:
            "Please check the device connections first by the signal strength values above. If the connection is poor, it may be a connection issue.",
    },
];

const checkIfSolutionIsRelevant = (errCode, solution) => solution.relevantErrors.includes(errCode);

export function VerificationTroubleshootText(props) {
    let errorCodeList = [];
    Object.keys(errorCodes).forEach((code) => {
        const err = errorCodes[code];
        if (err.isRelevant(props.verificationInfo)) {
            let valid = true;
            errorCodeList.forEach((existingCode) => {
                const overrides = errorCodes[existingCode].overrides;
                if (overrides && Array.isArray(overrides)) {
                    if (overrides.includes(err)) {
                        valid = false;
                    }
                }
            });
            if (valid) {
                errorCodeList.push(code);
                if (err.overrides && Array.isArray(err.overrides)) {
                    errorCodeList = errorCodeList.filter((e) => !err.overrides.includes(e));
                }
            }
        }
    });

    const allSuccess = errorCodeList.length === 0;
    const solutionList = [];
    if (!allSuccess) {
        potentialSolutions.forEach((solution) => {
            errorCodeList.forEach((code) => {
                if (checkIfSolutionIsRelevant(parseInt(code, 10), solution) && !solutionList.includes(solution)) {
                    solutionList.push(solution);
                }
            });
        });
        solutionList.sort((a, b) => b.priority - a.priority);
    }

    const TitleProps = {
        Icon: allSuccess ? SuccessOutlinedIcon : InfoOutlineBlueIcon,
        TitleText: allSuccess ? "All clear!" : "Troubleshooting Suggestions",
    };

    return (
        <Accordion
            className={`VerificationTroubleshootAccordion ${allSuccess ? "Success" : "Warning"}`}
            disabled={allSuccess}
        >
            <AccordionSummary
                className={"VerificationTroubleshootAccordionTitle"}
                expandIcon={allSuccess ? "" : <ExpandMoreIcon />}
            >
                <TitleProps.Icon className={"TitleIcon"} />
                <Typography className={"TitleText"} variant={"button"}>
                    {TitleProps.TitleText}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Typography>
                    <ol type={"1"}>
                        {solutionList.map((solution) => {
                            const existingErrors = solution.relevantErrors.filter((err) =>
                                errorCodeList.includes(err.toString()),
                            );
                            return (
                                <li>
                                    <b>{solution.title}</b>
                                    <br />
                                    {solution.details}
                                    <br />
                                    (Related errors:{" "}
                                    {existingErrors.map((err, index) => {
                                        const isLastElement = index === existingErrors.length - 1;
                                        return (
                                            <>
                                                {errorCodes[err.toString()].name}
                                                {isLastElement ? "" : ", "}
                                            </>
                                        );
                                    })}
                                    )
                                    <br />
                                    <br />
                                </li>
                            );
                        })}
                    </ol>
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
}
