import "./SoilLayerEditor.scss";

import DeleteIcon from "@mui/icons-material/Delete";
import { Button, InputLabel, Popover, Select, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { GenerateUID } from "verdiapi/dist/HelperFunctions";

import { getSoilType } from "../../../utils/SoilTypeCalculator";
import { LengthUnitTextField } from "../DeprecatedInputs/LengthUnitTextField";
import NumericTextField from "../DeprecatedInputs/NumericTextField";
import SoilTriangleSVG from "./SoilTriangleSVG";

const soilPresets = [
    {
        name: "Clay",
        clay: 0.55,
        sand: 0.1,
    },
    {
        name: "Sand",
        clay: 0.01,
        sand: 0.98,
    },
    {
        name: "Loam",
        clay: 0.16,
        sand: 0.34,
    },
    {
        name: "Clay Loam",
        clay: 0.316,
        sand: 0.402,
    },

    {
        name: "Greenhouse",
        clay: 0.16,
        sand: 0.34,
    },
    {
        name: "Loamy Sand",
        clay: 0.16,
        sand: 0.34,
    },
    {
        name: "Saline Clay",
        clay: 0.55,
        sand: 0.1,
    },
    {
        name: "Sandy Loam",
        clay: 0.13,
        sand: 0.58,
    },
    {
        name: "Tree Soil",
        clay: 0.01,
        sand: 0.83,
    },
];
export default class SoilLayerEditor extends React.Component {
    _ownElement;

    clay = 0;

    depth = undefined;

    imgHeight = 277;

    imgWidth = 300;

    sand = 1.0;

    constructor(props) {
        super(props);
        this.state = {
            sandText: undefined,
            clayText: undefined,
            deleteAnchor: undefined,
        };
        this.uid = GenerateUID("SoilLayerEditor");
        if (this.props.soilLayer) {
            this.clay = this.props.soilLayer.clayPercentage;
            this.sand = this.props.soilLayer.sandPercentage;
            this.depth = this.props.soilLayer.depth;
        }
    }

    componentDidMount() {
        if (this.ownElement) {
            const boundingBox = this.ownElement.getBoundingClientRect();
            this.imgWidth = boundingBox.width;
            this.imgHeight = boundingBox.height;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.soilLayer) {
            this.clay = nextProps.soilLayer.clayPercentage;
            this.sand = nextProps.soilLayer.sandPercentage;
            this.depth = nextProps.soilLayer.depth;
        }
        return true;
    }

    // eslint-disable-next-line class-methods-use-this
    getPositionOnTriangle(clay, sand) {
        const baseY = clay;
        const baseX = (-1 * baseY) / 2.0 - sand + 1;
        const actualY = (1 - baseY) * 88.08255659 + 1.23169108;
        const actualX = baseX * 94.61742401 + 0.55490474;
        return [actualX, actualY];
    }

    getSoilDataForTrianglePosition(x, y, width, height) {
        const baseX = (x - 0.55490474) / (0.9461742401 * width);
        const baseY = (1.23169108 - y) / (0.8808255659 * height) + 1;
        const clay = baseY;
        const sand = (-1 * baseY) / 2.0 - baseX + 1;
        if (clay < 0 || sand < 0 || 1 - clay - sand < 0) {
            return;
        }
        this.clay = clay;
        this.sand = sand;
        this.state.clayText = undefined;
        this.state.sandText = undefined;
        this.state.selectedPreset = -1;
        this.updateClayAndSand(this.clay, this.sand, this.depth);
        this.forceUpdate();
    }

    get ownElement() {
        if (!this._ownElement) {
            this._ownElement = document.getElementById(`${this.uid}SoilTriImage`);
        }
        return this._ownElement;
    }

    updateClayAndSand(clay, sand, depth) {
        if (clay !== undefined) {
            this.clay = clay;
            if (this.props.soilLayer) {
                this.props.soilLayer.clayPercentage = this.clay;
            }
        }
        if (sand !== undefined) {
            this.sand = sand;
            if (this.props.soilLayer) {
                this.props.soilLayer.sandPercentage = this.sand;
            }
        }
        if (depth !== undefined) {
            this.depth = depth;
            if (this.props.soilLayer) {
                this.props.soilLayer.depth = this.depth;
            }
        }
        if (this.props.onChange) {
            this.props.onChange({
                sand: this.sand,
                clay: this.clay,
                depth: this.depth,
            });
        }
    }

    render() {
        const positionOnTriangle = this.getPositionOnTriangle(this.clay, this.sand);
        const silt = 1 - this.clay - this.sand;
        const axes = {
            clay: {
                index: this.getPositionOnTriangle(this.clay, 1 - this.clay),
            },
            sand: {
                index: this.getPositionOnTriangle(0, this.sand),
            },
            silt: {
                index: this.getPositionOnTriangle(1 - silt, 0),
            },
        };
        Object.keys(axes).forEach((axisName) => {
            axes[axisName].width = Math.sqrt(
                (((axes[axisName].index[0] - positionOnTriangle[0]) / 100) * this.imgWidth) ** 2 +
                    (((axes[axisName].index[1] - positionOnTriangle[1]) / 100) * this.imgHeight) ** 2,
            );
        });

        const textureName = getSoilType(this.sand * 100, this.clay * 100);
        return (
            <Box
                sx={{
                    "display": "flex",
                    "flexDirection": "column",
                    // "& .MuiTextField-root": { width: "20ch" },
                    "& .MuiFormControl-root": { margin: "5px" },
                }}
                className={"SoilEditorBase"}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                />

                <Box
                    className={"SoilLayerEditorTriangleHolder"}
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    {this.props.onDelete && (
                        <IconButton
                            variant={"contained"}
                            // color={"error"}
                            className={"SoilLayerDeleteButton"}
                            onClick={(e) => {
                                this.setState({
                                    deleteAnchor: e.currentTarget,
                                });
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                    <Popover
                        anchorEl={this.state.deleteAnchor}
                        onClose={() => {
                            this.setState({
                                deleteAnchor: undefined,
                            });
                        }}
                        open={Boolean(this.state.deleteAnchor)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <Button
                            variant={"contained"}
                            color={"error"}
                            onClick={() => {
                                this.props.onDelete();
                            }}
                        >
                            Delete Layer
                        </Button>
                    </Popover>
                    <Typography
                        component={"div"}
                        className={"TextureLabel"}
                        style={{
                            left: `${positionOnTriangle[0]}%`,
                            top: `${positionOnTriangle[1]}%`,
                        }}
                        variant={"body1"}
                    >
                        {textureName}
                    </Typography>

                    <div
                        className={"indexLine clayLine"}
                        style={{
                            left: `${axes.clay.index[0]}%`,
                            top: `${axes.clay.index[1]}%`,
                            width: `${axes.clay.width}px`,
                        }}
                    />
                    <div
                        className={"indexLine sandLine"}
                        style={{
                            left: `${axes.sand.index[0]}%`,
                            top: `${axes.sand.index[1]}%`,
                            width: `${axes.sand.width}px`,
                        }}
                    />
                    <div
                        className={"indexLine siltLine"}
                        style={{
                            left: `${axes.silt.index[0]}%`,
                            top: `${axes.silt.index[1]}%`,
                            width: `${axes.silt.width}px`,
                        }}
                    />
                    <div
                        className={"soilDot"}
                        style={{
                            left: `${positionOnTriangle[0]}%`,
                            top: `${positionOnTriangle[1]}%`,
                        }}
                    />

                    <SoilTriangleSVG
                        soilType={textureName}
                        id={`${this.uid}SoilTriImage`}
                        className={"soilTriangleSVG"}
                        onClick={(e) => {
                            if (this.ownElement) {
                                const boundingBox = this.ownElement.getBoundingClientRect();
                                this.imgWidth = boundingBox.width;
                                this.imgHeight = boundingBox.height;
                                this.getSoilDataForTrianglePosition(
                                    e.clientX - boundingBox.left,
                                    e.clientY - boundingBox.top,
                                    boundingBox.width,
                                    boundingBox.height,
                                );
                            }
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        // "& .MuiTextField-root": { width: "12ch" },
                    }}
                    className={"DepthAndPresetRow"}
                >
                    <LengthUnitTextField
                        className={"SoilEditorDepthField"}
                        size={"small"}
                        useCase={"Depth"}
                        label={"Depth"}
                        defaultValue={this.depth}
                        onChange={(e) => {
                            this.depth = e;
                            this.updateClayAndSand(undefined, undefined, this.depth);
                        }}
                    />
                    <FormControl className={"SoilPresetSelector"}>
                        <InputLabel id={`${this.uid}TextureDropdown`}>Soil Presets</InputLabel>
                        <Select
                            size={"small"}
                            labelId={`${this.uid}TextureDropdown`}
                            value={this.state.selectedPreset || -1}
                            label={"Soil Presets"}
                            onChange={(e) => {
                                const value = e.target.value;
                                this.state.selectedPreset = value;
                                if (value >= 0 && value < soilPresets.length) {
                                    this.clay = soilPresets[value].clay;
                                    this.sand = soilPresets[value].sand;
                                    this.state.clayText = undefined;
                                    this.state.sandText = undefined;
                                    this.forceUpdate();
                                }
                            }}
                        >
                            {soilPresets.map((soilPreset, i) => (
                                <MenuItem value={i}>{soilPreset.name}</MenuItem>
                            ))}
                            <MenuItem value={-1}>Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        // "& .MuiTextField-root": { width: "12ch" },
                    }}
                    className={"ClayAndSandRow"}
                >
                    <NumericTextField
                        size={"small"}
                        label={"Clay %"}
                        value={this.state.clayText || Math.round(this.clay * 1000) / 10 || 0}
                        onChange={(e) => {
                            this.state.clayText = e;
                            this.state.selectedPreset = -1;
                            try {
                                const clayNumber = parseFloat(e);
                                this.clay = Math.min(1 - this.sand, clayNumber / 100);
                                this.forceUpdate();
                            } catch (error) {
                                console.warn("Error in soil layer editor:", error);
                            }
                        }}
                        onBlur={() => {
                            this.updateClayAndSand(this.clay, undefined, undefined);
                            this.setState({
                                clayText: undefined,
                            });
                        }}
                    />
                    <NumericTextField
                        size={"small"}
                        label={"Sand %"}
                        value={this.state.sandText || Math.round(this.sand * 1000) / 10 || 0}
                        onChange={(e) => {
                            this.state.sandText = e;
                            this.state.selectedPreset = -1;
                            try {
                                const sandNumber = parseFloat(e);
                                this.sand = Math.min(1 - this.clay, sandNumber / 100);
                                this.forceUpdate();
                            } catch (error) {
                                console.warn("error in soil layer editor:", error);
                            }
                        }}
                        onBlur={() => {
                            this.updateClayAndSand(undefined, this.sand, undefined);
                            this.setState({
                                sandText: undefined,
                            });
                        }}
                    />
                </Box>
            </Box>
        );
    }
}
