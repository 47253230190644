import "./TypographyWithMaxWidth.css";

import Typography from "@mui/material/Typography";
import React from "react";

import ReactComponentBase from "../../../utils/ReactComponentBase";

const strats = ["unchanged", "condensedFont", "smallText", "scale", "scaleSmallText", "scaleSmallTextWithElipsis"];

const stratsByName = {};
strats.forEach((v, i) => {
    stratsByName[v] = i;
    stratsByName[i] = v;
});

const compById = {};

const resizeObserver = new ResizeObserver((entries) => {
    for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (compById[entry.target.id]) {
            compById[entry.target.id].evaluateSize(entry);
        }
    }

    console.info("typography with max width had size change");
});

export default class TypographyWithMaxWidth extends ReactComponentBase {
    constructor(props) {
        super(props);
        this.state = {
            currentStrat: 0,
        };
    }

    unalteredWidth = undefined;

    lineHeight = undefined;

    widths = [undefined, undefined, undefined, undefined, undefined, undefined];

    currentWidth = undefined;

    tries = 0;

    active = false;

    componentDidMount() {
        // this.manageTextSize();
        compById[this.uid] = this;
        resizeObserver.observe(this.ownComponent);
    }

    componentWillUnmount() {
        resizeObserver.unobserve(this.ownComponent);
        delete compById[this.uid];
    }

    evaluateSize(ownEntry) {
        const curWidth = ownEntry.contentBoxSize[0].inlineSize;
        if (this.state.currentStrat < 5) {
            this.currentWidth = curWidth;
        }
        if (this.state.currentStrat === 0 || this.state.currentStrat === 1) {
            this.unalteredWidth = curWidth;
            this.lineHeight = ownEntry.contentBoxSize[0].blockSize;
        }
        this.widths[this.state.currentStrat] = curWidth;
        if (curWidth > this.props.MaxWidth) {
            if (this.unalteredWidth > this.props.MaxWidth * 2 || this.state.currentStrat >= 4) {
                this.setState({
                    currentStrat: 5,
                });
            } else if (this.unalteredWidth > this.props.MaxWidth * 1.5 || this.state.currentStrat >= 3) {
                this.setState({
                    currentStrat: 4,
                });
            } else if (this.state.currentStrat >= 2) {
                this.setState({
                    currentStrat: 3,
                });
            } else if (this.state.currentStrat >= 1) {
                this.setState({
                    currentStrat: 2,
                });
            } else {
                this.setState({
                    currentStrat: 1,
                });
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.MaxWidth !== this.props.MaxWidth) {
            return true;
        }
        if (nextProps.children !== this.props.children) {
            return true;
        }
        if (nextProps.className !== this.props.className) {
            return true;
        }
        if (nextState.currentStrat !== this.state.currentStrat) {
            return true;
        }
        return false;
    }

    render() {
        const typographyProps = { ...this.props };
        delete typographyProps.MaxWidth;
        typographyProps.id = this.uid;
        typographyProps.className = typographyProps.className || "";
        typographyProps.className += " TypographyWithMaxWidth ";
        typographyProps.className += " TypographyWithMaxWidthSuper ";
        typographyProps.className += ` TypographyWithMaxWidth--${strats[this.state.currentStrat]} `;
        typographyProps.style = { ...(typographyProps.style ?? {}) };
        if (this.props.MaxWidth) {
            typographyProps.style["--maxWidth"] = `${this.props.MaxWidth.toString()}px`;
            typographyProps.style["--maxWidthUnitless"] = this.props.MaxWidth.toString();
        }
        if (this.unalteredWidth) {
            typographyProps.style["--curWidth"] = this.unalteredWidth.toString();
        }
        if (this.lineHeight) {
            typographyProps.style["--lineHeight"] = `${this.lineHeight.toString()}px`;
        }

        return <Typography {...typographyProps} />;
    }
}
