import "./HelpCSS.css";

import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "./HelpBase";
import HelpImage from "./HelpImage";

const StepTitle = "h6";

export function ChoosingValves(props) {
    return (
        <HelpBase Title={"How to select the zones for the valves on your device"} Target={props.children}>
            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>1</span> Identify the valves{" "}
            </Typography>
            <Typography className={"StepDetails"}>
                On the device sticker, there are labels for V1 and V2. These are the wires for Valves 1 and 2. Typically
                Valve 1 will be on the left and Valve 2 on the right. Ensure you know where the valve outlet is and what
                zones you want each valve to connect to.
            </Typography>
            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                <i>
                    Note: If you cannot identify the valve outlets, contact Verdi directly and we will be happy to
                    assist you
                </i>
            </Typography>
            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>2</span> Identify the zones{" "}
            </Typography>
            <Typography className={"StepDetails"}>
                A Verdi associate should have already created zones for the different parts of your land. These should
                be visible on the map. Ideally, this device should be placed on the border between two such zones, with
                one valve on either side of the border.
            </Typography>
            <HelpImage
                Image={"DevicesOnZoneBordersDemo.webp"}
                Caption={"Optimal device placement is on the zone border"}
            />
            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>3</span> Choose the zone to assign to each valve{" "}
            </Typography>
            <Typography className={"StepDetails"}>
                When setting up the device, you will see two zone selection boxes (shown below). The active one will be
                highlighted green. Tapping on the map will allow you to set which zone is connected to the device.
                Ensure the "Valve 1 Zone" corresponds to the zone valve 1 will be connected to and the "Valve 2 Zone"
                corresponds to the zone valve 2 will be connected to.
            </Typography>
            <Typography className={"StepDetails"}>
                If you are registering a single valve device, you will only be permitted to select one zone.
            </Typography>
            <HelpImage
                Image={"ValveSelection.png"}
                Caption={
                    "The green highlighting indicates that Zone 1 is currently being edited. Tapping a zone on the map will set it to zone 1"
                }
            />
        </HelpBase>
    );
}
