import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { IrrigationDeviceBase } from "verdiapi/dist/Models/Devices/IrrigationDeviceBase";
import { IrrigationDeviceSensor } from "verdiapi/dist/Models/Devices/IrrigationDeviceSensor";
import { SensorType } from "verditypes";

import { getSensorSettingsPanelForSensorType } from "./getSensorSettingsPanelForSensorType";
/**
 * Creates the sensor settings icon button and deals with finding and producing the relevant settings panel
 * @param sensor
 * @param sensorType
 * @param device
 * @param onSettingsPanelClose
 * @returns {Element}
 * @constructor
 */
export function SensorSettingsButton({
    sensor,
    sensorType,
    device,
    onSettingsPanelClose,
}: {
    sensor?: IrrigationDeviceSensor;
    sensorType: SensorType | null;
    device: IrrigationDeviceBase;
    onSettingsPanelClose: (eventArgs: Record<string, any>, forceSave: boolean) => void;
}) {
    const [settingsPanelOpen, setSettingsPanelOpen] = React.useState(false);
    const SensorSettingsPanel = getSensorSettingsPanelForSensorType(sensorType);
    return (
        <>
            <IconButton
                className={"SensorDropdownSettingsButton"}
                onClick={() => {
                    setSettingsPanelOpen(true);
                }}
                disabled={!SensorSettingsPanel}
            >
                <SettingsIcon />
            </IconButton>

            {SensorSettingsPanel && (
                <SensorSettingsPanel
                    sensor={sensor}
                    device={device}
                    open={settingsPanelOpen}
                    onClose={(eventArgs: Record<string, any>, forceSave: boolean) => {
                        setSettingsPanelOpen(false);
                        if (onSettingsPanelClose) {
                            onSettingsPanelClose(eventArgs, forceSave);
                        }
                    }}
                />
            )}
        </>
    );
}
