import AddIcon from "@mui/icons-material/Add";
import { Button, ListItem, ListItemText, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import FlipMove from "react-flip-move";
import { MasterIndex } from "verdiapi";
import { SensorConfigurations } from "verditypes/dist/Configurations/SensorConfiguration";

import { useSnackbar } from "../../../hooks/useSnackbar";
import ConfirmChoiceButton from "../../generic/ConfirmChoiceButton/ConfirmChoiceButton";
import IconDatePicker from "../../generic/IconDatePicker/IconDatePicker";
import { SelectDropdown } from "../../generic/SelectDropdown";

const getCurrentSupplementalDataStreamsFromZone = (zoneID) =>
    MasterIndex.zone.byID[zoneID].supplementalZoneGraphInfo ?? {
        supplementalDataStreams: [],
    };

const setCurrentSupplementalDataStreamsFromZone = async (zoneID, supplementalInfo) => {
    MasterIndex.zone.byID[zoneID].argEdit({
        supplementalZoneGraphInfo: supplementalInfo,
    });
};
export function ZoneSupplementalDataStreamEditor({ zoneID }) {
    const [currentInfo, setCurrentInfo] = React.useState(getCurrentSupplementalDataStreamsFromZone(zoneID));

    const [selectedEntryIndex, setSelectedEntryIndex] = React.useState(-1);

    const [addingNewEntry, setAddingNewEntry] = React.useState(false);
    const [newEntryInfo, setNewEntryInfo] = React.useState({});

    const { enqueueSnackbar } = useSnackbar();
    return (
        <div>
            <div
                style={{
                    minWidth: "20ch",
                    width: "50ch",
                    maxWidth: "80vw",
                }}
            >
                <FlipMove>
                    {(currentInfo.supplementalDataStreams || []).map((supplamentalDataStream, i) => (
                        <ListItem
                            secondaryAction={
                                <ConfirmChoiceButton
                                    color={"error"}
                                    onClick={() => {
                                        const originalSelectedDataStream =
                                            currentInfo.supplementalDataStreams[selectedEntryIndex];
                                        currentInfo.supplementalDataStreams =
                                            currentInfo.supplementalDataStreams.filter(
                                                (n) => n !== supplamentalDataStream,
                                            );
                                        const newSelectedDataStreamIndex =
                                            currentInfo.supplementalDataStreams.indexOf(originalSelectedDataStream);
                                        setSelectedEntryIndex(newSelectedDataStreamIndex);
                                        setCurrentInfo(currentInfo);

                                        setCurrentSupplementalDataStreamsFromZone(zoneID, currentInfo)
                                            .then(() => {
                                                enqueueSnackbar(`Removed supplemental data streams for zone!`, {
                                                    variant: "success",
                                                });
                                                setCurrentInfo(getCurrentSupplementalDataStreamsFromZone(zoneID));
                                            })
                                            .catch(() => {
                                                enqueueSnackbar(`Error modifying supplemental data streams for zone`, {
                                                    variant: "error",
                                                });
                                                setCurrentInfo(getCurrentSupplementalDataStreamsFromZone(zoneID));
                                            });
                                    }}
                                >
                                    Delete
                                </ConfirmChoiceButton>
                            }
                            // onClick={()=>{
                            //     setSelectedEntryIndex(i);
                            // }}
                        >
                            <ListItemText
                                primary={supplamentalDataStream.name}
                                secondary={`${supplamentalDataStream.dataType}, ${supplamentalDataStream.sourceID}`}
                            />
                        </ListItem>
                    ))}
                    <ListItem
                        secondaryAction={
                            <IconButton
                                edge={"end"}
                                aria-label={"add number"}
                                onClick={() => {
                                    setAddingNewEntry(true);
                                    setNewEntryInfo({});
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        }
                    >
                        <ListItemText primary={"Add New Data Stream"} />
                    </ListItem>
                </FlipMove>
            </div>
            <div>
                {/* eslint-disable-next-line no-use-before-define */}
                {addingNewEntry && newEntryInfo && (
                    <SingleDataStreamEditor
                        dataStreamToEdit={newEntryInfo}
                        isNew={addingNewEntry}
                        onSubmit={(entry) => {
                            if (addingNewEntry) {
                                currentInfo.supplementalDataStreams.push(entry);
                            } else {
                                Object.assign(currentInfo.supplementalDataStreams[selectedEntryIndex], entry);
                            }
                            setCurrentSupplementalDataStreamsFromZone(zoneID, currentInfo)
                                .then(() => {
                                    enqueueSnackbar(`Modified supplemental data streams for zone!`, {
                                        variant: "success",
                                    });
                                    setCurrentInfo(getCurrentSupplementalDataStreamsFromZone(zoneID));
                                    setAddingNewEntry(false);
                                    setNewEntryInfo({});
                                })
                                .catch(() => {
                                    enqueueSnackbar(`Error modifying supplemental data streams for zone`, {
                                        variant: "error",
                                    });
                                    setCurrentInfo(getCurrentSupplementalDataStreamsFromZone(zoneID));
                                });
                        }}
                    />
                )}
            </div>
        </div>
    );
}
function SingleDataStreamEditor({ dataStreamToEdit, onSubmit, isNew }) {
    const [name, setName] = React.useState(dataStreamToEdit.name || "");
    const [sourceID, setSourceID] = React.useState(dataStreamToEdit.sourceID || "");
    const [sensorType, setSensorType] = React.useState(dataStreamToEdit.sensorType || "");
    const [startDate, setStartDate] = React.useState(dataStreamToEdit.startDate || null);
    const [endDate, setEndDate] = React.useState(dataStreamToEdit.endDate || null);

    // whenever changes are noticed by this, reset the state to default.
    React.useEffect(() => {
        setName(dataStreamToEdit.name);
        setSourceID(dataStreamToEdit.sourceID);
        setSensorType(dataStreamToEdit.sensorType);
    }, [dataStreamToEdit.name, dataStreamToEdit.sourceID, dataStreamToEdit.sensorType]);

    const valid = name && sourceID && sensorType && SensorConfigurations[sensorType];

    return (
        <div>
            <TextField
                label={"Name"}
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                }}
                variant={"outlined"}
            />

            <TextField
                label={"Source ID"}
                value={sourceID}
                onChange={(e) => {
                    setSourceID(e.target.value);
                }}
                variant={"outlined"}
            />

            <SelectDropdown
                value={sensorType}
                options={[
                    {
                        value: "",
                        label: "None",
                    },
                    ...Object.entries(SensorConfigurations).map(([key, config]) => ({
                        value: key,
                        label: config.friendlyName,
                    })),
                ]}
                onChange={(v) => {
                    setSensorType(v);
                }}
            />
            <IconDatePicker
                value={startDate}
                onChange={(v) => {
                    setStartDate(v);
                }}
                includeYear={true}
            />
            <IconDatePicker
                value={endDate}
                onChange={(v) => {
                    setEndDate(v);
                }}
                includeYear={true}
            />
            <Button
                disabled={!valid}
                onClick={() => {
                    onSubmit({
                        name,
                        sourceID,
                        sensorType,
                        dataType: SensorConfigurations[sensorType].parsedDataType,
                        startDate: startDate,
                        endDate: endDate,
                    });
                }}
            >
                {valid ? "Add Datastream" : "Invalid config"}
            </Button>
        </div>
    );
}
