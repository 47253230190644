import "./DateAvatar.scss";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import React from "react";
import { ShortMonthLookup } from "verdiapi/dist/HelperFunctions";

function DateAvatarDisplay({
    includeYear,
    includeTime,
    monthText,
    dateText,
    timeText,
    yearText,
    className,
    ...propsToPass
}) {
    return (
        <Avatar variant={"rounded"} className={className} {...propsToPass}>
            <Typography className={"ClickForAgendaPopup"} component={"p"} variant={"button"}>
                {monthText}
            </Typography>
            <Typography className={"ClickForAgendaPopup"} component={"p"} variant={"button"}>
                {dateText}
            </Typography>
            {includeTime && (
                <Typography className={"AgendaAvatarTime"} component={"p"} variant={"button"}>
                    {timeText}
                </Typography>
            )}
            {includeYear && (
                <Typography className={"AgendaAvatarTime"} component={"p"} variant={"button"}>
                    {yearText}
                </Typography>
            )}
        </Avatar>
    );
}

export function DateAvatar({ date, includeTime, includeYear, className, ...propsToPass }) {
    let avatarClassName = `AgendaDateAvatar Override ${className || ""}`;

    if (includeTime || includeYear) {
        avatarClassName += " AgendaDateAvatar--timeIncluded ";
    }

    if (date === null) {
        return (
            <DateAvatarDisplay
                includeYear={includeYear}
                includeTime={includeTime}
                monthText={"None"}
                dateText={"??"}
                timeText={"00:00"}
                yearText={`N/A`}
                className={avatarClassName}
                {...propsToPass}
            />
        );
    }
    const shortMonth = ShortMonthLookup[date.getMonth()];

    return (
        <DateAvatarDisplay
            includeYear={includeYear}
            includeTime={includeTime}
            monthText={shortMonth}
            dateText={date.getDate()}
            timeText={getTimeString(new Date(date.valueOf()), "h:mm nn")}
            yearText={`20${getTimeString(new Date(date.valueOf()), "yy")}`}
            className={avatarClassName}
            {...propsToPass}
        />
    );

    //
    // (
    //     <Avatar variant={"rounded"} className={avatarClassName} {...propsToPass}>
    //         <Typography className={"ClickForAgendaPopup"} component={"p"} variant={"button"}>
    //             {shortMonth}
    //         </Typography>
    //         <Typography className={"ClickForAgendaPopup"} component={"p"} variant={"button"}>
    //             {date.getDate()}
    //         </Typography>
    //         {includeTime && (
    //             <Typography className={"AgendaAvatarTime"} component={"p"} variant={"button"}>
    //                 {getTimeString(new Date(date.valueOf()), "h:mm nn")}
    //             </Typography>
    //         )}
    //         {includeYear && (
    //             <Typography className={"AgendaAvatarTime"} component={"p"} variant={"button"}>
    //                 {`20${getTimeString(new Date(date.valueOf()), "yy")}`}
    //             </Typography>
    //         )}
    //     </Avatar>
    // );
}
