import "../Microvalve/SensorDropdown.scss";

import { InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import React from "react";
import { EventHandler, SessionHandler } from "verdiapi";
import { doesDeviceVersionMatchSelector } from "verditypes";
import { SensorConfigurations } from "verditypes/dist/Configurations/SensorConfiguration";

import { GenerateUID } from "../../../../utils/HelperFunctions";
import SensorRecentValuesPreview from "./SensorRecentValuesPreview";
import { SensorSettingsButton } from "./SensorSettingsPanels/SensorSettingsButton";

// eslint-disable-next-line import/no-mutable-exports
export let currentOpenDropdown;

export class SensorDropdown extends React.Component {
    defaultValues = undefined;

    isOpen = false;

    /**
     * @type{IrrigationDeviceSensor}
     */
    sensor;

    constructor(props) {
        super(props);
        this.UID = GenerateUID("SensorDropdown");
        this.state = {
            sensorType: null,
            sensorDepth: undefined,
        };
    }

    componentDidMount() {
        if (this.props.device) {
            this.props.device.onChange.addListener(() => {
                this.forceUpdate();
            }, this.UID);
        }
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.UID);
    }

    onChange(args, forceSave = false) {
        // Checking if undefined, because null is not the same as undefined.
        const sensorType = firstDefinedValue(args.sensorType, this.state.sensorType, this.sensor.sensorType);
        // let depth = firstDefinedValue(args.depth, this.state.sensorDepth, this.sensor.depth);

        this.setState({
            sensorType: sensorType,
            // sensorDepth: depth,
        });

        this.sensor.sensorType = sensorType;
        // this.sensor.depth = depth;
        if (this.props.onChange) {
            this.props.onChange(this.sensor, forceSave);
        }
    }

    render() {
        if (!this.props.sensor) {
            throw new Error("sensor is a required prop");
        }
        if (!this.defaultValues) {
            this.sensor = this.props.sensor;
            this.defaultValues = { ...this.props.sensor };
            this.state.sensorType = this.defaultValues.sensorType || null;
            this.state.sensorDepth = this.defaultValues.depth;
        }

        // A list of all sensor configurations for sensors that are connected to Verdi devices,
        // not onboard, and that support the current device's version (if a device is selected)
        const allSupportedExternalVerdiDeviceSensorConfigurations = Object.values(SensorConfigurations).filter(
            (sensorConfig) => {
                if (!sensorConfig.verdiDeviceSensorConfiguration) {
                    return false;
                }
                if (sensorConfig.isOnboard) {
                    return false;
                }

                // If the device doesn't exist, then call it supported. This is until we get
                // continuous device versioning
                const supportsCurrentDevice =
                    !this.props.device ||
                    doesDeviceVersionMatchSelector(
                        this.props.device?.deviceVersionInfo,
                        sensorConfig.verdiDeviceSensorConfiguration.supportedDeviceVersions,
                    );

                return supportsCurrentDevice;
            },
        );

        // We want to sort by category, then manufacturer, then name. So that similar sensors are grouped together
        allSupportedExternalVerdiDeviceSensorConfigurations.sort((a, b) => {
            // map the two options into strings that can be sorted alphabetically
            const indexableStrings = [a, b].map(
                (config) => `${config.sensorCategory} ${config.manufacturerName} ${config.friendlyName}`,
            );
            return indexableStrings[0].localeCompare(indexableStrings[1]);
        });

        let isDeviceConnectedToTwoIdenticalSensors = false;
        if (this.props.device?.connectedSensors) {
            if (
                this.props.device.connectedSensors[0]?.sensorType === this.props.device.connectedSensors[1]?.sensorType
            ) {
                isDeviceConnectedToTwoIdenticalSensors = true;
            }
        }
        /**
         * We should not show the recent sensor values if the two sensors are of the same type because
         * the data gets mixed up. This is a duct tape solution until we fix the underlying problem.
         * @type {boolean}
         */
        const showRecentSensorValues = !isDeviceConnectedToTwoIdenticalSensors;
        return (
            <div className={`SensorSelectDropdownRoot ${this.props.className || ""}`} style={this.props.style}>
                <FormControl
                    className={`SensorSelectDropdown TextFieldAlignmentOverride--${this.props.alignment || "left"}`}
                    variant={"filled"}
                >
                    <InputLabel id={this.UID}>{this.props.label}</InputLabel>
                    <Select
                        className={`TextFieldAlignmentOverride--${this.props.alignment || "left"}`}
                        labelId={this.UID}
                        value={this.state.sensorType || ""}
                        onChange={(e) => {
                            this.onChange({
                                sensorType: e.target.value,
                            });
                        }}
                        onOpen={() => {
                            currentOpenDropdown = this.props.parentID;
                            this.isOpen = true;
                        }}
                        onClose={() => {
                            this.isOpen = false;
                            window.requestAnimationFrame(() => {
                                setTimeout(() => {
                                    if (currentOpenDropdown === this.props.parentID && this.isOpen === false) {
                                        currentOpenDropdown = undefined;
                                    }
                                }, 100);
                            });
                        }}
                    >
                        {allSupportedExternalVerdiDeviceSensorConfigurations.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                                {option.friendlyName} ({option.manufacturerName})
                            </MenuItem>
                        ))}
                        <MenuItem key={"NULL"} value={null}>
                            None
                        </MenuItem>
                    </Select>
                    <SensorSettingsButton
                        sensorType={this.state.sensorType}
                        sensor={this.sensor}
                        device={this.props.device}
                        onSettingsPanelClose={(e, forceSave = false) => {
                            if (forceSave) {
                                this.onChange({}, forceSave);
                            }
                        }}
                    />
                    {SessionHandler.admin && (
                        <FormHelperText
                            style={{
                                position: "absolute",
                                bottom: -3,
                            }}
                        >
                            {Math.round((this.sensor?.readingPeriod || 0) / (1000 * 60))}min/
                            {Math.round((this.sensor?.uplinkPeriod || 0) / (1000 * 60))}min
                        </FormHelperText>
                    )}
                </FormControl>
                {showRecentSensorValues && (
                    <SensorRecentValuesPreview sensor={this.sensor} device={this.props.device} />
                )}
            </div>
        );
    }
}

function firstDefinedValue(...args) {
    for (let i = 0; i < args.length; i++) {
        if (args[i] !== undefined) {
            return args[i];
        }
    }
    return undefined;
}

// <LengthUnitTextField
//     label={"Depth"}
//     useCase={"moistureSensorDepth"}
//     defaultValue={this.defaultValues.depth}
//     onChange={(e) => {
//         this.onChange({
//             depth: e,
//         });
//     }}
//     size={"small"}
// />
