import { SessionHandler } from "verdiapi";

interface ResetOwnPasswordResponse {
    username: string;
    passwordExpirationDate: string;
    password: string;
}
export interface Admin {
    username: string;
    superAdmin: boolean;
    passwordExpirationDate: string;
}

export interface WhoAmIResponse {
    accounts: Object[];
    admin: boolean;
    adminUsername: string;
    defaultPos: [number, number];
    id: string;
    user: {
        defaultPos: [number, number];
    };
}

export const getAllAdmins = async () =>
    SessionHandler.doFetch("admin/getAdmins", {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        method: "GET",
        redirect: "follow",
    })
        .then((res) => res.json())
        .catch(async (error) => {
            const messageObj = await error?.message?.json();
            throw new Error(messageObj?.message ?? "");
        });

export const createNewAdmin = async ({
    username,
    passwordExpirationDate,
}: {
    username: string;
    passwordExpirationDate: string; // date iso string
}) =>
    SessionHandler.doFetch("admin/createAdmin", {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        redirect: "follow",
        body: JSON.stringify({
            username,
            passwordExpirationDate,
        }),
    })
        .then((res) => res.json())
        .catch(async (error) => {
            const messageObj = await error?.message?.json();
            throw new Error(messageObj?.message ?? "");
        });

export const resetOwnPassword = async (): Promise<ResetOwnPasswordResponse> =>
    SessionHandler.doFetch("admin/resetPassword", {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        redirect: "follow",
    })
        .then((res) => res.json())
        .catch(async (error) => {
            const messageObj = await error?.message?.json();
            throw new Error(messageObj?.message ?? "");
        });

export const getWhoAmI = async (): Promise<WhoAmIResponse> =>
    SessionHandler.doFetch("WhoAmI", {
        headers: {
            "Content-Type": "application/json",
        },
        method: "GET",
        redirect: "follow",
    })
        .then((res) => res.json())
        .catch(async (error) => {
            const messageObj = await error?.message?.json();
            throw new Error(messageObj?.message ?? "");
        });
