import { useEffect, useState } from "react";
import { secondMs } from "verdiapi/dist/HelperFunctions";

import { ManualModeTransitionStatuses, ManualOverrideInfo } from "./types";

interface UseManualModeTransitionStatusProps {
    manualOverrideInfo: ManualOverrideInfo;
    deviceLastContact: string; // iso date string
}

// Hook to manage the state of the manual mode transition
export function useManualModeTransitionStatus({
    deviceLastContact,
    manualOverrideInfo,
}: UseManualModeTransitionStatusProps) {
    const [manualModeTransitionStatus, setManualModeTransitionStatus] = useState<ManualModeTransitionStatuses>(
        ManualModeTransitionStatuses.Sending,
    );

    // Handle transition to complete
    useEffect(() => {
        const transitionBuffer = secondMs * 10;

        // Device was contacted (recieved downlink payload) if deviceLastContact is after the dashboard's settings were updated
        const deviceContactedAfterUpdate =
            new Date(deviceLastContact).valueOf() >
            transitionBuffer + new Date(manualOverrideInfo.remoteOverrideSettingLastUpdated).valueOf();

        if (manualModeTransitionStatus === ManualModeTransitionStatuses.Sending && deviceContactedAfterUpdate) {
            setManualModeTransitionStatus(ManualModeTransitionStatuses.Confirming);
        }
    }, [deviceLastContact, manualOverrideInfo.remoteOverrideSettingLastUpdated, manualModeTransitionStatus]);

    // Handle transition to complete
    useEffect(() => {
        if (
            manualOverrideInfo.remoteOverrideSetting === manualOverrideInfo.deviceOverrideSetting &&
            manualModeTransitionStatus === ManualModeTransitionStatuses.Confirming
        ) {
            setManualModeTransitionStatus(ManualModeTransitionStatuses.Complete);
        }
    }, [
        manualOverrideInfo.deviceOverrideSetting,
        manualOverrideInfo.remoteOverrideSetting,
        manualModeTransitionStatus,
    ]);

    function resetManualModeTransitionStatus() {
        setManualModeTransitionStatus(ManualModeTransitionStatuses.Sending);
    }

    return {
        manualModeTransitionStatus,
        resetManualModeTransitionStatus,
        targetState:
            new Date(manualOverrideInfo.deviceOverrideSettingLastUpdated?.valueOf()).valueOf() >
            new Date(manualOverrideInfo.remoteOverrideSettingLastUpdated?.valueOf()).valueOf()
                ? manualOverrideInfo.deviceOverrideSetting
                : manualOverrideInfo.remoteOverrideSetting,
    };
}
