import "../HelpCSS.css";

import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "../HelpBase";
// TODO: headers for each section of the warning
// TODO: anchor for graph sensoterra date picker not anchored correctly
export function WarningFrequentResets(props) {
    return (
        <HelpBase
            Title={"Frequent Resets Warning"}
            Target={props.children}
            className={props.className}
            style={props.style}
            component={props.component}
        >
            <Typography className={"StepDetails"}>
                <b>Frequent Resets</b> is an indicator of potentially malfunctioning devices. This warning will appear
                if you magent the device on a regular basis, or could appear if the device is frequently offline
            </Typography>
            <Typography className={"StepDetails"}>
                If you are getting this error and are not frequently magentting the device, please contact us. This
                device may be malfunctioning.
            </Typography>
        </HelpBase>
    );
}
