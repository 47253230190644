import "./PerformanceOverview.scss";

import Typography from "@mui/material/Typography";
import React from "react";

import {
    FiniteStateGraphBase,
    FSGActualScheduledAndSuccessValveState,
} from "../../generic/FiniteStateGraph/FiniteStateGraph";

export function VerificationPerformanceOverview({ device, doubleValveDevice }) {
    const [getGraphStartTime] = React.useState(new Date(Date.now() - 1000 * 60 * 60 * 24 * 4));
    const [getGraphEndTime] = React.useState(new Date(Date.now() - 1000 * 60 * 2));
    return (
        <div className={"VerificationPerformanceOverview"}>
            <div className={"ValveStateLegend"}>
                <Typography variant={"h6"} className={"VerdiStateLegendTitle"} component={"span"}>
                    Valve History
                </Typography>

                <ValveStateHistoryLegend />
            </div>
            <FiniteStateGraphBase startTime={getGraphStartTime} endTime={getGraphEndTime}>
                <FSGActualScheduledAndSuccessValveState
                    startTime={getGraphStartTime}
                    endTime={getGraphEndTime}
                    relZones={doubleValveDevice ? [device.connectedZoneIDs[0]] : device.connectedZoneIDs}
                    deviceEUI={device.id}
                    valveIndex={0}
                />
            </FiniteStateGraphBase>
        </div>
    );
}
export function ValveStateHistoryLegend({
    actualStateKey = "Verdi Valve Open",
    scheduledStateKey = "Scheduled Valve Open",
}) {
    return (
        <>
            <Typography component={"span"} variant={"caption"} className={"VerdiStateLegendEntry"}>
                {actualStateKey}
            </Typography>

            <Typography component={"span"} variant={"caption"} className={"ScheduleLegendEntry"}>
                {scheduledStateKey}
            </Typography>
        </>
    );
}
