import { useSnackbar } from "notistack";
import React from "react";
import { ThirdPartyDeviceBase } from "verdiapi/dist/Models/Devices/ThirdPartyDeviceBase";
import { SDI12SensorClusterConfig } from "verditypes";

import { SelectDropdown } from "../../../../generic/SelectDropdown";

export function SDI12SensorTypeSelector({ Device }: { Device: ThirdPartyDeviceBase }) {
    const { enqueueSnackbar } = useSnackbar();
    const [currentSDI12NodeType, setCurrentSDI12NodeType] = React.useState(
        Device.deviceTypeSpecificInformation?.SDI12NodeType,
    );

    let sensorOptions = Object.values(SDI12SensorClusterConfig.SDI12SensorClusterConfigurations).map((config) => ({
        config: config,
        label: `${config.friendlyName} (${config.manufacturerName})`,
        value: config.key,
    }));

    sensorOptions.sort((a, b) =>
        `${a.config.manufacturerName} ${a.config.friendlyName}`.localeCompare(
            `${b.config.manufacturerName} ${b.config.friendlyName}`,
        ),
    );

    sensorOptions = [
        {
            label: "None",
            // @ts-ignore
            value: undefined,
            // @ts-ignore
            config: undefined,
        },
        ...sensorOptions,
    ];

    return (
        <div>
            <SelectDropdown
                value={currentSDI12NodeType}
                options={sensorOptions}
                // @ts-ignore
                onChange={(v) => {
                    setCurrentSDI12NodeType(v);
                    Device.edit({
                        deviceTypeSpecificInformation: {
                            ...Device.deviceTypeSpecificInformation,
                            SDI12NodeType: v,
                        },
                    })
                        .then(() => {
                            enqueueSnackbar("Saved SDI-12 Sensor configuration", { variant: "success" });
                            setCurrentSDI12NodeType(Device.deviceTypeSpecificInformation?.SDI12NodeType);
                        })
                        .catch(() => {
                            enqueueSnackbar("Failed to save SDI-12 sensor configuration", { variant: "error" });
                            setCurrentSDI12NodeType(Device.deviceTypeSpecificInformation?.SDI12NodeType);
                        });
                }}
            />
        </div>
    );
}
