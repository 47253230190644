import "./WarningHeader.scss";

import Typography from "@mui/material/Typography";
import React from "react";
import { WarningConfig } from "verditypes";

export default function WarningHeader(props) {
    let className = "CardWarning";
    if (!props.warnings || !Array.isArray(props.warnings) || props.warnings.length === 0) {
        return null;
    }
    /**
     * @type {WarningDescription[]}
     */
    const warnings = props.warnings;
    warnings.sort((a, b) => {
        const aScore = a.warningLevel * 10000 + a.warningTypePriority;
        const bScore = b.warningLevel * 10000 + b.warningTypePriority;
        return bScore - aScore;
    });
    const primaryWarning = { ...(warnings[0] || {}) };
    const warningConfig = WarningConfig.standardWarningDescriptions[primaryWarning.warningType] || {};

    // This is specifically to temporarily change the appearance of manual override mode. This will be removed later
    if (primaryWarning.warningType === "Manual Override Engaged") {
        primaryWarning.warningLevel = 0;
    }
    className += ` CardWarning_warningLevel--${primaryWarning.warningLevel}`;
    return (
        <div className={className}>
            <Typography variant={"h5"}>
                <Typography component={"span"} variant={"h4"} className={"WarningHeader"}>
                    {
                        // Choose a different string depending on the warning level index. So 0, 1, or 2
                        ["Info:", "Warning:", "Error:"][primaryWarning.warningLevel] || ""
                    }
                </Typography>{" "}
                {primaryWarning.warningTitle || warningConfig.alertName}
            </Typography>
            <Typography variant={"subtitle2"}>{primaryWarning.warningDetails || warningConfig.details}</Typography>
            {warningConfig.simpleFix && <Typography variant={"body2"}>Fix: {warningConfig.simpleFix}</Typography>}
        </div>
    );
}
