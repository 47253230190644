import { Box, Typography } from "@mui/material";
import { SelectDropdown } from "src/components/generic/SelectDropdown";

interface ColumnGroupSelectorProps {
    options: any;
    onChange: any;
}
export default function ColumnGroupSelector({ options, onChange }: ColumnGroupSelectorProps) {
    return (
        <Box>
            <Typography variant={"h4"}>Data Grouping</Typography>
            <Typography variant={"subtitle1"}>
                Data columns will be grouped together using the selected property.
            </Typography>
            <SelectDropdown
                formControlSx={{ mt: 8 }}
                sx={{ height: 50, width: 350 }}
                label={"Group By"}
                options={options}
                onChange={onChange}
            />
        </Box>
    );
}
