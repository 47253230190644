import "./TimeRangeSelectDropdown.scss";

import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as React from "react";

const oneDay = 1000 * 60 * 60 * 24;
export const StaticZoneGraphTimeRanges = [
    {
        name: "Day",
        startTime: new Date(Date.now() - oneDay),
        endTime: new Date(Date.now()),
    },
    {
        name: "4 days",
        startTime: new Date(Date.now() - oneDay * 4),
        endTime: new Date(Date.now()),
    },
    {
        name: "Week",
        startTime: new Date(Date.now() - oneDay * 7),
        endTime: new Date(Date.now()),
    },
    {
        name: "Month",
        startTime: new Date(Date.now() - oneDay * 30),
        endTime: new Date(Date.now()),
    },
    {
        name: "3 months",
        startTime: new Date(Date.now() - oneDay * 91),
        endTime: new Date(Date.now()),
    },
];

export default class TimeRangeSelectDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeRange: this.props.defaultTimeRange,
        };
    }

    render() {
        return (
            <FormControl className={"StaticZoneGraphTimeRangeDropdown"} size={"small"}>
                <Select
                    className={"StaticZoneGraphTimeRangeSelect"}
                    value={this.state.timeRange}
                    onChange={(e) => {
                        if (this.props.onChange) {
                            const timeRange = StaticZoneGraphTimeRanges.find((o) => o.name === e.target.value);
                            this.props.onChange(timeRange.startTime, timeRange.endTime);
                        }
                        this.setState({
                            timeRange: e.target.value,
                        });
                    }}
                >
                    {StaticZoneGraphTimeRanges.map((range) => (
                        <MenuItem value={range.name} key={range.name}>
                            {range.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}
