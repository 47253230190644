import Chip from "@mui/material/Chip";
import React from "react";

import { LightTooltip } from "../../generic/LightTooltip/LightTooltip";
import ErrorTooltipIcon from "./ErrorTooltipIcon";
import SuccessTooltipIcon from "./SuccessTooltipIcon";

export function VerificationChip(props) {
    const passedProps = { ...props };
    delete passedProps.Target;

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {props.lastConditionVerified !== undefined && (
                <LightTooltip title={props.tooltipText} {...passedProps}>
                    <Chip
                        className={props.lastConditionVerified ? "SuccessChipStyle" : "FailedChipStyle"}
                        label={props.chipLabel}
                        size={"small"}
                        variant={"outlined"}
                        icon={
                            props.lastConditionVerified ? (
                                <SuccessTooltipIcon tooltipText={"Success"} isInChip={true} />
                            ) : (
                                <ErrorTooltipIcon tooltipText={"Failed"} isInChip={true} />
                            )
                        }
                    />
                </LightTooltip>
            )}
        </>
    );
}
