import "./LengthUnitTextField.css";

import { InputLabel, Select, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { getFirstDefined } from "gardenspadejs/dist/general";
import * as React from "react";
import { EventHandler } from "verdiapi";

import ReactComponentBase from "../../../utils/ReactComponentBase";

const unitOptions = [
    {
        name: "ft",
        conversion: 0.3048,
        unitCategory: "length",
    },
    {
        name: "in",
        conversion: 0.0254,
        unitCategory: "length",
    },
    {
        name: "m",
        conversion: 1,
        unitCategory: "length",
    },
    {
        name: "cm",
        conversion: 0.01,
        unitCategory: "length",
    },
    {
        name: "LPH",
        conversion: 1,
        unitCategory: "flowRate",
    },
    {
        name: "LPM",
        conversion: 1 / 60,
        unitCategory: "flowRate",
    },
    {
        name: "GPH",
        conversion: 1 / 0.264172052,
        unitCategory: "flowRate",
    },
    {
        name: "GPM",
        conversion: 1 / (0.264172052 * 60),
        unitCategory: "flowRate",
    },
    {
        name: "Hrs",
        conversion: 1 * (60 * 60 * 1000),
        unitCategory: "time",
    },
    {
        name: "min",
        conversion: 1 * (60 * 1000),
        unitCategory: "time",
    },
    {
        name: "days",
        conversion: 1 * (60 * 60 * 1000) * 24,
        unitCategory: "time",
    },

    {
        name: "KPa",
        conversion: 1,
        unitCategory: "pressure",
    },

    {
        name: "%",
        conversion: 1,
        unitCategory: "percent",
    },
];
let unitByUseCase = {};

function updateCookie() {
    localStorage.setItem("defaultUnits", JSON.stringify(unitByUseCase));
}
let cookieLoaded = false;
function updateFromCookie() {
    cookieLoaded = true;
    try {
        const content = localStorage.getItem("defaultUnits");
        if (content) {
            const temp = JSON.parse(content);
            if (typeof temp === "object" && temp !== null) {
                unitByUseCase = temp;
            }
        }
    } catch (e) {
        console.warn(e);
    }
}

function conversionForUnit(unit) {
    return (unitOptions.find((o) => o.name === unit) || { conversion: 1 }).conversion;
}

/**
 * @deprecated Please use the NumericFieldWithUnit component
 */
export class LengthUnitTextField extends ReactComponentBase {
    unitCategory = "length";

    validUnits = unitOptions;

    constructor(props) {
        super(props);
        if (!cookieLoaded) {
            updateFromCookie();
        }
        if (this.props.unitCategory) {
            this.unitCategory = this.props.unitCategory;
        }
        this.validUnits = unitOptions.filter((unitOption) => unitOption.unitCategory === this.unitCategory);

        let startingUnitOptions = [this.props.defaultUnit, unitByUseCase[this.props.useCase], this.validUnits[0].name];
        startingUnitOptions = startingUnitOptions.filter((startingUnitOption) => {
            if (startingUnitOption === undefined) {
                return false;
            }
            // If valid units contains a unit with the name of the starting unit option.
            if (this.validUnits.map((vu) => vu.name).includes(startingUnitOption)) {
                return true;
            }
            return false;
        });
        const startingUnit = startingUnitOptions[0];
        // let startingUnit = this.props.defaultUnit;
        // if (this.props.useCase && startingUnit === undefined) {
        //     startingUnit = unitByUseCase[this.props.useCase];
        // }
        this.state = {
            curValue: this.props.value || this.props.defaultValue || "",
            unit: startingUnit || "ft",
        };
        try {
            this.meters = parseFloat(this.state.curValue) || 0;
            this.state.curValue = Math.round((this.meters / conversionForUnit(this.state.unit)) * 10000) / 10000;
        } catch (e) {
            this.meters = 0;
        }
    }

    componentDidMount() {
        if (this.props.resetEvent) {
            this.props.resetEvent.addListener((e) => {
                let newValue = getFirstDefined(e.newValue, this.props.defaultValue, 0);
                if (e && this.props.resetEventKey) {
                    newValue = getFirstDefined(e[this.props.resetEventKey], e?.newValue, this.props.defaultValue, 0);
                }
                this.meters = newValue;
                this.setState({
                    curValue: newValue / conversionForUnit(this.state.unit),
                });
            }, this.uid);
        }
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
    }

    meters = 0;

    render() {
        const passedProps = { ...this.props };
        delete passedProps.onChange;
        delete passedProps.units;
        delete passedProps.defaultUnit;
        delete passedProps.className;
        delete passedProps.defaultValue;
        delete passedProps.style;
        delete passedProps.helperTooltipText;
        delete passedProps.resetEvent;
        delete passedProps.resetEventKey;
        delete passedProps.useCase;
        delete passedProps.unitCategory;

        let renderedOptions = [...this.validUnits];
        if (this.props.units && Array.isArray(this.props.units)) {
            renderedOptions = unitOptions.filter((o) => this.props.units.includes(o.name));
        }
        return (
            <div
                style={{ display: "flex", flexDirection: "column" }}
                id={this.uid}
                className={`LengthUnitTextFieldRoot ${this.props.className}`}
            >
                <div className={"LengthUnitTextField "} style={this.props.style || {}}>
                    <TextField
                        {...passedProps}
                        value={this.props.value || this.state.curValue}
                        onChange={(e) => {
                            let newValue = e.target.value;
                            newValue = newValue.replace(/[^0-9.]/g, "");
                            this.setState({
                                curValue: newValue,
                            });
                            try {
                                this.meters = (parseFloat(newValue) || 0) * conversionForUnit(this.state.unit);
                            } catch (error) {
                                console.warn("Error converting units in length unit text field:", error);
                            }
                            if (this.props.onChange) {
                                this.props.onChange(this.meters, newValue);
                            }
                        }}
                    />
                    <FormControl>
                        <InputLabel id={`${this.uid}label`}>Unit</InputLabel>
                        <Select
                            size={passedProps.size}
                            labelId={`${this.uid}label`}
                            value={this.state.unit}
                            label={"Unit"}
                            onChange={(e) => {
                                const newUnit = e.target.value;
                                this.setState({
                                    unit: newUnit,
                                    curValue: Math.round((this.meters / conversionForUnit(newUnit)) * 1000) / 1000,
                                });
                                if (this.props.useCase) {
                                    unitByUseCase[this.props.useCase] = newUnit;
                                    updateCookie();
                                }
                            }}
                        >
                            {renderedOptions.map((o) => (
                                <MenuItem value={o.name} key={o.name}>
                                    {o.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={"LengthUnitTextFieldTooltipFont"}>{this.props.helperTooltipText}</div>
            </div>
        );
    }
}
