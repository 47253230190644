/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import React from "react";

import source from "!!raw-loader!./TagDemo.jsx";

import readme from "./README.md";
import { Tag } from "./Tag";

export function TagDemo() {
    return (
        <div>
            <Tag />
        </div>
    );
}

export default {
    component: TagDemo,
    name: "Tag",
    readme: readme,
    source: source,
};
