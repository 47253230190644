import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import React from "react";

import { LengthUnitTextField } from "../../../DeprecatedInputs/LengthUnitTextField";
import NumericTextField from "../../../DeprecatedInputs/NumericTextField";
import SensorSettingsBase from "./SensorSettingsBase";

export default class MoistureSensorSettings extends SensorSettingsBase {
    acceptableRangeUnitCategory = "percent";

    defaultAcceptableValueRange = [0, 100];

    loadSettingsFromDevice(device, sensor) {
        const values = super.loadSettingsFromDevice(device, sensor);
        values.depth = sensor.depth;
        values.depthString = null;
        values.soilCapacity = sensor.userMetadata?.soilCapacity || 1.0;
        values.soilCapacityText = Math.round((sensor.userMetadata?.soilCapacity || 1.0) * 100).toString();
        return values;
    }

    writeSettingsToDevice(values, device, sensor) {
        super.writeSettingsToDevice(values, device, sensor);
        if (values.depth !== undefined) {
            sensor.depth = values.depth;
        }
        if (!sensor.userMetadata) {
            sensor.userMetadata = {};
        }
        sensor.userMetadata.soilCapacity = values.soilCapacity;
        sensor.polynomialPostProcessing = [0, 1.0 / values.soilCapacity];
    }

    renderSupplementalSettings(values) {
        return (
            <>
                <div className={"SettingsItem"}>
                    <Typography>Depth: </Typography>
                    <LengthUnitTextField
                        label={"Depth"}
                        useCase={"moistureSensorDepth"}
                        defaultValue={values.depthString ? undefined : values.depth}
                        value={values.depthString}
                        onChange={(meters, text) => {
                            this.onChange({
                                depthString: text,
                                depth: meters,
                            });
                        }}
                        style={{ maxWidth: "10rem" }}
                    />
                </div>
                <div className={"SettingsItem"}>
                    <Typography>Soil Capacity: </Typography>
                    <NumericTextField
                        label={"Soil Capacity"}
                        value={values.soilCapacityText}
                        onChange={(capValue, text) => {
                            this.onChange({
                                soilCapacity: capValue / 100,
                                soilCapacityText: text,
                            });
                        }}
                        validate={(value) => {
                            if (value > 100) {
                                return 100;
                            }
                            if (value < 5) {
                                return 5;
                            }
                            return undefined;
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position={"end"}>%</InputAdornment>,
                        }}
                        style={{ maxWidth: "8rem" }}
                    />
                </div>
            </>
        );
    }
}
