/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import Typography from "@mui/material/Typography";
import React from "react";

// imports it's own source code
import source from "!!raw-loader!./CircleStepperDemo.jsx";

import { CircleStepper } from "./CircleStepper";
import readme from "./README.md";

function DemoComponent() {
    const [value, setValue] = React.useState(5);
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        width: "7rem",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Typography>Uncontrolled</Typography>
                    <CircleStepper
                        controlled={false}
                        defaultValue={2}
                        onChange={(v) => {
                            setValue(v);
                        }}
                    />
                </div>
                <div
                    style={{
                        width: "7rem",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Typography>Controlled</Typography>
                    <CircleStepper
                        controlled={true}
                        value={value}
                        onChange={(v) => {
                            setValue(v);
                        }}
                    />
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <Typography style={{ width: "8rem" }}> No minimum </Typography>
                <CircleStepper
                    controlled={true}
                    value={value}
                    onChange={(v) => {
                        setValue(v);
                    }}
                    horizontalView={true}
                />
            </div>
            <div style={{ display: "flex" }}>
                <Typography style={{ width: "8rem" }}> Minimum of 5 </Typography>
                <CircleStepper
                    controlled={true}
                    value={value}
                    onChange={(v) => {
                        setValue(v);
                    }}
                    horizontalView={true}
                    min={5}
                />
            </div>
            <div style={{ display: "flex" }}>
                <Typography style={{ width: "8rem" }}> Minimum of -10 </Typography>
                <CircleStepper
                    controlled={true}
                    value={value}
                    onChange={(v) => {
                        setValue(v);
                    }}
                    horizontalView={true}
                    min={-10}
                />
            </div>
        </div>
    );
}

const CircleStepperDemo = {
    component: DemoComponent,
    name: "CircleStepper",
    readme: readme,
    source: source,
};

export default CircleStepperDemo;
