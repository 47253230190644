/**
 *
 * @param {number} sand
 * @param  {number} clay
 * @returns {string}
 */
export function getSoilType(sand, clay) {
    const silt = 100.0 - sand - clay;

    if (clay >= 40) {
        if (silt >= 40) {
            return "Silty Clay";
        }
        if (sand <= 45) {
            return "Clay";
        }
    }

    if (clay >= 35 && sand > 45) {
        return "Sandy Clay";
    }

    if (clay >= 27) {
        if (sand <= 20) {
            return "Silty Clay Loam";
        }
        if (sand <= 45) {
            return "Clay Loam";
        }
    } else {
        if (silt >= 50) {
            if (clay < 12.0 && silt >= 80) {
                return "Silt";
            }

            return "Silt Loam";
        }
        if (silt >= 28 && clay >= 7 && sand <= 52) {
            return "Loam";
        }
    }

    if (clay >= 20 && sand > 45 && silt <= 28) {
        return "Sandy Clay Loam";
    }
    if (silt + 1.5 * clay < 15) {
        return "Sand";
    }
    if (silt + 2 * clay < 30) {
        return "Loamy Sand";
    }

    return "Sandy Loam";
}
