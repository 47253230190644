import "./UserAccountManager.css";

import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { SessionHandler } from "verdiapi";
import { User } from "verdiapi/dist/Models/User";

import { sleep } from "../utils/HelperFunctions";

export default class UserAccountManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userModel: undefined,
            creatingUser: false,
            editingUser: false,
            newUserInfo: { defaultPos: [0, 0], contactInfo: {} },
            loading: true,
            UsersList: [],
        };
    }

    componentDidMount() {
        let UsersList = [];
        if (SessionHandler.session && SessionHandler.session.MasterIndex) {
            if (SessionHandler.session.MasterIndex.User) {
                UsersList = [...SessionHandler.session.MasterIndex.User.all];
            } else {
                sleep(500).then(() => {
                    if (SessionHandler.session.MasterIndex.User) {
                        UsersList = [...SessionHandler.session.MasterIndex.User.all];
                    }
                    this.setState({ UsersList: UsersList });
                });
            }
            this.setState({ UsersList: UsersList });
        } else {
            SessionHandler.onSessionLoad.addListener(() => {
                if (SessionHandler.session.MasterIndex.User) {
                    UsersList = [...SessionHandler.session.MasterIndex.User.all];
                }
                this.setState({ UsersList: UsersList });
            });
            SessionHandler.loadSession();
        }
    }

    render() {
        let defaultPosition = ["", ""];
        if (this.state.newUserInfo && Array.isArray(this.state.newUserInfo.defaultPos)) {
            defaultPosition = this.state.newUserInfo.defaultPos;
        }
        return (
            <div style={{ display: "flex" }}>
                <Drawer variant={"permanent"} style={{}} className={"AccountManagerDrawer"}>
                    <div>
                        <Toolbar />
                        <List>
                            {this.state.UsersList.map((a) => (
                                <ListItem
                                    button
                                    key={`UserAccountManager${a.id}`}
                                    onClick={async () => {
                                        const newUserInfo = a.getJSON();
                                        console.info("new user info", newUserInfo);
                                        if (!newUserInfo.contactInfo) {
                                            newUserInfo.contactInfo = {};
                                        }
                                        this.setState({
                                            userModel: a,
                                            newUserInfo: newUserInfo,
                                            loading: false,
                                        });
                                    }}
                                >
                                    <ListItemIcon>{}</ListItemIcon>
                                    <ListItemText primary={a.username} secondary={a.id} />
                                </ListItem>
                            ))}
                        </List>
                        <ListItem
                            button
                            onClick={() => {
                                this.setState({
                                    creatingUser: true,
                                    editingUser: false,
                                    loading: false,
                                    newUserInfo: { defaultPos: [0, 0], contactInfo: {} },
                                });
                            }}
                        >
                            <ListItemIcon>{}</ListItemIcon>
                            <ListItemText primary={"Add User"} />
                        </ListItem>
                    </div>
                </Drawer>
                <main style={{ flexGrow: 1, marginTop: "20px" }}>
                    {this.state.loading && <div> Loading! </div>}
                    {!this.state.loading && (
                        <div>
                            <div style={{ marginTop: "20px" }}>
                                <TextField
                                    label={"Username"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.username || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.username = e.target.value || undefined;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                                <TextField
                                    label={"Password"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.password || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.password = e.target.value || undefined;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <br />
                                <Typography> Default map position </Typography>
                                <TextField
                                    label={"Lat"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={defaultPosition[0] || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.defaultPos[0] = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                                <TextField
                                    label={"Long"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={defaultPosition[1] || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.defaultPos[1] = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Typography> Contact Information </Typography>
                                <TextField
                                    label={"Email"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.contactInfo.email || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.contactInfo.email = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                                <TextField
                                    label={"Phone"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.contactInfo.phone || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.contactInfo.phone = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                                <TextField
                                    label={"other"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.contactInfo.other || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.contactInfo.other = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                                <TextField
                                    label={"other"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.contactInfo.other2 || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.contactInfo.other2 = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Typography> Things Network Info </Typography>
                                <TextField
                                    label={"Device Prefix"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.devicePrefix || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.devicePrefix = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                                <TextField
                                    label={"Application ID"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.applicationID || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.applicationID = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <TextField
                                    label={"API Key"}
                                    className={"UserManagerField UserManagerField--APIKey"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.TTI_api_key || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.TTI_api_key = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Typography> sensoterra Info </Typography>
                                <TextField
                                    label={"Sensoterra Email"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.sensoterraEmail || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.sensoterraEmail = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                                <TextField
                                    label={"Sensoterra Password"}
                                    disabled={!this.state.editingUser && !this.state.creatingUser}
                                    value={this.state.newUserInfo.sensoterraPassword || ""}
                                    onChange={(e) => {
                                        this.state.newUserInfo.sensoterraPassword = e.target.value;
                                        this.setState({ newUserInfo: this.state.newUserInfo });
                                    }}
                                />
                            </div>
                            {!this.state.editingUser && !this.state.creatingUser && (
                                <Button
                                    onClick={() => {
                                        this.setState({ creatingUser: false, editingUser: true });
                                    }}
                                >
                                    Edit User
                                </Button>
                            )}
                            {this.state.editingUser && (
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={async () => {
                                        this.setState({
                                            loading: true,
                                        });
                                        await this.state.userModel.edit(this.state.newUserInfo);
                                        const newUserInfo = this.state.userModel.getJSON();
                                        if (!newUserInfo.contactInfo) {
                                            newUserInfo.contactInfo = {};
                                        }
                                        this.setState({
                                            newUserInfo: newUserInfo,
                                            loading: false,
                                        });
                                    }}
                                >
                                    Save Edits
                                </Button>
                            )}
                            {this.state.creatingUser && (
                                <Button
                                    onClick={async () => {
                                        this.setState({
                                            loading: true,
                                        });
                                        await User.CreateNew(
                                            this.state.newUserInfo.username,
                                            this.state.newUserInfo.password,
                                            this.state.newUserInfo.defaultPosition,
                                            this.state.newUserInfo,
                                        );
                                        this.setState({
                                            loading: false,
                                            UsersList: [...SessionHandler.session.MasterIndex.User.all],
                                        });
                                    }}
                                >
                                    Create Account
                                </Button>
                            )}
                        </div>
                    )}
                </main>
            </div>
        );
    }
}
