/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import React from "react";

// imports it's own source code
import source from "!!raw-loader!./NDVIChipDemo.jsx";

import NDVIChip from "./NDVIChip";
import readme from "./README.md";

function DemoComponent() {
    return (
        <div style={{ display: "flex" }}>
            <NDVIChip areaAverageNDVI={0.2} lastNDVIReading={0.5} style={{ margin: 5 }} />
            <NDVIChip areaAverageNDVI={0.5} lastNDVIReading={0.2} style={{ margin: 5 }} />
            <NDVIChip areaAverageNDVI={0.5} lastNDVIReading={0.5} style={{ margin: 5 }} />
            <NDVIChip areaAverageNDVI={0.9} lastNDVIReading={0.9} style={{ margin: 5 }} />
        </div>
    );
}

const NDVIChipDemo = {
    component: DemoComponent,
    name: "NDVIChip",
    readme: readme,
    source: source,
};

export default NDVIChipDemo;
