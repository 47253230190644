import "../HelpCSS.css";

import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "../HelpBase";

export function WarningPoorConnection(props) {
    return (
        <HelpBase
            Title={"Poor Connection Warning"}
            Target={props.children}
            className={props.className}
            style={props.style}
            component={props.component}
        >
            <Typography className={"StepDetails"}>
                <b>Poor Connection</b> means that your Verdi device isn't able to reliably access the internet. This
                means the device may occasionally go offline, could take longer to respond when changes are made to the
                irrigation schedule, and may in rare cases fail to execute irrigations.
            </Typography>
            <Typography className={"StepDetails"}>
                To fix your device, ensure there are no large obstacles between the antenna and the gateway. If you
                contact us we can attempt to install additional gateways, move existing gateways, or adjust device
                antennas to improve performance.
            </Typography>
        </HelpBase>
    );
}
