import "../Navigation.css";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { MasterIndex } from "verdiapi";

import FocusContext from "../../../services/mapManagement/FocusContext";

export function AOISelect() {
    const { t } = useTranslation(["modules"], { keyPrefix: "navigation.drawer" });
    return (
        <FormControl variant={"filled"} style={{ zIndex: 1400 }}>
            <InputLabel id={"aoi-select"}>{t("field")}</InputLabel>
            <Select
                style={{ zIndex: 1400 }}
                className={"nav-drawer-select"}
                labelId={"aoi-select"}
                onChange={(e) => {
                    const foundAOI = MasterIndex.aoi.all.find((aoi) => aoi.id === e.target.value);
                    FocusContext.setAOI(foundAOI);
                }}
                defaultValue={""}
            >
                <MenuItem value={""}>
                    <em>{t("selectAField")}</em>
                </MenuItem>
                {MasterIndex.aoi.all &&
                    MasterIndex.aoi.all.map((aoi) => (
                        <MenuItem key={`NavBarMobileAcounts${aoi.id}`} value={aoi.id}>
                            {aoi.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
}
