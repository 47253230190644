import PendingIcon from "@mui/icons-material/Pending";
import React from "react";

import { LightTooltip } from "../../generic/LightTooltip/LightTooltip";

export default function UnverifiedTooltipIcon(props) {
    const passedProps = { ...props };
    delete passedProps.Target;
    delete passedProps.tooltipText;
    return (
        <LightTooltip title={props.tooltipText} {...passedProps}>
            {/* <IconButton > */}
            <PendingIcon
                style={{
                    width: props.subHeader ? "0.9rem" : "",
                    color: "var(--unverified-icon-main)",
                }}
            />
            {/* </IconButton> */}
        </LightTooltip>
    );
}
