import { Close } from "@mui/icons-material";
import { DialogContent, IconButton, Stack } from "@mui/material";
import React from "react";

// Simple wrapper function to provide a consistent layout for modals
export function ModalLayout({ onClickClose, children, pt = 0, sx = undefined }) {
    return (
        <DialogContent sx={sx}>
            {Boolean(onClickClose) && (
                <IconButton onClick={onClickClose} sx={{ position: "absolute", right: 8, top: 8 }}>
                    <Close sx={{ fontSize: "25px" }} />
                </IconButton>
            )}
            <Stack gap={6} pt={pt}>
                {children}
            </Stack>
        </DialogContent>
    );
}
