import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "./HelpBase";

const StepTitle = "h6";

export function ExplainingSproutVerification(props) {
    return (
        <HelpBase Title={"Verification Explained"} Target={props.children}>
            <Typography variant={"h2"}>Overview</Typography>

            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                Verification can tell you when problems occur in your irrigation system, and what those problems might
                be. Whenever your device changes it's valve state, we keep track of sensor readings, the reported valve
                state, transition timing, and other information so you can see exactly what was happening the last time
                you irrigated.
            </Typography>

            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                In the Verification section on the device card, you will see a tab for each valve state, as well either
                a ✔ or ⚠ indicating if the device passed verification. You can click on the tab to see more
                information about the problem.
            </Typography>
            <Typography className={"StepDetails HelpNote"} style={{ fontSize: 12 }}>
                <i>
                    For example: If a device passes it's "Valve Open" verification, then that means the last time the
                    device was supposed to open, it opened properly. If it failed, that means one or more problems were
                    found.
                </i>
            </Typography>

            <Typography variant={StepTitle}>Clearing failures</Typography>

            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                If a device failed one of it's verifications, the only way to clear it is to schedule an irrigation and
                have that irrigation be executed successfully. It is reccomended to schedule an irrigation that lasts at
                least 20 minutes to ensure it is able to be tracked properly.
            </Typography>

            <Typography variant={"h2"}>Different Checks</Typography>

            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                A verification passes when all of the relevant checks pass. Different checks may be enabled or disabled,
                or will apply to different types of devices. Here are some of the checks you might see:
            </Typography>

            <Typography variant={StepTitle}>Timing</Typography>

            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                The Timing check ensures the device transitioned within 5 minutes of the scheduled transition time. If
                they transitioned late or early, this check will be failed.
            </Typography>

            <Typography variant={StepTitle}>Valve State</Typography>

            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                This checks that the valve state reported by the device matches the valve state that should be active.
                This will fail if, for example, a networking issue means the device doesn't recieve a schedule.
            </Typography>
            <Typography className={"StepDetails HelpNote"} style={{ fontSize: 12 }}>
                <i>
                    It is important to note that this checks that the Verdi device sent an electrical pulse to the
                    valve. Our device cannot directly determine the actual valve state. If a device has it's wires cut,
                    or if the valve has a mechanical problem this check will not be able to detect the issue.
                </i>
            </Typography>

            <Typography variant={StepTitle}>Sensor Verification</Typography>

            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                Sensor Verification can be enabled in the settings menu of a connected sensor. This check is most often
                used in conjunction with a flow or pressure sensor to confirm that the valve is actually opening and
                closing.
            </Typography>

            <Typography className={"StepDetails"} style={{ fontSize: 12 }}>
                There, you can set the value range you expect when the valve is open and when the valve is closed. For
                example you might say you expect 20-25 LPM of flow when the valve is open. Then, if the flow is found to
                be outside that range, this check will fail.
            </Typography>
        </HelpBase>
    );
}
