import { Dialog } from "@mui/material";
import React, { createContext, useMemo, useState } from "react";
/**
 * This context defines the openModal and closeModal functions
 * For usage, see useModal hook which provide hook-based access to these functions
 */
export const ModalContext = createContext({
    openModal: (_content) => {},
    closeModal: () => {},
});

/**
 * This provider utilizes the context api to provide a context for the modal, and also
 * renders the modal itself so all components have simple access to the modal if needed
 * */
export function ModalProvider({ children }) {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState();

    const closeModal = () => {
        setOpen(false);
    };

    const openModal = (modalContent) => {
        setContent(modalContent);
        setOpen(true);
    };

    const contextValue = useMemo(
        () => ({
            openModal,
            closeModal,
        }),
        [openModal, closeModal],
    );

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
            {open && (
                <Modal onClose={closeModal} open={open}>
                    {content}
                </Modal>
            )}
        </ModalContext.Provider>
    );
}

function Modal({ children, onClose = () => {}, open }) {
    return (
        <Dialog fullWidth onClose={onClose} open={open}>
            {children}
        </Dialog>
    );
}
