import { ColumnGrouping, PerDataKeyValues } from "../types";

/* ========= Comparison Fns ============= */
interface BaseCompareFnParams {
    a: string;
    b: string;
}
interface DataTypeCompareFnParams extends BaseCompareFnParams {
    dataTypesPerDataKeys: Record<string, string>;
}
const dataTypeCompareFn = ({ a, b, dataTypesPerDataKeys }: DataTypeCompareFnParams) =>
    dataTypesPerDataKeys[a].localeCompare(dataTypesPerDataKeys[b], "en", { numeric: true });

interface SourceTypeCompareFnParams extends BaseCompareFnParams {
    sourceTypesPerDataKey: Record<string, string>;
}
const sourceTypeCompareFn = ({ a, b, sourceTypesPerDataKey }: SourceTypeCompareFnParams) =>
    sourceTypesPerDataKey[a].localeCompare(sourceTypesPerDataKey[b], "en", { numeric: true });

interface ZoneNameCompareFnParams extends BaseCompareFnParams {
    sourceZoneNamesPerDataKey: Record<string, string>;
}
const zoneNameCompareFn = ({ a, b, sourceZoneNamesPerDataKey }: ZoneNameCompareFnParams) =>
    sourceZoneNamesPerDataKey[a].localeCompare(sourceZoneNamesPerDataKey[b], "en", { numeric: true });

/* ========= Implement Sort ============= */
interface SortFunctionParams {
    a: string;
    b: string;
    dataTypesPerDataKeys: Record<string, string>;
    sourceTypesPerDataKey: Record<string, string>;
    sourceZoneNamesPerDataKey: Record<string, string>;
    key: ColumnGrouping;
}
/**
 * Compares two strings based on the sort key
 * @returns A number representing the comparison result
 */
const compareFn = ({
    a,
    b,
    sourceZoneNamesPerDataKey,
    sourceTypesPerDataKey,
    dataTypesPerDataKeys,
    key,
}: SortFunctionParams) => {
    if (key === ColumnGrouping.SOURCE_TYPE) {
        return sourceTypeCompareFn({ a, b, sourceTypesPerDataKey });
    }
    if (key === ColumnGrouping.ZONE_NAME) {
        return zoneNameCompareFn({ a, b, sourceZoneNamesPerDataKey });
    }
    if (key === ColumnGrouping.DATA_TYPE) {
        return dataTypeCompareFn({ a, b, dataTypesPerDataKeys });
    }
    return 0;
};

/**
 * Creates the sort order for the data export
 * @param groupingKey The key to group by
 * @returns The sort order
 */

// TODO Fix orderings - aside from the first item, the rest of the order is arbitrary. Make sure these are set correctly before release
const createSortOrder = (groupingKey: ColumnGrouping) => {
    if (groupingKey === ColumnGrouping.SOURCE_TYPE) {
        return [ColumnGrouping.SOURCE_TYPE, ColumnGrouping.ZONE_NAME, ColumnGrouping.DATA_TYPE];
    }
    if (groupingKey === ColumnGrouping.ZONE_NAME) {
        return [ColumnGrouping.ZONE_NAME, ColumnGrouping.SOURCE_TYPE, ColumnGrouping.DATA_TYPE];
    }
    if (groupingKey === ColumnGrouping.DATA_TYPE) {
        return [ColumnGrouping.DATA_TYPE, ColumnGrouping.ZONE_NAME, ColumnGrouping.SOURCE_TYPE];
    }
    // Default
    return [ColumnGrouping.SOURCE_TYPE, ColumnGrouping.ZONE_NAME, ColumnGrouping.DATA_TYPE];
};

interface SortProps {
    keys: string[];
    primarySortKey: ColumnGrouping;
    perDataKeyValues: PerDataKeyValues;
}
/**
 * Sorts the keys based on the primary sort key
 * @returns The sorted keys
 */
export function cascadingSort({ keys, primarySortKey, perDataKeyValues }: SortProps) {
    const { sourceTypesPerDataKey, sourceZoneNamesPerDataKey, dataTypesPerDataKeys } = perDataKeyValues;
    const sortOrder = createSortOrder(primarySortKey);
    const cpy = [...keys];
    // Sort cascading according to the sortOrder
    cpy.sort((a, b) => {
        // Call comparators in order of sortOrder and compile results
        const sortResults = sortOrder.map((sortKey) =>
            compareFn({ a, b, sourceZoneNamesPerDataKey, sourceTypesPerDataKey, dataTypesPerDataKeys, key: sortKey }),
        );

        // Use first non-zero sortResult
        const nonZeroSortResult = sortResults.find((sortRes) => sortRes !== 0);
        return nonZeroSortResult !== undefined ? nonZeroSortResult : 0;
    });

    return cpy;
}
