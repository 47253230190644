import { TAG } from "./types";

export interface TagVariant {
    backgroundColor?: string;
    textColo?: string;
    label: string;
}
export const tagVariants: Record<TAG, TagVariant> = {
    [TAG.BETA]: {
        backgroundColor: "#2dd5be",
        label: "BETA",
    },
    [TAG.NEW]: {
        backgroundColor: "#9833d2",
        label: "NEW",
    },
};
