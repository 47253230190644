import "./i18n/i18nConfig";

import React from "react";
import { createRoot } from "react-dom/client";
import HttpsRedirect from "react-https-redirect";

import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(
    <HttpsRedirect disabled={process.env.REACT_APP_ALLOWHTTP}>
        <App />
    </HttpsRedirect>,
);

// eslint-disable-next-line prefer-arrow-callback
navigator.serviceWorker.getRegistrations().then(function registerServiceWorker(registrations) {
    for (let i = 0; i < registrations.length; i++) {
        const registration = registrations[i];
        registration.unregister();
    }
});
// guide on service worker registration: https://blog.bitsrc.io/using-service-workers-with-react-27a4c5e2d1a9
// serviceWorkerRegistration.register();
