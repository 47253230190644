import "./TrendGraphBackground.scss";

import React from "react";
import { EventHandler } from "verdiapi";

import { DynamicGraphContext } from "../DynamicGraphUtility";

export default class TrendGraphYAxis extends React.Component {
    componentDidMount() {
        this.context.onActiveDataTypeChanged.addListener(() => {
            EventHandler.disposeOfAllHooksForUID(`${this.uid}--listeningToDataType`);
            if (this.context.activeDataType && this.context.staticGraphDictionary[this.context.activeDataType]) {
                this.context.staticGraphDictionary[this.context.activeDataType].onCurrentRangeChange.addListener(() => {
                    this.forceUpdate();
                }, `${this.uid}--listeningToDataType`);
            }
            this.forceUpdate();
        }, this.uid);
        if (this.context.staticGraphDictionary[this.context.activeDataType]) {
            this.context.staticGraphDictionary[this.context.activeDataType].onCurrentRangeChange.addListener(() => {
                this.forceUpdate();
            }, `${this.uid}--listeningToDataType`);
        }
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
        EventHandler.disposeOfAllHooksForUID(`${this.uid}--listeningToDataType`);
    }

    generateYAxisTicks(dataType) {
        if (!this.context.staticGraphDictionary[dataType]) {
            return [];
        }
        const bounds = this.context.staticGraphDictionary[dataType].currentRange;
        const range = Math.max(Math.abs(bounds[1] - bounds[0]), 0.000001);
        let stepSize = range / 10;
        let decimalFactor = 1;
        while (stepSize * decimalFactor < 1) {
            decimalFactor *= 10;
        }
        while (stepSize * decimalFactor > 10) {
            decimalFactor /= 10;
        }
        const bases = [10, 5];
        const deltas = bases.map((b) => Math.abs(stepSize * decimalFactor - b));
        let bestBase = 1;
        let lowestDelta = 100;
        for (let i = 0; i < bases.length; i++) {
            if (deltas[i] < lowestDelta) {
                bestBase = bases[i];
                lowestDelta = deltas[i];
            }
        }
        stepSize = bestBase / decimalFactor;

        let cursor = Math.ceil(bounds[0] / stepSize) * stepSize;
        const ticks = [];
        let drewLineLastTick = false;
        while (cursor < bounds[1]) {
            let drawLine = false;
            const lineType = "";
            if (Math.min(Math.abs(cursor - bounds[0]), Math.abs(cursor - bounds[1])) / range < 0.05) {
                drawLine = false;
            } else if (range / stepSize < 5) {
                drawLine = true;
            } else if (Math.abs(cursor * decimalFactor) % 10 === 0 && !drewLineLastTick) {
                drawLine = true;
            }
            ticks.push({
                value: cursor,
                label: this.context.staticGraphDictionary[dataType].labelForValue(cursor),
                yPercent: this.context.staticGraphDictionary[dataType].getYPercentage(cursor),
                line: drawLine,
                lineType: lineType,
            });
            cursor += stepSize;
            drewLineLastTick = drawLine;
        }
        return ticks;
    }

    render() {
        const activeAxis = this.context.activeDataType;
        const yAxisTicks = this.generateYAxisTicks(activeAxis);
        return (
            <div className={"yAxisLabelColumn"}>
                {yAxisTicks.map((tick, i) => (
                    <div
                        key={
                            // eslint-disable-next-line react/no-array-index-key
                            i
                        }
                        className={`yAxisLabel ${tick.line ? " yAxisLabel--drawLine " : " "} ${
                            tick.lineType === "top" ? " yAxisLabel--drawLine-top" : " yAxisLabel--drawLine-bottom"
                        }`}
                        style={{ bottom: `${tick.yPercent * 100}%`, position: "absolute" }}
                    >
                        {tick.label}
                    </div>
                ))}
            </div>
        );
    }
}
TrendGraphYAxis.contextType = DynamicGraphContext;
