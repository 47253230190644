import "./DeviceMarkerIcon.scss";

import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function TensiometerMarkerIcon(props) {
    return (
        <SvgIcon onClick={props.onClick} viewBox={"-4, -4, 32, 32"} {...props} className={"MarkerIcon"}>
            <path
                id={"path868"}
                d={
                    "m 23.99599,12.042984 c 0,3.309738 -1.340265,6.306441 -3.507666,8.476981 -5.738759,0.03256 -11.1701454,0.027 -16.9776666,9.9e-4 -2.1679842,-2.170618 -3.50865754,-5.16773 -3.50865754,-8.477973 0,-6.6257663 5.37123774,-11.99700348 11.99699514,-11.99700338 6.625757,0 11.996995,5.37123708 11.996995,11.99700338 z"
                }
                className={"MarkerIconRim"}
            />
            {/* <path */}
            {/*    id="path18458" */}
            {/*    style={{ */}
            {/*        fill: "#fefefd", */}
            {/*    }} */}
            {/*    d="M23.95 12.14c0 3.296-1.336 6.281-3.495 8.444-5.717.032-11.127.027-16.912 0A11.913 11.913 0 0 1 .047 12.14C.047 5.54 5.398.19 11.998.19c6.6 0 11.951 5.35 11.951 11.95z" */}
            {/*    className="MarkerIconRim" */}
            {/* /> */}
            <path
                id={"ellipse4673"}
                style={
                    {
                        // fill: "#8bc34a",
                        // strokeWidth: 0.119313,
                        // strokeLinecap: "round",
                        // strokeLinejoin: "round",
                        // strokeMiterlimit: 4,
                        // strokeDasharray: "none",
                    }
                }
                className={"MarkerIconOuterCircle"}
                d={
                    "M11.996.186C5.396.186.045 5.536.045 12.136c0 3.298 1.336 6.284 3.496 8.446 5.785.026 11.195.03 16.912-.002a11.91 11.91 0 0 0 3.494-8.443c0-6.6-5.35-11.951-11.95-11.951zm.002 2.09zm0 0zm-1.502.113zm3.004 0zm1.729 2.246a.213.213 0 0 1 .007.008.213.213 0 0 0-.007-.008zm-10.206.53h.002a9.914 9.914 0 0 0-.002 0zM3.82 6.622zM2.58 9.205zm-.393 1.924zm11.43 5.934a.213.213 0 0 1-.06.144.213.213 0 0 0 .06-.145zm-.068.152a.213.213 0 0 1-.145.06.213.213 0 0 0 .145-.06z"
                }
            />
            <path
                id={"path18458"}
                style={{ fill: "#ffffff" }}
                d={
                    "M11.998 2.275c-5.447 0-9.861 4.415-9.861 9.862 0 .429.029.85.082 1.265 2.22-.9 4.735-1.457 7.32-1.672a.213.213 0 0 0 .025.104l.813 1.473v3.755a.213.213 0 0 0 .213.213h2.814a.213.213 0 0 0 .213-.212v-3.756l.813-1.473a.213.213 0 0 0 .027-.1c2.584.22 5.095.783 7.314 1.692a9.93 9.93 0 0 0 .088-1.29c0-5.446-4.414-9.86-9.861-9.86zm-3.086 2.3h6.172a.213.213 0 0 1 .213.212V6.56h-.428l-5.744.005v.002h-.426V4.787a.213.213 0 0 1 .213-.213zm1.053 2.85h.828v5.759a.213.213 0 0 0-.016-.038l-.8-1.449-.012-.021v-4.25zm3.25 0h.814v4.25l-.013.024-.8 1.447a.213.213 0 0 0-.001.012V7.426z"
                }
                className={"MarkerIconInnerCircle"}
            />
            <ellipse id={"path824"} cx={"22"} cy={"2"} rx={"5"} ry={"5"} className={"AlertBubble"} />
        </SvgIcon>
    );
}
