import "./GraphDemoPage.scss";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Autocomplete, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { MasterIndex } from "verdiapi";
import { DataTypeSpecifierEnum } from "verditypes";

import DynamicGraph from "../components/specialized/DynamicGraph/DynamicGraph";

export default class GraphDemoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterMoistureSensorZones: true,
            selectedZone: undefined,
            displayedZonesList: [],
            displayedDevicesList: [],
            inputID: "",
            inputName: "",
            inputSensorType: "",
            inputDataType: "",
        };
        this.endTimeForGraph = new Date(Date.now());
        this.startTimeForGraph = new Date(this.endTimeForGraph - 1000 * 60 * 60 * 24 * 30);
    }

    render() {
        const zonesWithMoistureSensor = MasterIndex.zone.all.filter((zone) => {
            let doesContainMoistureSensor = false;
            for (let i = 0; i < zone.devices.length; i++) {
                if (zone.devices[i].category === "SensoterraMoisture") {
                    doesContainMoistureSensor = true;
                }
            }
            return doesContainMoistureSensor === true;
        });
        const allDataTypes = Object.keys(DataTypeSpecifierEnum);
        return (
            <div className={"GraphDemoPageContainer"}>
                <div className={"GraphDemoTitle"}>
                    <Typography variant={"h3"}>Graph Demo</Typography>
                </div>
                <Button
                    className={"fetchZonesButton"}
                    onClick={() => {
                        this.forceUpdate();
                    }}
                    variant={"contained"}
                >
                    Fetch zones
                </Button>
                <div className={"GraphDemoNewEntryContainer"}>
                    <div className={"GraphDemoZoneSelectContainer"}>
                        <div className={"GraphDemoZoneSelectColumn"}>
                            <FormControl className={"GraphDemoZoneSelect"} size={"small"}>
                                <InputLabel>Zone</InputLabel>
                                <Select
                                    label={"Zone"}
                                    onChange={(e) => {
                                        const newSelectedZone = MasterIndex.zone.all.find(
                                            (zone) => zone.dbID === e.target.value,
                                        );
                                        this.setState({
                                            selectedZone: newSelectedZone,
                                        });
                                    }}
                                >
                                    {this.state.filterMoistureSensorZones
                                        ? zonesWithMoistureSensor.map((zone) => (
                                              <MenuItem value={zone.dbID}>{zone.name}</MenuItem>
                                          ))
                                        : MasterIndex.zone.all.map((zone) => (
                                              <MenuItem value={zone.dbID}>{zone.name}</MenuItem>
                                          ))}
                                    <MenuItem value={""}>
                                        <em>None</em>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    label={"Filter only zones with moisture sensor(s)"}
                                    control={
                                        <Checkbox
                                            checked={this.state.filterMoistureSensorZones}
                                            onChange={() => {
                                                const newState = !this.state.filterMoistureSensorZones;
                                                this.setState({
                                                    filterMoistureSensorZones: newState,
                                                });
                                            }}
                                        />
                                    }
                                />
                            </FormControl>
                        </div>

                        <IconButton
                            className={"addNewEntryButton"}
                            color={"primary"}
                            onClick={() => {
                                const newZoneList = this.state.displayedZonesList;
                                if (!newZoneList.includes(this.state.selectedZone)) {
                                    newZoneList.push(this.state.selectedZone);
                                }

                                this.setState({
                                    displayedZonesList: newZoneList,
                                });
                            }}
                        >
                            <AddCircleIcon className={"addNewEntryButtonIcon"} />
                        </IconButton>
                    </div>

                    <div className={"deviceManualInputContainer"}>
                        <div className={"deviceManualInputColumn"}>
                            <TextField
                                label={"ID"}
                                variant={"outlined"}
                                size={"small"}
                                onChange={(e) => {
                                    this.setState({
                                        inputID: e.target.value,
                                    });
                                }}
                            />
                            <TextField
                                label={"Name"}
                                variant={"outlined"}
                                size={"small"}
                                onChange={(e) => {
                                    this.setState({
                                        inputName: e.target.value,
                                    });
                                }}
                            />
                            <TextField
                                label={"Sensor Type"}
                                variant={"outlined"}
                                size={"small"}
                                onChange={(e) => {
                                    this.setState({
                                        inputSensorType: e.target.value,
                                    });
                                }}
                            />
                            <Autocomplete
                                label={"Data Type"}
                                freeSolo={true}
                                options={allDataTypes}
                                variant={"outlined"}
                                size={"small"}
                                renderInput={(params) => (
                                    <TextField {...params} label={"Data Type"} variant={"outlined"} size={"small"} />
                                )}
                                onChange={(e, v) => {
                                    this.setState({
                                        inputDataType: v,
                                    });
                                }}
                            />
                            {/* <TextField */}
                            {/*    label={"Data Type"} */}
                            {/*    variant={"outlined"} */}
                            {/*    size={"small"} */}
                            {/*    onChange={(e) => { */}
                            {/*        this.setState({ */}
                            {/*            inputDataType: e.target.value, */}
                            {/*        }); */}
                            {/*    }} */}
                            {/* /> */}
                        </div>
                        <IconButton
                            className={"addNewEntryButton"}
                            color={"primary"}
                            onClick={() => {
                                const newDeviceInput = {
                                    id: this.state.inputID,
                                    name: this.state.inputName,
                                    sensorType: this.state.inputSensorType,
                                    type: this.state.inputDataType,
                                };

                                const newDeviceList = this.state.displayedDevicesList;
                                if (!newDeviceList.includes(newDeviceInput)) {
                                    newDeviceList.push(newDeviceInput);
                                }

                                this.setState({
                                    displayedDevicesList: newDeviceList,
                                });
                            }}
                        >
                            <AddCircleIcon className={"addNewEntryButtonIcon"} />
                        </IconButton>
                    </div>
                </div>

                <DynamicGraph
                    startTime={this.startTimeForGraph}
                    endTime={this.endTimeForGraph || new Date(Date.now())}
                    zoneList={this.state.displayedZonesList}
                    // sensorList={}
                    deviceList={this.state.displayedDevicesList}
                    graphDataType={"sensoterra"}
                    startingWidth={300}
                    canvasHeight={275}
                />
                <div className={"displayedListsContainer"}>
                    <div>
                        <Typography variant={"h6"}>Displayed Zones:</Typography>
                        <div>
                            {this.state.displayedZonesList.map((zone) => (
                                <Typography>{zone.name}</Typography>
                            ))}
                        </div>
                    </div>

                    <br />
                    <div>
                        <Typography variant={"h6"}>Displayed Devices:</Typography>
                        <div>
                            {this.state.displayedDevicesList.map((d) => (
                                <Typography>
                                    ({d.zone?.name}) {d.name}
                                </Typography>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
