import "./YaxisTabs.scss";

import { ListItem, ListItemIcon, ListItemText, Popover, Switch } from "@mui/material";
import List from "@mui/material/List";
import * as React from "react";
import { useContext } from "react";

import { DynamicGraphContext } from "../../DynamicGraphUtility";

export function YAxisTabs(props) {
    const [graphTypesToShow] = React.useState([]);
    const [, rerender] = React.useState(1);

    const [popoverAnchor, setPopoverAnchor] = React.useState(false);
    const [dummyVar, triggerReRender] = React.useState(false);
    const graphContext = useContext(DynamicGraphContext);
    const interTabPaddng = 3;
    const minHeightOfMoreButton = 20;

    let areAnyTabs = false;
    const dynamicGraphContext = useContext(DynamicGraphContext);
    React.useEffect(() => {
        dynamicGraphContext.onActiveDataTypeChanged.addListener(() => {
            rerender(Math.random());
        });
        dynamicGraphContext.onLineAdded.addListener(() => {
            rerender(Math.random());
        });
    }, []);
    const ownHeight = props.height;

    const relevantGraphTypes = new Set();
    dynamicGraphContext.activeSensorTypes.forEach((activeSensorType) => {
        const graphType = dynamicGraphContext.staticGraphDictionary[activeSensorType];
        if (graphType) {
            relevantGraphTypes.add(graphType.linkedGraphType || graphType);
            areAnyTabs = true;
        }
    });
    let activeGraphType = dynamicGraphContext.staticGraphDictionary[dynamicGraphContext.activeDataType];
    if (activeGraphType?.linkedGraphType) {
        activeGraphType = activeGraphType?.linkedGraphType;
    }

    relevantGraphTypes.forEach((relGraph) => {
        if (!graphTypesToShow.includes(relGraph)) {
            graphTypesToShow.push(relGraph);
        }
    });

    let hideAfterIndex = 0;
    let runningTotalHeight = 0;

    const calcHideAfterIndex = () => {
        hideAfterIndex = 0;
        runningTotalHeight = 0;
        while (
            // while there is more to add
            hideAfterIndex < graphTypesToShow.length &&
            // and adding another one wouldn't be too tall
            runningTotalHeight + graphTypesToShow[hideAfterIndex].YaxisLabelMinHeight <
                ownHeight - minHeightOfMoreButton
        ) {
            runningTotalHeight += graphTypesToShow[hideAfterIndex].YaxisLabelMinHeight + interTabPaddng;
            hideAfterIndex++;
        }
    };
    calcHideAfterIndex();

    let moreButtonVisible = hideAfterIndex < graphTypesToShow.length;

    // if the active graph type is outside the visible range of tabs, we need to move it to the front of the list
    if (activeGraphType && graphTypesToShow.indexOf(activeGraphType) >= hideAfterIndex) {
        graphTypesToShow.unshift(...graphTypesToShow.splice(graphTypesToShow.indexOf(activeGraphType), 1));
        calcHideAfterIndex();
        moreButtonVisible = hideAfterIndex < graphTypesToShow.length;
    }

    let slackHeight = ownHeight - runningTotalHeight;
    const baseHeightOfEachComp = {
        moreButton: 0,
    };

    const finalHeightOfEachComp = {
        moreButton: 0,
    };
    const growthProportions = {
        moreButton: 0,
    };
    const eachComponentVisible = {};
    let growthProportionsSum = 0;
    if (moreButtonVisible) {
        slackHeight -= minHeightOfMoreButton;
        baseHeightOfEachComp.moreButton = minHeightOfMoreButton;
    }
    const topPosOfEachComp = {
        moreButton: ownHeight - baseHeightOfEachComp.moreButton,
    };

    for (let i = 0; i < hideAfterIndex; i++) {
        const graphType = graphTypesToShow[i];
        const nameOfGraphType = graphType.sensorType;
        baseHeightOfEachComp[nameOfGraphType] = graphType.YaxisLabelMinHeight;
        eachComponentVisible[nameOfGraphType] = i < hideAfterIndex;
        const growthProp =
            activeGraphType === graphType ? graphType.YaxisLabelMinHeight * 2 : graphType.YaxisLabelMinHeight;
        growthProportions[nameOfGraphType] = growthProp;
        growthProportionsSum += growthProp;
    }
    Object.keys(baseHeightOfEachComp).forEach((nameOfGraphType) => {
        growthProportions[nameOfGraphType] /= growthProportionsSum || 1;
        finalHeightOfEachComp[nameOfGraphType] =
            baseHeightOfEachComp[nameOfGraphType] + slackHeight * growthProportions[nameOfGraphType];
    });

    const sortedGraphTypesList = [...graphTypesToShow];
    sortedGraphTypesList.sort((a, b) => {
        if (!a?.sensorType) {
            return 1;
        }
        if (!b?.sensorType) {
            return -1;
        }
        return a.sensorType.localeCompare(b.sensorType);
    });

    runningTotalHeight = 0;
    for (let i = 0; i < graphTypesToShow.length; i++) {
        const graphType = graphTypesToShow[i];
        const nameOfGraphType = graphType.sensorType;
        topPosOfEachComp[nameOfGraphType] = runningTotalHeight;
        // componentsToDisplay.push(
        //     <div
        //         key={nameOfGraphType}
        //         className={`YaxisSingleTab YaxisSingleTab--${
        //             i < hideAfterIndex ? "visible" : "hidden"
        //         } YaxisSingleTab--${graphType === activeGraphType ? "selected" : "unselected"}`}
        //         style={{
        //             top: runningTotalHeight,
        //             height: finalHeightOfEachComp[nameOfGraphType] || 0,
        //         }}
        //     >
        //         {graphType.unit}
        //     </div>
        // );
        if (i <= hideAfterIndex) {
            runningTotalHeight += finalHeightOfEachComp[nameOfGraphType] + interTabPaddng;
        }
    }
    return (
        <div className={"YaxisTabs"}>
            {sortedGraphTypesList.map((graphType) => {
                const nameOfGraphType = graphType.sensorType;
                let tabZIndex = ownHeight - (topPosOfEachComp[nameOfGraphType] || 0);
                if (graphType === activeGraphType) {
                    tabZIndex = ownHeight;
                }
                // this goes from 50 to 250
                tabZIndex = (tabZIndex / (ownHeight + 1)) * 200 + 50;
                return (
                    <div
                        onClick={() => {
                            dynamicGraphContext.activeDataType = nameOfGraphType;
                            dynamicGraphContext.onActiveDataTypeChanged.trigger();
                        }}
                        key={nameOfGraphType}
                        className={`YaxisSingleTab YaxisSingleTab--${
                            eachComponentVisible[nameOfGraphType] ? "visible" : "hidden"
                        } YaxisSingleTab--${graphType === activeGraphType ? "selected" : "unselected"}`}
                        style={{
                            top: eachComponentVisible[nameOfGraphType]
                                ? topPosOfEachComp[nameOfGraphType]
                                : ownHeight + 20,
                            // z index goes from 50 to 250
                            zIndex: tabZIndex,
                            height: finalHeightOfEachComp[nameOfGraphType] || 0,
                        }}
                    >
                        {graphType.unit || "N/A"}
                    </div>
                );
            })}
            <div
                onClick={(e) => {
                    setPopoverAnchor(e.currentTarget);
                }}
                className={`YaxisSingleTab YaxisSingleTab__more YaxisSingleTab--${
                    moreButtonVisible ? "visible" : "hidden"
                }  YaxisSingleTab--unselected`}
                style={{
                    top: ownHeight - baseHeightOfEachComp.moreButton,
                    height: finalHeightOfEachComp.moreButton,
                    zIndex: 50,
                }}
            >
                ...
            </div>
            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={() => {
                    setPopoverAnchor(null);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List dense className={"YAxisTabExpandedOptionsList"}>
                    {sortedGraphTypesList.map((graphType) => {
                        const GraphTypeIcon = graphType.icon;

                        return (
                            <ListItem
                                button
                                onClick={() => {
                                    dynamicGraphContext.activeDataType = graphType.sensorType;
                                    dynamicGraphContext.onActiveDataTypeChanged.trigger();
                                    setPopoverAnchor(null);
                                }}
                            >
                                <ListItemIcon>
                                    <GraphTypeIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <>
                                            <span className={"YAxisTabExtraSelectUnit"}>{graphType.unit || "N/A"}</span>
                                            {graphType.label || "N/A"}
                                        </>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
            {areAnyTabs && (
                <div
                    className={"YAxisTabsDecrotaivePipe"}
                    style={{
                        zIndex: 0,
                    }}
                />
            )}
            {areAnyTabs && (
                <div className={"ZoomOnYSwitch"}>
                    <Switch
                        color={"primary"}
                        checked={graphContext.zoomYToData}
                        onClick={() => {
                            graphContext.setZoomYToData(!graphContext.zoomYToData);
                            triggerReRender(!dummyVar);
                        }}
                        size={"small"}
                    />
                    <div style={{ fontSize: "8px" }}>Zoom Y</div>
                </div>
            )}
        </div>
    );
}
