import "./TextFieldAlignmentOverride.css";

import { TextField } from "@mui/material";
import * as React from "react";

export default function TextFieldAlignmentOverride(props) {
    const passedProps = { ...props };
    if (!passedProps.className) {
        passedProps.className = "";
    }
    delete passedProps.align;
    passedProps.className += ` TextFieldAlignmentOverride--${props.align || "right"}`;
    return <TextField {...passedProps} />;
}
