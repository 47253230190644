import "./VerificationIcons.scss";

import CheckIcon from "@mui/icons-material/Check";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Typography } from "@mui/material";
import React from "react";

export function VerificationSuccessCheckmark(props) {
    const className = `${props.className || ""} VerificationStatusIcon VerificationStatusIcon--successful`;
    return <CheckIcon {...props} className={className} />;
}
export function VerificationErrorCross(props) {
    const className = `${props.className || ""} VerificationStatusIcon VerificationStatusIcon--error`;
    return <PriorityHighIcon {...props} className={className} />;
}

export function VerificationPendingDots(props) {
    const className = `${props.className || ""} VerificationStatusIcon VerificationStatusIcon--pending`;
    return <MoreHorizIcon {...props} className={className} />;
}

export function VerificationInconclusiveQuestionMark(props) {
    const className = `${props.className || ""} VerificationStatusIcon VerificationStatusIcon--inconclusive`;
    return <QuestionMarkIcon {...props} className={className} />;
}

/**
 *
 * @param {VerificationStatus} status
 */
export function VerificationIconByStatus({ status, ...moreProps }) {
    if (status === "successful") {
        return VerificationSuccessCheckmark(moreProps);
    }
    if (status === "error") {
        return VerificationErrorCross(moreProps);
    }
    if (status === "pending") {
        return VerificationPendingDots(moreProps);
    }
    if (status === "inconclusive") {
        return VerificationInconclusiveQuestionMark(moreProps);
    }
    return VerificationInconclusiveQuestionMark(moreProps);
}

export function ValveOpenDroplet(props) {
    const className = `${props.className || ""} ValveStateIcon ValveStateIcon--open`;
    const text = props.valveText || "";
    return (
        // <Tooltip title={`${text || "Valve"} Open`}>
        <div className={className}>{text && <Typography>{text}</Typography>}</div>
    );
    // </Tooltip>;
    // return <WaterdropIcon {...props} className={className} />;
}
export function ValveCloseDroplet(props) {
    const className = `${props.className || ""} ValveStateIcon ValveStateIcon--close`;
    const text = props.valveText || "";
    return (
        // <Tooltip title={`${text || "Valve"} Closed`}>
        <div className={className}>{text && <Typography>{text}</Typography>}</div>
    );
    // </Tooltip>;;
    // let className = (props.className || "") + " ValveStateIcon ValveStateIcon--close";
    //
    // return <WaterdropIcon {...props} className={className} />;
}
export function ValveIcon({ open, ...props }) {
    if (open) {
        return <ValveOpenDroplet {...props} />;
    }
    return <ValveCloseDroplet {...props} />;
}
