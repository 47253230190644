import "./Login.css";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React from "react";
import { SessionHandler } from "verdiapi";

import { MUItheme } from "../styles/ColourPalette";
import { URLParams } from "../utils/URLParams";

export default class Login extends React.Component {
    password;

    username;

    async TryLogin() {
        // Extract the admin get param
        const adminParam = URLParams.getParam("admin");

        // Admin login
        if (this.username.startsWith("admin-")) {
            await SessionHandler.loginWithCredentials(this.username, this.password, true);
        }

        // Allow admin login if the admin get param is true (in case of bug with the above conditions)
        else if (adminParam === "true") {
            await SessionHandler.loginWithCredentials(this.username, this.password, true);
        }

        // User login
        else {
            await SessionHandler.loginWithCredentials(this.username, this.password, false);
        }
        this.setState({});
    }

    render() {
        let errorMessage = null;
        let error = false;
        if (SessionHandler.lastLoginMessage) {
            errorMessage = SessionHandler.lastLoginMessage;
            error = true;
        }
        return (
            <div className={"login__center"}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={MUItheme}>
                        <img
                            id={"main-logo-login"}
                            src={"https://verdi-web-assets.s3.us-east-2.amazonaws.com/logo/PNG/green_logo@3x.png"}
                            alt={"verdi"}
                        />

                        <Typography variant={"h4"} className={"LoginTitle"}>
                            Log in to Verdi
                        </Typography>

                        <TextField
                            InputProps={{
                                inputProps: {
                                    autoComplete: "username",
                                },
                            }}
                            label={"Username"}
                            onChange={(event) => {
                                this.username = event.target.value;
                            }}
                            // Allows the user to press enter to login
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.TryLogin();
                                }
                            }}
                            variant={"outlined"}
                            error={error}
                        />

                        <TextField
                            type={"password"}
                            InputProps={{
                                inputProps: {
                                    autoComplete: "current-password",
                                },
                            }}
                            onChange={(event) => {
                                this.password = event.target.value;
                            }}
                            // Allows the user to press enter to login
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.TryLogin();
                                }
                            }}
                            label={"Password"}
                            variant={"outlined"}
                            error={error}
                            helperText={errorMessage}
                        />

                        <Button
                            variant={"contained"}
                            color={"primary"}
                            className={"LoginSubmitButton"}
                            onClick={() => {
                                this.TryLogin();
                            }}
                        >
                            Log in
                        </Button>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        );
    }
}
