import { GenerateUID } from "gardenspadejs/dist/general";
import React from "react";

export class FiniteStateGraphContextClass {
    constructor() {
        this.uid = GenerateUID("FSG COntext");
    }

    uid = undefined;

    chunksByFSGElementID = {};

    startTime = new Date();

    endTime = new Date();
}
export const FiniteStateGraphContext = React.createContext();
