/* eslint-disable react-hooks/exhaustive-deps */
import "./SproutAutomationConfiguration.scss";

import SettingsIcon from "@mui/icons-material/Settings";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputLabel,
    ListItem,
    ListItemText,
    Select,
    Switch,
    Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { GenerateUID, xor } from "gardenspadejs/dist/general";
import { useSnackbar } from "notistack";
import React from "react";
import { IrrigationDevice, SessionHandler } from "verdiapi";
import {
    DataTypeConfig,
    DeviceConfig,
    DeviceType,
    DeviceVersionObject,
    doesDeviceVersionMatchSelector,
    SensorConfig,
    SensorType,
    SproutAutomationMode,
    SproutAutomationSensorSelector,
    SproutAutomationSettings,
    validateSproutAutomationSettings,
} from "verditypes";
import { SensorConfiguration } from "verditypes/dist/Configurations/SensorConfiguration";

import { NumericFieldWithUnit } from "../../generic/NumericFieldWithUnit/NumericFieldWithUnit";
import { Tag } from "../../generic/Tag/Tag";
import {
    SproutAutomatedValveValues,
    SproutAutomationValveSelection,
    useSproutAutomationValveSelection,
} from "./SproutAutomationValveSelection";

const minute = 1000 * 60;

export function SproutAutomationConfiguration({ device }: { device: IrrigationDevice }) {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogOpenKey, setDialogOpenKey] = React.useState(0);
    try {
        const availableAutomationModes = getAvailableAutomationModes(device);
        if (availableAutomationModes.length === 1) {
            return null;
        }
        // if (!SessionHandler.admin && SessionHandler.curUser !== "62381c7fa59bae000468c78c") {
        //     return null;
        // }
        if (!device.sproutAutomationSettings) {
            console.info("reseting sprout automation settings");
            device.sproutAutomationSettings = {
                automationMode: "none",
                sensorSelector: "S1",
                // @ts-ignore
                sensorType: "",
                thresholdAutomationThresholdRAW: [0, 0],
                dutyCycleAutomationTargetRAW: 0,
                dutyCycleAutomationPeriod: minute * 5,
                dutyCycleAutomationDutyCycle: 0.5,
                automationSensingPeriod: 5000,
                irrigateBelowThreshold: false,
            };
        }
        const automationSettings: SproutAutomationSettings =
            device.sproutAutomationSettings as SproutAutomationSettings;
        const automationSensorType: SensorType | null = getAutomationSensorType(
            device,
            automationSettings.sensorSelector,
        );
        const prettyPrintRawReading = (rawReading: number): string => {
            if (!automationSensorType) {
                return "N/A";
            }
            /**
             * @type {SensorConfiguration}
             */
            const config = SensorConfig.SensorConfigurations[automationSensorType] as SensorConfiguration;
            const idealSensorID = SensorConfig.getIdealSproutSensorIDForSensorType(config.key);
            if (idealSensorID === null) {
                return "N/A";
            }
            if (config && config.verdiDeviceSensorConfiguration) {
                const convertedValue =
                    config.verdiDeviceSensorConfiguration.rawDataToParsedDataConversion(rawReading, {
                        sensorID: idealSensorID,
                        dataType: config.parsedDataType,
                        rawValue: rawReading,
                    })?.[0]?.value || 0;
                return (
                    Math.round(convertedValue * 100) / 100 +
                    // @ts-ignore
                    DataTypeConfig.DataTypeConfigurations[config.parsedDataType]?.databaseUnit
                );
            }
            return "N/A";
        };

        let secondaryText = null;
        if (automationSettings.automationMode === "threshold") {
            const parsedThresholds = (automationSettings.thresholdAutomationThresholdRAW ?? [0, 0]).map((v) =>
                prettyPrintRawReading(v),
            );
            secondaryText = `Threshold : ${parsedThresholds[0]} to ${parsedThresholds[1]}`;
        }
        if (automationSettings.automationMode === "dutyCycle") {
            secondaryText = `Duty Cycle : ${prettyPrintRawReading(
                automationSettings.dutyCycleAutomationTargetRAW as number,
            )} at ${Math.round(automationSettings.dutyCycleAutomationDutyCycle as number) * 100}%`;
        }

        return (
            <>
                <ListItem
                    secondaryAction={
                        <IconButton
                            edge={"end"}
                            aria-label={"automation settings"}
                            onClick={() => {
                                setDialogOpen(true);
                            }}
                        >
                            <SettingsIcon />
                        </IconButton>
                    }
                >
                    <ListItemText
                        primary={
                            <Typography>
                                Automation - {automationSettings.automationMode === "none" ? "Disabled" : "Enabled"}
                            </Typography>
                        }
                        secondary={secondaryText}
                    />
                </ListItem>
                {/* <div className={"SproutAutomationOverview"}></div> */}
                {/* <Button>Sprout Automation</Button> */}
                <SproutAutomationConfigurationDialog
                    automationSettings={automationSettings}
                    dialogOpen={dialogOpen}
                    device={device}
                    onClose={() => {
                        setDialogOpen(false);
                        setDialogOpenKey(dialogOpenKey + 1);
                    }}
                    key={dialogOpenKey}
                />
            </>
        );
    } catch (e) {
        return (
            <div>
                <Typography>Error with automation settings, please contact support</Typography>
            </div>
        );
    }
}

/**
 *
 * @param {IrrigationDeviceBase} device
 * @param sensorSelector
 * @return {SensorType | null}
 */
function getAutomationSensorType(device: IrrigationDevice, sensorSelector: SproutAutomationSensorSelector) {
    if (sensorSelector === "S1" && device?.connectedSensors?.[0]) {
        return device.connectedSensors[0].sensorType;
    }
    if (sensorSelector === "S2" && device?.connectedSensors?.[1]) {
        return device.connectedSensors[1].sensorType;
    }
    if (sensorSelector === "TEMP") {
        return "v1onboardTemperature";
    }
    if (sensorSelector === "HUM") {
        return "v1onboardHumidity";
    }
    return null;
}

export function SproutAutomationConfigurationDialog({
    device,
    dialogOpen,
    automationSettings,
    onClose,
}: {
    device: IrrigationDevice;
    dialogOpen: boolean;
    automationSettings: SproutAutomationSettings;
    onClose: () => void;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const doneFirstRender = React.useRef(false);
    const [areChanges, setAreChanges] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [automationMode, setAutomationMode] = React.useState(automationSettings.automationMode);
    const [automationSensorSelector, setAutomationSensorSelector] = React.useState(automationSettings.sensorSelector);

    const { selectedValveOption, onChangeValveValue } = useSproutAutomationValveSelection({
        // @ts-ignore
        defaultValueArr: automationSettings.automationEnabledByValveIndex ?? SproutAutomatedValveValues.BOTH,
    });

    const [irrigateBelowRawThreshold, setIrrigateBelowRawThreshold] = React.useState(
        automationSettings.openValveWhenReadingBelowThreshold,
    );
    const automationSensorType = getAutomationSensorType(device, automationSensorSelector);

    const deviceTypeConfig = DeviceConfig.DeviceConfigurationsByType[device.type as DeviceType];
    const convertRawToHumanReadable = (rawValue: number) => {
        const config = SensorConfig.SensorConfigurations[automationSensorType as SensorType] as SensorConfiguration;
        const idealSensorID = SensorConfig.getIdealSproutSensorIDForSensorType(automationSensorType as SensorType);
        if (idealSensorID === null) {
            return 0;
        }
        if (!config) {
            return 0;
        }
        if (config && config.verdiDeviceSensorConfiguration) {
            return config.verdiDeviceSensorConfiguration.rawDataToParsedDataConversion(rawValue, {
                sensorID: idealSensorID,
                dataType: config.parsedDataType,
                rawValue: rawValue,
            })?.[0]?.value;
        }
        return 0;
    };
    const convertHumanReadableToRaw = (value: number) => {
        const config = SensorConfig.SensorConfigurations[automationSensorType as SensorType] as SensorConfiguration;
        const idealSensorID = SensorConfig.getIdealSproutSensorIDForSensorType(automationSensorType as SensorType);
        if (idealSensorID === null) {
            return 0;
        }
        if (!config) {
            return 0;
        }
        if (
            config &&
            config.verdiDeviceSensorConfiguration &&
            config.verdiDeviceSensorConfiguration.parsedDataToRawDataConversion
        ) {
            // @ts-ignore
            return config.verdiDeviceSensorConfiguration.parsedDataToRawDataConversion(value, {
                sensorID: idealSensorID,
                dataType: config.parsedDataType,
            });
        }
        return 0;
    };
    // this takes an arbitrary human-readable value and converts it to something that can be represented in 8 bits
    const snapHumanReadableValueToValidRawValue = (value: number) => {
        const config = SensorConfig.SensorConfigurations[automationSensorType as SensorType] as SensorConfiguration;
        const idealSensorID = SensorConfig.getIdealSproutSensorIDForSensorType(automationSensorType as SensorType);
        if (idealSensorID === null) {
            return 0;
        }
        if (!config) {
            return 0;
        }
        const sensorIDConfig = SensorConfig.sproutSensorIDTable[idealSensorID];
        if (
            !config ||
            !config.verdiDeviceSensorConfiguration?.rawDataToParsedDataConversion ||
            !config.verdiDeviceSensorConfiguration?.parsedDataToRawDataConversion
        ) {
            return 0;
        }
        const max = sensorIDConfig.maxValue ?? 1;
        const min = sensorIDConfig.minValue ?? 0;
        const perfectRawValue = convertHumanReadableToRaw(value);
        const normalizedRawValue = (perfectRawValue - min) / (max - min);
        const snappedRawValue = (Math.round(normalizedRawValue * 2 ** 8) / 2 ** 8) * (max - min) + min;
        const newReadableValue = convertRawToHumanReadable(snappedRawValue);
        console.info(
            "automation sensor type",
            automationSensorType,
            "orignal:",
            value,
            "perfect raw:",
            perfectRawValue,
            "snapped raw:",
            snappedRawValue,
            "new readable:",
            newReadableValue,
        );
        return newReadableValue;
    };

    const [thresholdAutomationThreshold, setThresholdAutomationThreshold] = React.useState(
        (automationSettings.thresholdAutomationThresholdRAW ?? [0, 0]).map((raw: number) =>
            convertRawToHumanReadable(raw),
        ),
    );
    console.info(
        "automation sensor type:",
        automationSensorType,
        "\nraw thresholds:",
        automationSettings.thresholdAutomationThresholdRAW?.toString(),
        "\nthreshold state",
        thresholdAutomationThreshold,
    );
    const [thresholdInvertedError, setThresholdInvertedError] = React.useState(
        thresholdAutomationThreshold[0] > thresholdAutomationThreshold[1],
    );
    const [dutyCycleAutomationTarget, setDutyCycleAutomationTarget] = React.useState(
        convertRawToHumanReadable(automationSettings.dutyCycleAutomationTargetRAW ?? 0),
    );
    const [dutyCycleAutomationPeriod, setDutyCycleAutomationPeriod] = React.useState(
        automationSettings.dutyCycleAutomationPeriod,
    );
    const [dutyCycleAutomationDutyCycle, setDutyCycleAutomationDutyCycle] = React.useState(
        automationSettings.dutyCycleAutomationDutyCycle,
    );

    const availableAutomationModes = getAvailableAutomationModes(device);
    const uid = React.useMemo(() => GenerateUID("automationSettingsPanel"), []);
    // when the automation mode changes, reset the sensor
    React.useEffect(() => {
        if (doneFirstRender.current) {
            if (automationMode === "threshold" && ["TEMP", "HUM"].includes(automationSensorSelector)) {
                setAutomationSensorSelector("S1");
            }
        }
    }, [automationMode]);

    // when the sensor changes, reset the thresholds
    React.useEffect(() => {
        if (doneFirstRender.current) {
            const config = SensorConfig.SensorConfigurations[automationSensorType as SensorType] as SensorConfiguration;
            setThresholdAutomationThreshold([0, 0]);
            setDutyCycleAutomationTarget(0);
            setIrrigateBelowRawThreshold(
                xor(
                    config.openValveIncreasesDatabaseValue,
                    !config.verdiDeviceSensorConfiguration?.rawAndParsedValuesIncreaseTogether,
                ),
            );
        }
    }, [automationSensorSelector]);

    const sensorTypeBySensorSelector = {
        TEMP: "v1onboardTemperature",
        HUM: "v1onboardHumidity",
        S1: device?.connectedSensors?.[0]?.sensorType,
        S2: device?.connectedSensors?.[1]?.sensorType,
    };

    // @ts-ignore
    const selectedSensorType = sensorTypeBySensorSelector[automationSensorSelector] || false;

    let isValid = true;
    let errorText = "valid automation settings";
    if (!availableAutomationModes.includes(automationMode)) {
        isValid = false;
        errorText = "Automation mode not supported";
    }
    if (automationMode === "threshold") {
        if (["TEMP", "HUM"].includes(automationSensorSelector)) {
            isValid = false;
            errorText = "Temperature and humidity sensors not available for threshold automation";
        }
        if (thresholdAutomationThreshold[0] > thresholdAutomationThreshold[1]) {
            isValid = false;
            errorText = "Automation minimum is greater than maximum";
        }
        if (!selectedSensorType || !checkSensorTypeSupportsThresholdAutomation(selectedSensorType as SensorType)) {
            isValid = false;
            errorText = "Sensor does not support this kind of automation";
        }
    }
    if (automationMode === "dutyCycle") {
        // @ts-ignore
        if (!checkSensorTypeSupportsThresholdAutomation(sensorTypeBySensorSelector[automationSensorSelector])) {
            isValid = false;
            errorText = "Sensor does not support this kind of automation";
        }
        // @ts-ignore
        if (dutyCycleAutomationDutyCycle > 1 || dutyCycleAutomationDutyCycle < 0) {
            isValid = false;
            errorText = "Duty cycle must be between 0% and 100%";
        }
    }
    let irrigatingBelowThreshold = irrigateBelowRawThreshold;
    if (SensorConfig.SensorConfigurations[automationSensorType as SensorType]) {
        const sensorConfig = SensorConfig.SensorConfigurations[automationSensorType as SensorType];
        irrigatingBelowThreshold = xor(
            irrigateBelowRawThreshold,
            !sensorConfig.verdiDeviceSensorConfiguration?.rawAndParsedValuesIncreaseTogether,
        );
    }
    const newAutomationSettingsObject = {
        automationMode: automationMode,
        sensorSelector: automationSensorSelector,
        sensorType: selectedSensorType,
        openValveWhenReadingBelowThreshold: irrigatingBelowThreshold,
        thresholdAutomationThresholdRAW: React.useMemo(
            () => thresholdAutomationThreshold.map((v: number) => convertHumanReadableToRaw(v)),
            [(thresholdAutomationThreshold || []).join("||")],
        ),
        dutyCycleAutomationTargetRAW: React.useMemo(
            () => convertHumanReadableToRaw(dutyCycleAutomationTarget),
            [dutyCycleAutomationTarget],
        ),
        dutyCycleAutomationPeriod: dutyCycleAutomationPeriod,
        dutyCycleAutomationDutyCycle: dutyCycleAutomationDutyCycle,
        automationSensingPeriod: 1000 * 60 * 5,
        automationEnabledByValveIndex: [...selectedValveOption.asArray],
    };
    React.useEffect(() => {
        if (doneFirstRender.current) {
            setAreChanges(true);
        }
    }, [JSON.stringify(newAutomationSettingsObject)]);
    if (isValid) {
        // @ts-ignore
        const objectValidation = validateSproutAutomationSettings(newAutomationSettingsObject);
        if (!objectValidation.sproutAutomationSettingsValid) {
            isValid = false;
            errorText = objectValidation.reason ?? errorText;
        }
    }
    React.useEffect(() => {
        setTimeout(() => {
            doneFirstRender.current = true;
        }, 200);
    }, []);
    // if it's saving, say saving. If there are changes, say save, if there are no changes, say saved
    let saveText;
    if (saving) {
        saveText = "Saving...";
    } else if (areChanges) {
        saveText = "Save";
    } else {
        saveText = "Saved";
    }
    // @ts-ignore
    return (
        <Dialog fullWidth={true} maxWidth={"md"} open={dialogOpen} onClose={onClose}>
            <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                Sprout Automation
                <div style={{ marginLeft: "5px" }}>
                    <Tag />
                </div>
            </DialogTitle>

            <DialogContent className={"SproutAutomationConfigWindow"}>
                <Typography>
                    This is an beta feature, please be careful using this feature and report any issues to our team.
                </Typography>
                <div className={"Select Automation Mode"}>
                    <FormControl fullWidth>
                        <InputLabel id={`modeLabel${uid}`}>Automation Mode</InputLabel>
                        <Select
                            labelId={`modeLabel${uid}`}
                            value={automationMode}
                            label={"Automation Mode"}
                            onChange={(e) => {
                                setAutomationMode(e.target.value as SproutAutomationMode);
                            }}
                        >
                            {availableAutomationModes.map((availableAutomationMode) => (
                                <MenuItem key={availableAutomationMode} value={availableAutomationMode}>
                                    {availableAutomationMode}
                                </MenuItem>
                            ))}
                            {!availableAutomationModes.includes(automationMode) && (
                                <MenuItem key={automationMode} value={automationMode}>
                                    {automationMode} - NOT AVAILABLE
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>

                {automationMode !== "none" && (
                    <div className={"AutomationSettings AutomationSettings--threshold"}>
                        <div className={"SelectAutomationSensor"}>
                            <FormControl fullWidth>
                                <InputLabel id={`sensorLabel${uid}`}>Sensor</InputLabel>
                                <Select
                                    labelId={`sensorLabel${uid}`}
                                    value={automationSensorSelector}
                                    label={"Sensor"}
                                    onChange={(e) => {
                                        setAutomationSensorSelector(e.target.value as SproutAutomationSensorSelector);
                                    }}
                                >
                                    {automationMode === "dutyCycle" && <MenuItem value={"TEMP"}>Temperature</MenuItem>}
                                    {automationMode === "dutyCycle" && <MenuItem value={"HUM"}>Humidity</MenuItem>}
                                    {(automationSensorSelector === "S1" ||
                                        checkSensorSupportsThresholdAutomation(device?.connectedSensors?.[0])) && (
                                        <MenuItem value={"S1"}>
                                            {SensorConfig.SensorConfigurations[
                                                sensorTypeBySensorSelector.S1 as SensorType
                                            ]?.friendlyName || "DISCONNECTED!"}
                                        </MenuItem>
                                    )}
                                    {(automationSensorSelector === "S2" ||
                                        checkSensorSupportsThresholdAutomation(device?.connectedSensors?.[1])) && (
                                        <MenuItem value={"S2"}>
                                            {SensorConfig.SensorConfigurations[
                                                sensorTypeBySensorSelector.S2 as SensorType
                                            ]?.friendlyName || "DISCONNECTED!"}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        {SessionHandler.admin && deviceTypeConfig?.valveCount > 1 && (
                            <SproutAutomationValveSelection
                                className={"SelectAutomationValves"}
                                value={selectedValveOption.value}
                                onChange={onChangeValveValue}
                            />
                        )}
                        {automationMode === "threshold" && selectedSensorType && (
                            // @ts-ignore
                            <NumericFieldWithUnit
                                key={selectedSensorType}
                                sensorType={selectedSensorType}
                                isRange={true}
                                value={thresholdAutomationThreshold}
                                label={"Target"}
                                error={thresholdInvertedError ? "Min must be smaller than max!" : false}
                                onChange={(e: { value: number[] }) => {
                                    setThresholdAutomationThreshold(e.value);
                                    if (
                                        thresholdInvertedError &&
                                        thresholdAutomationThreshold[0] < thresholdAutomationThreshold[1]
                                    ) {
                                        setThresholdInvertedError(false);
                                    }
                                }}
                                onBlur={() => {
                                    const newThreshold = [
                                        snapHumanReadableValueToValidRawValue(thresholdAutomationThreshold[0]),
                                        snapHumanReadableValueToValidRawValue(thresholdAutomationThreshold[1]),
                                    ];

                                    setThresholdAutomationThreshold(newThreshold);
                                    setThresholdInvertedError(newThreshold[0] > newThreshold[1]);
                                }}
                            />
                        )}
                        {automationMode === "dutyCycle" && selectedSensorType && (
                            <>
                                {/* @ts-ignore */}
                                <NumericFieldWithUnit
                                    key={selectedSensorType}
                                    sensorType={selectedSensorType}
                                    isRange={false}
                                    value={dutyCycleAutomationTarget}
                                    label={"Target"}
                                    onChange={(e: { value: number }) => {
                                        setDutyCycleAutomationTarget(e.value);
                                    }}
                                    onBlur={() => {
                                        const newDutyCycleTarget =
                                            snapHumanReadableValueToValidRawValue(dutyCycleAutomationTarget);
                                        setDutyCycleAutomationTarget(newDutyCycleTarget);
                                    }}
                                />

                                <div className={"DutyCycleSettings"}>
                                    <FormControl>
                                        <InputLabel id={`dutyCyclePeriod${uid}`}>Duty Cycle Period</InputLabel>
                                        <Select
                                            labelId={`dutyCyclePeriod${uid}`}
                                            value={dutyCycleAutomationPeriod}
                                            label={"Duty Cycle Period"}
                                            onChange={(e) => {
                                                setDutyCycleAutomationPeriod(e.target.value as number);
                                            }}
                                        >
                                            {[5, 10, 15, 20, 30, 40, 50, 60, 90].map((dutyCycleMinutes) => {
                                                const dutyCyclePeriod = dutyCycleMinutes * minute;
                                                return (
                                                    <MenuItem key={dutyCyclePeriod} value={dutyCyclePeriod}>
                                                        {dutyCycleMinutes} minutes
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    {/* @ts-ignore */}
                                    <NumericFieldWithUnit
                                        databaseUnit={"m"}
                                        alternateUnits={["cm"]}
                                        unitOverrideString={"%"}
                                        isRange={false}
                                        value={dutyCycleAutomationDutyCycle}
                                        onChange={(e: { value: number }) => {
                                            setDutyCycleAutomationDutyCycle(e.value);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={irrigatingBelowThreshold}
                                    onClick={() => {
                                        setIrrigateBelowRawThreshold(!irrigateBelowRawThreshold);
                                    }}
                                />
                            }
                            label={"Irrigate Below Threshold"}
                        />
                    </div>
                )}

                <div>
                    {!isValid && (
                        <Typography variant={"caption"} className={"ErrorText"}>
                            Invalid Settings: {errorText}
                        </Typography>
                    )}
                </div>
                <Button
                    onClick={() => {
                        if (automationMode === "threshold") {
                            const newThreshold = [
                                convertRawToHumanReadable(convertHumanReadableToRaw(thresholdAutomationThreshold[0])),
                                convertRawToHumanReadable(convertHumanReadableToRaw(thresholdAutomationThreshold[1])),
                            ];
                            setThresholdAutomationThreshold(newThreshold);
                        }
                        if (automationMode === "dutyCycle") {
                            const newDutyCycleTarget = convertRawToHumanReadable(
                                convertHumanReadableToRaw(dutyCycleAutomationTarget),
                            );
                            setDutyCycleAutomationTarget(newDutyCycleTarget);
                        }
                    }}
                >
                    Validate
                </Button>
                <Button
                    disabled={!isValid || saving || !areChanges}
                    onClick={() => {
                        setSaving(true);
                        device
                            .edit({ sproutAutomationSettings: newAutomationSettingsObject })
                            .then(() => {
                                enqueueSnackbar("Saved automation settings!", {
                                    variant: "success",
                                });
                                setAreChanges(false);

                                setSaving(false);
                            })
                            .catch(() => {
                                enqueueSnackbar("Could not save automation configuration", {
                                    variant: "error",
                                });
                                setSaving(false);
                            });
                    }}
                >
                    {saveText}
                </Button>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                >
                    {areChanges ? "Cancel" : "Close"}
                </Button>
            </DialogContent>
        </Dialog>
    );
}

/**
 *
 * @param {IrrigationDeviceBase} device
 */
function getAvailableAutomationModes(device: IrrigationDevice) {
    const availableAutomationModes = ["none"];
    const deviceTypeConfig = DeviceConfig.DeviceConfigurationsByType[device.type as DeviceType];
    if (!deviceTypeConfig) {
        return availableAutomationModes;
    }
    if (
        doesDeviceVersionMatchSelector(
            device.deviceVersionInfo as DeviceVersionObject,
            "(3.micro.1+.*.3+) (3.block.*.*.7+)",
        ) &&
        Array.isArray(device.connectedSensors) &&
        device.connectedSensors.some((cs) => cs && checkSensorSupportsThresholdAutomation(cs))
    ) {
        availableAutomationModes.push("threshold");
    }
    if (
        doesDeviceVersionMatchSelector(
            device.deviceVersionInfo as DeviceVersionObject,
            "(3.micro.1+.*.3+) (3.block.*.*.9+)",
        ) &&
        Array.isArray(device.connectedSensors) &&
        device.connectedSensors.some((cs) => Boolean(cs))
    ) {
        availableAutomationModes.push("dutyCycle");
    }
    return availableAutomationModes;
}

function checkSensorSupportsThresholdAutomation(sensor: any & { sensorType: SensorType }) {
    const sensorType = sensor?.sensorType;
    return checkSensorTypeSupportsThresholdAutomation(sensorType);
}

function checkSensorTypeSupportsThresholdAutomation(sensorType: SensorType) {
    const sensorConfig = SensorConfig.SensorConfigurations[sensorType as SensorType] as SensorConfiguration;
    if (
        sensorConfig &&
        sensorConfig.verdiDeviceSensorConfiguration &&
        sensorConfig.verdiDeviceSensorConfiguration.parsedDataToRawDataConversion
    ) {
        return true;
    }
    return false;
}
