import { EventHandler } from "verdiapi";

import SensorSettingsBase from "./SensorSettingsBase";

export default class FlowMeterSensorSettings extends SensorSettingsBase {
    acceptableRangeUnitCategory = "flowRate";

    defaultAcceptableValueRange = [0, 100];

    loadSettingsFromDevice(device, sensor) {
        const values = super.loadSettingsFromDevice(device, sensor);
        values.depth = sensor.depth;
        return values;
    }

    writeSettingsToDevice(values, device, sensor) {
        super.writeSettingsToDevice(values, device, sensor);
        if (values.depth !== undefined) {
            sensor.depth = values.depth;
        }
        if (!sensor.userMetadata) {
            sensor.userMetadata = {};
        }
    }

    resetThresholdField = new EventHandler();
}
