import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import SignalCellular1BarIcon from "@mui/icons-material/SignalCellular1Bar";
import SignalCellular2BarIcon from "@mui/icons-material/SignalCellular2Bar";
import SignalCellular3BarIcon from "@mui/icons-material/SignalCellular3Bar";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import SignalCellularConnectedNoInternet0BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { GenerateApproximateTime } from "gardenspadejs/dist/dateHelpers";
import { getFirstDefined, xor } from "gardenspadejs/dist/general";
import React from "react";
import { withTranslation } from "react-i18next";
import * as VerdiAPI from "verdiapi";
import { GenerateUID } from "verdiapi/dist/HelperFunctions";

import { LightTooltip } from "../../generic/LightTooltip/LightTooltip";

class SignalStrengthStatusIcon extends React.Component {
    lastContact;

    packetLossDown;

    packetLossUp;

    interval = undefined;

    constructor(props) {
        super(props);
        this.packetLossUp = getFirstDefined(this.props.packetLossUp, this.props.packetLoss);
        this.packetLossDown = getFirstDefined(this.props.packetLossDown, this.props.packetLoss);
        if (xor(this.packetLossUp === undefined, this.packetLossDown === undefined)) {
            throw new Error("Must define either both packetLossUP and PacketLossDown or just define packet loss");
        }
        if ((this.packetLossUp === undefined || this.packetLossUp === null) && this.props.Target) {
            if (
                this.props.Target.sproutNetworkInfo &&
                this.props.Target.sproutNetworkInfo.uplinkPacketLoss !== undefined
            ) {
                this.packetLossUp = this.props.Target.sproutNetworkInfo.uplinkPacketLoss;
                this.packetLossDown = this.props.Target.sproutNetworkInfo.downlinkPacketLoss;
            } else if (this.props.Target.saplingNetworkInfo) {
                this.packetLossUp = this.props.Target.saplingNetworkInfo?.uplinkPacketLoss;
                this.packetLossDown = this.props.Target.saplingNetworkInfo?.downlinkPacketLoss;
            } else if (this.props.Target.device_stats) {
                this.packetLossDown = this.props.Target.device_stats.downlinkPacketLossRatio;
                this.packetLossUp = this.props.Target.device_stats.uplinkPacketLossRatio;
            }
        }
        this.uid = GenerateUID("SignalStrengthIcon");
        if (this.props.Target) {
            this.updateFromTarget();
        }
    }

    componentDidMount() {
        if (this.props.Target) {
            this.props.Target.onChange.addListener(() => {
                this.updateFromTarget();
                this.forceUpdate();
            }, this.uid);
        }
        this.interval = setInterval(
            () => {
                this.forceUpdate();
            },
            20000 + Math.random() * 2000,
        );
    }

    componentWillUnmount() {
        if (this.props.Target) {
            VerdiAPI.EventHandler.disposeOfAllHooksForUID(this.uid);
        }
        clearInterval(this.interval);
    }

    getPacketLossResults() {
        const { t } = this.props;
        const results = {
            text: "",
            score: -1,
            description: t("description.noData"),
            icon: DeviceUnknownIcon,
            color: "#adadad",
            upError: this.packetLossUp < 0.5,
            downError: this.packetLossDown < 0.5,
        };
        if (this.props.rssi && !this.lastContact && !this.packetLossDown && !this.packetLossUp) {
            const absRSSI = Math.abs(this.props.rssi);
            results.text = `${this.props.rssi}dBA`;
            if (absRSSI > 115) {
                results.color = "#c62828";
                results.score = 0;
                results.description = t("veryPoor", { ns: "common", keyPrefix: "status" });
                results.icon = SignalCellularConnectedNoInternet0BarIcon;
            } else if (absRSSI > 100) {
                results.color = "#e65100";
                results.score = 1;
                results.description = t("poor", { ns: "common", keyPrefix: "status" });
                results.icon = SignalCellular1BarIcon;
            } else if (absRSSI > 80) {
                results.color = "#e66119";
                results.score = 2;
                results.description = t("ok", { ns: "common", keyPrefix: "status" });
                results.icon = SignalCellular2BarIcon;
            } else if (absRSSI > 70) {
                results.color = "#ffa000";
                results.score = 3;
                results.description = t("good", { ns: "common", keyPrefix: "status" });
                results.icon = SignalCellular3BarIcon;
            } else {
                results.color = "#43a047";
                results.score = 4;
                results.description = t("excellent", { ns: "common", keyPrefix: "status" });
                results.icon = SignalCellular4BarIcon;
            }
            return results;
        }

        let packetLoss = (this.packetLossUp + this.packetLossDown) / 2;
        if (
            this.packetLossDown > 0.4 ||
            this.packetLossDown > 0.4 ||
            (this.packetLossDown === 0 && this.packetLossUp > 0.2)
        ) {
            packetLoss = Math.max(this.packetLossDown, this.packetLossUp);
        }

        if (!packetLoss) {
            return {
                text: "?",
                score: -1,
                description: t("description.noData"),
                icon: DeviceUnknownIcon,
                color: "#adadad",
            };
        }
        results.text = `${(packetLoss * 100).toPrecision(2)}%`;

        if (packetLoss * 100 > 99) {
            results.text = "100%";
        }

        if (this.lastContact && Date.now() - this.lastContact.valueOf() > 1000 * 60 * 60 * 4) {
            results.score = 0;
            results.description = t("offline", { ns: "common", keyPrefix: "states" });
            results.icon = DeviceUnknownIcon;
            results.color = "#c62828";
        } else if (packetLoss > 0.8) {
            results.color = "#c62828";
            results.score = 0;
            results.description = t("veryPoor", { ns: "common", keyPrefix: "status" });
            results.icon = SignalCellularConnectedNoInternet0BarIcon;
        } else if (packetLoss > 0.4) {
            results.color = "#e65100";
            results.score = 1;
            results.description = t("poor", { ns: "common", keyPrefix: "status" });
            results.icon = SignalCellular1BarIcon;
        } else if (packetLoss > 0.28) {
            results.color = "#e66119";
            results.score = 2;
            results.description = t("ok", { ns: "common", keyPrefix: "status" });
            results.icon = SignalCellular2BarIcon;
        } else if (packetLoss > 0.15) {
            results.color = "#ffa000";
            results.score = 3;
            results.description = t("good", { ns: "common", keyPrefix: "status" });
            results.icon = SignalCellular3BarIcon;
        } else if (packetLoss > 0.000001) {
            results.color = "#43a047";
            results.score = 4;
            results.description = t("excellent", { ns: "common", keyPrefix: "status" });
            results.icon = SignalCellular4BarIcon;
        }
        return results;
    }

    updateFromTarget() {
        if (this.props.Target) {
            if (this.props.Target.saplingNetworkInfo && this.props.Target.saplingNetworkInfo.lastContact) {
                const hopefulDate = new Date(this.props.Target.saplingNetworkInfo.lastContact);
                if (Number.isNaN(hopefulDate?.valueOf())) {
                    console.warn(`Last contact ${this.props.Target.saplingNetworkInfo.lastContact} not a date`);
                } else {
                    this.lastContact = hopefulDate;
                }
            } else if (this.props.Target.sproutNetworkInfo && this.props.Target.sproutNetworkInfo.lastContact) {
                const hopefulDate = new Date(this.props.Target.sproutNetworkInfo.lastContact);
                if (Number.isNaN(hopefulDate?.valueOf())) {
                    console.warn(`Last contact ${this.props.Target.sproutNetworkInfo.lastContact} not a date`);
                } else {
                    this.lastContact = hopefulDate;
                }
            }
        }
    }

    render() {
        const { t } = this.props;
        const packetLossResults = this.getPacketLossResults();

        // eslint-disable-next-line no-unused-vars
        const { t: _, tReady, showDetails, ...propsToPass } = this.props;
        const passedProps = { ...propsToPass };
        delete passedProps.listItem;
        delete passedProps.Target;
        delete passedProps.packetLossUp;
        delete passedProps.packetLossDown;
        delete passedProps.packetLoss;

        let secondaryText = packetLossResults.description;
        if (VerdiAPI.SessionHandler.admin) {
            secondaryText += ` ${Math.round(this.packetLossUp * 100)}% ↑ | ↓ ${Math.round(this.packetLossDown * 100)}%`;
        }
        let lastContactText = "";
        if (this.lastContact) {
            lastContactText = GenerateApproximateTime(this.lastContact, new Date(Date.now()), false, true);
        }
        if (this.props.listItem) {
            return (
                <ListItem {...passedProps}>
                    <ListItemIcon>
                        <packetLossResults.icon style={{ color: packetLossResults.color }} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <>
                                {t("labels.signalStrength")}
                                <span style={{ color: "rgba(0,0,0,0.58)", fontWeight: "300" }}>
                                    {` ${lastContactText}`}
                                </span>
                            </>
                        }
                        secondary={secondaryText}
                    />
                </ListItem>
            );
        }
        if (showDetails) {
            return (
                <div {...passedProps}>
                    <LightTooltip title={secondaryText}>
                        <IconButton>
                            <packetLossResults.icon style={{ color: packetLossResults.color }} />
                        </IconButton>
                    </LightTooltip>
                    <Typography variant={"caption"}>{packetLossResults?.description || "N/A"}</Typography>
                    <Typography variant={"caption"}>{lastContactText}</Typography>
                </div>
            );
        }
        return (
            <LightTooltip title={secondaryText}>
                <IconButton {...passedProps}>
                    <packetLossResults.icon style={{ color: packetLossResults.color }} />
                </IconButton>
            </LightTooltip>
        );
    }
}

export default withTranslation(["icons", "common"], { keyPrefix: "statusIcons.signalStrengthStatusIcon" })(
    SignalStrengthStatusIcon,
);
