/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import { FormControlLabel, Switch } from "@mui/material";
import { sleep } from "gardenspadejs/dist/general";
import React from "react";

// imports it's own source code
import source from "!!raw-loader!./AsyncTaskButtonDemo.jsx";

import AsyncTaskButton from "./AsyncTaskButton";
import readme from "./README.md";

function DemoComponent() {
    const [shouldFail, setShouldFail] = React.useState(false);
    const [isActionAllowed, setIsActionAllowed] = React.useState(false);
    const [needsConfirmation, setNeedsConfirmation] = React.useState(false);
    return (
        <div>
            <div className={"AsyncTaskResults"}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={shouldFail}
                            onClick={() => {
                                setShouldFail((v) => !v);
                            }}
                        />
                    }
                    label={"Should Fail:"}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={isActionAllowed}
                            onClick={() => {
                                setIsActionAllowed((v) => !v);
                            }}
                        />
                    }
                    label={"Is Available:"}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={needsConfirmation}
                            onClick={() => {
                                setNeedsConfirmation((v) => !v);
                            }}
                        />
                    }
                    label={"Needs Confirmation:"}
                />
            </div>
            <AsyncTaskButton
                buttonType={"save"}
                onStart={async (e) => {
                    await sleep(1500);
                    if (shouldFail) {
                        throw new Error("Predictable Failure");
                    }
                }}
                needsConfirmation={needsConfirmation}
                isActionAllowed={isActionAllowed}
            />
        </div>
    );
}

const AsyncTaskButtonDemo = {
    component: DemoComponent,
    name: "AsyncTaskButton",
    readme: readme,
    source: source,
};

export default AsyncTaskButtonDemo;
