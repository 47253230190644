import { Typography } from "@mui/material";
import { GenerateApproximateTime } from "gardenspadejs/dist/dateHelpers";
import React from "react";
import { isMobile } from "react-device-detect";

import TypographyWithMaxWidth from "../../generic/TypographyWithMaxWidth/TypographyWithMaxWidth";
import ErrorTooltipIcon from "./ErrorTooltipIcon";
import StaleTooltipIcon, { threeDays } from "./StaleTooltipIcon";
import SuccessTooltipIcon from "./SuccessTooltipIcon";
import UnverifiedTooltipIcon from "./UnverifiedTooltipIcon";
import { VerificationChip } from "./VerificationChip";

export function ValveOpenAndCloseVerify(props) {
    const lastVerification = new Date(new Date(props.valveState.lastVerification).valueOf());
    const timeSinceLastVerification = Date.now() - lastVerification.valueOf();

    const incompleteVerification = props.valveState.lastVerification === undefined;
    const didLastVerificationSucceed = props.valveState.lastVerificationSuccessful && lastVerification;
    let tooltipIcon = <UnverifiedTooltipIcon tooltipText={"Unverified"} subHeader={true} />;
    if (!incompleteVerification) {
        if (didLastVerificationSucceed) {
            tooltipIcon = <SuccessTooltipIcon tooltipText={"Success"} subHeader={true} />;
        } else {
            tooltipIcon = <ErrorTooltipIcon tooltipText={"Failed"} subHeader={true} />;
        }
    }
    return (
        <div className={"ValveVerifyContainer"}>
            <div className={"ValveVerifyDetails"}>
                <Typography
                    component={"div"}
                    variant={"subtitle1"}
                    sx={{
                        fontWeight: didLastVerificationSucceed || incompleteVerification ? 400 : 600,
                        color: incompleteVerification ? "var(--unverified-text-main)" : "",
                    }}
                    className={!isMobile ? "ValveTitleText" : "ValveTitleTextMobile"}
                >
                    {tooltipIcon}
                    &nbsp;Valves {incompleteVerification || didLastVerificationSucceed ? "Able" : "Unable"} To{" "}
                    {props.valveState.title}
                </Typography>

                <TypographyWithMaxWidth
                    MaxWidth={270}
                    component={"div"}
                    variant={"caption"}
                    className={"ValveLastVerifiedText"}
                >
                    {props.valveState.lastVerification !== undefined ? (
                        <>
                            Verified{" "}
                            {GenerateApproximateTime(
                                new Date(lastVerification.valueOf()),
                                new Date(Date.now()),
                                false,
                                true,
                            )}
                            {!(lastVerification && timeSinceLastVerification < threeDays) ? (
                                <StaleTooltipIcon lastVerification={lastVerification} />
                            ) : (
                                <div />
                            )}
                        </>
                    ) : (
                        "No verification data yet"
                    )}
                </TypographyWithMaxWidth>
            </div>

            <div className={"ValveVerificationsChipArray"}>
                <VerificationChip
                    valveState={props.valveState.title}
                    chipLabel={"Device"}
                    lastConditionVerified={props.valveState.lastReportedStateVerified}
                    tooltipText={`Device has ${
                        props.valveState.lastReportedStateVerified
                            ? "successfully received schedule"
                            : "failed to receive schedule"
                    }`}
                />
                <VerificationChip
                    valveState={props.valveState.title}
                    chipLabel={"Flow"}
                    lastConditionVerified={props.valveState.lastFlowVerified}
                    tooltipText={
                        props.valveState.lastFlowVerified
                            ? "Flow rate successfully verified"
                            : "Failed to verify flow rate"
                    }
                />
            </div>
        </div>
    );
}
