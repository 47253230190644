/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import { Box, MenuItem, Select } from "@mui/material";
import React from "react";
import { SensorConfigurations } from "verditypes/dist/Configurations/SensorConfiguration";

// imports it's own source code
import source from "!!raw-loader!./NumericFieldWithUnitDemo.jsx";

import { NumericFieldWithUnit } from "./NumericFieldWithUnit";
import readme from "./README.md";

function DemoComponent() {
    const [singleValue, setSingleValue] = React.useState(1);
    const [secondValue, setSecondValue] = React.useState(100);
    const [sensorType, setSensorType] = React.useState(Object.keys(SensorConfigurations)[0]);

    return (
        <div>
            <Box>
                <Select
                    value={sensorType}
                    label={"Sensor Type:"}
                    onChange={(e) => {
                        setSensorType(e.target.value);
                    }}
                >
                    {Object.keys(SensorConfigurations).map((potentialSensorType) => (
                        <MenuItem value={potentialSensorType}>{potentialSensorType}</MenuItem>
                    ))}
                </Select>
            </Box>
            <NumericFieldWithUnit
                value={singleValue}
                onChange={(e) => {
                    setSingleValue(e.value);
                }}
                showUnits={false}
                singleFieldTitle={"Most Basic:"}
                style={{
                    margin: "1rem",
                }}
            />
            <NumericFieldWithUnit
                key={sensorType}
                value={singleValue}
                helperText={"Some helper text"}
                onChange={(e) => {
                    setSingleValue(e.value);
                }}
                showUnits={true}
                label={"Label:"}
                isRange={false}
                sensorType={sensorType}
                singleFieldTitle={"Single Field Title:"}
                style={{
                    margin: "1rem",
                }}
            />
            <NumericFieldWithUnit
                key={sensorType}
                value={singleValue}
                onChange={(e) => {
                    setSingleValue(e.value);
                }}
                showUnits={true}
                isRange={false}
                sensorType={sensorType}
                singleFieldTitle={"No Label"}
                style={{
                    margin: "1rem",
                }}
            />
            <NumericFieldWithUnit
                key={sensorType}
                value={singleValue}
                onChange={(e) => {
                    setSingleValue(e.value);
                }}
                showUnits={false}
                isRange={false}
                sensorType={sensorType}
                singleFieldTitle={"Hiding units"}
                style={{
                    margin: "1rem",
                }}
            />
            <NumericFieldWithUnit
                key={sensorType}
                value={[singleValue, secondValue]}
                onChange={(e) => {
                    setSingleValue(e.value[0]);
                    setSecondValue(e.value[1]);
                }}
                showUnits={true}
                isRange={true}
                sensorType={sensorType}
                lowFieldTitle={"Bottom of range"}
                highFieldTitle={"Top of range"}
                label={"Two values:"}
                style={{
                    margin: "1rem",
                }}
            />
        </div>
    );
}

const NumericFieldWithUnitDemo = {
    component: DemoComponent,
    name: "NumericFieldWithUnit",
    readme: readme,
    source: source,
};

export default NumericFieldWithUnitDemo;
