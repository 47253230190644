import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function PressureTransducerIcon(props) {
    let viewBox = "0, 0,  32, 32";
    if (props.slim) {
        viewBox = "7.558, 0, 16.882, 32";
    }
    return (
        <SvgIcon viewBox={viewBox} {...props}>
            <path
                fill={"currentColor"}
                d={
                    "m 15.799971,1.3269958 a 4.5278454,4.5278454 0 0 0 -4.52782,4.5278716 4.5278454,4.5278454 0 0 0 3.684045,4.4453186 l -0.138302,1.783018 0.982081,0.01479 0.982134,-0.01479 -0.138302,-1.783018 a 4.5278454,4.5278454 0 0 0 3.684042,-4.4453186 4.5278454,4.5278454 0 0 0 -4.52787,-4.5278716 z m 0,0.7161435 A 3.8117294,3.8117294 0 0 1 19.6117,5.8548674 3.8117294,3.8117294 0 0 1 15.799971,9.6665971 3.8117294,3.8117294 0 0 1 11.988243,5.8548674 3.8117294,3.8117294 0 0 1 15.799971,2.0431393 Z m -0.0017,0.8763627 a 2.9334844,2.9334844 0 0 0 -2.933356,2.9334401 h 0.395704 a 2.5376969,2.5376969 0 0 1 2.537685,-2.537682 2.5376969,2.5376969 0 0 1 1.605622,0.5744037 l -1.167861,1.1678581 a 0.90798311,0.90798311 0 0 0 -0.437761,-0.1125455 0.90798311,0.90798311 0 0 0 -0.90796,0.9079579 0.90798311,0.90798311 0 0 0 0.90796,0.908011 0.90798311,0.90798311 0 0 0 0.90801,-0.908011 0.90798311,0.90798311 0 0 0 -0.112543,-0.4376617 l 1.167806,-1.1679093 a 2.5376969,2.5376969 0 0 1 0.574508,1.605571 h 0.395706 A 2.9334844,2.9334844 0 0 0 18.04284,3.96607 l 0.108941,-0.1089465 a 0.25292868,0.25292868 0 0 0 0,-0.3576544 0.25292868,0.25292868 0 0 0 -0.357659,0 L 17.685181,3.6084156 A 2.9334844,2.9334844 0 0 0 15.798293,2.9194964 Z m -0.660917,10.135322 -1.071455,2.919697 -5.2e-5,0.0086 h 1.734089 1.734089 l -5e-5,-0.0086 -1.071458,-2.919697 h -0.662581 z m -1.071928,3.532361 -0.225339,1.194092 v 5.350882 l 0.22362,1.078117 h 1.736224 1.736224 l 0.223618,-1.078117 v -5.350882 l -0.225336,-1.194092 h -1.734506 z m -1.190866,8.720261 v 1.733102 h 1.061462 v -1.733102 z m 1.993312,0.0037 v 1.732477 h 1.864118 v -1.732477 z m 2.808929,0 v 1.727065 h 1.061516 v -1.727065 z m -4.382331,2.670419 v 0.325645 h 8.83e-4 v 2.157955 c 0,0.115153 0.09269,0.207847 0.207847,0.207847 h 4.592789 c 0.115154,0 0.207846,-0.09269 0.207846,-0.207847 v -2.158023 h 0.0016 V 27.98149 Z"
                }
            />
        </SvgIcon>
    );
}
