import "./ZoneOverviewUtility.css";

import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import DryIcon from "@mui/icons-material/Dry";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import WaterIcon from "@mui/icons-material/Water";
import Chip from "@mui/material/Chip";
import * as React from "react";

export const statusDictionary = {
    winterDamage: {
        iconName: AcUnitRoundedIcon,
        iconColor: "#40e0d0",
        labelName: "Winter",
    },
    heatDamage: {
        iconName: LocalFireDepartmentIcon,
        iconColor: "#ff4500",
        labelName: "Heat",
    },
    drought: {
        iconName: DryIcon,
        iconColor: "#cd853f",
        labelName: "Drought",
    },
    flood: {
        iconName: WaterIcon,
        iconColor: "#4169e1",
        labelName: "Flood",
    },
};

export function getChipsFromDictionary(itemList, dictionary) {
    return (
        <>
            {Array.isArray(itemList) &&
                itemList.map((item) => {
                    if (item in dictionary) {
                        const IconName = dictionary[item].iconName;
                        return (
                            <Chip
                                className={"zoneOverviewStatusChipStyle"}
                                sx={{ "& .MuiChip-icon": { color: dictionary[item].iconColor } }}
                                icon={<IconName />}
                                label={dictionary[item].labelName}
                                size={"small"}
                                variant={"outlined"}
                            />
                        );
                    }
                    return null;
                })}
        </>
    );
}

export function getIconsFromStatus(statusEffectsList) {
    return (
        <>
            {Array.isArray(statusEffectsList) &&
                statusEffectsList.map((statusEffect) => {
                    if (statusEffect in statusDictionary) {
                        const IconName = statusDictionary[statusEffect].iconName;
                        return <IconName className={"zoneOverviewStatusIconStyle"} />;
                    }
                    return null;
                })}
        </>
    );
}

export function getYearsOfAge(dateEstablished) {
    if (!(dateEstablished === undefined)) {
        return Math.round(((Date.now() - dateEstablished.valueOf()) / (1000 * 60 * 60 * 24 * 365)) * 10) / 10;
    }
    return undefined;
}

export function getMostRelevantSoilLayer(soilCompositions) {
    if (Array.isArray(soilCompositions) && soilCompositions.length > 0) {
        return soilCompositions[0];
    }
    return undefined;
}
