import React from "react";

import BlockValveIcon from "./BlockValveIcon";
import DraginoECIcon from "./DraginoECIcon";
import DualValveSeedIcon from "./DualValveSeedIcon";
import MoistureSensorIcon from "./MoistureSensorIcon";
import SingleValveSeedIcon from "./SingleValveSeedIcon";
import SproutBlockValveIcon from "./SproutBlockValveIcon";

export default function DeviceIcon(props) {
    const { DeviceType, ...propsForChild } = props;

    if (DeviceType === "seed1v") {
        return <SingleValveSeedIcon {...propsForChild} />;
    }
    if (DeviceType === "seed2v") {
        return <DualValveSeedIcon {...propsForChild} />;
    }
    if (DeviceType === "sproutBlock") {
        return <SproutBlockValveIcon {...propsForChild} />;
    }
    if (DeviceType === "draginoEC") {
        return <DraginoECIcon {...propsForChild} />;
    }
    if (DeviceType === "block") {
        return <BlockValveIcon {...propsForChild} />;
    }
    if (DeviceType === "msense") {
        return <MoistureSensorIcon {...propsForChild} />;
    }

    return <DualValveSeedIcon {...propsForChild} />;
}
