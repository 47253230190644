import { Typography } from "@mui/material";
import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import React from "react";

import { FiniteStateGraphContext } from "./FiniteStateGraphContext";

export function FSGBasicTooltip({ xPosition, time, showTooltip }) {
    return (
        <div className={`FSGtooltip FSGtooltip--${showTooltip ? "active" : "inactive"}`} style={{ left: xPosition }}>
            <div className={"FSGtooltipTime"}>{getTimeString(time, "MMM dd hh:mm nn")}</div>
        </div>
    );
}

export function FSGIrrigationReportTooltip({ xPosition, time, showTooltip, percentageXPosition }) {
    const fsgContext = React.useContext(FiniteStateGraphContext);
    const [relevantChunks, scheduleChunks] = React.useMemo(() => {
        let allRelevantChunks = [];
        const allRelChunksSet = new Set();

        Object.keys(fsgContext.chunksByFSGElementID).forEach((dataStreamID) => {
            const listOfChunks = fsgContext.chunksByFSGElementID[dataStreamID];
            listOfChunks.forEach((c) => {
                if (c.percentageStart < percentageXPosition + 0.002 && c.percentageEnd > percentageXPosition - 0.002) {
                    allRelChunksSet.add(c);
                }
            });
        });
        allRelevantChunks = Array.from(allRelChunksSet);
        allRelevantChunks = allRelevantChunks.filter((c) => !["closed"].includes(c.chunkValue));
        let startPercentage;
        let endPercentage;
        allRelevantChunks.forEach((c) => {
            if (c.chunkKey === "ScheduledValve" && c.chunkValue !== "closed") {
                startPercentage = Math.min(c.percentageStart, startPercentage ?? c.percentageStart, c.percentageEnd);
                endPercentage = Math.max(c.percentageStart, endPercentage ?? c.percentageStart, c.percentageEnd);
            }
        });
        Object.keys(fsgContext.chunksByFSGElementID).forEach((dataStreamID) => {
            const listOfChunks = fsgContext.chunksByFSGElementID[dataStreamID];
            listOfChunks.forEach((c) => {
                if (
                    c.percentageStart < endPercentage + 0.005 &&
                    c.percentageEnd > startPercentage - 0.005 &&
                    c.chunkKey === "ScheduledValve"
                ) {
                    allRelChunksSet.add(c);
                }
            });
        });
        allRelevantChunks = Array.from(allRelChunksSet);
        allRelevantChunks = allRelevantChunks.filter((c) => !["closed"].includes(c.chunkValue));
        let allScheduleChunks = allRelevantChunks.filter((c) => c.chunkKey === "ScheduledValve");
        allScheduleChunks.sort((a, b) => a.startTime - b.startTime);
        for (let i = 0; i < allScheduleChunks.length - 1; i++) {
            if (Math.abs(allScheduleChunks[i + 1].startTime - allScheduleChunks[i].endTime) < 45000) {
                const newHybridChunk = {
                    ...allScheduleChunks[i],
                };
                newHybridChunk.endTime = Math.max(allScheduleChunks[i + 1].endTime, allScheduleChunks[i].endTime);
                newHybridChunk.startTime = Math.min(allScheduleChunks[i + 1].startTime, allScheduleChunks[i].startTime);
                newHybridChunk.percentageEnd = Math.max(
                    allScheduleChunks[i + 1].percentageEnd,
                    allScheduleChunks[i].percentageEnd,
                );
                newHybridChunk.percentageStart = Math.min(
                    allScheduleChunks[i + 1].percentageStart,
                    allScheduleChunks[i].percentageStart,
                );
                newHybridChunk.chunkValue = "open";
                newHybridChunk.duration = newHybridChunk.endTime - newHybridChunk.startTime;
                newHybridChunk.errorDuration =
                    (allScheduleChunks[i + 1].errorDuration || 0) + (allScheduleChunks[i].errorDuration || 0);
                if (allScheduleChunks[i].chunkValue === "unknown") {
                    newHybridChunk.errorDuration += allScheduleChunks[i].duration;
                }
                if (allScheduleChunks[i + 1].chunkValue === "unknown") {
                    newHybridChunk.errorDuration += allScheduleChunks[i + 1].duration;
                }
                allScheduleChunks.splice(i, 2, newHybridChunk);
                i = -1;
                allScheduleChunks.sort((a, b) => a.startTime - b.startTime);
            }
        }
        allScheduleChunks = allScheduleChunks.filter(
            (c) => c.percentageStart < percentageXPosition + 0.002 && c.percentageEnd > percentageXPosition - 0.002,
        );
        return [allRelevantChunks, allScheduleChunks];
    }, [showTooltip ? time.valueOf().toString() : "hidden", showTooltip ? percentageXPosition : "none", showTooltip]);

    return (
        <div
            className={`FSGtooltip FSGtooltip--${showTooltip ? "active" : "inactive"}`}
            style={{ left: `min(calc(100% - 60px), ${xPosition}px)` }}
        >
            <div className={"FSGtooltipTime"}>{getTimeString(time, "MMM dd hh:mm nn")}</div>
            <div className={"FSGtooltipBody"}>
                {scheduleChunks
                    .filter((c) => c.chunkKey === "ScheduledValve")
                    .map((rc) => {
                        const [timeStopsString, durationString] = geTimeStopsAndDuration(rc);

                        return (
                            <div className={"FSGtooltipBody_ScheduledIrrigation"}>
                                <Typography variant={"caption"}>
                                    {rc.chunkValue === "unknown" ? "Scheduled (⚠):" : "Scheduled:"}
                                </Typography>
                                <Typography variant={"caption"}>
                                    {" "}
                                    {timeStopsString} ({durationString}{" "}
                                    {rc.errorDuration && `±${getDurationStringFromDuration(rc.errorDuration)}`})
                                </Typography>
                            </div>
                        );
                    })}
                {relevantChunks
                    .filter((c) => c.chunkKey === "ZoneOpenPercentageState")
                    .map((rc) => {
                        const [timeStopsString, durationString] = geTimeStopsAndDuration(rc);
                        return (
                            <div className={"FSGtooltipBody_ActualIrrigation"}>
                                <Typography variant={"caption"}>Actual Irrigation:</Typography>
                                <Typography variant={"caption"}>
                                    {" "}
                                    {timeStopsString} ({durationString})
                                </Typography>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
function getDurationStringFromDuration(duration) {
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration - hours * (1000 * 60 * 60)) / (1000 * 60));
    let durationString = `${hours}h ${minutes.toString().padStart(2, "0")}m`;
    if (hours === 0) {
        durationString = `${minutes.toString()}m`;
    }
    if (minutes < 2) {
        durationString = `${hours.toString()}hrs`;
    }
    if (minutes > 58) {
        durationString = `${(hours + 1).toString()}hrs`;
    }
    return durationString;
}
function geTimeStopsAndDuration(rc) {
    let timeStopsString = "";
    if (
        new Date(rc.startTime).getDate() === new Date(rc.endTime).getDate() &&
        Math.abs(rc.endTime - rc.startTime) < 1000 * 60 * 60 * 48
    ) {
        timeStopsString = `${getTimeString(new Date(rc.startTime), "MMM dd hh:mm nn")} - ${getTimeString(
            new Date(rc.endTime),
            "hh:mm nn",
        )}`;
    } else {
        timeStopsString = `${getTimeString(new Date(rc.startTime), "MMM dd hh:mm nn")} - ${getTimeString(
            new Date(rc.endTime),
            "MMM dd hh:mm nn",
        )}`;
    }
    const duration = rc.endTime - rc.startTime;
    const durationString = getDurationStringFromDuration(duration);
    return [timeStopsString, durationString];
}
