import { SensorConfig, SensorType } from "verditypes";
import { SensorCategory, SensorConfiguration } from "verditypes/dist/Configurations/SensorConfiguration";

import FlowMeterSensorSettings from "./FlowMeterSensorSettings";
import MoistureSensorSettings from "./MoistureSensorSettings";
import ParameterizedFlowmeterSettings from "./ParameterizedFlowmeterSettings";
import SensorSettingsBase from "./SensorSettingsBase";
import TensiometerSensorSettings from "./TensiometerSensorSettings";

const defaultSettingsPanelByCategory: Partial<Record<SensorCategory, new () => SensorSettingsBase>> = {
    liquidFlow: FlowMeterSensorSettings,
    resistiveSoilMoisture: MoistureSensorSettings,
    volumetricSoilMoisture: MoistureSensorSettings,
    soilTension: TensiometerSensorSettings,
};

const specificSensorSettingsPanels: Partial<Record<SensorType, new () => SensorSettingsBase>> = {
    mccrometerFlowmeterFC101: ParameterizedFlowmeterSettings,
    genericFlowmeter: ParameterizedFlowmeterSettings,
};

export function getSensorSettingsPanelForSensorType(sensorType: SensorType | null) {
    if (!sensorType) {
        return null;
    }

    const config = SensorConfig.SensorConfigurations[sensorType] as SensorConfiguration;

    // Specific settings panels always take precedent over anything else
    if (specificSensorSettingsPanels[sensorType]) {
        return specificSensorSettingsPanels[sensorType];
    }

    // If there is nothing specific, we can look at the settings panel for the category
    if (config.sensorCategory && defaultSettingsPanelByCategory[config.sensorCategory]) {
        return defaultSettingsPanelByCategory[config.sensorCategory];
    }

    // If there is nothing for either, we can use the base settings panel
    return SensorSettingsBase;
}
