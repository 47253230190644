import MoistureSensorMarkerIcon from "../../../components/icons/MapIcons/MoistureSensorMarkerIcon";
import DeviceMapEntity from "./DeviceMapEntity";

export class ThirdPartyDeviceMapEntity extends DeviceMapEntity {
    status = "Unknown";

    pickIcon() {
        this.icon = MoistureSensorMarkerIcon;
    }
}
