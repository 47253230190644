import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import VerifiedIcon from "@mui/icons-material/Verified";
import React from "react";

import { LightTooltip } from "../../generic/LightTooltip/LightTooltip";

export default function SuccessTooltipIcon({ tooltipText, subHeader, isInChip, ...propsToPass }) {
    let IconType = isInChip ? CheckCircleIcon : VerifiedIcon;
    if (subHeader) {
        IconType = CircleIcon;
    }

    if (isInChip) {
        return (
            <IconType
                style={{
                    color: "var(--success-icon-main)",
                    width: "1.2rem",
                    marginLeft: "0.2rem",
                }}
            />
        );
    }
    return (
        <LightTooltip title={tooltipText} {...propsToPass}>
            {/* <IconButton > */}
            <IconType
                style={{
                    width: subHeader ? "0.9rem" : "",
                    color: "var(--success-icon-main)",
                }}
            />
            {/* </IconButton> */}
        </LightTooltip>
    );
}
