import "./BlockValveStatus.css";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { getFirstDefined } from "gardenspadejs/dist/general";
import React from "react";

import FocusContext from "../../../services/mapManagement/FocusContext";
import SimpleBarCompatability from "../../generic/SimpleBarCompatability/SimpleBarCompatability";
import { ChoosingValves } from "../HelpDialogues/RegisteringADevice";
import CurrentIrrigationStatusBar from "./DeviceStatusComponents/CurrentIrrigationStatusBar";
import { MicrovalveStatusV2Base } from "./Microvalve/MicrovalveStatus";
import { SensorDropdown } from "./Sensors/SensorDropdown";

export class BlockValveStatus extends MicrovalveStatusV2Base {
    /**
     * @param {{Device: Device, StartEditing: Boolean, OnZonesChanged: function(Zone, Zone), ShowValveState: Boolean}} props
     */
    constructor(props) {
        super(props);
        this.state.focusedZone = undefined;
        this.state.zones = [];
    }

    lastInteraction = new Date(0);

    lastZoneInteractedWith = undefined;

    componentDidMount() {
        this.mounted = true;
        let selectedZones = [];
        if (this.props.Device) {
            selectedZones = this.props.Device.connectedZones || [];
        }
        this.setState({
            zones: selectedZones,
        });

        this.focusContext = new FocusContext();
        this.focusContext.name = "Block valve zone focus context";
        this.focusContext.defaultFunction = (m) => {
            if (m.model === this.props.Device) {
                return "selected";
            }
            if (m.model && m.model.category) {
                if (m.model.category === "zone") {
                    if (!this.props.editing && this.state.focusedZone === m) {
                        return "selected";
                    }
                    if (this.state.zones.includes(m.model)) {
                        if (this.props.editing) {
                            return "selected";
                        }
                        return "active";
                    }
                    return "inactive";
                }
            }
            if (this.props.editing) {
                return "hidden";
            }
            return undefined;
        };
        this.CheckIfVisible();
        this.focusContext.onInteract = (e, mapEntity) => {
            const targetModel = mapEntity.model;
            if (this.lastZoneInteractedWith === mapEntity && Date.now() - this.lastInteraction.valueOf() < 500) {
                return false;
            }
            this.lastZoneInteractedWith = mapEntity;
            this.lastInteraction = new Date(Date.now());
            if (this.props.editing) {
                if (targetModel && targetModel.category === "zone") {
                    if (this.state.zones.includes(targetModel)) {
                        this.state.zones.splice(this.state.zones.indexOf(targetModel), 1);
                        this.focusContext.updateMapEntityState(mapEntity);

                        this.focusContext.setFocused(undefined);
                    } else {
                        this.state.zones.push(targetModel);
                        this.focusContext.updateMapEntityState(mapEntity);
                        this.focusContext.setFocused(targetModel);
                    }
                    this.setState({
                        focusedZone: this.focusContext.focused,
                        zones: this.state.zones,
                    });
                }
                return false;
            }
            return true;
        };
    }

    _lastSelectedZonesLength;

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.editing && !nextProps.editing) {
            FocusContext.allMapEntites.forEach((me) => {
                if (me.model && me.model.category !== "zone") {
                    this.focusContext.setHidden(me, false);
                }
            });
        } else if (!this.props.editing && nextProps.editing) {
            FocusContext.allMapEntites.forEach((me) => {
                if (me.model && me.model.category !== "zone" && me.model !== this.props.Device) {
                    this.focusContext.setHidden(me);
                }
            });
        }
        const zonesChanged = nextState?.zones?.length !== this._lastSelectedZonesLength;
        const anySensorChanges =
            this.state.sensors.map((s, i) => nextState.sensors[i] === s).includes(false) ||
            this.state.sensors !== nextState.sensors;
        if (zonesChanged && this.props.OnZonesChanged) {
            this.props.OnZonesChanged(nextState.zones);
        }
        if (anySensorChanges || zonesChanged) {
            if (this.props.onChange) {
                this.props.onChange({
                    connectedZones: nextState.zones,
                    connectedSensors: nextState.sensors,
                });
            }
        }
        this._lastSelectedZonesLength = nextState?.zones?.length;
        return true;
    }

    // This allows us to gracefully handle zones being deleted/disconnected from the block valve
    allZones = [];

    render() {
        this.deviceHasSensors = !this.props.hideSensors;
        this.state.zones.forEach((z) => {
            if (!this.allZones.includes(z)) {
                this.allZones.push(z);
            }
        });
        const allZoneEntries = this.allZones.map((zone) => {
            const deleted = !this.state.zones.includes(zone);
            return (
                <ListItem
                    selected={zone === this.state.focusedZone?.model}
                    onClick={() => {
                        const previousMapEntity = this.state.focusedZone;
                        const newMapEntity = FocusContext.MapEntitesByModelID[zone.id];
                        this.setState({ focusedZone: newMapEntity });

                        this.state.focusedZone = newMapEntity;
                        this.focusContext.updateMapEntityState(newMapEntity);
                        if (previousMapEntity) {
                            try {
                                this.focusContext.updateMapEntityState(previousMapEntity);
                            } catch (error) {
                                console.warn("Error updating map entity state for block valve zone:", error);
                            }
                        }
                    }}
                    key={zone.uid}
                    button
                    className={`BlockValveZone ${deleted ? " BlockValveZone--deleted" : " BlockValveZone--active"}`}
                >
                    <ListItemText primary={zone.name} />
                    {this.props.editing && (
                        <ListItemSecondaryAction
                            className={`BlockValveIcon ${
                                deleted ? " BlockValveIcon--deleted" : " BlockValveIcon--active"
                            }`}
                        >
                            <IconButton
                                onClick={() => {
                                    this.focusContext.setInactive(zone);
                                    this.focusContext.setFocused(undefined);
                                    this.state.zones.splice(this.state.zones.indexOf(zone), 1);
                                    this.setState({
                                        focusedZone: undefined,
                                        zones: this.state.zones,
                                    });
                                }}
                                size={"large"}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}{" "}
                </ListItem>
            );
        });
        allZoneEntries.push(
            <ListItem
                key={"NoZones"}
                selected={false}
                button
                className={`BlockValveZone ${
                    allZoneEntries.length > 0 ? " BlockValveZone--deleted" : " BlockValveZone--active"
                }`}
            >
                <ListItemText primary={"No zones"} />
            </ListItem>,
        );
        let editingInstructions = "Tap the zones this block valve is connected to:";
        if (window.innerWidth < 500) {
            editingInstructions = "Tap connected zones:";
        }
        const useSimpleBar = this.state.zones.length > 5;
        const showIrrigationBar =
            getFirstDefined(this.props.showIrrigationBar, !this.props.editing) && this.props.Device;

        return (
            <div
                className={`BlockValveZoneSelectorRoot ${this.props.className || ""}`}
                style={this.props.style}
                id={this.uid}
            >
                {showIrrigationBar && <CurrentIrrigationStatusBar device={this.props.Device} />}
                <div className={"BlockValveSectionHeader ConnectedZonesHeader"}>
                    {this.props.editing && <Typography>{editingInstructions}</Typography>}
                    {!this.props.editing && <Typography>Connected Zones:</Typography>}
                    <div className={"cz_spacer"} />
                    <ChoosingValves>
                        <Typography variant={"button"}>Help </Typography>
                    </ChoosingValves>
                </div>

                <List
                    className={`BlockValveZoneSelectorBody ${
                        this.props.editing ? " BlockValveZoneSelectorBody--editing " : ""
                    }`}
                >
                    {useSimpleBar && (
                        <SimpleBarCompatability
                            style={{
                                // maxHeight: "20px"
                                maxHeight: "calc(40vh - 100px)",
                                minHeight: "calc(40vh - 100px)",
                            }}
                        >
                            {allZoneEntries}
                        </SimpleBarCompatability>
                    )}
                    {!useSimpleBar && allZoneEntries}
                </List>

                {this.deviceHasSensors && (
                    <>
                        <div className={"BlockValveSectionHeader SensorHeader"}>
                            <Typography>Connected Sensors:</Typography>
                            <div className={"cz_spacer"} />
                            {/* <ChoosingValves> */}
                            {/*    <Typography variant={"button"}>Help </Typography> */}
                            {/* </ChoosingValves> */}
                        </div>
                        <div className={"BlockValveSensorSelection"}>
                            {this.state.sensors.map((sensor, i) => {
                                const valve = i;
                                return (
                                    <SensorDropdown
                                        key={`${this.uid}_S${valve + 1}`}
                                        parentID={this.uid}
                                        label={`S${valve + 1} Sensor:`}
                                        sensor={sensor}
                                        onChange={(newSensor, forceSave = false) =>
                                            this.onSensorChangeHandler(newSensor, valve, forceSave)
                                        }
                                        device={this.props.Device}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        );
    }
}
