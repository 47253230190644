import "./NotesField.scss";

import ReplayIcon from "@mui/icons-material/Replay";
import SaveIcon from "@mui/icons-material/Save";
import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

function NotesField({ notes, onSave }) {
    const [showNotes, setShowNotes] = React.useState(false);
    const [modifiedNotes, setModifiedNotes] = React.useState(notes);
    const [changes, setChanges] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setShowNotes(true);
        }, 200);
    }, []);

    React.useEffect(() => {
        setModifiedNotes(notes);
    }, [notes]);

    const { enqueueSnackbar } = useSnackbar();
    return (
        <div className={"NotesFieldWrapper"}>
            {showNotes && (
                <TextField
                    label={"Notes"}
                    multiline
                    rows={4}
                    value={modifiedNotes}
                    onChange={(e) => {
                        setModifiedNotes(e.target.value);
                        setChanges(e.target.value !== notes);
                    }}
                />
            )}
            {!showNotes && <div style={{ minHeight: "calc(1.4375em * 8)" }} />}
            <div className={`NotesControls NotesControls--${changes ? "visible" : "hidden"}`}>
                <Button
                    color={"info"}
                    startIcon={<ReplayIcon />}
                    onClick={() => {
                        setModifiedNotes(notes);
                        setChanges(false);
                    }}
                    variant={"contained"}
                >
                    {" "}
                    Revert{" "}
                </Button>
                <Button
                    color={"primary"}
                    startIcon={<SaveIcon />}
                    onClick={async () => {
                        if (onSave) {
                            try {
                                await onSave(modifiedNotes || "");
                                enqueueSnackbar("notes saved", {
                                    variant: "success",
                                });
                            } catch (e) {
                                enqueueSnackbar("notes not saved!", {
                                    variant: "error",
                                });
                            }
                        }
                    }}
                    variant={"contained"}
                >
                    {" "}
                    Save{" "}
                </Button>
            </div>
        </div>
    );
}

export default NotesField;
