import { clamp } from "gardenspadejs/dist/general";
import React from "react";
import { HistoricalDataResponse } from "verdiapi/dist/APICommands/HistoricalDataTypes";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { ParsedDataTypeSpecifier } from "verditypes";

import { prettifyDepth } from "./MultiDepthSoilMoistureUtils";
import { SentekSoilMoistureGraphDataStream } from "./types";

type ModifiedHistoricalDataResponse = Omit<HistoricalDataResponse, "data"> & {
    data: {
        name: string;
        dateValue: number;
        value: number[];
    }[];
    position: { depth: number };
    idOfNextLowestDepthDataStream?: string;
    boostNeededToStayAboveLowerDepthDataStream?: number;
    mostRecentlyAddedValue?: number;
    range: [number, number];
};

export const useMultiDepthDataProcessing = ({
    numDatapoints,
    historicalDatabase,
}: {
    numDatapoints: number;
    historicalDatabase: HistoricalDataBase;
}) => {
    const [allDataSeriesArray, setAllDataSeriesArray] = React.useState<SentekSoilMoistureGraphDataStream[]>([]);
    const [dataSeriesByID, setDataSeriesByID] = React.useState<Record<string, SentekSoilMoistureGraphDataStream>>({});

    React.useEffect(() => {
        // create a look up of each modified response based on the data stream ID so we can add points to them
        const modifiedHistoricalDataResponseByID: Record<string, ModifiedHistoricalDataResponse> = {
            ...historicalDatabase.dataKeyMetadataLookup,
        } as unknown as Record<string, ModifiedHistoricalDataResponse>;
        modifiedHistoricalDataResponseByID.sumOfMoisture = {
            ...modifiedHistoricalDataResponseByID[Object.keys(modifiedHistoricalDataResponseByID)[0]],

            boostNeededToStayAboveLowerDepthDataStream: 0,
            data: [],
            idOfNextLowestDepthDataStream: undefined,
            mostRecentlyAddedValue: undefined,
            position: {
                lat: 0,
                lng: 0,
                depth: 0,
            },
            range: [0, 0],
            dataType: "moisture",
        };
        const curMoistureAtEachDepth: Record<number, number> = {};

        // // for each historical data response, we add the fields we defined above to make it a "modified" response
        // Object.entries(modifiedHistoricalDataResponseByID).forEach(([key, dataStream])=>{
        //     // range defaults with extremely positive min and extremely negative max
        //     modifiedHistoricalDataResponseByID[key].range = [100000000, -100000000]
        //
        //     const depth = dataStream.position?.depth;
        //     if(depth > 0.5){
        //         console.log("Pruning ", key);
        //         delete modifiedHistoricalDataResponseByID[key]
        //     }
        // })

        const lastDataPoint =
            historicalDatabase.data.value[historicalDatabase.data.value.length - 1]?.getAllData() ?? {};
        // for each historical data response, we add the fields we defined above to make it a "modified" response
        Object.entries(modifiedHistoricalDataResponseByID).forEach(([key, dataStream]) => {
            // range defaults with extremely positive min and extremely negative max
            modifiedHistoricalDataResponseByID[key].range = [100000000, -100000000];

            const depth = dataStream.position?.depth;

            // find the data stream underneath this one that is of the same data type
            dataStream.idOfNextLowestDepthDataStream = getIDOfHighestDatastreamUnderDepth(
                depth,
                dataStream.dataType,
                modifiedHistoricalDataResponseByID,
            );
            // @ts-ignore
            if (modifiedHistoricalDataResponseByID[key].dataType === "moisture_drillAndDrop") {
                // @ts-ignore
                curMoistureAtEachDepth[depth] = lastDataPoint?.[key] ?? 0;
            }
        });
        const maxDepth = Math.max(...Object.keys(curMoistureAtEachDepth).map((x) => parseFloat(x)));
        const rangeOfEachReading = maxDepth / Object.keys(curMoistureAtEachDepth).length;
        // initialize on the first point
        let curPoint = historicalDatabase.data.value[historicalDatabase.data.value.length - 1];
        // itterate through every point going backwards
        while (curPoint?.prevPoint) {
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            Object.entries({
                ...curPoint.data,
                // @ts-ignore
                sumOfMoisture:
                    (Object.values(curMoistureAtEachDepth).reduce((a, b) => a + clamp(b, 0, 100), 0) *
                        rangeOfEachReading) /
                    100,
                // eslint-disable-next-line @typescript-eslint/no-loop-func
            }).forEach(([key, value]) => {
                // if this is a metadata key or unrelated to the data streams we are processing, just return
                if (!modifiedHistoricalDataResponseByID[key]) {
                    return;
                }

                const dataStreamForPoint = modifiedHistoricalDataResponseByID[key];
                // @ts-ignore
                if (dataStreamForPoint.dataType === "moisture_drillAndDrop") {
                    // @ts-ignore
                    curMoistureAtEachDepth[dataStreamForPoint.position?.depth || 0] = value;
                }

                if (!dataStreamForPoint.data) {
                    dataStreamForPoint.data = [];
                }

                if (dataStreamForPoint.data) {
                    // @ts-ignore
                    dataStreamForPoint.data.push({
                        name: "test",
                        // @ts-ignore
                        dateValue: curPoint.date.valueOf(),
                        // @ts-ignore
                        value: [curPoint.date.valueOf(), value],
                    });
                }
                dataStreamForPoint.range[0] = Math.min(dataStreamForPoint.range[0], value);
                dataStreamForPoint.range[1] = Math.max(dataStreamForPoint.range[1], value);
                dataStreamForPoint.mostRecentlyAddedValue = value;
                if (dataStreamForPoint.idOfNextLowestDepthDataStream) {
                    // @ts-ignore
                    const nextLowestDataStream =
                        modifiedHistoricalDataResponseByID[dataStreamForPoint.idOfNextLowestDepthDataStream];
                    if (
                        nextLowestDataStream.mostRecentlyAddedValue !== undefined &&
                        dataStreamForPoint.mostRecentlyAddedValue !== undefined
                    ) {
                        dataStreamForPoint.boostNeededToStayAboveLowerDepthDataStream = Math.max(
                            dataStreamForPoint.boostNeededToStayAboveLowerDepthDataStream ?? -10000,
                            nextLowestDataStream.mostRecentlyAddedValue - dataStreamForPoint.mostRecentlyAddedValue,
                        );
                    }
                }
            });
            // @ts-ignore
            curPoint = curPoint.prevPoint;
        }
        const newDataSeriesByID: Record<string, SentekSoilMoistureGraphDataStream> = {};
        const newDataSeries: SentekSoilMoistureGraphDataStream[] = Object.entries(
            modifiedHistoricalDataResponseByID,
        ).map(([id, ds]) => {
            ds.data.sort((a, b) => a.dateValue - b.dateValue);
            newDataSeriesByID[id] = {
                name: `${ds.dataType} ${prettifyDepth(ds.position?.depth)}`,
                type: "line",
                showSymbol: false,
                data: ds.data,
                average: "average",
                dataType: ds.dataType,
                depth: ds.position?.depth,
                boostNeededToStayAboveLowerDepthDataStream: ds.boostNeededToStayAboveLowerDepthDataStream ?? 0,
                range: ds.range,
                id: id,
            };
            return newDataSeriesByID[id];
        });

        setAllDataSeriesArray(newDataSeries);
        setDataSeriesByID(newDataSeriesByID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numDatapoints]);

    return {
        allDataSeriesArray,
        dataSeriesByID,
    };
};

/**
 * Returns the ID of the data stream with a depth just below the depth given.
 * @param depth
 * @param dataType
 * @param modifiedHistoricalDataResponseByID
 * @return {string | undefined}
 */
const getIDOfHighestDatastreamUnderDepth = (
    depth: number,
    dataType: ParsedDataTypeSpecifier,
    modifiedHistoricalDataResponseByID: Record<string, ModifiedHistoricalDataResponse>,
): string | undefined => {
    // if depth is not given, then there is no highest data stream under this depth
    if (depth === undefined || Number.isNaN(depth)) {
        return undefined;
    }

    // find the data stream underneath this one that is of the same data type
    const nextLowest = Object.entries(modifiedHistoricalDataResponseByID)
        // only data streams with the same data type
        .filter(([_id, candidateNextLowest]) => candidateNextLowest.dataType === dataType)
        // only data streams that are lower
        .filter(([_id, candidateNextLowest]) => (candidateNextLowest.position?.depth ?? 0) > depth)
        // pick the one that is the l
        .reduce<[string, ModifiedHistoricalDataResponse] | undefined>((champEntry, challengerEntry) => {
            if (!champEntry) {
                return challengerEntry;
            }
            // return the one that has a smaller depth
            if (champEntry[1].position?.depth < challengerEntry[1].position.depth) {
                return champEntry;
            }
            return challengerEntry;
        }, undefined);

    return nextLowest?.[0];
};
