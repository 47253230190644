import { useCallback } from "react";

import { useInterval } from "../../hooks/useInterval";
import { POLLING_FREQUENCIES, refreshChangedDevices, shouldPoll } from "./devicePollingService";

interface UseDevicePollingParams {
    pollingFrequency: (typeof POLLING_FREQUENCIES)[keyof typeof POLLING_FREQUENCIES];
    tickControlFn?: () => boolean;
}

/**
 * Creates a polling interval for fetching devices that have updated information
 * @param pollingFrequency - The frequency at which the devices should be polled
 * @param tickControlFn - A function that returns a boolean. If this returns false, the refresh will not be called this tick
 */
export function useDevicePolling({ pollingFrequency, tickControlFn }: UseDevicePollingParams) {
    const onTick = useCallback(async () => {
        if (shouldPoll(pollingFrequency) && (tickControlFn ? tickControlFn() : true)) {
            refreshChangedDevices({});
        }
    }, [pollingFrequency, tickControlFn]);

    useInterval({
        callback: onTick,
        delay: pollingFrequency,
    });
}
