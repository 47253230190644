import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function WatermarkIcon(props) {
    let viewBox = "0, 0,  32, 32";
    if (props.slim) {
        viewBox = "7.558, 0, 16.882, 32";
    }
    return (
        <SvgIcon viewBox={viewBox} {...props}>
            {/* <path */}
            {/*    d="M10.624 5.047a.439.439 0 0 0-.175.118c-.023.036-.042.819-.042 1.74l-.001 1.675.822-.035c.452-.02 3.086-.036 5.853-.036h5.032l-.001-1.639c0-.902-.019-1.669-.042-1.704a.472.472 0 0 0-.192-.122c-.202-.077-11.061-.074-11.254.003m1.258 8.706.015 4.051.745 1.339.745 1.34v3.406c0 2.856.013 3.423.077 3.512.074.101.175.105 2.669.12 1.426.009 2.646.003 2.711-.013.301-.075.288.09.288-3.613v-3.412l.745-1.34.746-1.339.014-4.051.015-4.051h-.815l-.001 3.97-.001 3.97-.704 1.272-.703 1.273V9.702h-4.347l.019 5.257c.01 2.891.016 5.257.013 5.257-.004 0-.327-.581-.718-1.291l-.713-1.291V9.702h-.815l.015 4.051" */}
            {/*    fillRule="evenodd" */}
            {/* /> */}
            <path
                style={{
                    fillOpacity: 1,
                    strokeWidth: 0.2,
                    strokeLinecap: "butt",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                }}
                d={
                    "M12 4.293V5.92h-1.333l.001 9.107c0 5.184.022 9.366.05 9.71.068.817.226 1.426.536 2.063.293.603.571.963 1.069 1.387.594.505 1.707.976 2.538 1.074.157.019.286.054.286.08 0 .025.384.046.853.046s.853-.021.853-.046c0-.026.129-.061.286-.08.448-.053 1.193-.283 1.671-.516 1.282-.626 2.069-1.708 2.403-3.301.076-.359.087-1.477.104-9.964l.02-9.56H20V2.667h-8v1.626m8.04 11.8-.017 8.894-.118.443c-.352 1.323-1.14 2.12-2.43 2.458-1.714.449-3.57.101-4.515-.847-.593-.595-.866-1.276-.96-2.401-.03-.36-.052-4.085-.052-9.027L11.947 7.2h8.11l-.017 8.893m-6.096-6.959c-.645.262-.975.779-.916 1.434.106 1.165 1.545 1.666 2.332.813.282-.307.388-.559.39-.928.002-.754-.538-1.327-1.287-1.366-.204-.011-.426.009-.519.047m3.523 3.42c-.499.236-.77.671-.772 1.24a1.11 1.11 0 0 0 .147.612c.517.99 1.905.99 2.422 0 .272-.519.159-1.227-.257-1.616a1.43 1.43 0 0 0-1.54-.236m-3.562 3.253c-.797.222-1.23 1.179-.864 1.907.221.437.767.793 1.218.793.775 0 1.391-.616 1.388-1.387a1.366 1.366 0 0 0-1.742-1.313m3.872 3.662c-.322.064-.511.176-.748.446a1.357 1.357 0 0 0 .05 1.854c.289.288.544.391.974.391.772 0 1.356-.559 1.359-1.3.002-.416-.094-.676-.357-.968a1.345 1.345 0 0 0-1.278-.423m-4.15 3.511c-.53.241-.826.694-.825 1.26.001.781.579 1.359 1.358 1.359.78 0 1.356-.577 1.359-1.362a1.333 1.333 0 0 0-1.359-1.355c-.196 0-.403.038-.533.098"
                }
                fillRule={"evenodd"}
            />
            {/* <path */}
            {/*    style={{ */}
            {/*        fillOpacity: 1, */}
            {/*        strokeWidth: 0.2, */}
            {/*        strokeLinecap: "butt", */}
            {/*        strokeLinejoin: "round", */}
            {/*        strokeMiterlimit: 4, */}
            {/*        strokeDasharray: "none", */}
            {/*        strokeOpacity: 1, */}
            {/*    }} */}
            {/*    d="M7.195 3.687v-.835h2.895v.831" */}
            {/*    transform="translate(-4.34 -1.43)" */}
            {/* /> */}
            {/* <path */}
            {/*    style={{ */}
            {/*        fill: "none", */}
            {/*        stroke: "#000", */}
            {/*        strokeWidth: 0.2, */}
            {/*        strokeLinecap: "butt", */}
            {/*        strokeLinejoin: "round", */}
            {/*        strokeMiterlimit: 4, */}
            {/*        strokeDasharray: "none", */}
            {/*        strokeOpacity: 1, */}
            {/*    }} */}
            {/*    d="M7.589 3.99v2.12l.394.713V8.61h1.32V6.823l.393-.714V3.99" */}
            {/*    transform="translate(-4.34 -1.43)" */}
            {/* /> */}
            {/* <path */}
            {/*    style={{ */}
            {/*        fillOpacity: 1, */}
            {/*        stroke: "none", */}
            {/*        strokeWidth: ".264583px", */}
            {/*        strokeLinecap: "butt", */}
            {/*        strokeLinejoin: "miter", */}
            {/*        strokeOpacity: 1, */}
            {/*    }} */}
            {/*    d="M7.983 6.823h.095V3.99h1.136v2.833h.089V8.61h-1.32V6.823" */}
            {/*    transform="translate(-4.34 -1.43)" */}
            {/* /> */}
        </SvgIcon>
    );
}
