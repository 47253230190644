import Typography from "@mui/material/Typography";
import React from "react";

import { LengthUnitTextField } from "../../../DeprecatedInputs/LengthUnitTextField";
import SensorSettingsBase from "./SensorSettingsBase";

export default class TensiometerSensorSettings extends SensorSettingsBase {
    acceptableRangeUnitCategory = "pressure";

    defaultAcceptableValueRange = [0, 100];

    loadSettingsFromDevice(device, sensor) {
        const values = super.loadSettingsFromDevice(device, sensor);
        values.depth = sensor.depth;
        values.depthString = null;
        values.soilCapacity = sensor.userMetadata?.soilCapacity || 1.0;
        values.soilCapacityText = Math.round((sensor.userMetadata?.soilCapacity || 1.0) * 100).toString();
        return values;
    }

    writeSettingsToDevice(values, device, sensor) {
        super.writeSettingsToDevice(values, device, sensor);
        if (values.depth !== undefined) {
            sensor.depth = values.depth;
        }
        if (!sensor.userMetadata) {
            sensor.userMetadata = {};
        }
        sensor.userMetadata.soilCapacity = values.soilCapacity;
        sensor.polynomialPostProcessing = [0, 1.0 / values.soilCapacity];
    }

    renderSupplementalSettings(values) {
        return (
            <div className={"SettingsItem"}>
                <Typography>Depth: </Typography>
                <LengthUnitTextField
                    label={"Depth"}
                    useCase={"moistureSensorDepth"}
                    defaultValue={values.depthString ? undefined : values.depth}
                    value={values.depthString}
                    onChange={(meters, text) => {
                        this.onChange({
                            depthString: text,
                            depth: meters,
                        });
                    }}
                    style={{ maxWidth: "10rem" }}
                />
            </div>
        );
    }
}
