import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

export default class ServiceDownMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessage: Date.now() < new Date(2023, 8, 10).valueOf(),
        };
    }

    clear() {
        this.setState({ showMessage: false });
        this.props.onClose(0);
    }

    render() {
        return (
            <Dialog
                open={this.state.showMessage && !this.props.cleared}
                onClose={() => {
                    this.clear(0);
                }}
                aria-labelledby={"alert-dialog-title"}
                ara-describedby={"alert-dialog-description"}
            >
                <DialogTitle>Current Interruption of Service (updated Sept. 5th)</DialogTitle>
                <DialogContent>
                    <DialogContentText variant={"h4"}>Status: Resolved</DialogContentText>
                    <br />
                    <DialogContentText>
                        Currently devices are working properly and our telecom partner (Teal Communications) has marked
                        this issue as resolved. Thank you for your patience in handling this issue.
                    </DialogContentText>
                    <br />

                    <DialogContentText variant={"h4"}>Next Steps:</DialogContentText>
                    <br />
                    <DialogContentText>
                        We understand that outages are very disruptive. Preparing for potential future outages, our
                        goals are to prevent occurrences, mitigate damages, and notify customers.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        <b>Prevention:</b> To remove the point of failure that caused this outage, we are going to start
                        using local sim cards instead of global sim cards.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        <b>Mitigation:</b> To ensure service in the event of a future Teal outage we will use multiple
                        internet service providers instead of just Teal.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        <b>Notification:</b> To keep you better informed we are going to add automated systems to alert
                        you sooner and provide a public system status dashboard.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        We want to do our best keeping you informed and online. If you have feedback about how we
                        handled this outage, please do not hesitate to pass it along. There is a button in the top right
                        corner to write to us, or you can reach out directly to one of our team members.
                    </DialogContentText>
                    <br />
                    <DialogContentText>We are very sorry for any inconvenience this caused,</DialogContentText>
                    <br />
                    <DialogContentText>The Verdi Team</DialogContentText>
                    <br />
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.clear();
                        }}
                        color={"primary"}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
