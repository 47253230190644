import "./DeviceMarkerIcon.scss";

import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function WatermarkMarkerIcon(props) {
    return (
        <SvgIcon onClick={props.onClick} viewBox={"-4, -4, 32, 32"} {...props} className={"MarkerIcon"}>
            <path
                id={"path868"}
                d={
                    "m 23.99599,12.042984 c 0,3.309738 -1.340265,6.306441 -3.507666,8.476981 -5.738759,0.03256 -11.1701454,0.027 -16.9776666,9.9e-4 -2.1679842,-2.170618 -3.50865754,-5.16773 -3.50865754,-8.477973 0,-6.6257663 5.37123774,-11.99700348 11.99699514,-11.99700338 6.625757,0 11.996995,5.37123708 11.996995,11.99700338 z"
                }
                className={"MarkerIconRim"}
            />
            <path
                id={"ellipse4673"}
                style={
                    {
                        // fill: "#8bc34a",
                        // strokeWidth: 0.119313,
                        // strokeLinecap: "round",
                        // strokeLinejoin: "round",
                        // strokeMiterlimit: 4,
                        // strokeDasharray: "none",
                    }
                }
                className={"MarkerIconOuterCircle"}
                d={
                    "M23.996 12c0 3.31-1.34 6.306-3.508 8.477-5.738.032-11.17.027-16.977 0A11.96 11.96 0 0 1 .002 12C.002 5.374 5.373.003 11.999.003c6.626 0 11.997 5.37 11.997 11.997z"
                }
            />
            <path
                id={"path18458"}
                style={{ fill: "#ffffff" }}
                d={
                    "M12 2.1C8.23 2.051 4.582 4.39 3.025 7.816a9.923 9.923 0 0 0-.844 5.454c2.22-.897 4.583-1.411 6.963-1.643V5.314c.23-.074.793.184.716-.198V3.574h4.223v1.74c.23.075.793-.183.716.2v6.113c2.399.234 4.781.755 7.017 1.667.523-3.644-1.21-7.477-4.281-9.503A9.869 9.869 0 0 0 12 2.1ZM9.818 5.988c.005 3.099-.01 6.198.008 9.296-.008.877.64 1.691 1.529 1.786 1.021.203 2.397-.072 2.674-1.241.181-.997.062-2.02.096-3.029V5.988c-1.426.008-2.881.008-4.307 0zm1.291 1.006c1.257-.002.636 2.048-.41 1.342-.598-.37-.294-1.37.41-1.342zm1.957 1.78c1.257 0 .635 2.048-.41 1.343-.601-.37-.295-1.37.41-1.342zm-2.008 1.783c1.256-.002.635 2.048-.41 1.342-.6-.37-.295-1.37.41-1.342zm2.008 1.963c1.257-.002.635 2.048-.41 1.342-.601-.37-.296-1.37.41-1.342zm-2.076 1.83c1.256-.002.635 2.048-.41 1.342-.6-.37-.295-1.37.41-1.342z"
                }
                className={"MarkerIconInnerCircle"}
            />
            <ellipse id={"path824"} cx={"22"} cy={"2"} rx={"5"} ry={"5"} className={"AlertBubble"} />
        </SvgIcon>
    );
}
