import "../HelpCSS.css";

import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "../HelpBase";
import HelpImage from "../HelpImage";

export function SettingRelativeIrrigationDuration(props) {
    return (
        <HelpBase
            Title={"Relative irrigation duration"}
            Target={props.children}
            className={props.className}
            style={props.style}
        >
            <Typography className={"StepDetails"}>
                A zone's <b>relative irrigation duration</b> is how much you want this zone to be irrigated compared to
                others.
            </Typography>
            <br />
            <Typography className={"StepDetails"}>
                Setting this will mean that new multi-zone events will default to being longer or shorter based on this
                percentage. It will not affect any events that have already been created.
            </Typography>
            <br />
            <HelpImage
                Image={"ActualSchedulerRelativeIrrDurExample.webp"}
                Caption={
                    "In this image, Example Zone 2 has an relative irrigation duration of +100% " +
                    "compared to Example Zone 1. This means Example Zone 2 will be irrigated for twice as long"
                }
            />
            <br />
            <Typography className={"StepDetails"}>
                The <b>recommended value</b> is a machine learning generated value based on past irrigation data. This
                feature is currently experimental.
            </Typography>
            <br />
            {/* <Typography className={"StepDetails"}> */}
            {/*    If <b>"Automatically update to recommended value"</b> is toggled on, the relative irrigation */}
            {/*    duration will be automatically updated every time when a new recommended value is recalculated. */}
            {/* </Typography> */}
            {/* <br /> */}
            <HelpImage
                Image={"RelativeIrrDurSlider.webp"}
                Caption={'Clicking on the "Recommended" button will set the slider to the recommended value.'}
            />
            <br />
            <Typography className={"StepDetails"}>
                The <b>example scheduler</b> provides a preview of what this zone's irrigation events will look like
                compared to other zones in the actual scheduler.
            </Typography>
            <br />
            <HelpImage
                Image={"RelativeIrrDurExampleScheduler.webp"}
                Caption={
                    "For example, in this image, this zone has an relative irrigation duration of +100% compared to other zones."
                }
            />
        </HelpBase>
    );
}
