import "./TrendGraphChips.scss";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import { GeneralUtil } from "gardenspadejs";
import { GenerateUID } from "gardenspadejs/dist/general";
import React from "react";

import SimpleBarCompatability from "../../generic/SimpleBarCompatability/SimpleBarCompatability";
import { DynamicGraphContext } from "./DynamicGraphUtility";
import TrendGraphChip from "./TrendGraph/TrendGraphChip";

export default class TrendGraphChips extends React.Component {
    numberOfChips = 0;

    constructor(props) {
        super(props);
        this.uid = GenerateUID("TrendGraphChips");
        this.state = {
            needArrows: false,
        };
        this.scrollableContainer = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.scrollableContainer.current) {
                if (this.scrollableContainer.current.scrollWidth > this.scrollableContainer.current.clientWidth) {
                    this.setState({
                        needArrows: true,
                    });
                }
            }
        }, 50);
    }

    shift(shiftRight = true) {
        const elem = this.scrollableContainer.current;
        if (elem) {
            let amountToScrollBy = 100;
            const endOfScroll = elem.scrollWidth - elem.clientWidth;
            if (this.numberOfChips) {
                amountToScrollBy = GeneralUtil.clamp((elem.scrollWidth / this.numberOfChips) * 2, 50, elem.clientWidth);
            }
            if (!shiftRight) {
                amountToScrollBy *= -1;
            }
            let newLeftPos = GeneralUtil.clamp(elem.scrollLeft + amountToScrollBy, 0, endOfScroll);
            if (Math.abs(newLeftPos - endOfScroll) < Math.abs(amountToScrollBy / 3)) {
                newLeftPos = endOfScroll;
            }
            if (Math.abs(newLeftPos) < Math.abs(amountToScrollBy / 3)) {
                newLeftPos = 0;
            }
            elem.scrollTo({
                left: newLeftPos,
                top: 0,
                behavior: "smooth",
            });
        }
    }

    render() {
        let className = `TrendGraphChips TrendGraphChips--row ${this.props.className || ""}`;
        if (this.state.needArrows) {
            className += "TrendGraphChips--showArrows";
        }
        this.numberOfChips = 0;
        return (
            <div className={className} id={this.uid}>
                <SimpleBarCompatability
                    className={"HorizontalScroll"}
                    style={{ flexGrow: 10, flexDir: "row" }}
                    scrollableNodeProps={{
                        ref: this.scrollableContainer,
                        onWheel: (event) => {
                            event.currentTarget.scrollTop = 0;
                            if (!event.deltaY) {
                                return;
                            }
                            const elem = this.scrollableContainer.current;
                            if (elem) {
                                elem.scrollTo({
                                    left: elem.scrollLeft + event.deltaY + event.deltaX,
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }
                            event.preventDefault();
                        },
                    }}
                >
                    <IconButton className={"ArrowScroll ArrowScroll-padding"} size={"large"}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    {Array.isArray(Object.keys(this.context.lines)) &&
                        Object.keys(this.context.lines).map((lineID) => {
                            const line = this.context.lines[lineID];
                            if (line.parentGraph !== this.props.parentGraphID) {
                                return null;
                            }
                            this.numberOfChips++;
                            return <TrendGraphChip sensor={line} lineID={lineID} key={`${lineID}`} />;
                        })}
                    <IconButton className={"ArrowScroll ArrowScroll-padding"} size={"large"}>
                        <ArrowBackIosIcon />
                    </IconButton>
                </SimpleBarCompatability>
                <IconButton className={"ArrowScroll ArrowScroll-left"} onClick={() => this.shift(false)} size={"large"}>
                    <ArrowForwardIosIcon style={{ transform: "rotate(180deg)" }} />
                </IconButton>
                <IconButton className={"ArrowScroll ArrowScroll-right"} onClick={() => this.shift()} size={"large"}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </div>
        );
    }
}

TrendGraphChips.contextType = DynamicGraphContext;
