import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps } from "@mui/material";
import { useState } from "react";

interface SelectDropdownProps {
    multiple?: boolean;
    disabled?: boolean;
    onChange?: (value: string) => void;
    options: { value: string; label: string }[];
    label?: string;
    defaultValue?: string;
    value?: string;
    formControlSx?: SxProps;
    sx?: SxProps;
}
export function SelectDropdown({
    multiple = false,
    disabled = false,
    onChange = () => {},
    options,
    label = undefined,
    defaultValue,
    value: controlledValue,
    formControlSx = {},
    sx = {},
}: SelectDropdownProps) {
    // Ignored in favor of
    const [localValue, setLocalValue] = useState(defaultValue || (options && options[0].value));
    // Need controlled to allow click-away hiding
    const [open, setOpen] = useState(false);

    const handleChange = (event: SelectChangeEvent) => {
        if (controlledValue === undefined) {
            setLocalValue(event.target.value);
        }
        onChange(event.target.value);
    };
    return (
        <FormControl
            size={"small"}
            sx={{
                "m": 1,
                "&:hover": {
                    backgroundColor: "#F2F0F1",
                },
                ...formControlSx,
            }}
        >
            <InputLabel id={`${label}-label`}>{label}</InputLabel>
            <Select
                labelId={`${label}-select`}
                label={label}
                multiple={multiple}
                defaultValue={defaultValue}
                disabled={disabled}
                open={open}
                onOpen={() => setOpen(!open)}
                onClose={() => setOpen(false)}
                sx={{
                    // Override the hover border color change
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    // Override focus state
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                        borderWidth: "1px",
                    },
                    ...sx,
                }}
                value={controlledValue !== undefined ? controlledValue : localValue}
                onChange={handleChange}
                displayEmpty
                MenuProps={{
                    elevation: 0,
                    sx: {
                        "top": 5, // Aligns the dropdown with the input

                        "& .MuiMenu-paper": {
                            border: "1px solid",
                            borderColor: "rgba(0, 0, 0, 0.13)",
                        },

                        // Override menu item styles
                        "& li.MuiButtonBase-root": {
                            padding: "8px",
                            marginTop: "4px",
                            marginBottom: "4px",
                            marginLeft: "8px",
                            marginRight: "8px",
                            borderRadius: "4px",
                        },
                        // Override menu item selected state
                        "&& .Mui-selected": {
                            backgroundColor: "#F2F0F1",
                        },
                    },
                }}
            >
                {options &&
                    options.map((option) => (
                        <MenuItem key={`${option.value}_${option.label}`} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
}
