/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import { Typography } from "@mui/material";
import React from "react";

// imports it's own source code
import source from "!!raw-loader!./LightTooltipDemo.jsx";

import { LightTooltip } from "./LightTooltip";
import readme from "./README.md";

function DemoComponent() {
    return (
        <div>
            <LightTooltip title={"Title Text"}>
                <Typography style={{ width: "fit-content" }}>Some Text to hover on </Typography>
            </LightTooltip>
        </div>
    );
}

const LightTooltipDemo = {
    component: DemoComponent,
    name: "LightTooltip",
    readme: readme,
    source: source,
};

export default LightTooltipDemo;
