import { IrrigationDeviceBase } from "verdiapi/dist/Models/Devices/IrrigationDeviceBase";
import { IrrigationDeviceSensor } from "verdiapi/dist/Models/Devices/IrrigationDeviceSensor";
import { DataPoint } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { Zone } from "verdiapi/dist/Models/Zone";
import { DataTypeSpecifier, SensorConfig, SensorType } from "verditypes";

export type DataStreamSearchScope = {
    dataStreams: [];
    devices: IrrigationDeviceBase[];
    zones: Zone[];
};

const colorPreferenceTable = {
    watermark: "yellows",
    irrometer: "greens",
    pressureTransducer: "oranges",
    v1transducer: "reds",
    v1flowmeter: "blues",
    teros_vol: "purples",
    veggie_vol: "purples",
};
export type GraphDataStream = {
    id: string;
    uid: string;
    connectedZones: Zone[];
    name: string;
    sensorType: SensorType;
    dataType: DataTypeSpecifier;
    automationRange?: number[];
    visible: boolean;
    parentGraph?: string;
    lastDataPoint: DataPoint;
    colorPreference?: string;
    startDate?: Date;
    endDate?: Date;
};

/**
 * Given a particular scope, this function finds data streams that should be represented in that scope.
 * You can give it a list of data streams, which will all be included,
 * a list of devices, which will have all sensors on them included,
 * and a list of zones, which will have all devices in them with sensors that are also relevant to them included
 *
 * Currently if a sensor has a relevant zone that is in the zones list, but the device the sensor is attached to isn't
 * connected to a zone in the zones list, then the sensor will not be included. This is an oversight, but isn't going
 * to be corrected at this time.
 * @param dataStreams
 * @param devices
 * @param zones
 * @return {[GraphDataStream[], string]}
 */
export function generateDataStreamList({
    dataStreams = [],
    devices = [],
    zones = [],
}: DataStreamSearchScope): [GraphDataStream[], string] {
    const originalScope = {
        dataStreams,
        devices,
        zones,
    };
    const allDataStreams: GraphDataStream[] = [...dataStreams];
    const devicesToProcess = new Set([...devices]);

    // find all devices in zones
    zones.forEach((zone) => {
        zone.devices.forEach((d) => {
            devicesToProcess.add(d);
        });
        // @ts-ignore
        if (zone.supplementalZoneGraphInfo?.supplementalDataStreams) {
            // @ts-ignore
            zone.supplementalZoneGraphInfo?.supplementalDataStreams.forEach((supplementalDataStream) => {
                let startDate;
                if (supplementalDataStream.startDate) {
                    startDate = new Date(supplementalDataStream.startDate);
                }
                let endDate;
                if (supplementalDataStream.endDate) {
                    endDate = new Date(supplementalDataStream.endDate);
                }
                allDataStreams.push({
                    id: supplementalDataStream.sourceID,
                    uid: supplementalDataStream.sourceID + supplementalDataStream.dataType,
                    // @ts-ignore
                    connectedZones: undefined,
                    name: supplementalDataStream.name,
                    sensorType: supplementalDataStream.sensorType,
                    dataType: supplementalDataStream.dataType,
                    visible: true,
                    startDate: startDate,
                    endDate: endDate,
                    // @ts-ignore
                    colorPreference: colorPreferenceTable[supplementalDataStream.sensorType],
                });
            });
        }
    });

    const allDevices = Array.from(devicesToProcess);

    // get data streams from these devices
    allDataStreams.push(...generateDataStreamsFromDeviceList(allDevices, originalScope));

    // create a hash. This will be useful for telling the graph when theresult of this function has changed, indicating
    // it needs a redraw. Currently unused, but should be used for performance reasons.
    const hash = allDataStreams
        .map((ds) => ds.uid)
        .sort()
        .join("#");
    return [allDataStreams, hash];
}

/**
 * Gets all the data streams within a scope from a list of devices. by going through all the sensors attatched to
 * those devices.
 * @param devices
 * @param scope
 * @return {GraphDataStream[]}
 */
export function generateDataStreamsFromDeviceList(
    devices: IrrigationDeviceBase[],
    scope: DataStreamSearchScope,
): GraphDataStream[] {
    const dataStreamsToReturn: GraphDataStream[] = [];
    devices.forEach((device) => {
        if (Array.isArray(device.connectedSensors)) {
            device.connectedSensors.forEach((connectedSensor, sensorPort: number) => {
                const dataStream = getDataStreamForOnDeviceSensor(device, connectedSensor, sensorPort, scope);
                if (dataStream) {
                    dataStreamsToReturn.push(dataStream);
                }
            });
        }

        // since sensoterra sensors are extra special (they don't have connected sensors because they were added
        // before we had third party device infrastrcture) we need to treat them special.
        // msense = sensoterra moisture sensor
        // they always have a single sensoterra moisutre sensor as the only connected sensor
        if (device.type === "msense") {
            const uid = `${device.id}sensoterra`;
            dataStreamsToReturn.push({
                id: device.id,
                uid: uid,
                // @ts-ignore
                connectedZones: device.connectedZones,
                name: device.name,
                // @ts-ignore
                sensorType: "sensoterra",
                dataType: "moisture",
                visible: true,

                // @ts-ignore
                lastDataPoint: device.lastDataPoint,
                colorPreference: "pinks",
            });
        }
    });
    return dataStreamsToReturn;
}

export function isOnDeviceSensorInScope(
    device: IrrigationDeviceBase,
    connectedSensor: IrrigationDeviceSensor,
    // @ts-ignore
    sensorPort: number,
    scope: DataStreamSearchScope,
): boolean {
    if (!connectedSensor) {
        return false;
    }
    // no sensor type means it's not an actual sensor, it's just a dummy value
    if (!connectedSensor.sensorType) {
        return false;
    }
    if (scope.devices.includes(device)) {
        return true;
    }
    if (connectedSensor.getConnectedZoneIDs) {
        // if some of the connected zoneIDs are in the connectedZone scope, then the device sensor is in scope
        if (
            connectedSensor
                .getConnectedZoneIDs(device)
                .some((connectedZoneID: string) => scope.zones.some((z) => (z?.id || z) === connectedZoneID))
        )
            return true;
    }
    return false;
}

/**
 * Generates a data stream object with things like color preference, a label,
 * etc. based on properties of the device and it's sensor. If the sensor doesn't exist
 * or is out of scope, then this returns undefined.
 * @param device
 * @param connectedSensor
 * @param sensorPort
 * @param scope
 */
export function getDataStreamForOnDeviceSensor(
    device: IrrigationDeviceBase,
    // @ts-ignore
    connectedSensor,
    sensorPort: number,
    scope: DataStreamSearchScope,
): void | Omit<GraphDataStream, "parentGraph"> {
    if (!connectedSensor) {
        return undefined;
    }
    if (!isOnDeviceSensorInScope(device, connectedSensor, sensorPort, scope)) {
        return undefined;
    }

    const uid = device.id + sensorPort.toString() + connectedSensor.sensorType;
    let name = device.name;
    if (device.name && device.name.includes("eui")) {
        name = device.labelText;
    }

    let automationRange;
    if (
        device.saplingAutomationSettings &&
        device.saplingAutomationSettings.automationEnabled &&
        device.saplingAutomationSettings.targetSensor === sensorPort
    ) {
        // @ts-ignore
        automationRange = device.saplingAutomationSettings.targetRawRange.map((r) =>
            // @ts-ignore
            IrrigationDeviceSensor.convertRawValueToReading(r, connectedSensor),
        );
    }

    // this should always be set on a connected sensor. If it isn't then it's a problem.
    if (!connectedSensor.sensorType) {
        console.warn("NO SENSOR TYPE:", device, connectedSensor);
    }

    const sensorDataType = SensorConfig.SensorConfigurations[connectedSensor.sensorType as SensorType]?.parsedDataType; // @ts-ignore

    if (!sensorDataType) {
        console.warn(`Graph error!`);
        console.warn(`Sensor Type ${connectedSensor.sensorType} has no associated data type!`);
        console.warn(
            `configuration of ${connectedSensor.sensorType}:`,
            SensorConfig.SensorConfigurations[connectedSensor.sensorType as SensorType],
        );
        return undefined;
    }
    return {
        id: device.id,
        uid: uid,
        connectedZones: connectedSensor.getConnectedZoneIDs(device),
        name: name,
        sensorType: connectedSensor.sensorType,
        dataType: sensorDataType,
        automationRange: automationRange,
        visible: true,
        lastDataPoint: connectedSensor.lastDataPoint,
        // @ts-ignore
        colorPreference: colorPreferenceTable[connectedSensor.sensorType],
    };
}
