import { Refresh } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { IconButtonWithTooltip } from "src/components/generic/IconButtonWithTooltip";
import { useTimeSince } from "src/hooks/useTimeSince";

interface LastUpdatedProps {
    deviceLastContact: string; // iso date string
    onClickRefresh: () => void;
    lastRefresh: number; // timestamp
}

const ANIMATION_DURATION = 500;
export function StatusTimers({ lastRefresh, deviceLastContact, onClickRefresh }: LastUpdatedProps) {
    const { t } = useTranslation(["specializedComponents"], { keyPrefix: "manualModeToggle.statusTimers" });
    const [refresh, setRefresh] = useState(false);
    const { timeSinceString } = useTimeSince({
        startTime: lastRefresh,
        timerTickInterval: 1000,
    });
    const handleRefresh = () => {
        // Handle spin animation
        setRefresh(true);
        setTimeout(() => {
            setRefresh(false);
        }, ANIMATION_DURATION);

        onClickRefresh();
    };

    return (
        <Stack direction={isMobile ? "column-reverse" : "row"} alignItems={isMobile ? "flex-start" : "center"}>
            <Typography variant={"caption"}>
                {t("lastContacted", { timeAgo: getTimeString(new Date(deviceLastContact), "yyyy/MM/dd hh:mm:ss nn") })}
            </Typography>
            <Stack direction={"row"} alignItems={"center"}>
                <Typography variant={"caption"} sx={{ marginLeft: isMobile ? "0px" : "4px" }}>
                    {t("refreshed", { timeAgo: timeSinceString })}
                </Typography>
                <IconButtonWithTooltip
                    icon={<Refresh sx={{ fontSize: "16px" }} />}
                    title={"Refresh"}
                    onClick={handleRefresh}
                    sx={{
                        "padding": "4px",
                        "&.spin": {
                            animation: `spin ${ANIMATION_DURATION}ms 1`,
                        },
                        "@keyframes spin": {
                            "0%": {
                                transform: "rotate(0deg)",
                            },
                            "100%": {
                                transform: "rotate(360deg)",
                            },
                        },
                    }}
                    className={refresh ? "spin" : ""}
                />
            </Stack>
        </Stack>
    );
}
