import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function SproutBlockValveIcon(props) {
    return (
        <SvgIcon viewBox={"0, 0, 8.2916508, 10.06577"} {...props}>
            <path
                style={{
                    fillRule: "evenodd",
                    strokeWidth: 0.464217,
                }}
                d={
                    "m 2.0646077,0.44965732 c -0.6307017,0 -1.13886415,0.50763411 -1.13886415,1.13833528 V 7.567427 c 0,0.5854446 0.43828475,1.0646166 1.00556465,1.1304006 V 9.6161128 H 2.4258916 V 8.7057623 h 0.4750116 0.024862 V 9.6013019 H 3.4203483 V 8.7057623 H 3.8985338 V 9.6013019 H 4.3931173 V 8.7057623 H 4.8818822 V 9.586491 H 5.3764656 V 8.7057623 h 0.014811 0.4739537 V 9.6161128 H 6.3592844 V 8.6978276 C 6.9274692,8.6329368 7.3659072,8.1535589 7.3659072,7.567427 V 1.5879926 c 0,-0.63070117 -0.5076347,-1.13833528 -1.1383353,-1.13833528 H 5.3912763 2.9009032 Z m 0.500931,1.00239108 h 1.5805512 1.5805508 c 0.2371896,2e-7 0.3866742,0.1798201 0.3866742,0.4141805 v 1.1801236 c 0.1751297,1.0161784 0.658034,1.6081517 0.658034,2.473975 0.002,0.7683029 -0.5596686,2.0691338 -2.1867463,2.2586857 -0.1422275,0.025901 -0.2883418,0.039143 -0.4379838,0.039143 -0.1491043,0 -0.2951345,-0.013434 -0.4369261,-0.039143 C 2.0806041,7.5905497 1.5188578,6.2890421 1.5208304,5.5203275 c 0,-0.8658233 0.4829042,-1.4577966 0.6580339,-2.473975 V 1.8662289 c 0,-0.2343604 0.1494851,-0.4141803 0.3866744,-0.4141805 z M 3.0558903,1.6297809 A 0.47493665,0.47493665 0 0 0 2.5808787,2.1047926 0.47493665,0.47493665 0 0 0 3.0558903,2.5798043 0.47493665,0.47493665 0 0 0 3.5309019,2.1047926 0.47493665,0.47493665 0 0 0 3.0558903,1.6297809 Z M 3.9096419,1.8276143 V 2.381971 H 5.5631904 V 1.8276143 Z M 4.1466188,3.224085 c -1.1913044,-2e-7 -2.1555374,0.9615878 -2.1555374,2.1528926 -1.1e-6,1.1913058 0.964233,2.1534215 2.1555374,2.1534215 1.1913054,0 2.1528928,-0.9621157 2.1528928,-2.1534215 0,-1.1913048 -0.9615874,-2.1528928 -2.1528928,-2.1528926 z M 4.1460899,3.82023 A 1.6010069,1.5566123 0 0 1 5.7467415,5.3769776 1.6010069,1.5566123 0 0 1 4.1460899,6.9337254 1.6010069,1.5566123 0 0 1 2.5449091,5.3769776 1.6010069,1.5566123 0 0 1 4.1460899,3.82023 Z"
                }
            />
        </SvgIcon>
    );
}
