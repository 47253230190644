import "../HelpCSS.css";

import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "../HelpBase";

export function WarningOffline(props) {
    return (
        <HelpBase
            Title={"Offline Warning"}
            Target={props.children}
            className={props.className}
            style={props.style}
            component={props.component}
        >
            <Typography className={"StepDetails"}>
                <b>Offline</b> means that your Verdi device has not been able to connect to the internet for at least 3
                hours. If the device is red, then this means it has not been connected for more than 6 hours.
            </Typography>
            <Typography className={"StepDetails"}>
                <b>Block valves</b> that are offline will close themselves and remain closed until they come back
                online.
            </Typography>
            <Typography className={"StepDetails"}>
                <b>Microvalves</b> that are offline will open themseleves and remain open until they come back online.
            </Typography>
            <Typography className={"StepDetails"}>
                To fix your device, ensure there are no large obstacles between the antenna and the gateway, check that
                the antenna is attached to the device, and verify there is no major damage to the device. If this does
                not resolve the issue, reach out to the Verdi team for support.
            </Typography>
        </HelpBase>
    );
}
