import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { getFirstDefined } from "gardenspadejs/dist/general";
import React, { Component } from "react";
import { SessionHandler } from "verdiapi";
import { DeviceAPI } from "verdiapi/dist/APICommands";

import ConfirmChoiceButton from "../../../generic/ConfirmChoiceButton/ConfirmChoiceButton";
import JSONDropdown from "../../../generic/JSONDropdown/JSONDropdown";
import NotesField from "../../../generic/NotesField/NotesField";
import WarningHeader from "../../../generic/WarningHeader/WarningHeader";
import { DeviceNetworkTest } from "../../DeviceOverview/DeviceNetworkTest";
import DeviceOverview from "../../DeviceOverview/DeviceOverview";
import { DeviceStatus } from "../../DeviceStatus/DeviceStatus";
import { ManualModeToggle } from "../../ManualModeToggle/ManualModeToggle";
import SaplingVerification from "../../SaplingVerification/SaplingVerification";
import { SproutAutomationConfiguration } from "../../SproutAutomationConfiguration/SproutAutomationConfiguration";
import SproutVerification from "../../SproutVerification/SproutVerification";

export class DeviceInfoCardBody extends Component {
    newConnectedZones = undefined;

    constructor(props) {
        super(props);
        this.Target = props.Target;
        this.state = {
            lat: this.Target.lat || 0,
            long: this.Target.long || 0,
            id: this.Target.id || 0,
            status: this.Target.status || "idle",
            zone: this.Target.zone || -1,
            MenuClickAnchor: null,
            openDialoge: undefined,
            SureYouWantToDeleteOpen: false,
            deleting: false,
            editingZones: false,
            ...this.state,
        };
        // this.setMenuAnchor(null);
    }

    render() {
        const V1ZoneDetails = {
            ZoneName: "Not Connected",
            ValveState: "No valve info",
        };
        const V2ZoneDetails = {
            ZoneName: "Not Connected",
            ValveState: "No valve info",
        };
        if (this.Target.ValveStates && this.Target.ValveStates[0]) {
            V1ZoneDetails.ValveState = this.Target.ValveStates[0];
        }
        if (this.Target.ValveStates && this.Target.ValveStates[1]) {
            V2ZoneDetails.ValveState = this.Target.ValveStates[1];
        }
        if (this.Target.ZonesByValve && this.Target.ZonesByValve[0]) {
            V1ZoneDetails.ZoneName = this.Target.ZonesByValve[0].name;
        }
        if (this.Target.ZonesByValve && this.Target.ZonesByValve[1]) {
            V2ZoneDetails.ZoneName = this.Target.ZonesByValve[1].name;
        }
        let deviceStatusComp = false;

        // TODO: revert this line
        deviceStatusComp = (
            <DeviceOverview
                Device={this.Target}
                showValveState={true}
                autoSave={true}
                editing={this.state.editingZones}
                onChange={(e) => {
                    console.info("Device change recorded");
                    console.info(e);
                    if (e.connectedZones) {
                        this.newConnectedZones = e.connectedZones;
                    }
                }}
            />
        );

        let RSSIInfo;
        if (this.Target.saplingNetworkInfo && SessionHandler.admin) {
            RSSIInfo = {
                lastContact: this.Target.saplingNetworkInfo.lastContact,
                rssiLevels: this.Target.saplingNetworkInfo.lastRSSI,
            };
        }
        return (
            <List className={"InfoPane_detailList"}>
                <WarningHeader warnings={this.Target.warnings} />
                {Boolean(deviceStatusComp !== false) && <ListItem style={{ padding: 0 }}>{deviceStatusComp}</ListItem>}
                {SessionHandler.admin && !this.state.editingZones && (
                    <ListItem>
                        <Button
                            onClick={() => {
                                this.setState({
                                    editingZones: true,
                                });
                            }}
                        >
                            {" "}
                            Edit connected Zones
                        </Button>
                    </ListItem>
                )}
                {SessionHandler.admin && this.state.editingZones && (
                    <ListItem>
                        <Button
                            onClick={() => {
                                if (this.newConnectedZones) {
                                    this.Target.edit({
                                        connectedZones: this.newConnectedZones.map(
                                            (zoneRef) => zoneRef.id || zoneRef._id || zoneRef.toString(),
                                        ),
                                    });
                                }

                                this.setState({
                                    editingZones: false,
                                });
                            }}
                        >
                            {" "}
                            Save edits
                        </Button>
                    </ListItem>
                )}
                {/* <BatteryStatusIcon Target={this.Target} listItem={true} /> */}
                {/* <SignalStrengthStatusIcon Target={this.Target} listItem={true} /> */}
                <SproutAutomationConfiguration device={this.Target} />
                <DeviceStatus Target={this.Target} />
                <SaplingVerification className={"VerificationInfoCardLine"} device={this.Target} listItem={true} />
                {this.Target.sproutIrrigationVerification && <SproutVerification device={this.Target} />}

                <ManualModeToggle device={this.Target} />

                <NotesField
                    notes={this.Target.notes}
                    onSave={async (v) => {
                        await this.Target.edit({ notes: v });
                        this.forceUpdate();
                    }}
                />

                {this.Target.createdAt &&
                    this.Target.createdAt.toDateString &&
                    this.Target.createdAt.valueOf() > 1000 * 60 * 60 * 24 * 364 && (
                        <ListItem>
                            <Typography variant={"subtitle2"}>
                                Installed on {this.Target.createdAt.toDateString()}
                            </Typography>
                            {/* <ListItemText secondary={`Installed on ${this.Target.createdAt.toDateString()}`} /> */}
                        </ListItem>
                    )}

                <ListItem
                    style={{
                        padding: 0,
                        justifyContent: "center",
                    }}
                >
                    <DeviceNetworkTest device={this.Target} />
                </ListItem>
                <Dialog open={getFirstDefined(this.state.SureYouWantToDeleteOpen, false)}>
                    <DialogContent>
                        <DialogContentText id={"alert-dialog-description"}>
                            Permanently delete the device {this.Target.id}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={this.state.deleting}
                            onClick={() => {
                                this.setState({ SureYouWantToDeleteOpen: false });
                            }}
                            color={"primary"}
                        >
                            Go back
                        </Button>
                        <Button
                            disabled={this.state.deleting}
                            onClick={() => {
                                this.setState({ deleting: true });

                                (this.Target.model || this.Target)
                                    .delete()
                                    .then(() => {
                                        this.setState({ SureYouWantToDeleteOpen: false });
                                    })
                                    .catch((e) => {
                                        // eslint-disable-next-line no-alert
                                        alert(`Error! ${e.toString()}`);
                                    });
                            }}
                            color={"primary"}
                            autoFocus
                        >
                            Delete Device
                        </Button>
                    </DialogActions>
                </Dialog>

                {SessionHandler.admin && (
                    <div style={{ display: "flex" }}>
                        <Button
                            style={{ width: "100%", marginTop: "8px" }}
                            onClick={async () => {
                                this.setState({
                                    jsonOpen: true,
                                });
                            }}
                        >
                            Raw Device JSON
                        </Button>
                    </div>
                )}
                {SessionHandler.admin && (
                    <>
                        <br />
                        <ConfirmChoiceButton
                            onClick={() => {
                                DeviceAPI.clearWarnings(this.Target.id);
                            }}
                        >
                            Admin: Clear Warnings
                        </ConfirmChoiceButton>

                        <ConfirmChoiceButton
                            onClick={() => {
                                DeviceAPI.clearVerification(this.Target.id);
                            }}
                        >
                            Admin: Clear Verification
                        </ConfirmChoiceButton>
                        <br />
                    </>
                )}

                <Dialog open={getFirstDefined(this.state.jsonOpen, false)}>
                    <DialogContent>
                        <JSONDropdown title={"recent data"} body={this.Target.recentData} />
                        <JSONDropdown title={"device details"} body={this.Target._lastJSON} />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={this.state.deleting}
                            onClick={() => {
                                this.setState({ jsonOpen: false });
                            }}
                            color={"primary"}
                        >
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
                {SessionHandler.admin && (
                    <div style={{ display: "flex" }}>
                        <Button
                            style={{ width: "100%", marginTop: "8px" }}
                            onClick={async () => {
                                if (this.Target.refresh) {
                                    await this.Target.refresh();
                                    this.forceUpdate();
                                }
                            }}
                        >
                            Refresh
                        </Button>
                    </div>
                )}
                {SessionHandler.admin && (
                    <Typography>
                        FW:
                        {
                            (this.Target.saplingNetworkInfo?.version || this.Target.sproutNetworkInfo?.version)
                                ?.firmwareVersion
                        }
                        {"\n"}
                        HW:
                        {
                            (this.Target.saplingNetworkInfo?.version || this.Target.sproutNetworkInfo?.version)
                                ?.hardwareVersion
                        }
                        {"\n"}
                        variant:
                        {
                            (this.Target.saplingNetworkInfo?.version || this.Target.sproutNetworkInfo?.version)
                                ?.deviceVariant
                        }
                        {"\n"}
                        DMUPort:
                        {(this.Target.saplingNetworkInfo?.version || this.Target.sproutNetworkInfo?.version)?.DMUport}
                    </Typography>
                )}
                {SessionHandler.admin &&
                    this.Target &&
                    this.Target.lastRefreshed &&
                    this.Target.lastRefreshed.toTimeString && (
                        <Typography> Last Refreshed: {this.Target.lastRefreshed.toTimeString()} </Typography>
                    )}
                {SessionHandler.admin && RSSIInfo && JSON.stringify(RSSIInfo)}
                <Button
                    style={{ width: "100%", backgroundColor: "#b00020", color: "white", marginTop: "8px" }}
                    onClick={() => {
                        this.setState({ SureYouWantToDeleteOpen: true });
                    }}
                >
                    Delete
                </Button>
            </List>
        );
    }
}
