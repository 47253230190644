import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

export default function WidgetLoadingState({ text = "Loading..." }) {
    return (
        <Stack sx={{ my: 2 }} direction={"row"} alignItems={"center"}>
            <CircularProgress sx={{ mr: 4 }} />
            <Typography variant={"h4"}>{text}</Typography>
        </Stack>
    );
}
