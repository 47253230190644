import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import React from "react";

import { WarningFrequentResets } from "../../HelpDialogues/ErrorsAndWarnings/WarningFrequentResets";
import { WarningLowAccuracy } from "../../HelpDialogues/ErrorsAndWarnings/WarningLowAccuracy";
import { WarningOffline } from "../../HelpDialogues/ErrorsAndWarnings/WarningOffline";
import { WarningPoorConnection } from "../../HelpDialogues/ErrorsAndWarnings/WarningPoorConnection";
import { WarningRTCSyncError } from "../../HelpDialogues/ErrorsAndWarnings/WarningRTCSyncError";

export default function DeviceStatusText(props) {
    const warning = props.warning;
    let statusText = props.statusText;
    let WarningComponent = React.Fragment;
    let showInfoIcon = false;
    let warningLevel = 0;
    const warningComponentLookup = {
        "offline": WarningOffline,
        "poor connection": WarningPoorConnection,
        "low accuracy": WarningLowAccuracy,
        "RTC sync error": WarningRTCSyncError,
        "frequent resets": WarningFrequentResets,
    };
    if (warning) {
        statusText = warning.warningType;
        warningLevel = warning.warningLevel;
        WarningComponent = warningComponentLookup[warning.warningType];
        if (WarningComponent) {
            showInfoIcon = true;
        } else {
            WarningComponent = React.Fragment;
        }
    }
    if (statusText) {
        // IMPORTANT! Replacing spaces with non breaking spaces!
        /// statusText.replace(" ", " ");
        statusText = statusText.replaceAll(" ", "\u00a0"); // statusText.replace(" ", "&nbsp;");
    }
    const warningLevelClassNames = ["", "WarningText", "WarningText WarningText--error"];

    return (
        // only sometimes a react fragment. can really be anything. A button or whatever.
        // eslint-disable-next-line react/jsx-fragments
        <WarningComponent>
            <Typography
                variant={"subtitle2"}
                component={"span"}
                className={warningLevelClassNames[warningLevel]}
                color={"textSecondary"}
            >
                {statusText}{" "}
                {showInfoIcon && (
                    <InfoOutlinedIcon
                        fontSize={"0.9rem"}
                        style={{
                            fontSize: "1rem",
                            marginTop: "-3px",
                        }}
                    />
                )}
            </Typography>
        </WarningComponent>
    );
}
