import CancelIcon from "@mui/icons-material/Cancel";
import CircleIcon from "@mui/icons-material/Circle";
import React from "react";

import { LightTooltip } from "../../generic/LightTooltip/LightTooltip";

export default function ErrorTooltipIcon({ tooltipText, subHeader, isInChip, ...propsToPass }) {
    let IconType = CancelIcon;
    if (subHeader) {
        IconType = CircleIcon;
    }
    if (isInChip) {
        return (
            <IconType
                style={{
                    color: "var(--error-icon-main)",
                    width: "1.2rem",
                    marginLeft: "0.2rem",
                }}
            />
        );
    }
    return (
        <LightTooltip title={tooltipText} {...propsToPass}>
            {/* <IconButton > */}
            <IconType
                style={{
                    width: subHeader ? "0.9rem" : "",
                    color: "var(--error-icon-main)",
                }}
            />
            {/* </IconButton> */}
        </LightTooltip>
    );
}
