import "./ZoneSelector.scss";

import { getFirstDefined } from "gardenspadejs/dist/general";
import React from "react";
import { IrrigationDeviceSensor } from "verdiapi/dist/Models/Devices/IrrigationDeviceSensor";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import TextFieldAlignmentOverride from "../../../generic/TextFieldAlignmentOverride/TextFieldAlignmentOverride";
import { SensorDropdown } from "../Sensors/SensorDropdown";
import CurrentIrrigationStatusBar from "./CurrentIrrigationStatusBar";

export default function ZoneSelector(props) {
    const showSensor = props.showSensor;
    const alignment = props.alignment || "left";
    let valveLabel;
    if (props.valveLabel) {
        valveLabel = props.valveLabel;
    } else if (props.valve !== undefined) {
        if (props.device && DeviceConfigurationsByType[props.device.type]?.valveCount === 0) {
            valveLabel = `Z${props.valve + 1}`;
        } else {
            valveLabel = `V${props.valve + 1}`;
        }
    } else if (props.device && DeviceConfigurationsByType[props.device.type]?.valveCount === 0) {
        valveLabel = "Z?";
    } else {
        valveLabel = "V?";
    }
    let sensorLabel;
    if (props.sensorLabel) {
        sensorLabel = props.sensorLabel;
    } else if (props.valve !== undefined) {
        sensorLabel = `S${props.valve + 1}`;
    } else {
        sensorLabel = "S?";
    }
    const blueTintBackground = getFirstDefined(props.blueTintBackground, false);
    const disableValve = props.valveDisabled;
    const shortText = getFirstDefined(props.shortText, window.innerWidth < 450);
    const attachedZone = props.zone;
    const attachedSensor = props.sensor;
    const activeClassName = `
            ${props.className || ""}
            ZoneSelect 
            ZoneSelectAlign--${alignment}
            ZoneSelectActiveValve--${props.active ? "active" : "inactive"}
        `;
    const wrapperClassName = `
            ${props.className || ""}
            ZoneSelectWrapper 
            ZoneSelectWrapperBlueTint--${blueTintBackground ? "enabled" : "disabled"}
            ZoneSelectWrapperAlign--${alignment}
            ZoneSelectWrapperActiveValve--${props.active ? "active" : "inactive"}
        `;
    let zoneName = "No Zone";
    if (props.editing && props.active) {
        zoneName = "Choose on Map...";
    }
    if (attachedZone) {
        zoneName = attachedZone.name;
    }
    const endCaps = [
        <div className={`ZoneSelectEdge ZoneSelectEdge__activity ZoneSelectEdgeAlign--${alignment}`} />,
        <div className={`ZoneSelectEdge ZoneSelectEdge__Details ZoneSelectEdgeAlign--${alignment} `} />,
    ];
    const showIrrigatingStatus = props.zone && props.device;
    let zoneFieldText = props.zoneFieldText;
    if (!zoneFieldText) {
        zoneFieldText =
            shortText || (props?.device?.type || "").includes("sense")
                ? `Zone ${props.valve + 1}`
                : `Valve ${props.valve + 1} Zone`;
    }

    return (
        <div className={wrapperClassName} style={props.style || {}}>
            {alignment === "right" ? endCaps[0] : endCaps[1]}
            <div
                className={` ${activeClassName}`}
                style={{
                    "--valveLabel": `"${valveLabel}"`,
                    "--sensorLabel": `"${sensorLabel}"`,
                }}
            >
                {showIrrigatingStatus && <CurrentIrrigationStatusBar device={props.device} zone={attachedZone} />}

                <TextFieldAlignmentOverride
                    className={"zoneSelectorTextField"}
                    disabled={disableValve}
                    align={alignment}
                    label={zoneFieldText}
                    size={"small"}
                    value={disableValve ? "N/A" : zoneName}
                    readOnly={true}
                    InputProps={{
                        readOnly: true,
                    }}
                    onClick={props.onClick || props.onInteract}
                    onFocus={props.onFocus || props.onInteract}
                />
                {showSensor && (
                    <SensorDropdown
                        alignment={alignment}
                        parentID={props.parentID}
                        label={`${sensorLabel} Sensor:`}
                        sensor={attachedSensor || new IrrigationDeviceSensor({})}
                        onChange={props.onSensorChange}
                        device={props.device}
                    />
                )}
            </div>
            {alignment === "right" ? endCaps[1] : endCaps[0]}
        </div>
    );
}
