import "./MapEntityComponents/ZonePoly.scss";

import { BufferedAction } from "gardenspadejs/dist/general";
import gsap from "gsap";

import ZoneIcon from "../../../components/icons/ZoneIcon";
import { FunctionalZoneInfoCard } from "../../../components/specialized/infoCards/ZoneInfoCard/FunctionalZoneInfoCard";
import { hsvToRgb, rgbToHsv } from "../../../utils/ColorStuff";
import { getNDVIColorComponents } from "../../../utils/GetNDVIColor";
import { GenerateUID } from "../../../utils/HelperFunctions";
import FocusContext from "../FocusContext";
import { addMapEntityPolygonToMap } from "../MapHelper";
import MapEntityBase from "./MapEntityBase";

export default class ZoneMapEntity extends MapEntityBase {
    static infoCardClass = FunctionalZoneInfoCard;

    infoCardClass = FunctionalZoneInfoCard;

    /**
     *
     * @type {zone}
     */
    model = undefined;

    icon = ZoneIcon;

    /**
     * @param {Zone} zone
     * @param {boolean} renderImmediately
     */
    constructor(zone, renderImmediately = true) {
        super(zone, GenerateUID("Z"), false);
        this.model = zone;
        if (this.model.vertices && this.model.vertices.length > 0) {
            const maxPoint = [-100000, -100000];
            const minPoint = [100000, 100000];
            this.model.vertices.forEach((vertice) => {
                maxPoint[0] = Math.max(maxPoint[0], vertice.lat);
                maxPoint[1] = Math.max(maxPoint[1], vertice.lng);
                minPoint[0] = Math.min(minPoint[0], vertice.lat);
                minPoint[1] = Math.min(minPoint[1], vertice.lng);
            });
            this.lat = (maxPoint[0] + minPoint[0]) / 2;
            this.long = (maxPoint[1] + minPoint[1]) / 2;
            this.model.onChange.addListener(() => {
                if (this.leafletElement) {
                    this.leafletElement.setLatLngs(this.model.polygon);
                }
            });
        }

        this.doTweenerUpdate = new BufferedAction(
            () => {
                try {
                    this.updateTweener();
                } catch (e) {
                    console.warn(e);
                }
            },
            100,
            true,
            false,
        );
        if (renderImmediately) {
            this.renderOnMap();
        }
    }

    renderOnMap() {
        if (!this.renderState.onMap && !this.renderState.beingRendered) {
            this.renderState.beingRendered = true;

            if (this.model?.recentData?.avgNDVI) {
                const zoneColor = getNDVIColorComponents(this.model.recentData.avgNDVI.value);
                let dim = rgbToHsv(...zoneColor);
                dim[2] *= 0.8;
                dim[1] = 1;
                dim = hsvToRgb(...dim);

                let dark = rgbToHsv(...zoneColor);
                dark[2] *= 0.6;
                dark[1] = 1;
                dark = hsvToRgb(...dark);
            }

            addMapEntityPolygonToMap(this, this.model.vertices)
                .then((r) => {
                    this.leafletElement = r.leafletPolygon;
                    this.leafletElement.on("click", (e) => {
                        FocusContext.onInteraction(e, this);
                    });
                    this.renderState.onMap = true;
                    this.renderState.beingRendered = false;

                    this.updateFocusState();
                })
                .catch(() => {
                    this.renderState.beingRendered = false;
                    this.renderState.onMap = false;
                });

            // addMapEntityPolygonToMap(this, this.model.vertices, { pane: "shadowPane", interactive: false })
            //     .then((r) => {
            //         //this.domElements.push(r.domElement);
            //         // r.domElement.classList.add(...this.DOMClassNames);
            //         // r.domElement.classList.add("ZoneShadowPolygon");
            //         // if (NDVIColours) {
            //         //     r.domElement.style.setProperty(
            //         //         "--status-color-normal",
            //         //         `rgba(${NDVIColours.zoneColor[0]}, ${NDVIColours.zoneColor[1]}, ${NDVIColours.zoneColor[2]})`
            //         //     );
            //         //     // r.domElement.style.setProperty("--status-color-dimmed", `rgba(${dim[0]}, ${dim[1]}, ${dim[2]})`)
            //         //     // r.domElement.style.setProperty("--status-color-dark", `rgba(${dark[0]}, ${dark[1]}, ${dark[2]})`)
            //         //     r.domElement.style.setProperty(
            //         //         "--dark-shade",
            //         //         `rgba(${NDVIColours.dark[0]}, ${NDVIColours.dark[1]}, ${NDVIColours.dark[2]})`
            //         //     );
            //         // }
            //         this.renderState.onMap = true;
            //         this.renderState.beingRendered = false;
            //
            //         this.updateFocusState();
            //     })
            //     .catch((e) => {
            //         this.renderState.beingRendered = false;
            //         this.renderState.onMap = false;
            //     });
        }
    }

    updateFocusState() {
        super.updateFocusState();
        this.doTweenerUpdate.trigger();

        if (this.focusState === "focused") {
            this.domElements.forEach((de) => {
                if (de.tagName === "path") {
                    try {
                        de.parentElement.appendChild(de);
                    } catch (e) {
                        console.warn(e);
                    }
                }
            });
        }
    }

    propsOfLeaflet = {
        color: "#82c926",
        weight: 1.5,
        opacity: 1,
        fillOpacity: 0.25,
        fillColor: "#4e9800",
        patternOpacity: 0,
    };

    lastTween = undefined;

    paintTweenUpdate() {
        const propsToSend = { ...this.propsOfLeaflet };
        propsToSend.imgId = propsToSend.patternOpacity > 0.05 ? "lamp" : null;
        if (this.leafletElement) {
            this.leafletElement.setStyle(propsToSend);
        }
    }

    updateTweener() {
        if (this.lastTween && this.lastTween.isActive instanceof Function && this.lastTween.isActive()) {
            this.lastTween.invalidate();
            this.lastTween = undefined;
            // return;
        }
        this.lastTween = gsap.to(this.propsOfLeaflet, {
            duration: 0.4,
            onUpdate: () => {
                this.paintTweenUpdate();
            },
            color: () =>
                ({
                    focused: "#26e00c",
                    selected: "#26e00c",
                    inactive: "#F0FFD3FF",
                })[this.focusState] || "#82c926",
            weight: () =>
                ({
                    focused: 3.5,
                    selected: 2,
                    inactive: 1,
                })[this.focusState] || 1.5,
            fillOpacity: () =>
                ({
                    focused: 0.35,
                    selected: 0.25,
                    inactive: 0.1,
                })[this.focusState] || 0.25,
            opacity: () =>
                ({
                    focused: 1,
                    selected: 1,
                    inactive: 0.4,
                })[this.focusState] || 1,
            fillColor: () =>
                ({
                    focused: "#26e00c",
                    selected: "#26e00c",
                    inactive: "#F0FFD3FF",
                })[this.focusState] || "#4e9800",
            patternOpacity: () =>
                ({
                    focused: 1,
                    selected: 0,
                    inactive: 0,
                })[this.focusState] || 0,
            ease: "power1.inOut",
        });
    }
}
