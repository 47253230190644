import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";

interface PositionDepthToggleProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function PositionDepthToggle({ checked, onChange }: PositionDepthToggleProps) {
    return (
        <Box>
            <Typography variant={"h4"}>Position and Depth</Typography>
            <Typography variant={"subtitle1"} sx={{ mb: 2 }}>
                Required when exporting multi-depth data types.
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={onChange}
                        sx={{ "&.Mui-checked": { color: "primary.main" } }}
                    />
                }
                label={"Include and split data by position and depth."}
            />
        </Box>
    );
}
