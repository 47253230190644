import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { minuteMs } from "verdiapi/dist/HelperFunctions";

import { timeElapsedString } from "../../utils/time";

interface UseTimeSinceParams {
    startTime: number; // Time in ms
    timerTickInterval?: number; // in ms
}
export function useTimeSince({ startTime, timerTickInterval = minuteMs }: UseTimeSinceParams) {
    const { t } = useTranslation(["common"], { keyPrefix: "time" });
    const [time, setTime] = useState(Date.now() - new Date(startTime).valueOf());

    // Immediately reset if startTime changes
    useEffect(() => {
        setTime(Date.now() - new Date(startTime).valueOf());
    }, [startTime]);

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now() - new Date(startTime).valueOf()), timerTickInterval);
        return () => {
            clearInterval(interval);
        };
    }, [startTime, timerTickInterval]);

    const timeSinceString = useMemo(() => timeElapsedString(time, t("day.char")), [time, t]);

    return {
        timeSince: time,
        timeSinceString: timeSinceString,
    };
}
