import "./ProvideFeedbackModal.scss";

import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { GenerateUID } from "gardenspadejs/dist/general";
import { useState } from "react";
import { SessionHandler } from "verdiapi";

import SimpleBarCompatability from "../../generic/SimpleBarCompatability/SimpleBarCompatability";

interface ProvideFeedbackModalProps {
    open: boolean;
    handleClose: () => void;
}

export function ProvideFeedbackModal({ open, handleClose }: ProvideFeedbackModalProps) {
    const uid = GenerateUID("ProvideFeedbackModal");

    const [name, setName] = useState("");
    const [feedbackString, setFeedbackString] = useState("");
    const [sendStatus, setSendStatus] = useState("composing");
    const [validationError, setValidationError] = useState({ name: false, feedback: false });

    const resetForm = () => {
        setSendStatus("composing");
        setFeedbackString("");
        setName("");
        setValidationError({ name: false, feedback: false });
    };

    const validateForm = () => {
        // Both strings must be non-empty
        if (!name.trim() || !feedbackString.trim()) {
            setSendStatus("error");
            setValidationError({ name: !name.trim(), feedback: !feedbackString.trim() });
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        setSendStatus("sending");

        // Reject submit if not valid
        if (!validateForm()) {
            return;
        }
        /**
         * API currently only accepts a single string - this feedback form is not used much,
         * so for convenience of avoiding changes to API, we append name directly to the feedback string
         */
        const feedbackWithName = `${name} - ${feedbackString}`;

        SessionHandler.doFetch("users/feedback", {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            redirect: "follow",
            body: JSON.stringify({ feedback: feedbackWithName }),
        })
            .then(() => {
                setSendStatus("success");
            })
            .catch(() => {
                setSendStatus("error");
            });
    };

    const onClose = () => {
        resetForm();
        handleClose();
    };

    const className = `ProvideFeedbackModal ProvideFeedbackModal--${sendStatus}`;
    return (
        <Dialog onClose={onClose} open={open} className={className} id={uid} maxWidth={"md"} fullWidth>
            <DialogTitle>Send us feedback!</DialogTitle>
            <DialogContent>
                <div className={`SuccessOverlay`}>
                    <Typography variant={"h3"} className={"ThanksText"}>
                        Thanks!
                    </Typography>
                    <Typography variant={"subtitle1"}>We appreciate your feedback!</Typography>
                    <Button
                        className={"FinishedFeedbackButton"}
                        variant={"contained"}
                        onClick={() => {
                            resetForm();
                            handleClose();
                        }}
                    >
                        Close
                    </Button>
                </div>
                <SimpleBarCompatability>
                    <Typography variant={"subtitle1"}>
                        Whether you're having a problem, have a feature you want to see, or just want to tell us what
                        you think, we read every piece of feedback we receive and we use it to inform our decisions.
                    </Typography>
                    <TextField
                        className={"FeedbackTextInput"}
                        error={validationError.name}
                        label={"Full name"}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        helperText={validationError.name ? "Full name is required" : ""}
                    />

                    <TextField
                        className={"FeedbackTextInput"}
                        error={validationError.feedback}
                        label={"Feedback"}
                        multiline
                        minRows={6}
                        value={feedbackString}
                        onChange={(e) => {
                            setFeedbackString(e.target.value);
                        }}
                        helperText={validationError.feedback ? "Feedback is required" : ""}
                    />

                    <Typography variant={"subtitle1"}>
                        Note: If you require immediate assistance, please phone your contact at Verdi directly.
                    </Typography>
                </SimpleBarCompatability>
                <Button className={"SubmitButton"} variant={"contained"} onClick={handleSubmit}>
                    {sendStatus === "error" ? "Failed, try again?" : "Submit"}
                </Button>
            </DialogContent>
        </Dialog>
    );
}
