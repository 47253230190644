export interface ManualOverrideInfo {
    deviceOverrideSetting: string;
    deviceOverrideSettingLastUpdated: Date;
    remoteOverrideSetting: string;
    remoteOverrideSettingLastUpdated: Date;
}

export enum ManualModeState {
    OPEN = "open",
    NONE = "none",
    CLOSED = "closed",
}
export type ManualModeObject = {
    upperText: string;
    lowerText: string;
    icon: React.ReactNode;
    mode: ManualModeState;
};

export enum ManualModeTransitionStatuses {
    Sending = "sending",
    Confirming = "confirming",
    Complete = "complete",
}
