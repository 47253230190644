import "./DeviceMarkerIcon.scss";

import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function DeviceMarkerIcon(props) {
    return (
        <SvgIcon onClick={props.onClick} viewBox={"-4, -4, 32, 32"} {...props} className={"MarkerIcon"}>
            <ellipse
                id={"path868"}
                cx={"11.998995"}
                cy={"11.999647"}
                rx={"11.996995"}
                ry={"11.996996"}
                className={"MarkerIconRim"}
            />
            <ellipse
                id={"path868"}
                cx={"12.023627"}
                cy={"12.001252"}
                rx={"11.978597"}
                ry={"11.978598"}
                className={"MarkerIconOuterCircle"}
            />
            <path
                id={"path874"}
                style={{ fill: "var(--iconBackgroundColor, #ffffff)" }}
                d={
                    "m 12.067922,2.1566076 c -8.9791098,0 -13.05148699,10.5402184 -7.0185649,16.7929204 L 4.9923844,12.821701 H 5.4719516 V 11.046188 H 4.992384 V 9.2274153 H 8.1886777 V 11.047424 H 7.5731499 v 1.775513 h 0.6155278 l 0.00479,8.247427 c 1.1924475,0.503973 2.5054903,0.786843 3.8814663,0.786843 6.208917,0 9.795794,-5.311691 9.795794,-9.881447 0,-1.630559 -0.480047,-3.4142051 -1.386311,-5.0078209 -3.203698,0.2485275 -9.608959,1.7924137 -11.7970027,3.6555369 0.00491,-0.12955 0.00717,-0.259023 0.00741,-0.388721 C 8.6946084,9.9188847 8.6804093,9.603264 8.652795,9.2892154 L 17.526756,3.8382518 C 16.011614,2.7850084 14.318201,2.1416352 12.067922,2.1566076 Z"
                }
                className={"MarkerIconInnerCircle"}
            />
            <ellipse id={"path824"} cx={"22"} cy={"2"} rx={"5"} ry={"5"} className={"AlertBubble"} />
        </SvgIcon>
    );
}
