import { useContext } from "react";

import { ModalContext } from "./ModalContext";

/**
 * Hooks into the ModalContext to provide the openModal and closeModal functions
 *
 * sample usage:
 *  const { openModal, closeModal } = useModal();
 *  openModal(<ComponentToOpenInModal />);
 */
export function useModal() {
    return useContext(ModalContext);
}
