import "./DeviceNetworkTest.scss";

import SyncIcon from "@mui/icons-material/Sync";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GenerateUID } from "gardenspadejs/dist/general";
import { withSnackbar } from "notistack";
import React from "react";
import { DeviceConfig } from "verditypes";

const min = 1000 * 60;
const hour = min * 60;

class DeviceNetworkTestTemp extends React.Component {
    constructor(props) {
        super(props);
        this.uid = GenerateUID("DeviceNetworkTest");
        this.state = {
            dialogOpen: false,
            sendingTestRequest: false,
        };
    }

    async handleClose(runTest) {
        if (runTest) {
            this.setState({
                sendingTestRequest: true,
            });
            let success = false;
            await this.props.device
                .conductNetworkTest()
                .then(() => {
                    this.props.enqueueSnackbar("Started network test!", {
                        variant: "success",
                    });
                    success = true;
                })
                .catch(() => {
                    this.props.enqueueSnackbar("Failed to run network test", {
                        variant: "error",
                    });
                });
            this.setState({
                sendingTestRequest: false,
            });
            if (!success) {
                return;
            }
        }
        this.setState({
            dialogOpen: false,
        });
        setTimeout(() => {
            this.setState({});
        }, 400);
    }

    render() {
        let currentlyRunningNetworkTest = false;
        if (
            this.props.device &&
            this.props.device.sproutNetworkInfo?.lastNetworkTestStartDate &&
            Math.abs(
                new Date(this.props.device.sproutNetworkInfo?.lastNetworkTestStartDate.valueOf()).valueOf() -
                    Date.now(),
            ) <
                hour * 4
        ) {
            currentlyRunningNetworkTest = true;
        }
        if (DeviceConfig.DeviceConfigurationsByType[this.props.device?.type]?.generation !== "sprout") {
            return null;
        }
        let buttonToUse = "default";
        if (currentlyRunningNetworkTest) {
            buttonToUse = "alreadyRunning";
        }
        if (this.state.sendingTestRequest) {
            buttonToUse = "sendingRequest";
        }
        const className = "DeviceNetworkTest";
        return (
            <div className={className} id={this.uid}>
                {!currentlyRunningNetworkTest && (
                    <Button
                        onClick={() => {
                            this.setState({
                                dialogOpen: true,
                            });
                        }}
                        variant={"contained"}
                    >
                        Start Network Test
                    </Button>
                )}
                {currentlyRunningNetworkTest && (
                    <Button
                        variant={"contained"}
                        onClick={() => {}}
                        disabled={true}
                        startIcon={<SyncIcon />}
                        className={"NetworkTestButton NetworkTestButton--CurrentlyRunningTest LoadingButton"}
                    >
                        Network Test Running...
                    </Button>
                )}
                <Dialog open={this.state.dialogOpen} className={"DeviceNetworkTestDialog"}>
                    <DialogTitle>Start Network Test:</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <b>This tests your device's current connection to the internet.</b> Network information is
                            normally calculated by averaging your device's activity over several days, but sudden
                            network changes (such as temporary outages) can disrupt our measurements. During a network
                            test we send extra transmissions to the device so we can update it's signal strength in
                            hours instead of days.
                        </DialogContentText>
                        <br />
                        <DialogContentText>
                            While this test is conducted your device will operate normally, following irrigation
                            schedules and collecting measurements. The device's "Signal strength" will update to reflect
                            the results of this test.
                        </DialogContentText>
                        <br />
                        <DialogContentText>
                            <b>Note: </b> Most "poor connection" or "offline" warnings are caused by:
                            <ul>
                                <li>device placement</li>
                                <li>network outages</li>
                                <li>disconnected antennas</li>
                            </ul>
                        </DialogContentText>
                        <DialogContentText className={"WarningSection"}>
                            <b>Warnings:</b>
                            <ul>
                                <li>This test will take 4-5 hours to complete and cannot be cancelled once started</li>
                                <li>No more than 10 network tests may be running at any one time.</li>
                                <li>Network information may change rapidly during the test, this is normal.</li>
                            </ul>
                        </DialogContentText>
                        <br />
                        {currentlyRunningNetworkTest && (
                            <DialogContentText>
                                <b>A network test is currently running on this device!</b> You must wait for this
                                network test to finish before starting another one.
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose(false)}>Go back</Button>
                        {buttonToUse === "default" && (
                            <Button
                                variant={"contained"}
                                onClick={() => this.handleClose(true)}
                                className={"NetworkTestButton NetworkTestButton--Default"}
                            >
                                Conduct Test
                            </Button>
                        )}
                        {buttonToUse === "currentlyRunning" && (
                            <Button
                                variant={"contained"}
                                onClick={() => {}}
                                disabled={true}
                                startIcon={<SyncIcon />}
                                className={"NetworkTestButton NetworkTestButton--CurrentlyRunningTest LoadingButton"}
                            >
                                Test Already running...
                            </Button>
                        )}
                        {buttonToUse === "sendingRequest" && (
                            <Button
                                variant={"contained"}
                                onClick={() => {}}
                                disabled={true}
                                startIcon={<SyncIcon />}
                                className={"NetworkTestButton NetworkTestButton--SendingRequest LoadingButton"}
                            >
                                Starting Test...
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const DeviceNetworkTest = withSnackbar(DeviceNetworkTestTemp);
