// eslint-disable-next-line max-classes-per-file
import GrassIcon from "@mui/icons-material/Grass";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import OpacityIcon from "@mui/icons-material/Opacity";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { BufferedAction, GenerateUID, getFirstDefined } from "gardenspadejs/dist/general";
import React from "react";
import { EventHandler } from "verdiapi";
import { SensorConfig } from "verditypes";
import { dayMs } from "verditypes/dist/timeConstants";

import FocusContext from "../../../services/mapManagement/FocusContext";
import MoistureSensorIcon from "../../icons/MoistureSensorIcon";
import { colorConfusionScore, colorSets, longerColorList, releaseColor } from "./GraphColors";
import { graphOptionsBySensorCategory } from "./graphOptionsBySensorCategory";

export class DynamicGraphContextProvider {
    dateRange = [new Date(Date.now() - 1000 * 60 * 60 * 24 * 4), new Date()];

    onDateRangeChange = new EventHandler();

    uid = undefined;

    constructor() {
        this.uid = GenerateUID("DynamicGraphContext");
        longerColorList.forEach((color) => {
            this.reservedColors[color] = new Set();
        });
        this.linkedFocusContext = new FocusContext();
        this.linkedFocusContext.defaultFunction = () => undefined;

        // generates all the different types of graphs (essentially defines the various y-axese and how
        // each data type should be smoothed etc.)
        this.staticGraphDictionary = generateStaticGraphDictionary();
    }

    get startTime() {
        return this.dateRange[0];
    }

    get endTime() {
        return this.dateRange[1];
    }

    get timeRange() {
        return this.dateRange[1].valueOf() - this.dateRange[0].valueOf();
    }

    getXPercentageOfTime(time) {
        return (time.valueOf() - this.startTime.valueOf()) / this.timeRange;
    }

    activeSensorTypes = new Set();

    lines = {};

    colorPreferenceBySensorType = {
        watermark: "yellows",
        irrometer: "greens",
        v1transducer: "reds",
        v1flowmeter: "blues",
        teros_vol: "purples",
        veggie_vol: "purples",
        sensoterra: "pinks",
    };

    registerLine(lineID, sensorType, otherProps = {}) {
        const lineObject = Object.assign(otherProps, { sensorType: sensorType });
        this.lines[lineID] = lineObject;
        if (!this.lines[lineID].color) {
            this.lines[lineID].color = this.reserveColor(
                lineID,
                this.colorPreferenceBySensorType[lineObject.sensorType],
                lineObject.parentGraph,
            );
        }
        this.lines[lineID].focused = true;
        this.activeSensorTypes.add(sensorType);
        if (this.activeDataType === undefined) {
            this.activeDataType = sensorType;
            this.onActiveDataTypeChanged.trigger();
        }
    }

    unRegisterLine(lineID) {
        const sensorType = this.lines[lineID]?.sensorType;
        if (this.lines[lineID]?.color) {
            this.releaseColor(this.lines[lineID].color, lineID, this.lines[lineID].parentGraph);
            releaseColor(this.lines[lineID].color, lineID);
        }
        delete this.lines[lineID];

        if (sensorType) {
            let sensorTypeStillExists = false;
            Object.keys(this.lines).forEach((lid) => {
                if (this.lines[lid].sensorType === sensorType) {
                    sensorTypeStillExists = true;
                }
            });
            if (!sensorTypeStillExists) {
                this.activeSensorTypes.delete(sensorType);
                if (this.activeDataType === sensorType) {
                    this.activeDataType = undefined;
                    this.onActiveDataTypeChanged.trigger();
                }
            }
        }
        this.onLineRemoved.trigger();
    }

    /**
     * Toggles the focus state of a line. If you include the spotlight parameter it will also
     * unfocus all other lines. If you inlcudde the spotlight parameter and the line has already been spotlit,
     * it will focus all lines.
     * @param lineID
     * @param spotlight
     */
    toggleLineFocus(lineID, spotlight = false) {
        // TODO: add hinting to explain the spotlight feature to users when we detect they are trying
        // use it
        // if one line is focused that isn't this one, or if this line is unfocsed, this line is not spotlit
        const alreadySpotlit = !Object.values(this.lines).some((v) => {
            if (v !== this.lines[lineID]) {
                if (v.focused) {
                    return true;
                }
            }
            return false;
        });
        this.lines[lineID].focused = !this.lines[lineID].focused;

        if (spotlight && alreadySpotlit) {
            Object.values(this.lines).forEach((v) => {
                v.focused = true;
            });
        } else if (spotlight) {
            this.lines[lineID].focused = true;
            Object.values(this.lines).forEach((v) => {
                if (v !== this.lines[lineID]) {
                    v.focused = false;
                }
            });
        }
        // let noLinesFocused = !Object.values(this.lines).some((v) => {
        //     return v.focused;
        // });
        // if (noLinesFocused) {
        //     Object.values(this.lines).forEach((v) => {
        //         v.focused = true;
        //     });
        // }
        if (this.linkedFocusContext) {
            const activeMapEntities = new Set();
            const inactiveMapEntities = new Set();
            Object.values(this.lines).forEach((v) => {
                const linkedMapEntity = FocusContext.MapEntitesByModelID[v.id];
                if (!linkedMapEntity) {
                    return;
                }
                if (v.focused) {
                    activeMapEntities.add(linkedMapEntity);
                    inactiveMapEntities.delete(linkedMapEntity);
                } else if (!activeMapEntities.has(linkedMapEntity)) {
                    inactiveMapEntities.add(linkedMapEntity);
                }
            });
            activeMapEntities.forEach((me) => {
                this.linkedFocusContext.setInactive(me, false);
            });
            inactiveMapEntities.forEach((me) => {
                this.linkedFocusContext.setInactive(me, true);
            });
            // Object.values(this.lines).forEach((v) => {
            //     let linkedMapEntity = FocusContext.MapEntitesByModelID[v.id];
            //     if (!linkedMapEntity) {
            //         return;
            //     }
            //     // if (v.focused && !this.linkedFocusContext.selected.has(linkedMapEntity)) {
            //     //     this.linkedFocusContext.setSelected(linkedMapEntity, true);
            //     // } else if (!v.focused && this.linkedFocusContext.selected.has(linkedMapEntity)) {
            //     //     this.linkedFocusContext.setSelected(linkedMapEntity, false);
            //     // }
            //     if (v.focused && this.linkedFocusContext.inactive.has(linkedMapEntity)) {
            //         this.linkedFocusContext.setInactive(linkedMapEntity, false);
            //     } else if (!v.focused && !this.linkedFocusContext.inactive.has(linkedMapEntity)) {
            //         this.linkedFocusContext.setInactive(linkedMapEntity, true);
            //     }
            // });
        }
        this.onFocusedLinesChanged.trigger();
    }

    /**
     *
     * @type { FocusContext}
     */
    linkedFocusContext = undefined;

    onLineAdded = new EventHandler();

    onLineRemoved = new EventHandler();

    onFocusedLinesChanged = new EventHandler();

    activeDataType = undefined;

    onActiveDataTypeChanged = new EventHandler();

    cursorPosition = [0, 0];

    cursorTime = new Date(Date.now());

    onCursorPositionChanged = new EventHandler();

    _zoomYToData = false;

    // noinspection JSUnusedGlobalSymbols
    setZoomYToData(value) {
        this._zoomYToData = value;
        Object.values(this.staticGraphDictionary).forEach((graphType) => {
            graphType._zoomYToData = value;
        });
        Object.values(this.staticGraphDictionary).forEach((graphType) => {
            graphType.triggerPotentialYAxisChange.trigger();
        });
    }

    get zoomYToData() {
        return this._zoomYToData;
    }

    /**
     * This function should be destroyed with predjuidice. But fixing this is out of scope right now and we are
     * going to refactor this all anyway.
     * @param graphType
     */
    addGraphType(graphType) {
        if (!this.staticGraphDictionary[graphType]) {
            this.staticGraphDictionary[graphType] = new GraphType(graphType, ThermostatIcon, {
                sensorType: graphType,
            });
        }
    }

    registeredGraphs = {};

    registerGraph(graphUID, dynamicGraph) {
        this.registeredGraphs[graphUID] = dynamicGraph;
    }

    filteredGraphPoints = {};

    reservedColors = {};

    reservedColorsByGraph = {};

    reserveColor(uid, preference, graph = "NONE") {
        if (!this.reservedColorsByGraph[graph]) {
            this.reservedColorsByGraph[graph] = {};
            longerColorList.forEach((color) => {
                this.reservedColorsByGraph[graph][color] = new Set();
            });
        }
        let preferenceSet = new Set();
        if (preference && colorSets[preference]) {
            preferenceSet = colorSets[preference];
        }
        const selectedColor = longerColorList.reduce((champ, challenger) => {
            if (!champ) {
                return challenger;
            }
            if (this.reservedColorsByGraph[graph][challenger].size > this.reservedColorsByGraph[graph][champ].size) {
                return champ;
            }
            if (this.reservedColorsByGraph[graph][challenger].size < this.reservedColorsByGraph[graph][champ].size) {
                return challenger;
            }
            if (colorConfusionScore[champ] < colorConfusionScore[challenger]) {
                return champ;
            }
            if (colorConfusionScore[champ] > colorConfusionScore[challenger]) {
                return challenger;
            }
            if (preferenceSet.has(champ)) {
                return champ;
            }
            if (preferenceSet.has(challenger)) {
                return challenger;
            }
            return champ;
        });
        this.reservedColorsByGraph[graph][selectedColor].add(uid);
        return selectedColor;
    }

    releaseColor(color, uid, graph = "NONE") {
        if (this.reservedColorsByGraph[graph]) {
            this.reservedColorsByGraph[graph][color].delete(uid);
        }
    }
}

class GraphType {
    get iconName() {
        return this.icon;
    }

    get labelName() {
        return this.label;
    }

    label;

    icon;

    invert = false;

    hardZero = false;

    _zoomYToData = false;

    conversionEquation = undefined;

    /**
     * Converts value based on conversion equation, which is a list-form polynomial.
     * @param v
     * @return {number|*}
     */
    convertValue(v) {
        if (!this.conversionEquation) {
            return v;
        }
        let runningValue = 0;
        for (let i = 0; i < this.conversionEquation.length; i++) {
            runningValue += v ** i * this.conversionEquation[i];
        }
        return runningValue;
    }

    /**
     * The height required to display this graphs label on the y axis.
     * @type {number}
     */
    YaxisLabelMinHeight = 50;

    YPositionMultiplier = 1;

    typicalRange = [0, 100];

    linkedGraphType = undefined;

    _requiredRanges = {};

    interpolation = "linear";

    maxInterpolationTime = 1000 * 60 * 60 * 10;

    // smoothingVarianceThreshold is the expected variance between similar data points. When smoothing the graph,
    // datapoints that are different by more than this amount will not be used.
    // default is zero
    smoothingVarianceThreshold = 0;

    get requiredRanges() {
        if (this.linkedGraphType) {
            return this.linkedGraphType.requiredRanges;
        }
        return this._requiredRanges;
    }

    set requiredRanges(v) {
        if (this.linkedGraphType) {
            this.linkedGraphType.requiredRanges = v;
        }
        this._requiredRanges = v;
    }

    _currentRange = [0, 100];

    get currentRange() {
        if (this.linkedGraphType) {
            return this.linkedGraphType.currentRange;
        }
        return this._currentRange;
    }

    set currentRange(v) {
        if (this.linkedGraphType) {
            this.linkedGraphType.currentRange = v;
        }
        this._currentRange = v;
    }

    // labelForValue = (v, showUnits) => {
    //     return null;
    // };
    labelForValue;

    unit = "";

    _onCurrentRangeChange = new EventHandler();

    get onCurrentRangeChange() {
        if (this.linkedGraphType) {
            return this.linkedGraphType.onCurrentRangeChange;
        }
        return this._onCurrentRangeChange;
    }

    constructor(label, icon, options = {}) {
        this.label = label;
        this.icon = icon;
        this.uid = GenerateUID("GraphType");
        this.sensorType = options.sensorType;
        this.hardZero = options.hardZero || this.hardZero;
        this.maxInterpolationTime = options.maxInterpolationTime || this.maxInterpolationTime;
        this.linkedGraphType = options.linkedGraphType || undefined;
        this.typicalRange = options.typicalRange || this.typicalRange;
        this.conversionEquation = options.conversionEquation || this.conversionEquation;
        this.currentRange = [...this.typicalRange];
        this.unit = options.unit || this.unit;
        this.labelForValue =
            options.labelForValue ||
            ((v, showUnits = true) => {
                let convertedV = this.convertValue(v);
                convertedV = (Math.round(convertedV * 10) / 10).toString();
                if (showUnits) {
                    // return (
                    //     <React.Fragment>
                    // eslint-disable-next-line no-irregular-whitespace
                    //         {v}​<span className="yAxisUnits">{this.unit}</span>
                    //     </React.Fragment>
                    // );
                    // fragment is now of JSX type instead of string type, not useless
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    return <>{convertedV}</>;
                }
                // fragment is now of JSX type instead of string type, not useless
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <>{convertedV}</>;
            });
        this.interpolation = options.interpolation || "linear";
        this.YPositionMultiplier = options.YPositionMultiplier;

        // smoothingVarianceThreshold is the expected variance between similar data points. When smoothing the graph,
        // datapoints that are different by more than this amount will not be used.
        // default is zero
        this.smoothingVarianceThreshold = options.smoothingVarianceThreshold || 0;

        this.invert = options.invert || this.invert;
        if (this.linkedGraphType) {
            this.triggerPotentialYAxisChange = this.linkedGraphType.triggerPotentialYAxisChange;
            this.getXPercentage = this.linkedGraphType.getXPercentage;
            this.getYPercentage = this.linkedGraphType.getYPercentage;
        } else {
            this.triggerPotentialYAxisChange = new BufferedAction(
                () => {
                    let minimum = this.typicalRange[0];
                    let maximum = this.typicalRange[1];
                    if (this._zoomYToData) {
                        minimum = undefined;
                        maximum = undefined;
                    }
                    Object.keys(this.requiredRanges).forEach((lineID) => {
                        minimum = Math.min(
                            this.requiredRanges[lineID][0],
                            this.requiredRanges[lineID][1],
                            getFirstDefined(minimum, this.requiredRanges[lineID][1]),
                        );
                        maximum = Math.max(
                            this.requiredRanges[lineID][0],
                            this.requiredRanges[lineID][1],
                            getFirstDefined(maximum, this.requiredRanges[lineID][1]),
                        );
                    });
                    minimum = getFirstDefined(minimum, this.typicalRange[0]);
                    maximum = getFirstDefined(maximum, this.typicalRange[1]);

                    const range = maximum - minimum;

                    maximum += range * 0.1;
                    minimum -= range * 0.1;
                    // if ((minimum > 0 && minimum - range * 0.1 > 0) || minimum < 0) {
                    //
                    // } else {
                    //     minimum = 0;
                    // }
                    if (this.hardZero && minimum < 0) {
                        minimum = 0;
                    }
                    if (this.currentRange[0] !== minimum || this.currentRange[1] !== maximum) {
                        this.currentRange = [minimum, maximum];
                        this.onCurrentRangeChange.trigger();
                    }
                },
                300,
                false,
                false,
            );
            this.getXPercentage = (test) => test;
            this.getYPercentage = (value) => {
                const v = (value - this.currentRange[0]) / (this.currentRange[1] - this.currentRange[0]);
                if (this.invert) {
                    return 1 - v;
                }
                return v;
            };
        }
    }
}

/**
 * Generates all the graph types for the arbitrary graphs, each sensor category, and each sensor based
 * primarily on configs found in graphOptionsBySensorCategory.
 *
 * The graph type defines how the data is visualized. Everythign from color preference, to icon, to
 * smoothing/interpolation.
 * @return {Record<string, GraphType>}
 */
function generateStaticGraphDictionary() {
    // first, generate any ad hoc graphs that we need that aren't related to sensors
    const staticGraphDictionary = {
        irrigation: new GraphType("Irrigation", GrassIcon, {
            sensorType: "irrigation",
        }),
        NDVI: new GraphType("NDVI", LocalFloristIcon, {
            YPositionMultiplier: 1,
            typicalRange: [0, 3],
            unit: "",
            interpolation: "linear",
            maxInterpolationTime: dayMs * 15,
            sensorType: "NDVI",
            hardZero: true,
        }),
        valveOpenPercent: new GraphType("Irrigation", OpacityIcon, {
            YPositionMultiplier: 1,
            typicalRange: [0, 1],
            unit: "%",
            sensorType: "valveOpenPercent",
            hardZero: true,
        }),
        sensoterra: new GraphType("Sensoterra", MoistureSensorIcon, {
            YPositionMultiplier: 1,
            typicalRange: [0, 30],
            unit: "%",
            interpolation: "linear",
            sensorType: "sensoterra",
            hardZero: true,
        }),
    };

    // Now generate the graphs for every category of sensor based on the neighbouring config file
    Object.entries(graphOptionsBySensorCategory).forEach(([sensorCategoryName, graphOptions]) => {
        staticGraphDictionary[sensorCategoryName] = new GraphType(graphOptions.label, graphOptions.icon, {
            ...graphOptions,
            sensorType: sensorCategoryName,
        });
    });

    // Generates a graph type for each kind of sensor, linked to the sensor category to merge the y axes
    Object.entries(SensorConfig.SensorConfigurations).forEach(([sensorType, sensorConfig]) => {
        const sensorCategoryName = sensorConfig.sensorCategory;
        const graphOptionsForSensorCategory = graphOptionsBySensorCategory[sensorCategoryName];
        if (!graphOptionsForSensorCategory) {
            console.warn(`No graph config for sensor category ${sensorCategoryName} so graph cannot be generated`);
            return;
        }
        staticGraphDictionary[sensorType] = new GraphType(
            graphOptionsForSensorCategory.label,
            graphOptionsForSensorCategory.icon,
            {
                ...graphOptionsForSensorCategory,
                sensorType: sensorType,
                linkedGraphType: staticGraphDictionary[sensorCategoryName],
            },
        );
    });
    return staticGraphDictionary;
}

export const DynamicGraphContext = React.createContext(new DynamicGraphContextProvider());
