import { TabContext, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { GenerateUID, RisingEdgeBufferedAction } from "verdiapi/dist/HelperFunctions";
import { SoilLayer } from "verdiapi/dist/Models/soilLayer";

import SoilLayerEditor from "./SoilLayerEditor";

export default class TabbedSoilLayerEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            soilCompositions: this.props.defaultSoilProfiles || [],
            activeTab: -1, // -1,
        };
        if (this.state.soilCompositions.length > 0) {
            this.state.activeTab = 0;
        }
        this.onLayerChange = new RisingEdgeBufferedAction(() => {
            this.forceUpdate();
            this.triggerOnChange();
        }, 200);
        this.uid = GenerateUID("TabbedSoilEditor");
    }

    triggerOnChange(newCompositions = undefined) {
        if (this.props.onChange) {
            this.props.onChange(newCompositions || this.state.soilCompositions);
        }
    }

    render() {
        return (
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        variant={"fullWidth"}
                        value={this.state.activeTab}
                        onChange={(e, newValue) => {
                            if (newValue === -1) {
                                return;
                            }
                            this.setState({
                                activeTab: newValue,
                            });
                        }}
                    >
                        {this.state.soilCompositions.map((sc, i) => (
                            <Tab
                                style={{ minWidth: null }}
                                value={i}
                                label={`${Math.round(sc.depthInches).toString()}"`}
                                key={`${this.uid}TabNumber${i.toString()}`}
                            />
                        ))}
                        <Tab
                            style={{ minWidth: null }}
                            value={-1}
                            label={"+"}
                            onClick={() => {
                                let previousDepth = 0;
                                this.state.soilCompositions.forEach((sc) => {
                                    previousDepth = Math.max(sc.depth + 0.0254 * 12, previousDepth);
                                });
                                this.state.soilCompositions = [
                                    ...this.state.soilCompositions,
                                    new SoilLayer({
                                        sandPercentage: 0.33,
                                        clayPercentage: 0.33,
                                        depth: previousDepth,
                                    }),
                                ];
                                this.setState({
                                    soilCompositions: this.state.soilCompositions,
                                    activeTab: this.state.soilCompositions.length - 1,
                                });
                                this.triggerOnChange();
                            }}
                        />
                    </Tabs>
                </Box>
                <TabContext value={this.state.activeTab.toString()} className={"SoilLayerEditorTabContext"}>
                    {this.state.soilCompositions.map((sc, i) => (
                        <TabPanel
                            value={i.toString()}
                            index={i}
                            key={`${this.uid}TabPanel${i.toString()}`}
                            style={{
                                margin: 0,
                                padding: "10px 0 0 0",
                            }}
                        >
                            <SoilLayerEditor
                                soilLayer={sc}
                                onChange={() => {
                                    this.onLayerChange.trigger();
                                }}
                                onDelete={() => {
                                    this.state.soilCompositions.splice(i, 1);
                                    let newActiveTab = this.state.activeTab;
                                    if (newActiveTab === i) {
                                        newActiveTab -= 1;
                                    }
                                    if (newActiveTab < 0 && this.state.soilCompositions.length > 0) {
                                        newActiveTab = 0;
                                    }
                                    this.setState({
                                        soilCompositions: this.state.soilCompositions,
                                        activeTab: newActiveTab,
                                    });
                                }}
                            />
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
        );
    }
}
