export function getRandomRGBTuple() {
    let h = Math.random();
    h %= 1;

    return hsvToRgb(h, 0.5, 0.95);
}

export const longerColorList = [];
export const lineColors = [
    "#D32F2F",
    "#C2185B",
    "#7B1FA2",
    "#512DA8",
    "#303F9F",
    "#1976D2",
    "#0097A7",
    "#00796B",
    "#388E3C",
    "#689F38",
    "#d3b703",
    "#FFA000",
    "#F57C00",
    "#E64A19",
];
export const colorSets = {
    purples: new Set([
        // purples
        "#6a1b9a",
        "#4a148c",
        // "#5e35b1",
        // "#7b1fa2",
        "#673ab7",
        "#7B1FA2",
        "#8e24aa",
        "#9c27b0",
    ]),
    reds: new Set([
        // reds
        // "#c2185b",
        // "#e53935",
        "#C2185B",
        "#f44336",
        "#D32F2F",
    ]),
    pinks: new Set([
        // pinks
        "#ff4081",
        "#c51162",
        "#f50057",
    ]),
    yellows: new Set([
        // oranges
        "#E64A19",
        "#FFA000",
        "#F57C00",
        "#ffab00",
        "#d3b703",
        "#fdd835",
    ]),
    greens: new Set([
        // greens
        "#8bc34a",

        // "#689f38",
        "#388E3C",
        "#9e9d24",
        // "#afb42b",
        "#c0ca33",
        "#d4e157",
        "#aed581",
        "#81c784",

        "#4caf50",
        "#689F38",
        "#2e7d32",
        "#1b5e20",
        "#66bb6a",
        // "#43a047",

        "#00897b",
        "#00796B",
        "#004d40",
    ]),
    blues: new Set([
        // blues
        "#0277bd",
        "#1976D2",
        // "#0d47a1",
        // "#283593",
        "#1e88e5",
        "#512DA8",
        "#1a237e",
        "#303F9F",
        "#01579b",
        "#0097A7",
    ]),
};
export const colorConfusionScore = {};
export const colorsSortedByMostConfusing = [
    "#0097A7",
    "#303F9F",
    "#512DA8",
    "#1976D2",
    "#00796B",
    "#689F38",
    "#388E3C",
    "#d3b703",
    "#F57C00",
    "#FFA000",
    "#E64A19",
    "#D32F2F",
    "#ff4081",
    "#C2185B",
    "#7B1FA2",
    "#aed581",
    "#fdd835",
    "#004d40",
    "#f50057",
    "#d4e157",
    "#1b5e20",
    "#1e88e5",
    "#81c784",
    "#1a237e",
    "#9c27b0",
    "#9e9d24",
    "#01579b",
    "#c0ca33",
    "#4a148c",
    "#f44336",
    "#4caf50",
    "#0277bd",
    "#8bc34a",
    "#673ab7",
    "#00897b",
    "#66bb6a",
    "#ffab00",
    "#2e7d32",
    "#c51162",
    "#6a1b9a",
    "#8e24aa",
];

export const reservedColors = {};

export function reserveColor(uid, preference) {
    let preferenceSet = new Set();
    if (preference && colorSets[preference]) {
        preferenceSet = colorSets[preference];
    }
    const selectedColor = longerColorList.reduce((champ, challenger) => {
        if (!champ) {
            return challenger;
        }
        if (reservedColors[challenger].size > reservedColors[champ].size) {
            return champ;
        }
        if (reservedColors[challenger].size < reservedColors[champ].size) {
            return challenger;
        }
        if (colorConfusionScore[champ] < colorConfusionScore[challenger]) {
            return champ;
        }
        if (colorConfusionScore[champ] > colorConfusionScore[challenger]) {
            return challenger;
        }
        if (preferenceSet.has(champ)) {
            return champ;
        }
        if (preferenceSet.has(challenger)) {
            return challenger;
        }
        return champ;
    });
    reservedColors[selectedColor].add(uid);
    return selectedColor;
}

export function releaseColor(color, uid) {
    reservedColors[color].delete(uid);
}

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function hsvToRgb(h, s, v) {
    const hueI = Math.floor(h * 6);
    const f = h * 6 - hueI;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    let r;
    let g;
    let b;
    switch (hueI) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;
        case 1:
            r = q;
            g = v;
            b = p;
            break;
        case 2:
            r = p;
            g = v;
            b = t;
            break;
        case 3:
            r = p;
            g = q;
            b = v;
            break;
        case 4:
            r = t;
            g = p;
            b = v;
            break;
        case 5:
            r = v;
            g = p;
            b = q;
            break;
        default:
            r = v;
            g = t;
            b = p;
    }

    return [Math.floor(r * 256), Math.floor(g * 256), Math.floor(b * 256)];
}

colorsSortedByMostConfusing.forEach((color, i) => {
    let confusionIndex = 0;
    if (i > 11) {
        confusionIndex = 1;
    }
    if (i > 26) {
        confusionIndex = 2;
    }
    colorConfusionScore[color] = confusionIndex;
});

Object.values(colorSets).forEach((colorList) => {
    longerColorList.push(...colorList);
});

longerColorList.forEach((color) => {
    reservedColors[color] = new Set();
});

shuffleArray(lineColors);
shuffleArray(longerColorList);
