/**
 * Prevents zooming on the page so that the map zooms instead. Without this users get pretty confused
 */
export function preventAccidentalPageZooming() {
    // Prevents zooming when scrolling with the Ctrl key pressed
    document.body.addEventListener(
        "wheel",
        (event) => {
            if (event.ctrlKey) {
                event.preventDefault();
            }
        },
        { passive: false },
    );

    // Prevents zooming using the Ctrl + + and Ctrl + - shortcuts
    document.body.addEventListener(
        "keydown",
        (event) => {
            if (event.ctrlKey && (event.key === "-" || event.key === "=")) {
                event.preventDefault();
            }
        },
        { passive: false },
    );
}
