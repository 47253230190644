import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React from "react";

import GlobalOptions from "../../../utils/GlobalOptions";

// Call the iOSVersion function to get the iOS version number
export default class IOSShaderUpdateMessage extends React.Component {
    constructor(props) {
        super(props);
        const affected = GlobalOptions.isBrokenShaderIOS;
        this.state = {
            showMessage: affected,
        };
    }

    clear() {
        this.setState({ showMessage: false });
        this.props.onClose(0); // 1000 * 60 * 60 * 24 * 4);
    }

    render() {
        return (
            <Dialog
                open={this.state.showMessage && !this.props.cleared}
                onClose={() => {
                    // this.setState({ showMessage: false });
                }}
            >
                <DialogTitle>Resolved Issue</DialogTitle>
                <DialogContent>
                    <Typography variant={"h4"}>Update your iPhone to regain full functionality</Typography>
                    <br />
                    <DialogContentText>
                        <b>Context:</b> In iOS update 16.4 Apple inadvertently broke support for key web features that
                        we relied on to deliver a smooth experience. We released a temprary patch to provide interm
                        support. In iOS update 16.6, Apple has patched Safari allowing us to once again deliver a smooth
                        experience.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        <b>Current Status:</b> We advise you update to iOS 16.6 in order to get the best experience. In
                        the meantime, we will maintain the iterm patch, but will not improve it.
                    </DialogContentText>
                    <br />
                    <DialogContentText>The Verdi Team</DialogContentText>
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.clear();
                        }}
                        color={"primary"}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
