import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, Stack, Typography } from "@mui/material";

import { IconButtonWithTooltip } from "../../../components/generic/IconButtonWithTooltip";
import { ModalLayout } from "../../../components/generic/ModalLayout";
import { useModal } from "../../../hooks/useModal/useModal";

interface AccountDetailsModalContentProps {
    title: string;
    accountDetails: { label: string; value: string }[];
}
export function AccountDetailsModalContent({ title, accountDetails }: AccountDetailsModalContentProps) {
    const { closeModal } = useModal();

    const onClickConfirm = () => {
        closeModal();
    };

    return (
        <ModalLayout onClickClose={() => closeModal()}>
            <Typography variant={"h2"}>{title}</Typography>
            {accountDetails.map((accountDetail) => (
                <AccountDetailDisplay
                    key={accountDetail.label}
                    title={accountDetail.label}
                    dataToDisplay={accountDetail.value}
                />
            ))}

            <Typography variant={"h4"}>
                Make sure you have saved this password before exiting this modal. You will not be able to view this
                password again.
            </Typography>
            <Button onClick={onClickConfirm} variant={"contained"} color={"primary"} sx={{ maxWidth: 220 }}>
                Done
            </Button>
        </ModalLayout>
    );
}

interface AccountDetailDisplayProps {
    title: string;
    dataToDisplay: string;
}
function AccountDetailDisplay({ title, dataToDisplay }: AccountDetailDisplayProps) {
    const copyToClipboard = (content: string) => {
        navigator.clipboard.writeText(content);
    };

    return (
        <Stack gap={2}>
            <Typography variant={"h5"}>{`The new ${title} is`}</Typography>
            <Stack
                gap={3}
                direction={"row"}
                sx={{ px: 5, py: 1, alignItems: "center", justifyContent: "space-between" }}
                border={1}
                borderColor={"GrayText"}
            >
                <Typography variant={"h4"}>{dataToDisplay}</Typography>
                <IconButtonWithTooltip
                    title={"Copy to Clipboard"}
                    icon={<ContentCopyIcon />}
                    onClick={() => copyToClipboard(dataToDisplay)}
                />
            </Stack>
        </Stack>
    );
}
