import { FunctionalDeviceInfoCard } from "../../../components/specialized/infoCards/DeviceInfoCard/FunctionalDeviceInfoCard";
import { GenerateUID } from "../../../utils/HelperFunctions";
import MapEntityBase from "./MapEntityBase";

export default class IrrigationSetMapEntity extends MapEntityBase {
    static infoCardClass = FunctionalDeviceInfoCard;

    /**
     *  @type {IrrigationSet}
     */
    model;

    /**
     * @param {IrrigationSet} IrrigationSet
     */
    constructor(IrrigationSet, renderImmediately = true) {
        super(IrrigationSet, GenerateUID("IrrSet"), false);
        this.lat = undefined;
        this.long = undefined;

        // this.icon = DeviceMarkerIcon;
        if (renderImmediately) {
            this.renderOnMap();
        }
    }

    renderOnMap() {
        if (!this.renderState.onMap && !this.renderState.beingRendered) {
            this.renderState.beingRendered = true;
        }
    }
}
