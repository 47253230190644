// This file is used to configure i18next, a library that allows us to use multiple languages in our app.

import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

import { languages, namespaces } from "./constants";

i18n.use(resourcesToBackend((language, namespace) => import(`./locales/${language}/${namespace}.json`)))
    .on("failedLoading", (lng, ns, msg) => console.error(msg))
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: languages.default,
        fallbackLng: languages.default,
        ns: [...Object.values(namespaces)],
        load: "currentOnly",
        keySeparator: ".",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

i18n.on("languageChanged", (lng) => {
    document.documentElement.setAttribute("lang", lng);
});

export default i18n;
