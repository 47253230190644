import { Typography } from "@mui/material";
import * as React from "react";
import { getTimeString, ShortMonthLookup } from "verdiapi/dist/HelperFunctions";

import { DynamicGraphContext } from "./DynamicGraphUtility";

export default function DynamicGraphHeader(props) {
    const context = React.useContext(DynamicGraphContext);
    return (
        <Typography className={"currentCursorTime"} variant={"button"}>
            {ShortMonthLookup[context.cursorTime.getMonth()]} {context.cursorTime.getDate()},{" "}
            {getTimeString(new Date(context.cursorTime.valueOf()), "h:mm nn")}
            {props.enlarged && (
                <Typography
                    component={"span"}
                    variant={"caption"}
                    style={{
                        fontStyle: "italic",
                        color: "rgba(0,0,0,0.38)",
                    }}
                >
                    {" "}
                    Events Enlarged for visibility
                </Typography>
            )}
        </Typography>
    );
}
DynamicGraphHeader.contextType = DynamicGraphContext;
