import "./HelpCSS.css";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import VerifiedIcon from "@mui/icons-material/Verified";
import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "./HelpBase";

const StepTitle = "h6";

export function DeviceVerificationHelp(props) {
    return (
        <HelpBase Title={"Understanding Device Verification Information"} Target={props.children}>
            The device verification information displays if this device's valves have been recently verified to be able
            to open or close.
            <br />
            <br />A device is considered <b>successfully verified</b> if it has successfully received a schedule and has
            a verified flow rate for both opening and closing its valves.
            <br />
            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>&nbsp;</span> Icon Meanings{" "}
            </Typography>
            <Typography className={"StepDetails"}>
                <ul>
                    <li>
                        A <b>green checkmark icon</b>{" "}
                        <VerifiedIcon
                            sx={{
                                color: "var(--success-icon-main)",
                            }}
                        />{" "}
                        indicates a successful verification.
                    </li>
                    <li>
                        A <b>red "X" icon</b>{" "}
                        <CancelIcon
                            sx={{
                                color: "var(--error-icon-main)",
                            }}
                        />{" "}
                        indicates a failed verification.
                    </li>
                    <li>
                        A <b>grey pending icon</b>{" "}
                        <PendingIcon
                            sx={{
                                color: "var(--unverified-icon-main)",
                            }}
                        />{" "}
                        indicates something is unable to be verified or there has not been data collected. This does not
                        necessarily indicate a failed verification.
                    </li>
                    <li>
                        A <b>yellow clock icon</b>{" "}
                        <AccessTimeIcon
                            sx={{
                                color: "var(--stale-icon-dark)",
                            }}
                        />{" "}
                        indicates some amount of time has passed since the last verification, such that the displayed
                        results have the potential to be outdated. Generally, verifications that took place within the
                        last three weeks are very likely to still be accurate and up-to-date.
                    </li>
                </ul>
            </Typography>
            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>&nbsp;</span> Troubleshooting Failed Verifications{" "}
            </Typography>
            <Typography className={"StepDetails"}>
                &lt;To be added: Exhaustive list of troubleshooting text&gt;
            </Typography>
            <br />
        </HelpBase>
    );
}
