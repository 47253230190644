import "./TrendGraphBackground.scss";

import { ShortDayLookup, ShortMonthLookup } from "gardenspadejs/dist/dateHelpers";
import { GenerateUID } from "gardenspadejs/dist/general";
import React from "react";
import { EventHandler } from "verdiapi";

import { DynamicGraphContext } from "../DynamicGraphUtility";
import TrendGraphYAxis from "./TrendGraphYAxis";
import { YAxisTabs } from "./Yaxis/YAxisTabs";

const min = 1000 * 60;
const hour = min * 60;
const day = hour * 24;

export default class TrendGraphBackground extends React.Component {
    constructor(props) {
        super(props);
        this.uid = GenerateUID("trend graph Background");
    }

    componentDidMount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
    }

    componentWillUnmount() {
        this.context.onDateRangeChange.addListener(() => {
            this.forceUpdate();
        }, this.uid);
    }

    createYAxis(firstRun) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{firstRun && <TrendGraphYAxis width={this.props.width} height={this.props.height} />}</>;
    }

    render() {
        const totalGraphWidth = this.props.width - 5;
        const stripArray = [];
        let graphTimeType = "day";
        const dateRange = this.context.timeRange;

        if (dateRange < day * 7) {
            graphTimeType = "day";
        } else if (dateRange < day * 40 && dateRange >= day * 7) {
            graphTimeType = "week";
        } else if (dateRange >= day * 40) {
            graphTimeType = "month";
        } else {
            console.warn("error, no time type recognized");
        }

        let numOfDays = 7;
        if (graphTimeType === "week") {
            numOfDays = 40;
        } else if (graphTimeType === "month") {
            numOfDays = 365;
        } else {
            numOfDays = 7;
        }

        for (let i = 0; i < numOfDays; i++) {
            const graphDate = new Date(this.context.startTime.valueOf());
            const dateDuration = new Date(this.context.startTime.valueOf());
            graphDate.setHours(12, 0, 0, 0);
            graphDate.setDate(graphDate.getDate() + i);
            dateDuration.setHours(0, 0, 0, 0);
            dateDuration.setDate(dateDuration.getDate() + i);

            stripArray.push(
                graphTimeType === "day" && {
                    dateToAppear: graphDate,
                    content: `${ShortDayLookup[new Date(graphDate).getDay()]} ${new Date(graphDate).getDate()}`,
                    graphDatePos: this.context.getXPercentageOfTime(graphDate) * totalGraphWidth,
                    verticalLinePos: this.context.getXPercentageOfTime(dateDuration) * totalGraphWidth,
                },
                graphTimeType === "week" && {
                    dateToAppear: graphDate,
                    content: ShortDayLookup[new Date(graphDate).getDay()],
                    graphDatePos: this.context.getXPercentageOfTime(graphDate) * totalGraphWidth,
                    verticalLinePos: this.context.getXPercentageOfTime(dateDuration) * totalGraphWidth,
                },
                graphTimeType === "month" && {
                    dateToAppear: graphDate,
                    content:
                        new Date(graphDate).getDate() === 15 &&
                        `${ShortMonthLookup[new Date(graphDate).getMonth()]} ${new Date(graphDate).getDate()}`,
                    graphDatePos:
                        new Date(graphDate).getDate() === 15 &&
                        this.context.getXPercentageOfTime(graphDate) * totalGraphWidth,
                    verticalLinePos:
                        new Date(graphDate).getDate() === 1 &&
                        this.context.getXPercentageOfTime(dateDuration) * totalGraphWidth,
                },
            );
        }

        return (
            <div
                className={`${this.props.className || ""} staticGraphBackgroundContainer`}
                style={{
                    width: totalGraphWidth,
                    height: this.props.height,
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                {this.createYAxis(this.props.graphDataType)}
                <YAxisTabs height={this.props.height} />
                <div className={"beginningText"}>
                    {new Date(this.context.startTime.valueOf()).toLocaleString("default", { month: "long" })}{" "}
                    {new Date(this.context.startTime.valueOf()).getDate()}
                </div>
                <div style={{ display: "flex" }}>
                    {stripArray.map((item, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={i}>
                            <div
                                className={"dates"}
                                style={{
                                    left: `${item.graphDatePos || 0}px`,
                                    minHeight: 5,
                                    minWidth: 5,
                                    fontSize: 10,
                                    color: "#222222",
                                    whiteSpace: "nowrap",
                                    visibility:
                                        item.graphDatePos > 8 && item.graphDatePos < totalGraphWidth - 6
                                            ? "visible"
                                            : "hidden",
                                }}
                            >
                                {item.content}
                            </div>
                            <div
                                className={"gridVerticalLine"}
                                style={{
                                    height: this.props.height === this.props.modalOpenHeight ? "100%" : "113%",
                                    left: `${item.verticalLinePos || 0}px`,
                                    visibility:
                                        item.verticalLinePos > 0 && item.verticalLinePos < totalGraphWidth
                                            ? "visible"
                                            : "hidden",
                                }}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    }
}

TrendGraphBackground.contextType = DynamicGraphContext;
//
// TrendGraphBackground.propTypes = {
//     className: PropTypes.string,
//     width: PropTypes.number,
//     height: PropTypes.number,
//     days: PropTypes.bool,
//     sensors: PropTypes.array,
//     endTime: PropTypes.instanceOf(Date),
//     startTime: PropTypes.instanceOf(Date),
//     sunriseHour: PropTypes.number,
//     sunsetHour: PropTypes.number,
//     graphDataType: PropTypes.string,
// };
