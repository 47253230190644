import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SessionHandler } from "verdiapi";

import {
    ComboBox,
    ComboBoxOption,
    ComboBoxTagGroup,
    separateOptionsIntoGroups,
} from "../../../components/generic/ComboBox/ComboBox";
import { ALL_DATA_TYPES, DATA_TYPE_CATEGORY, USER_DATA_TYPES } from "../constants";
import SelectAllButtonGroup from "./SelectAllButtonGroup";

/**
 * Sorts groups alphabetically and then sorts alphabetically within each group
 * Admin-Only items are always sorted to the bottom
 */
const sortDataTypeOptions = (dataTypeOptions: ComboBoxOption[]) => {
    const sortedOptions = [...dataTypeOptions];
    sortedOptions.sort((a, b) => {
        if (a.group === b.group) {
            return a.label.localeCompare(b.label);
        }

        // Admin-Only group: preserve alphabetical in-group order but list group last
        if (a.group === DATA_TYPE_CATEGORY.ADMIN_ONLY) {
            return 1;
        }
        if (b.group === DATA_TYPE_CATEGORY.ADMIN_ONLY) {
            return -1;
        }

        return a.group?.localeCompare(b.group ?? "") ?? 0;
    });

    return sortedOptions;
};

interface DataTypeSelectionProps {
    dataTypes: ComboBoxOption[];
    setDataTypes: (newDataTypes: ComboBoxOption[]) => void;
}
export function DataTypeSelector({ dataTypes, setDataTypes }: DataTypeSelectionProps) {
    const [dataTypeOptions, setDataTypeOptions] = useState<ComboBoxOption[]>([]);

    useEffect(() => {
        // Set data type options with admin-only types included for admin users
        setDataTypeOptions(
            sortDataTypeOptions(
                Object.entries(SessionHandler.admin ? ALL_DATA_TYPES : USER_DATA_TYPES).map(([key, value]) => ({
                    value: key,
                    label: value.label,
                    group: value.category,
                })),
            ),
        );
    }, []);

    return (
        <Box>
            <Typography variant={"h4"}>Data Types</Typography>

            <Box sx={{ mt: 6, maxWidth: 450 }}>
                <SelectAllButtonGroup
                    sx={{ mb: 5 }}
                    onClickSelectAll={() => {
                        setDataTypes(dataTypeOptions);
                    }}
                    onClickClear={() => {
                        setDataTypes([]);
                    }}
                />
                <ComboBox
                    sx={{ maxWidth: 450 }}
                    multiple
                    groupBy={(option) => option.group ?? ""}
                    defaultRenderInputLabel={"Data Types"}
                    placeholder={"Search data types..."}
                    value={dataTypes}
                    onChange={(newDataTypes) => {
                        if (newDataTypes) {
                            setDataTypes(newDataTypes as ComboBoxOption[]);
                        }
                    }}
                    options={dataTypeOptions}
                    renderTags={() => null}
                    clearable={false}
                />
                <ComboBoxTagGroup
                    tags={separateOptionsIntoGroups({ options: dataTypes })}
                    onDelete={(value) => setDataTypes(dataTypes.filter((dt) => dt.value !== value.value))}
                    sx={{ mt: 4 }}
                />
            </Box>
        </Box>
    );
}
