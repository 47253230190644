import Typography from "@mui/material/Typography";
import React from "react";

import { NumericFieldWithUnit } from "../../../../generic/NumericFieldWithUnit/NumericFieldWithUnit";
import SensorSettingsBase from "./SensorSettingsBase";

export default class ParameterizedFlowmeterSettings extends SensorSettingsBase {
    acceptableRangeUnitCategory = "percent";

    defaultAcceptableValueRange = [0, 100];

    loadSettingsFromDevice(device, sensor) {
        const values = super.loadSettingsFromDevice(device, sensor);
        values.kFactor = sensor.polynomialPostProcessing?.[1] ?? 1.0;
        values.offset = (sensor.polynomialPostProcessing?.[0] ?? 0.0) / (sensor.polynomialPostProcessing?.[1] ?? 1.0);
        return values;
    }

    writeSettingsToDevice(values, device, sensor) {
        if (values.kFactor <= 0) {
            throw new Error("Error, K Factor is not allowed");
        }
        super.writeSettingsToDevice(values, device, sensor);
        sensor.polynomialPostProcessing = [values.offset * values.kFactor, values.kFactor];
    }

    renderSupplementalSettings(values) {
        return (
            <>
                <div
                    className={"SettingsItem"}
                    style={{
                        flexWrap: "wrap",
                    }}
                >
                    <Typography>Configuration Values: </Typography>
                    <div
                        className={"spacer"}
                        style={{
                            maxWidth: "20%",
                            minWidth: "0",
                            flexGrow: "1000",
                        }}
                    />
                    <NumericFieldWithUnit
                        label={"K Factor"}
                        showUnits={false}
                        value={values.kFactor ?? 1}
                        onChange={(e) => {
                            this.onChange({
                                kFactor: e.value,
                            });
                        }}
                        error={values.kFactor <= 0 ? "K Value must be greater than 0" : undefined}
                        style={{ maxWidth: "10rem", margin: "0.4rem" }}
                    />

                    <NumericFieldWithUnit
                        label={"Offset"}
                        showUnits={false}
                        value={values.offset ?? 0}
                        onChange={(e) => {
                            this.onChange({
                                offset: e.value,
                            });
                        }}
                        style={{ maxWidth: "10rem", margin: "0.4rem" }}
                    />
                </div>
                <Typography>
                    Conversion Preview: Liters/min = (frequency + {values.offset}) * {values.kFactor}
                </Typography>
            </>
        );
    }
}
