import "../HelpCSS.css";

import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "../HelpBase";
import HelpImage from "../HelpImage";

const StepTitle = "h6";

export function InputtingZoneDetails(props) {
    return (
        <HelpBase Title={"How to calculate the zone's flow rate"} Target={props.children}>
            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>1</span> Input the spacing between each emitter{" "}
            </Typography>

            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>2</span> Input the flow rate of a single emitter{" "}
            </Typography>

            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>3</span> Input the total length of tubing used in the zone{" "}
            </Typography>

            <Typography variant={StepTitle}>
                {" "}
                <span className={"StepNumber"}>4</span> Click the "Result" button to save the calculated value{" "}
            </Typography>
            <br />
            <HelpImage
                Image={"ZoneFlowRateCalculator.webp"}
                Caption={
                    "The result is calculated by multiplying the number of emitters in the zone with the flow rate of a single emitter in the zone."
                }
            />
        </HelpBase>
    );
}
