/* eslint-disable react/require-default-props,react/forbid-prop-types */
import "../FullSizeZoneOverview/fullsizeZoneOverview.css";
import "./DynamicGraph.scss";

import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Modal, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { getFirstDefined } from "gardenspadejs/dist/general";
import Papa from "papaparse";
import PropTypes from "prop-types";
import * as React from "react";

import FocusContext from "../../../services/mapManagement/FocusContext";
import GlobalOptions from "../../../utils/GlobalOptions";
import { GenerateUID, sleep } from "../../../utils/HelperFunctions";
import IconDatePicker from "../../generic/IconDatePicker/IconDatePicker";
import ZoneIrrigationScheduleBar from "../ZoneIrrigationScheduleBar/ZoneIrrigationScheduleBar";
import { DynamicGraphContext } from "./DynamicGraphUtility";
import { generateDataStreamList } from "./generateDataStreamList";
import TimeRangeSelectDropdown from "./TimeRangeSelectDropdown";
import TrendGraphBase from "./TrendGraph/TrendGraphBase";

let lastGraphWidth = 450;

export default class DynamicGraph extends React.Component {
    _ownElement = undefined;

    _seriesWrapperElement = undefined;

    allSensors;

    mounted = false;

    sensorDeviceZoneLengths = [];

    constructor(props) {
        super(props);
        this.uid = GenerateUID("ZoneOverviewGraph");

        this.state = {
            width: this.props.startingWidth,
            canvasHeight: this.props.canvasHeight,
            startTime: this.props.startTime,
            open: false,
        };
        if (lastGraphWidth && Math.abs(this.state.width - lastGraphWidth) < 250) {
            this.state.width = lastGraphWidth;
        }
    }

    componentDidMount() {
        this.mounted = true;
        if (this.ownElement) {
            this.refreshGraphSize();
            this.seriesWrapperElement.addEventListener("resize", () => {
                console.info("Dynamic Graph: resizing heard, updating the graph size");
                this.refreshGraphSize();
            });
        }
        this.generateSensorList();
        this.updateGraphSize();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.startTime.valueOf() !== nextState.startTime.valueOf()) {
            FocusContext.onYAxisUpdated.trigger();
        }
        const newSensorDevZoneLengths = [
            (this.props.sensorList || []).length,
            (this.props.deviceList || []).length,
            (this.props.zoneList || []).length,
        ];
        if (newSensorDevZoneLengths.toString() !== this.sensorDeviceZoneLengths.toString()) {
            this.generateSensorList();
        }
        return true;
    }

    componentWillUnmount() {
        this.mounted = false;
        const linesToUnregister = [];
        Object.values(this.context.lines).forEach((l) => {
            if (l.parentGraph === this.uid) {
                linesToUnregister.push(l);
            }
        });
        linesToUnregister.forEach((l) => {
            this.context.unRegisterLine(l.uid);
        });
    }

    /**
     *
     * @returns {HTMLElement}
     */
    get ownElement() {
        if (this._ownElement) {
            return this._ownElement;
        }
        this._ownElement = document.getElementById(this.uid);
        return this._ownElement;
    }

    /**
     *
     * @returns {HTMLElement}
     */
    get seriesWrapperElement() {
        if (this._seriesWrapperElement) {
            return this._seriesWrapperElement;
        }
        this._seriesWrapperElement = document.getElementById(`SeriesGraphWrapper${this.uid}`);
        return this._seriesWrapperElement;
    }

    generateSensorList() {
        this.sensorDeviceZoneLengths = [
            (this.props.sensorList || []).length,
            (this.props.deviceList || []).length,
            (this.props.zoneList || []).length,
        ];

        const [allSensors] = generateDataStreamList(
            {
                dataStreams: this.props.sensorList,
                devices: this.props.deviceList,
                zones: this.props.zoneList,
            },
            this.context,
        );

        allSensors.forEach((sensor) => {
            sensor.parentGraph = this.uid;
            if (!this.context.lines[sensor.uid]) {
                this.context.reserveColor(sensor.uid, sensor.colorPreference);
                this.context.registerLine(sensor.uid, sensor.sensorType, sensor);
            }
        });
        this.allSensors = Object.values(this.context.lines).filter((line) => line.parentGraph === this.uid);
        return this.allSensors;
    }

    exportData() {
        const data = [];
        Object.values(this.context.filteredGraphPoints).map((point) => data.push(...point.data));
        data.sort((a, b) => a.date.valueOf() - b.date.valueOf());
        let allColumns = new Set();
        data.forEach((dataEntry) => {
            Object.keys(dataEntry).forEach((k) => {
                allColumns.add(k);
            });
        });

        allColumns.delete("date");
        const sourceNameRow = {
            date: "source:",
        };
        const dataTypeRow = {
            date: "datatype:",
        };
        allColumns.forEach((columnName) => {
            const splitName = columnName.split(/(__|@)/g);
            sourceNameRow[columnName] = splitName[0];
            dataTypeRow[columnName] = splitName[2];
        });
        allColumns = ["date", "Local Time", ...allColumns];

        const cursor = {};

        data.forEach((dataEntry) => {
            Object.assign(cursor, dataEntry);
            Object.assign(dataEntry, cursor);
        });

        for (let i = 0; i < data.length; i++) {
            const dataEntry = data[i];
            const nextPoint = data[i + 1];
            if (nextPoint && dataEntry && Math.abs(nextPoint.date.valueOf() - dataEntry.date.valueOf()) < 1000 * 60) {
                data.splice(i, 1);
                i--;
            }
        }

        data.forEach((dataEntry) => {
            dataEntry["Local Time"] = dataEntry.date.toLocaleString();
        });
        data.unshift(dataTypeRow);
        data.unshift(sourceNameRow);

        const csvData = Papa.unparse(data, {
            header: true,
            columns: allColumns,
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = `data:text/csv;charset=utf-8,${encodeURI(csvData)}`;
        const curDate = new Date(Date.now());
        downloadLink.download = `${getFirstDefined(
            this.props.csvTitle,
            this.props.title,
            "Trend Graph",
        )} ${curDate.getFullYear()}-${curDate.getMonth() + 1}-${curDate.getDate()}.csv`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    async updateGraphSize() {
        let lastWidth = this.ownElement.getBoundingClientRect().width;
        await sleep(50);
        for (let i = 0; i < 15; i++) {
            if (!this.mounted) {
                return;
            }
            const newWidth = this.ownElement.getBoundingClientRect().width;
            if (lastWidth === newWidth && this.state.width !== newWidth) {
                this.refreshGraphSize();
            }
            lastWidth = newWidth;
            await sleep(50);
        }
        lastGraphWidth = this.ownElement.getBoundingClientRect().width;
    }

    refreshGraphSize() {
        if (this.ownElement) {
            this.setState({
                width: this.ownElement.getBoundingClientRect().width,
                canvasHeight: this.props.canvasHeight || this.seriesWrapperElement.getBoundingClientRect().height,
            });
        }
    }

    render() {
        const ModalOpenWrapper = ({ condition, wrap, children }) => (condition ? wrap(children) : children);
        const commonProps = {
            // subtract to account for the width of the y axis labels
            width: this.state.open ? "80vw" : this.state.width - 42,
            height: this.state.canvasHeight,
            duration: this.state.duration,
            graphID: this.UID,
        };
        const zonesToShowIrrigation = this.props.irrigationBarZones || (this.props.zone ? [this.props.zone] : []);
        const showIrrigationBarLabels = getFirstDefined(
            this.props.showIrrigationBarLabels,
            zonesToShowIrrigation.length > 0,
        );
        const style = {
            ...this.props.style,
        };

        if (this.state.open === true) {
            style["--graphWidth"] = "90vw";
        } else {
            style["--graphWidth"] = `${this.state.width - 15}px`;
        }
        const halfDay = 1000 * 60 * 60 * 12;
        return (
            <div id={this.uid} style={style} className={`StaticZoneGraphComponent ${this.props.className}`}>
                <div className={"staticGraphHeaderContainer"}>
                    <Typography
                        variant={"h4"}
                        style={{
                            // this makes sure that it's this text that's the first thing to clip
                            minWidth: 0,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {this.props.title || "Graph"}
                    </Typography>
                    {GlobalOptions.enableFullScreenGraph && (
                        <Tooltip title={"Fullscreen"}>
                            <IconButton
                                size={"small"}
                                onClick={() => {
                                    this.setState({ open: true });
                                }}
                            >
                                <FullscreenIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={"Export Data to CSV"}>
                        <IconButton
                            size={"small"}
                            onClick={() => {
                                this.exportData();
                            }}
                        >
                            <FileDownloadIcon />
                        </IconButton>
                    </Tooltip>
                    <div style={{ maxWidth: "100%", minWidth: 0, flexGrow: 100 }} />
                    <div className={"staticGraphHeaderContainer__chooseDate"}>
                        <TimeRangeSelectDropdown
                            defaultTimeRange={"4 days"}
                            onChange={(start, end) => {
                                if (
                                    this.context.dateRange[0].valueOf() !== start.valueOf() ||
                                    this.context.dateRange[1].valueOf() !== end.valueOf()
                                ) {
                                    this.context.dateRange = [new Date(start.valueOf()), new Date(end.valueOf())];
                                    console.info("Time range select dropdown changed, updating date range to", [
                                        this.context.dateRange[0],
                                        this.context.dateRange[1],
                                    ]);
                                    this.context.onDateRangeChange.trigger();
                                }
                            }}
                        />
                        <div className={"staticGraphHeaderContainer__chooseDate--picker"}>
                            <IconDatePicker
                                value={this.context.cursorTime}
                                onChange={(v) => {
                                    this.context.cursorTime = v;
                                    this.context.dateRange = [
                                        new Date(this.context.cursorTime.valueOf() - halfDay),
                                        new Date(this.context.cursorTime.valueOf() + halfDay),
                                    ];
                                    console.info("Date picker changed time, updating date range to", [
                                        this.context.dateRange[0],
                                        this.context.dateRange[1],
                                    ]);
                                    this.context.onDateRangeChange.trigger();
                                    this.setState({});
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={"staticGraphsContainer"}>
                    <div
                        style={{
                            height:
                                this.state.open === true &&
                                `${document.querySelector(".graphContainer").offsetHeight}px`,
                        }}
                    />
                    <div className={"graphContainer"}>
                        <ModalOpenWrapper
                            condition={this.state.open === true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(255,255,255,0.8)",
                                height: "100vh",
                                width: "100vw",
                                margin: "auto",
                            }}
                            wrap={
                                // eslint-disable-next-line react/no-unstable-nested-components
                                (children) => (
                                    <Modal
                                        open={this.state.open}
                                        close={() => {
                                            this.setState({ open: false });
                                        }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "rgba(255,255,255,0.8)",
                                            height: "100vh",
                                            width: "100vw",
                                            margin: "auto",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                backgroundColor: "white",
                                                padding: "10px",
                                                paddingLeft: "60px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography variant={"h3"} mr={"auto"} ml={"auto"}>
                                                    {this.props.csvTitle ?? "Zone Data"}
                                                </Typography>
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({ open: false });
                                                    }}
                                                    size={"large"}
                                                    aria-label={"close"}
                                                    style={{
                                                        maxWidth: "3rem",
                                                        maxHeight: "3rem",
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>
                                            {children}
                                        </div>
                                    </Modal>
                                )
                            }
                        >
                            {!this.props.hideIrrigationBars &&
                                zonesToShowIrrigation.map((z) => (
                                    <React.Fragment key={z.id}>
                                        {showIrrigationBarLabels && (
                                            <Typography variant={"caption"}>{z.name}</Typography>
                                        )}
                                        <ZoneIrrigationScheduleBar
                                            {...commonProps}
                                            height={15}
                                            modalOpen={this.state.open}
                                            zoneID={z.id}
                                            dataType={"zoneIrrigationScheduled"}
                                        />
                                    </React.Fragment>
                                ))}
                            {!this.props.hideIrrigationBars &&
                                (this.props.zoneList || []).map((z) => (
                                    <ZoneIrrigationScheduleBar
                                        key={z.id}
                                        {...commonProps}
                                        modalOpen={this.state.open}
                                        height={15}
                                        zoneID={z.id}
                                        dataType={"zoneIrrigationScheduled"}
                                    />
                                ))}
                            <div id={`SeriesGraphWrapper${this.uid}`} className={"SeriesGraphWrapper"}>
                                <TrendGraphBase
                                    {...commonProps}
                                    modalOpen={this.state.open}
                                    sensors={this.allSensors}
                                    displayBackground={true}
                                    graphDataType={this.props.graphDataType}
                                    parentGraphID={this.uid}
                                />
                            </div>
                        </ModalOpenWrapper>
                    </div>
                </div>
            </div>
        );
    }
}
DynamicGraph.contextType = DynamicGraphContext;

DynamicGraph.propTypes = {
    startTime: PropTypes.instanceOf(Date),
    startingWidth: PropTypes.number,
    canvasHeight: PropTypes.number,
    styleOptions: PropTypes.object,
    sensorList: PropTypes.array,
    irrigationBarZones: PropTypes.array,
    showIrrigationBarLabels: PropTypes.bool,
    deviceList: PropTypes.array,
    zoneList: PropTypes.array,
    className: PropTypes.string,
    graphDataType: PropTypes.string,
    title: PropTypes.string,
    csvTitle: PropTypes.string,
};
