import "./CurrentIrrigationStatusBar.scss";

import { Typography } from "@mui/material";
import React from "react";
import { MasterIndex } from "verdiapi";
import { SessionHandler } from "verdiapi/dist/APICommands/SessionHandler";
import { HashedDaySchedule } from "verdiapi/dist/Models";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

const min = 1000 * 60;
export default class CurrentIrrigationStatusBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const states = this.getStates();
        this.setState(states);
        SessionHandler.reloadRecentDataAction.trigger();
        SessionHandler.onRecentDataUpdated.addListener((e) => {
            let update = false;
            if (this.props.device && e.affectedDevices && e.affectedDevices.has(this.props.device.id)) {
                update = true;
            }
            if (this.props.zone && e.affectedDevices && e.affectedDevices.has(this.props.zone.id)) {
                update = true;
            }
            if (update) {
                const newStates = this.getStates();
                this.setState(newStates);
            }
        });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.device !== this.props.device || nextProps.zone !== this.props.zone) {
            setTimeout(() => {
                this.getStates();
                this.setState({});
            }, 50);
        }
        return true;
    }

    componentWillUnmount() {}

    // not refactoring this right now, but the method should probably be extracted
    // eslint-disable-next-line class-methods-use-this
    getCurrentIrrigationInfoForZone(zone, ...otherEvents) {
        if (!zone) {
            return {
                shouldBeIrrigating: false,
                closestEvent: undefined,
                uncertain: true,
            };
        }
        const uncertain = zone.recentData.zoneIrrigationScheduled === 0.5;
        const relevantDays = [
            HashedDaySchedule.getHashRecord(new Date(Date.now()), zone.id),
            HashedDaySchedule.getHashRecord(new Date(Date.now() + min * 60 * 24), zone.id),
            HashedDaySchedule.getHashRecord(new Date(Date.now() - min * 60 * 24), zone.id),
        ];
        const eventsHappeningNow = [];
        const closeEvents = [];
        let shouldBeIrrigating = false;
        const processEvent = (e) => {
            if (e === undefined) {
                return;
            }
            if (e.startTime.valueOf() < Date.now() && e.endTime.valueOf() > Date.now()) {
                eventsHappeningNow.push(e);
                shouldBeIrrigating = true;
            }
            if (e.startTime.valueOf() < Date.now() + min * 30 && e.endTime.valueOf() > Date.now() - min * 30) {
                closeEvents.push(e);
            }
        };
        relevantDays.forEach((rd) => {
            rd.includedEvents.forEach(processEvent);
        });
        otherEvents.forEach(processEvent);

        const closestEvent = eventsHappeningNow[0] || closeEvents[0] || undefined;
        return {
            shouldBeIrrigating,
            closestEvent,
            uncertain,
        };
    }

    getStates() {
        const newState = {
            isAutomated: false,
        };
        const zones = [];
        if (this.props.zones) {
            zones.push(...this.props.zones);
        }
        if (this.props.zone) {
            zones.push(this.props.zone);
        }
        if (!this.props.zones && !this.props.zone && this.props.device) {
            zones.push(...this.props.device.connectedZoneIDs.map((zid) => MasterIndex.zone.byID[zid]));
        }
        if (this.props.device) {
            if (DeviceConfigurationsByType[this.props.device?.type]) {
                newState.isSensorOnly = DeviceConfigurationsByType[this.props.device?.type].valveCount === 0;
            }
            if (this.props.device?.saplingAutomationSettings?.automationEnabled) {
                newState.isAutomated = true;
            }
            if (this.props.device.recentData.valveState !== undefined) {
                const valveStates = [
                    this.props.device.recentData.valveState.value % 2 > 0,
                    Math.floor(this.props.device.recentData.valveState.value / 2) % 2 > 0,
                ];
                // if it's a single valve or sensor only device.
                if (DeviceConfigurationsByType[this.props.device?.type].valveCount < 2) {
                    if (valveStates[0] === valveStates[1]) {
                        newState.isIrrigating = valveStates[0];
                    } else {
                        newState.isIrrigating = null;
                    }
                    newState.timeOfReading = new Date(SessionHandler.lastLoadedRecentData.valueOf()); // this.props.device.lastRefreshed;
                } else if (zones) {
                    if (zones.length === 1) {
                        const zone = zones[0];
                        const valve = this.props.device.connectedZoneIDs.indexOf(zone.id);
                        if (valve !== -1) {
                            newState.isIrrigating = valveStates[valve];
                            newState.timeOfReading = new Date(SessionHandler.lastLoadedRecentData.valueOf());
                        }
                    }
                }
            }
        }
        if (zones) {
            newState.shouldBeIrrigating = false;
            newState.closestEvent = undefined;
            let atLeastOneZoneUncertain = false;
            zones.forEach((z) => {
                const { shouldBeIrrigating, closestEvent, uncertain } = this.getCurrentIrrigationInfoForZone(
                    z,
                    newState.closestEvent,
                );
                newState.shouldBeIrrigating = newState.shouldBeIrrigating || shouldBeIrrigating;
                newState.closestEvent = closestEvent;
                if (uncertain) {
                    atLeastOneZoneUncertain = true;
                }
            });
            if (atLeastOneZoneUncertain && !newState.shouldBeIrrigating) {
                newState.zoneIrrigationStateUncertain = true;
            } else {
                newState.zoneIrrigationStateUncertain = false;
            }
        }
        return newState;
    }

    render() {
        if (this.state.isSensorOnly) {
            return null;
        }
        const isIrrigating = this.state.isIrrigating;
        const isAutomated = this.state.isAutomated;
        const shouldBeIrrigating = this.state.shouldBeIrrigating;
        const zoneIrrigationStateUncertain = this.state.zoneIrrigationStateUncertain;
        const irrigationStartTime = this.state.closestEvent ? this.state.closestEvent.startTime : 0; // new Date(Date.now() - 1000 * 60 * 30);
        const irrigationEndTime = this.state.closestEvent ? this.state.closestEvent.endTime : 0; // ;
        const timeOfReading = new Date(SessionHandler.lastLoadedRecentData.valueOf());
        let percentDone =
            (Date.now() - irrigationStartTime.valueOf()) /
            Math.max(2, irrigationEndTime.valueOf() - irrigationStartTime.valueOf());
        if (percentDone > 1) {
            percentDone = 1;
        }
        if (percentDone < 0) {
            percentDone = 0;
        }
        let className = `${this.props.className || ""} CurrentIrrigationStatusBar`;
        let text;
        let showBar = false;

        const timeTillStart = irrigationStartTime.valueOf() - timeOfReading.valueOf();
        const timeTillEnd = irrigationEndTime.valueOf() - timeOfReading.valueOf();
        const nearStart = timeTillStart < min * 5 && timeTillStart > min * 20;
        const nearEnd = timeTillEnd < min * 5 && timeTillEnd > min * 20;
        let warning = false;
        const outdated = Math.abs(Date.now() - timeOfReading.valueOf()) > 1000 * 60 * 10;
        if (isIrrigating === undefined) {
            showBar = false;
            text = "No data";
        } else if (isAutomated) {
            showBar = false;
            if (isIrrigating) {
                text = "Currently Irrigating (auto)";
            } else {
                text = "Not Irrigating (auto)";
            }
        } else if (shouldBeIrrigating) {
            showBar = true;

            if (isIrrigating) {
                text = "Currently Irrigating:";
            } else if (zoneIrrigationStateUncertain) {
                text = "Sending irrigation...";
            } else if (nearStart) {
                text = "Irrigation starting...";
            } else if (nearEnd) {
                text = "Irrigation complete";
            } else if (isIrrigating === null) {
                text = "Uncertain state";
            } else if (outdated) {
                text = "Irrigation not started. Try refreshing";
            } else {
                warning = true;
                text = "Irrigation not started!";
            }
        } else if (isIrrigating === false) {
            text = "Not Irrigating";
            showBar = false;
        } else if (zoneIrrigationStateUncertain) {
            text = "Canceling/changing irrigation...";
            showBar = false;
        } else if (nearStart) {
            text = "Irrigation started!";
            showBar = true;
        } else if (nearEnd) {
            text = "Irrigation ending...";
            showBar = true;
        } else if (isIrrigating === null) {
            text = "Uncertain state";
        } else if (outdated) {
            text = "Irrigating, try refreshing";
            showBar = false;
        } else {
            text = "Still irrigating!";
            warning = true;
            showBar = false;
        }
        if (warning) {
            className += " CurrentIrrigationStatusBar--warning ";
        }
        return (
            <div className={className}>
                <Typography className={"IrrigationStatusText"} variant={"caption"}>
                    {text}
                </Typography>
                {showBar && (
                    <div className={"irrigationBar"}>
                        <Typography
                            variant={"caption"}
                            component={"div"}
                            className={"irrigationBarFill"}
                            style={{
                                width: `${percentDone * 100}%`,
                            }}
                        >
                            {Math.round(percentDone * 100)}%
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}
