import { EventHandler, SessionHandler } from "verdiapi";
import { DeviceConfig } from "verditypes";

import CropIcon from "../../../components/icons/CropIcon";
import InfoCard from "../../../components/specialized/infoCards/InfoCard";
import { GenerateUID } from "../../../utils/HelperFunctions";
import FocusContext from "../FocusContext";
import { MapComponent } from "../MapHelper";

// export let AllMapEntities = [];

export const OnMapEntityCreated = new EventHandler();
export const OnMapEntityDeleted = new EventHandler();
export default class MapEntityBase {
    /**
     * Globally unique identifier to match the marker on the map to the component
     * @type {string}
     */
    uid;

    /**
     *
     * @type {InfoCard}
     */
    infoCard = undefined;

    /**
     * The database ID of the model this is representing
     * @type {string}
     */
    id;

    /**
     * The VerdiAPI model that the MapEntity is displaying data for
     * @type {ModelBase}
     */
    model = undefined;

    /**
     * Latitude position
     * @type {number}
     */
    lat = 0;

    /**
     * Longditude position
     * @type {number}
     */
    long = 0;

    /**
     * Current Status. Will normally be ACTIVE or ERROR
     * @type {"Error" | "Warning" | "Active"}
     */
    status = "Unknown";

    /**
     * Information panel to be displayed when the component is clicked
     * @type {InfoCard}
     */
    infoPanel = InfoCard;

    /**
     * OnMap is true if the component is already being rendered on the map
     * beingRendered is true if the component is in the process of being rendered
     * on the map
     * @type {{onMap: boolean, beingRendered: boolean}}
     */
    renderState = {
        onMap: false,
        beingRendered: false,
    };

    get name() {
        return this.model.name;
    }

    /**
     *
     * @type {Element[]}
     */
    domElements = [];

    /**
     * An element that can be used to anchor menus or information icons to a point on the map
     * @type {Element}
     */
    anchor = undefined;

    /**
     * A leaflet Element, such as a marker or a polygon, that is representing the MapEntity
     * @type {L.Polygon || L.Marker}
     */
    leafletElement = undefined;

    onFocusStateChange = new EventHandler();

    /**
     * based on current FocusContext;
     * @return {"hidden" | "focused" | "selected" | "inactive" | "inactive" | "active"}
     */
    get focusState() {
        if (FocusContext.cachedMapEntityStates[this.uid]) {
            return FocusContext.cachedMapEntityStates[this.uid];
        }
        FocusContext.activeContext.updateMapEntityState(this, false, true);
        return FocusContext.cachedMapEntityStates[this.uid] || "active";
    }

    _LastClassNames = [];

    customColor = undefined;

    get DOMClassNames() {
        let modelType = "";
        if (this.model && this.model.category) {
            modelType = this.model.category;
        }
        const initialClassNames = [modelType, "ME", `ME--${this.focusState}`];
        if (SessionHandler.admin && SessionHandler.currentUserObject?.webPreferences?.hideRedDevicesOnAdmin) {
            console.warn("Admin and hiding red devices");
            initialClassNames.push(`ME_Active`);
        } else {
            initialClassNames.push(`ME_${this.status}`);
        }
        if (this.customColor) {
            initialClassNames.push(`StandardColor_${this.customColor}`);
        }
        const deviceConfig = DeviceConfig.DeviceConfigurationsByType[this.model.type];
        if (
            deviceConfig &&
            deviceConfig.valveCount > 0 &&
            deviceConfig.generation === "sprout" &&
            this.model.valveState &&
            Array.isArray(this.model.valveState.open) &&
            (this.model.valveState.open[0] || this.model.valveState.open[1])
        ) {
            initialClassNames.push(`ME_ValveOpen`);
        }
        try {
            if (this.model && this.model?.sproutNetworkInfo?.manualOverrideInfo) {
                const manualOverrideInfo = this.model.sproutNetworkInfo.manualOverrideInfo;
                let targetState = manualOverrideInfo.remoteOverrideSetting;
                if (
                    new Date(manualOverrideInfo.deviceOverrideSettingLastUpdated?.valueOf()).valueOf() >
                    new Date(manualOverrideInfo.remoteOverrideSettingLastUpdated?.valueOf()).valueOf()
                ) {
                    targetState = manualOverrideInfo.deviceOverrideSetting;
                }
                const actualState = manualOverrideInfo.deviceOverrideSetting;
                if (actualState !== targetState) {
                    initialClassNames.push(`ME_CheckingForManualMode`);
                }
            }
        } catch (e) {
            console.warn("error applying manual override flash");
        }

        if (this.statusOptions && this.statusOptions.classNames) {
            if (Array.isArray(this.statusOptions.classNames)) {
                initialClassNames.push(...this.statusOptions.classNames);
            }
        }
        return initialClassNames;
    }

    _lastStatusOptions = undefined;

    get statusOptions() {
        if (this._lastStatusOptions) {
            return this._lastStatusOptions;
        }
        const stack = FocusContext.contextStack;
        const builtOptions = {};
        for (let i = 0; i < stack.length; i++) {
            Object.assign(builtOptions, stack[i].MapEntityStatusOptions[this.uid] || {});
        }
        this._lastStatusOptions = builtOptions;
        return this._lastStatusOptions;
    }

    _lastCSSVarKeys = [];

    onMap = true;

    updateIfClassNamesChanged() {
        const newClassNames = new Set(this.DOMClassNames);
        const lastClassNames = new Set(this._LastClassNames);
        // update needed if one set has an element the other does not.
        const updateNeeded =
            newClassNames.size !== lastClassNames.size ||
            Array.from(newClassNames).some((newClassName) => !lastClassNames.has(newClassName)) ||
            Array.from(lastClassNames).some((lastClassName) => !newClassNames.has(lastClassName));

        if (updateNeeded) {
            this.updateFocusState();
        }
    }

    /**
     * Updates the focus state of this MapEntity based on the active FocusContext
     */
    updateFocusState() {
        const newClassNames = this.DOMClassNames;
        const classNamesToRemove = [];
        const classNamesToAdd = [];
        this._LastClassNames.forEach((prevName) => {
            if (!newClassNames.includes(prevName)) {
                classNamesToRemove.push(prevName);
            }
        });
        newClassNames.forEach((newName) => {
            if (!this._LastClassNames.includes(newName)) {
                classNamesToAdd.push(newName);
            }
        });
        const cssVarKeys = Object.keys(this.statusOptions).filter((k) => k.includes("--", 0));
        const cssVarsToClear = this._lastCSSVarKeys.filter((key) => !cssVarKeys.includes(key));
        this.domElements.forEach((de) => {
            cssVarsToClear.forEach((clearVar) => {
                de.style.removeProperty(clearVar);
            });
            cssVarKeys.forEach((varToSet) => {
                de.style.setProperty(varToSet, this.statusOptions[varToSet]);
            });
            de.classList.remove(...classNamesToRemove);
            de.classList.add(...classNamesToAdd);
        });
        if (this.leafletElement) {
            if (this.renderState.onMap && this.focusState === "hidden") {
                this.leafletElement.removeFrom(MapComponent);
                this.renderState.onMap = false;
            } else if (!this.renderState.onMap && this.focusState !== "hidden") {
                this.renderOnMap();
                this.renderState.onMap = true;
            }
        }

        this._lastCSSVarKeys = cssVarKeys;
        this._LastClassNames = newClassNames;
        this.onFocusStateChange.trigger();
    }

    icon = CropIcon;

    // eslint-disable-next-line class-methods-use-this
    renderOnMap() {
        // pass
    }

    /**
     * id is the database id of the object being represented on the map, if available
     * @param UID
     * @param id
     */
    constructor(model, uid = undefined, renderImmediately = true) {
        this.uid = uid || GenerateUID("ME");
        FocusContext.MapEntitesByUID[this.uid] = this;
        if (model) {
            this.id = model.id;
            this.model = model;
            this.model.MapEntity = this;
            FocusContext.MapEntitesByModelID[model.id] = this;
            model.onDispose.addListener(() => {
                this.remove();
            });
        }
        // AllMapEntities.push(this);
        OnMapEntityCreated.trigger({ target: this });
        FocusContext.allMapEntites.add(this);
        if (renderImmediately) {
            this.renderOnMap();
        }
    }

    remove() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
        FocusContext.allMapEntites.delete(this);
        this.domElements.forEach((d) => {
            try {
                if (d) {
                    d.remove();
                }
            } catch (e) {
                console.warn("error removing map entity:", e);
            }
        });
        if (this.leafletElement) {
            this.leafletElement.remove();
        }
        if (this.infoCard) {
            this.infoCard.closeHandler();
        }
    }
}
