import "./SproutIndividualVerification.scss";

import { Typography } from "@mui/material";
import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import React from "react";
import { PacketEncodings } from "verditypes";

import {
    SproutOnlineCheck,
    SproutSensorCheck,
    SproutTimingCheck,
    SproutValveStateCheck,
} from "./SproutVerificationChecks/SproutVerificationCheck";
import { ValveCloseDroplet, ValveOpenDroplet } from "./VerificationIcons";

/**
 *
 * @param {SproutVerificationInstance} individualVerification
 * @param {boolean} doubleValve
 * @param {boolean} selected
 * @param {boolean} currentlyActive
 */
export default function SproutIndividualVerification({
    individualVerification,
    verificationIndex,
    selected,
    doubleValve,
    currentlyActive,
}) {
    if (!individualVerification) {
        return (
            <div className={"SproutIndividualVerification"}>
                <Typography>No Verification Data...</Typography>
            </div>
        );
    }
    const valvesIntendedOpen = PacketEncodings.ValveState.decode(verificationIndex).open;
    const verificationTimeString = getTimeString(
        new Date(individualVerification.date?.valueOf() || 0),
        "MMM d, hh:mm nn",
    );
    let ownStatus;
    if (individualVerification === undefined) {
        ownStatus = "inconclusive";
    } else if (individualVerification?.pendingData) {
        ownStatus = "pending";
    } else if (individualVerification?.successful) {
        ownStatus = "successful";
    } else {
        ownStatus = "error";
    }
    const ownStatusText = {
        inconclusive: "???",
        pending: "Pending",
        successful: "Pass",
        error: "Fail",
    }[ownStatus];
    const commonCheckProps = {
        individualVerification,
        verificationIndex,
        selected,
        doubleValve,
    };
    return (
        <div className={`SproutIndividualVerification SproutIndividualVerification--${ownStatus}`}>
            <div className={"SproutIndividualVerification__Header"}>
                <div className={"SproutIndividualVerification__HeaderTitle"}>
                    <div className={"SproutIndividualVerification__HeaderTitleText"}>
                        <Typography variant={"h4"}>Verification: </Typography>
                        {
                            <>
                                {(doubleValve ? [0, 1] : [0]).map((i) => (
                                    <div key={`V${i + 1}`} className={"IndividualValveState"}>
                                        {/* {doubleValve && ( */}
                                        {/*    <div className={"IndividualValveState__label"}> */}
                                        {/*        <Typography variant={"subtitle2"}>Valve {i + 1}</Typography> */}
                                        {/*    </div> */}
                                        {/* )} */}
                                        <div className={"IndividualValveState__icon"}>
                                            {valvesIntendedOpen[i] && (
                                                <ValveOpenDroplet valveText={doubleValve ? `V${i + 1}` : ""} />
                                            )}
                                            {!valvesIntendedOpen[i] && (
                                                <ValveCloseDroplet valveText={doubleValve ? `V${i + 1}` : ""} />
                                            )}
                                        </div>
                                        <div className={"IndividualValveState__state"}>
                                            {/* <Typography variant={"caption"}> */}
                                            {/*    {valvesIntendedOpen[i] ? "open" : "close"} */}
                                            {/* </Typography> */}
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                        <div className={"SproutIndividualVerification__HeaderDescriptiveState"}>
                            {!doubleValve && <Typography>Valve {valvesIntendedOpen[0] ? "open" : "close"}</Typography>}
                            {doubleValve && (
                                <Typography>Valve 1: {valvesIntendedOpen[0] ? "open" : "close"}</Typography>
                            )}
                            {doubleValve && (
                                <Typography> Valve 2: {valvesIntendedOpen[1] ? "open" : "close"}</Typography>
                            )}
                        </div>
                    </div>
                    <Typography variant={"body2"} className={"SproutIndividualVerification__HeaderTitleUpdatedAt"}>
                        Last updated @{verificationTimeString}
                    </Typography>
                </div>
                {/* <div className={"SproutIndividualVerification__HeaderValveIcons"}> */}

                <div className={"Spacer"}>
                    {currentlyActive && <div className={"liveIcon"} />}
                    {currentlyActive && (
                        <Typography className={"LiveText"} variant={"button"}>
                            NOW
                        </Typography>
                    )}
                </div>

                <div
                    className={`SproutIndividualVerification__HeaderResult VerificationStatusIcon VerificationStatusIcon--${ownStatus}`}
                >
                    <Typography variant={"button"}>{ownStatusText}</Typography>
                </div>
                {/* </div> */}
            </div>
            <div className={"SproutIndividualVerification__Body"}>
                <div className={"SproutIndividualVerification__BodyChecks"}>
                    {individualVerification?.verificationChecks?.transitionOnTime && (
                        <SproutTimingCheck
                            {...commonCheckProps}
                            check={individualVerification?.verificationChecks?.transitionOnTime}
                        />
                    )}
                    {individualVerification?.verificationChecks?.online && (
                        <SproutOnlineCheck
                            {...commonCheckProps}
                            check={individualVerification?.verificationChecks?.online}
                        />
                    )}
                    {individualVerification?.verificationChecks?.valveState && (
                        <SproutValveStateCheck
                            {...commonCheckProps}
                            check={individualVerification?.verificationChecks?.valveState}
                        />
                    )}
                    {individualVerification?.verificationChecks?.sensor1 && (
                        <SproutSensorCheck
                            {...commonCheckProps}
                            check={individualVerification?.verificationChecks?.sensor1}
                        />
                    )}
                    {individualVerification?.verificationChecks?.sensor2 && (
                        <SproutSensorCheck
                            {...commonCheckProps}
                            check={individualVerification?.verificationChecks?.sensor2}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
