import "./ProvideFeedbackModal.scss";

import { MenuItem, SxProps } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ProvideFeedbackModal } from "./ProvideFeedbackModal";

interface ProvideFeedbackButtonProps {
    onClick?: () => void;
    children?: React.ReactNode;
    sx?: SxProps;
}
export function ProvideFeedbackButton({ onClick, children, sx }: ProvideFeedbackButtonProps) {
    const { t } = useTranslation("specializedComponents");
    const [open, setOpen] = useState(false);

    return (
        <>
            <MenuItem
                key={`SettingsMenu_provideFeedback`}
                sx={{
                    minWidth: "100px",
                    ...sx,
                }}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                    setOpen(true);
                }}
            >
                {children || t("provideFeedbackModal.feedbackBtn.feedbackBtnTitle")}
            </MenuItem>

            <ProvideFeedbackModal
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
            />
        </>
    );
}
