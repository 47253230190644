import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { Suspense, useEffect } from "react";
import SimpleBar from "simplebar-react";

import FocusContext from "../../../services/mapManagement/FocusContext";
import GhostFocusContext from "../../../services/mapManagement/mapEntities/GhostFocusContext";
import GlobalOptions from "../../../utils/GlobalOptions";
import { GenerateUID } from "../../../utils/HelperFunctions";
import { DefaultAvatar, DefaultHeader } from "./DefaultFunctionalInfoCardComponents";

export const FunctionalInfoCardEffect = React.memo((props) => {
    const mapEntity = props.mapEntity || props.MapEntity;
    const isFirstRender = React.useRef(true);

    // start as loaded, this becomes false when the component is unloaded.
    const isLoaded = React.useRef(true);
    const ownFocusContext = React.useRef(undefined);
    const controlPanel = React.useRef(undefined);
    const ownUID = React.useRef(undefined);
    const currentlyInteracting = React.useRef(false);
    const getCurrentlyVisState = React.useRef(false);
    const timeOfLastContentVisible = React.useRef(Date.now());
    const rootElement = React.useRef(undefined);
    const cachedHeaderSize = React.useRef(-1);
    const headerElement = React.useRef(undefined);

    const initialVisiblity = React.useRef(undefined);
    initialVisiblity.current = FocusContext._mapEntityBeingFocused === mapEntity ? "visible" : "hidden";
    const simpleBarRef = React.createRef();
    const [getVisibleState, setVisibleState] = React.useState(initialVisiblity.current);
    getCurrentlyVisState.current = getVisibleState;

    useEffect(() => {
        if (props?.onChangeVisibleState) {
            props.onChangeVisibleState(getVisibleState);
        }
    }, [getVisibleState]);

    // const [getContentLoaded, getContentLoaded] = React.useState(false)
    const [isError] = React.useState(false);
    if (getVisibleState !== "hidden") {
        timeOfLastContentVisible.current = Date.now();
    }
    const getContentLoaded = Math.abs(Date.now() - timeOfLastContentVisible.current) < 1000 * 60 * 5; // getVisibleState !== "hidden";

    const maxHeightExpression =
        props.maxHeightExpression || ((windowHeight) => `${((windowHeight - 200) * 0.8).toString()}px`);
    if (!ownUID.current) {
        ownUID.current = GenerateUID("InformationPanelUID");
    }
    const minHeightExpression = props.minHeightExpression;
    const Target = props.Target || {
        uid: ownUID.current,
    };
    let TargetUID = ownUID.current;
    if (Target) {
        TargetUID = Target.uid;
    }
    const scrollable = props.scrollable || true;

    const getClassNames = () => {
        const allClassNames = [];
        const newVisiblityClass = ` InfoPane_CardHolder--${getVisibleState}`;
        if (props.className) {
            allClassNames.push(props.className);
        }
        allClassNames.push(newVisiblityClass);
        if (props.bounceLoad) {
            allClassNames.push("InfoPane_CardHolder--bounceLoad");
        }
        allClassNames.push("InfoPane_CardHolder");
        if (isFirstRender.current) {
            allClassNames.push("InfoPane_CardHolder--firstRender");
        }
        return allClassNames.join(" ");
    };
    if (isFirstRender.current) {
        // ownUID.current = GenerateUID("InformationPanelUID");

        let focusContext;
        if (props.focusContext && typeof props.focusContext === "function") {
            focusContext = props.focusContext();
        } else if (props.focusContext) {
            focusContext = props.focusContext;
        } else {
            focusContext = new GhostFocusContext();
        }

        ownFocusContext.current = focusContext;

        controlPanel.current = {
            currentInfoCardType: props.currentInfoCardType,
            focusContext: focusContext,
            VisiblityState: getVisibleState,
            usurp: () => {
                // if the component is not currently loaded, setting visible state to hidden will not work.
                if (isLoaded.current) {
                    setVisibleState("hidden");
                }
                FocusContext.releaseStack(ownFocusContext.current);
            },
            focus: () => {
                setVisibleState("visible");

                if (!FocusContext.contextStack.includes(ownFocusContext.current)) {
                    FocusContext.clearStack().then(() => {
                        FocusContext.pushContextToStack(ownFocusContext.current);
                    });
                }
            },
            CauseInteractEvent: () => {
                if (!currentlyInteracting.current && rootElement.current) {
                    window.requestAnimationFrame(() => {
                        rootElement.current.classList.add("InfoPane_CardHolder--interacted");
                        currentlyInteracting.current = true;
                    });
                    rootElement.current.addEventListener("animationend", () => {
                        window.requestAnimationFrame(() => {
                            rootElement.current.classList.remove("InfoPane_CardHolder--interacted");
                            currentlyInteracting.current = false;
                        });
                    });
                }
            },
            getHeaderSize: () => {
                if (headerElement.current) {
                    if (cachedHeaderSize.current <= 0) {
                        cachedHeaderSize.current = headerElement.current.getBoundingClientRect().height;
                    }
                }
                return cachedHeaderSize.current;
            },
            setHeaderHeight: () => {
                requestAnimationFrame(() => {
                    if (headerElement.current) {
                        const HeaderSize = Math.max(40, controlPanel.current.getHeaderSize());
                        rootElement.current.style.setProperty("--header-height", `${HeaderSize}px`);
                    }
                });
            },
            setMaxHeight: () => {
                window.requestAnimationFrame(() => {
                    try {
                        if (rootElement.current) {
                            rootElement.current.style.setProperty(
                                "--maxInfoPaneHeight",
                                maxHeightExpression(window.innerHeight),
                            );
                        }

                        if (minHeightExpression) {
                            rootElement.current.style.setProperty(
                                "--minInfoPaneHeight",
                                minHeightExpression(window.innerHeight),
                            );
                        }
                    } catch (e) {
                        console.warn(e);
                    }
                });
            },
            closeHandler: (e) => {
                setVisibleState("hidden");
                const actuallyClose = () => {
                    setTimeout(() => {
                        if (FocusContext.currentInfoCard === controlPanel.current) {
                            FocusContext.currentInfoCard = undefined;
                        }
                    }, 200);
                };
                if (ownFocusContext.current) {
                    FocusContext.releaseStack(ownFocusContext.current).then(() => {
                        actuallyClose();
                    });
                } else {
                    actuallyClose();
                }
                if (e) {
                    e.stopPropagation();
                }
            },
        };
        if (props.controlPanel) {
            Object.assign(controlPanel.current, props.controlPanel);
        }
        if (mapEntity) {
            mapEntity.infoCard = controlPanel.current;
        }
        setTimeout(() => {
            controlPanel.current.setHeaderHeight();
        }, 200);
    }
    const title = props.title || props.name || "No title";
    // this means that after the components is unloaded, isLoaded gets set to false.
    React.useEffect(
        () => () => {
            console.info(`Disposing of info card for "${title}" info card`);
            isLoaded.current = false;
        },
        [],
    );

    // Ensure root element ref is set before we add it to FocusContext
    React.useLayoutEffect(() => {
        controlPanel.current.RootElement = rootElement.current;
    }, []);

    // React.useEffect(() => {
    //     if (getVisibleState !== "hidden" && !FocusContext.contextStack.includes(ownFocusContext.current)) {
    //         FocusContext.pushContextToStack(ownFocusContext.current);
    //     } else {
    //         FocusContext.releaseStack(ownFocusContext.current);
    //     }
    // }, [getVisibleState]);
    controlPanel.current.VisiblityState = getVisibleState;
    const Header = props.header || DefaultHeader;
    const avatar = props.avatar || DefaultAvatar;
    const Tray = props.TrayComponent || null;
    const classNames = getClassNames();
    isFirstRender.current = false;
    return (
        <div
            id={ownUID.current}
            className={`${classNames} InfoCardWrapper`}
            style={{
                "--maxInfoPaneHeight": maxHeightExpression(window.innerHeight),
                "--minInfoPaneHeight": minHeightExpression(window.innerHeight),
            }}
            ref={rootElement}
        >
            <div id={`Tray--${TargetUID}`} key={`Generated_tray--${TargetUID}`} className={"InfoPanelTray"}>
                {Tray && (
                    <Tray
                        id={`InfoCardTray--${TargetUID}`}
                        Target={Target}
                        Visiblity={getVisibleState}
                        getVisibility={() => getVisibleState}
                    />
                )}
            </div>
            <Card className={`InfoCardMainCard InfoCardMainCard--${getVisibleState}`}>
                <Header
                    ref={headerElement}
                    title={title}
                    action={props.action || null}
                    subheader={props.subheader || null}
                    avatar={avatar}
                    closeHandler={React.useCallback((e) => {
                        console.info("closing info card");
                        controlPanel.current.closeHandler(e);
                    })}
                    mapEntity={mapEntity}
                    Target={Target}
                    headerClickHandler={React.useCallback((e) => {
                        e.stopPropagation();
                    })}
                />
                {!isError && (
                    <CardContent
                        component={scrollable && GlobalOptions.useSimpleBar ? SimpleBar : null}
                        ref={simpleBarRef}
                        id={`InfoCard--${TargetUID}`}
                        key={`Generated_InfoCard--${TargetUID}`}
                        className={`InfoPaneBody InfoPaneBody--${
                            props.contentComponent && getContentLoaded ? "loaded" : "unloaded"
                        }${scrollable && !GlobalOptions.useSimpleBar ? " InfoPaneBody--nativeScroll" : ""}`}
                    >
                        {props.contentComponent && getContentLoaded && (
                            <Suspense fallback={<div style={{ minHeight: "50vh" }}>Loading</div>}>
                                <props.contentComponent
                                    Target={Target}
                                    Visiblity={getVisibleState}
                                    InfoCard={controlPanel.current}
                                    MapEntity={mapEntity}
                                />
                            </Suspense>
                        )}
                    </CardContent>
                )}
            </Card>
        </div>
    );
});
