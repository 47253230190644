import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

const gl = document.createElement("canvas").getContext("webgl2");
let supportsWebGL2 = true;
if (!gl) {
    supportsWebGL2 = false;
}
export default class WebGL2Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessage: !supportsWebGL2,
        };
    }

    clear() {
        this.setState({ showMessage: false });
        this.props.onClose(0);
    }

    render() {
        return (
            <Dialog
                open={this.state.showMessage && !this.props.cleared}
                onClose={() => {
                    this.clear();
                }}
                aria-labelledby={"alert-dialog-title"}
                aria-describedby={"alert-dialog-description"}
            >
                <DialogTitle>Update your browser</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <b>Please update your browser!</b> WebGL2 is required for certain parts of this app to work
                        properly and is not available in your browser. Please update it to get the most out of this
                        site.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        <b>iPhone or iPad:</b> please update to at least IOS 15 and ensure the browser you are using is
                        up to date.
                    </DialogContentText>
                    <DialogContentText>
                        <b>Mac:</b> please download and install macOS 12 (Monterey).
                    </DialogContentText>
                    <DialogContentText>
                        <b>Windows:</b> If you are using Chrome, Firefox, Edge, or another browser on a Windows
                        computer, use the browser's settings to update it. If you need help, instructions are available{" "}
                        <a href={"https://browser-update.org/update-browser.html"}>here</a>
                    </DialogContentText>
                    <br />

                    <DialogContentText>
                        Updating your browser or OS is a hassle, but we really appreciate being able to use the latest
                        features to make your experience better. Thanks for your understanding and please reach out if
                        you have any questions regarding this message!
                    </DialogContentText>
                    <br />
                    <DialogContentText>The Verdi Team</DialogContentText>
                    <br />
                    <br />
                    <DialogContentText>
                        <i>
                            If you believe that this message is being displayed in error, go{" "}
                            <a href={"https://get.webgl.org/webgl2/"}>here</a> to check if your browser has WebGL2
                            support. If you see a spinning cube, then contact the Verdi team and we will work on fixing
                            this warning.
                        </i>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.clear();
                        }}
                        color={"primary"}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
