import "./SproutVerificationCheck.scss";

import Typography from "@mui/material/Typography";
import { GenerateApproximateTime, getTimeString } from "gardenspadejs/dist/dateHelpers";
import * as math from "mathjs";
import React from "react";
import { DataTypeConfig, PacketEncodings } from "verditypes";

import { VerificationIconByStatus } from "../VerificationIcons";

export function SproutVerificationCheck({ status, title, expectedRange, actualValue, updatedAt }) {
    // status = "pending";
    let statusText = status;
    if (statusText === "successful") {
        statusText = "pass";
    }
    if (statusText === "inconclusive") {
        statusText = "no data";
    }
    const showUpdatedAt = Boolean(updatedAt);
    let timeStringSinceUpdate = "";
    if (showUpdatedAt) {
        timeStringSinceUpdate = GenerateApproximateTime(
            new Date(updatedAt?.valueOf()),
            new Date(Date.now()),
            false,
            false,
            "seconds",
        );
    }

    return (
        <div className={"SproutVerificationCheck"}>
            <div className={"SproutVerificationCheck__AccordianHeader"}>
                <div className={"SproutVerificationCheck__AccordianHeaderDetails"}>
                    <Typography variant={"h6"}>
                        {title}
                        {showUpdatedAt && (
                            <Typography component={"span"} variant={"subtitle1"} className={"UpdatedAt"}>
                                {" "}
                                Data collected: {timeStringSinceUpdate}
                            </Typography>
                        )}
                    </Typography>
                    <Typography variant={"body1"}>Actual: {actualValue}</Typography>
                    <Typography variant={"body2"}>Expected: {expectedRange}</Typography>
                </div>
                <div
                    className={`SproutVerificationCheck__AccordianHeaderStatus VerificationStatusIcon VerificationStatusIcon--${status}`}
                >
                    {/* <VerificationIconByStatus status={status} /> */}
                    {/* <Typography variant={"button"}>{status}</Typography> */}
                    <VerificationIconByStatus status={status} />
                    <Typography variant={"button"}>{statusText}</Typography>
                </div>
            </div>
        </div>
    );
}
/**
 *
 * @param {SproutVerificationInstance} individualVerification
 * @param {boolean} doubleValveDevice
 * @param {boolean} selected
 * @param {SproutVerificationCheck} check
 */
export function SproutTimingCheck({ individualVerification, selected, check }) {
    const startRange = new Date(check.acceptableRange?.min?.valueOf() || 0);
    const endRange = new Date(check.acceptableRange?.max?.valueOf() || 0);
    const actualTransitionTime = new Date(check.value?.valueOf() || 0);
    const expectedRangeString = getTimeString(startRange, "MMM dd h:mm: nn - ") + getTimeString(endRange, "h:mm nn");
    return (
        <SproutVerificationCheck
            status={check.status}
            title={"Timing"}
            expectedRange={expectedRangeString}
            actualValue={
                actualTransitionTime.valueOf() > 1000
                    ? getTimeString(actualTransitionTime, "MMM dd h:mm nn")
                    : "waiting..."
            }
        />
    );
}

/**
 *
 * @param {SproutVerificationInstance} individualVerification
 * @param {boolean} doubleValveDevice
 * @param {boolean} selected
 * @param {SproutVerificationCheck} check
 */
export function SproutValveStateCheck({ individualVerification, selected, doubleValve, check }) {
    const expectedState = PacketEncodings.ValveState.decode(check?.acceptableRange?.exact || 0).open;
    const actualState = PacketEncodings.ValveState.decode(check?.value || 0).open;
    const expectationString = doubleValve
        ? `V1 ${expectedState[0] ? "open" : "close"}, V2 ${expectedState[1] ? "open" : "close"}`
        : `Valve ${expectedState[0] ? "open" : "close"}`;

    const actualStateString = doubleValve
        ? `V1 ${actualState[0] ? "open" : "close"}, V2 ${actualState[1] ? "open" : "close"}`
        : `Valve ${actualState[0] ? "open" : "close"}`;
    return (
        <SproutVerificationCheck
            status={check.status}
            title={"Valve State"}
            expectedRange={expectationString}
            actualValue={actualStateString}
            updatedAt={check.valueGatheredAt || null}
        />
    );
}

/**
 *
 * @param {SproutVerificationInstance} individualVerification
 * @param {boolean} doubleValveDevice
 * @param {boolean} selected
 * @param {SproutVerificationCheck} check
 */
export function SproutSensorCheck({ individualVerification, selected, check }) {
    const sensorDataType = check.valueDataType;
    const databaseUnit = DataTypeConfig.DataTypeConfigurations[sensorDataType]?.databaseUnit;
    let current = check?.value;
    let min = check?.acceptableRange?.min;
    let max = check?.acceptableRange?.max;
    let unit = databaseUnit;
    if (sensorDataType === "v1transducer") {
        current = math.unit(current, "kPa").toNumber("psi");
        min = math.unit(min, "kPa").toNumber("psi");
        max = math.unit(max, "kPa").toNumber("psi");
        unit = "psi";
    }
    const actualStateString = `${Math.round(current * 10) / 10} ${unit}`;
    const expectationString = `${Math.round(min * 10) / 10} - ${Math.round(max * 10) / 10} ${unit}`;
    return (
        <SproutVerificationCheck
            status={check.status}
            title={"Sensor Verification"}
            expectedRange={expectationString}
            actualValue={actualStateString}
            updatedAt={check.valueGatheredAt || null}
        />
    );
}

/**
 *
 * @param {SproutVerificationInstance} individualVerification
 * @param {boolean} doubleValveDevice
 * @param {boolean} selected
 * @param {SproutVerificationCheck} check
 */
export function SproutOnlineCheck({ individualVerification, selected, check }) {
    return (
        <SproutVerificationCheck
            status={check.status}
            title={"Device Online"}
            expectedRange={"online"}
            actualValue={check.status === "successful" ? "online" : "offline"}
            updatedAt={check.valueGatheredAt || null}
        />
    );
}
