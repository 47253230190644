/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import React from "react";

import source from "!!raw-loader!./ConfirmChoiceButtonDemo.jsx";

import ConfirmChoiceButton from "./ConfirmChoiceButton";
import readme from "./README.md";

export function DemoComponent() {
    return (
        <div>
            <ConfirmChoiceButton
                onClick={() => {
                    console.info("Button clicked");
                }}
            >
                Do Action
            </ConfirmChoiceButton>
            <ConfirmChoiceButton
                color={"error"}
                confirmationQuestion={"Are you REALLY sure?"}
                confirmationText={"Yes I am sure"}
                onClick={() => {
                    console.info("Button clicked");
                }}
            >
                Delete
            </ConfirmChoiceButton>
        </div>
    );
}

const ConfirmChoiceButtonDemo = {
    component: DemoComponent,
    name: "ConfirmChoiceButton",
    readme: readme,
    source: source,
};

export default ConfirmChoiceButtonDemo;
