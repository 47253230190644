import "../HelpCSS.css";

import Typography from "@mui/material/Typography";
import React from "react";

import HelpBase from "../HelpBase";

export function WarningLowAccuracy(props) {
    return (
        <HelpBase
            Title={"Low Accuracy Warning"}
            Target={props.children}
            className={props.className}
            style={props.style}
            component={props.component}
        >
            <Typography className={"StepDetails"}>
                <b>Low Accuracy</b> means that your Verdi device isn't reliably executing the schedules it is given.
                This could be for a number of reasons, including poor network connection or frequent network outages. It
                could also be the result of frequently using a magnet to override the device valve state.
            </Typography>
            <Typography className={"StepDetails"}>
                If you are getting this error and are not frequently magnetting the device, please contact us. This
                device may be malfunctioning.
            </Typography>
        </HelpBase>
    );
}
