import "./HelpCSS.css";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { MUItheme } from "../../../styles/ColourPalette";

function DialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            style={{
                margin: 0,
                padding: MUItheme.spacing(2),
                display: "flex",
            }}
            {...other}
        >
            <Typography variant={"h6"} style={{ margin: "auto" }}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label={"close"}
                    style={{
                        right: MUItheme.spacing(1),
                        color: MUItheme.palette.grey[500],
                        display: "block",
                        textAlign: "right",
                        padding: 0,
                        lineHeight: "initial",
                        height: "1.5rem",
                        marginTop: "auto",
                        marginBottom: "auto",
                    }}
                    onClick={onClose}
                    size={"large"}
                >
                    <CloseIcon
                        style={{
                            verticalAlign: "unset",
                        }}
                    />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
}
// );

export default class HelpBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    render() {
        return (
            <>
                {this.props.component && (
                    <this.props.component
                        onClick={() => {
                            this.setState({ open: true });
                        }}
                        className={this.props.className}
                        style={this.props.style}
                    >
                        {this.props.Target}
                    </this.props.component>
                )}
                {!this.props.component && (
                    <span
                        onClick={() => {
                            this.setState({ open: true });
                        }}
                        className={this.props.className}
                        style={this.props.style}
                    >
                        {this.props.Target}
                    </span>
                )}
                <MobileView renderWithFragment={true}>
                    <Dialog scroll={"paper"} open={this.state.open} fullScreen={true}>
                        <DialogTitle
                            id={"scroll-dialog-title"}
                            onClose={() => {
                                this.setState({ open: false });
                            }}
                        >
                            {this.props.Title}
                        </DialogTitle>
                        <DialogContent dividers={true} className={"HelpText"}>
                            {this.props.children}
                        </DialogContent>
                    </Dialog>
                </MobileView>

                <BrowserView renderWithFragment={true}>
                    <Dialog scroll={"paper"} open={this.state.open} fullScreen={false}>
                        <DialogTitle
                            id={"scroll-dialog-title"}
                            onClose={() => {
                                this.setState({ open: false });
                            }}
                        >
                            {this.props.Title}
                        </DialogTitle>
                        <DialogContent dividers={true} className={"HelpText"}>
                            {this.props.children}
                        </DialogContent>
                    </Dialog>
                </BrowserView>
            </>
        );
    }
}
