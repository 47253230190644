import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { Stack, SvgIconProps, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getTimeString } from "gardenspadejs/dist/dateHelpers";

import { Admin } from "../api";

const expiringSoonThresholdDays = 7;
const expiringSoonThresholdMS = expiringSoonThresholdDays * 24 * 60 * 60 * 1000;

function createRowData({ username, superAdmin, passwordExpirationDate }: Admin) {
    return {
        username,
        role: superAdmin ? "Super Admin" : "Admin",
        passwordExpirationDate: new Date(passwordExpirationDate).getTime(),
    };
}
function isExpiringSoon(timestamp: number) {
    return timestamp <= Date.now() + expiringSoonThresholdMS;
}

function isExpired(timestamp = 0) {
    return timestamp < Date.now();
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export function AdminUserTable({ data }: { data: Admin[] }) {
    const tableData = data.sort((a, b) => a.username.localeCompare(b.username)).map((user) => createRowData(user));
    return (
        <Stack>
            <Stack direction={"row"} gap={4} mb={4}>
                <Typography variant={"h2"} mb={2}>
                    All admin users
                </Typography>
                <Stack>
                    <Stack direction={"row"}>
                        <ErrorIcon color={"error"} />
                        <Typography ml={1}>Expired password</Typography>
                    </Stack>
                    <Stack direction={"row"}>
                        <WarningIcon color={"warning"} />
                        <Typography ml={1}>{`Expiring in the next ${expiringSoonThresholdDays} days`}</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, maxWidth: 1000 }} aria-label={"simple table"}>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Username</StyledTableCell>
                            <StyledTableCell align={"right"}>Role</StyledTableCell>
                            <StyledTableCell align={"right"}>Password expiration date</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row) => (
                            <StyledTableRow
                                key={row.username}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <StyledTableCell component={"th"} scope={"row"}>
                                    {row.username}
                                </StyledTableCell>
                                <StyledTableCell align={"right"}>{row.role}</StyledTableCell>
                                <StyledTableCell align={"right"}>
                                    <Icon timestamp={row.passwordExpirationDate} sx={{ mb: 1, mr: 2 }} />
                                    {getTimeString(new Date(row.passwordExpirationDate), "yyyy-MM-dd")}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
}

interface IconProps extends SvgIconProps {
    timestamp: number;
}

// Conditionally renders an icon based on the expiration date
function Icon({ timestamp, ...props }: IconProps) {
    if (isExpired(timestamp)) {
        return <ErrorIcon color={"error"} {...props} />;
    }

    if (isExpiringSoon(timestamp)) {
        return <WarningIcon color={"warning"} {...props} />;
    }

    return <></>;
}
