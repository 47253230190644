import { TextField } from "@mui/material";
import * as React from "react";

/**
 * @deprecated
 */
export default class NumericTextField extends React.Component {
    beingEdited = false;

    newDefault = undefined;

    constructor(props) {
        super(props);
        this.state = {
            curValue: this.props.defaultValue || undefined,
        };
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.defaultValue && nextProps.defaultValue !== this.props.defaultValue) {
            this.newDefault = nextProps.defaultValue;
        }
        return true;
    }

    triggerOnChange(newValue) {
        let coercedValue = newValue;
        try {
            coercedValue = parseFloat(newValue.toString());
        } catch (e) {
            console.warn("Non float value in numeric text field");
        }
        if (this.props.onChange) {
            this.props.onChange(coercedValue, newValue);
        }
    }

    render() {
        if (this.newDefault && !this.beingEdited) {
            this.state.curValue = this.newDefault;
            this.newDefault = undefined;
        }
        const passedProps = { ...this.props };
        delete passedProps.onChange;
        delete passedProps.defaultValue;
        delete passedProps.validate;
        return (
            <TextField
                {...passedProps}
                value={this.props.value || (this.props.value === 0 ? this.props.value : this.state.curValue)}
                onFocus={(e) => {
                    this.beingEdited = true;
                    if (this.props.onFocus) {
                        this.props.onFocus(e);
                    }
                }}
                onBlur={(e) => {
                    this.beingEdited = false;
                    if (this.props.validate) {
                        const valid = this.props.validate(this.state.curValue); // adds + or - sign
                        if (valid !== undefined && this.state.curValue !== valid) {
                            this.setState({
                                curValue: valid,
                            });
                            this.triggerOnChange(valid);
                            this.state.curValue = valid;
                        }
                    }
                    if (this.props.onBlur) {
                        this.props.onBlur(e);
                    }
                }}
                onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/[^0-9.]/g, "");
                    this.setState({
                        curValue: newValue,
                    });
                    this.triggerOnChange(newValue);
                }}
            />
        );
    }
}
