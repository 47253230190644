import "./RelativeIrrigationDurationEditor.css";
import "./ZoneProfileEditor.css";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Input, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import Slider from "@mui/material/Slider";
import { clamp, getFirstDefined } from "gardenspadejs/dist/general";
import * as React from "react";

import ReactComponentBase from "../../../utils/ReactComponentBase";
import { SettingRelativeIrrigationDuration } from "../HelpDialogues/ZoneProfileEditor/SettingRelativeIrrigationDuration";
import { irrigationDurationSliderMarks } from "./ZoneProfileEditorUtility";

const convertToPercent = (rawValue) => Math.round(-100 + rawValue * 100);
const convertToString = (rawValue) => (rawValue > 1 ? "+" : "") + Math.round(-100 + rawValue * 100).toString();
const convertToRawValue = (percent) => (percent + 100) / 100;

export default class RelativeIrrigationDurationEditor extends ReactComponentBase {
    constructor(props) {
        super(props);
        const startingValue = getFirstDefined(this.props.defaultValue, 1);

        this.state = {
            automaticallyUpdateIrrigationDuration: this.props.automaticallyUpdateIrrigationDuration,
            leftOffsetOfChip: 0,
            leftOffsetOfTextChip: 0,

            currentRelativeValue: startingValue,
            currentTextValue: convertToString(startingValue),
        };
    }

    sliderLeftOffset;

    sliderWidth;

    componentDidMount() {
        if (this.ownComponent) {
            const sliderWrapper = this.ownComponent.getElementsByClassName("relativeIrrigationDurationSlider")[0];
            const sliderBar = sliderWrapper.getElementsByClassName("MuiSlider-root")[0];
            const recommendedValueChip = this.ownComponent.getElementsByClassName("recommendedValueChip")[0];
            const recommendedValueChipWidth = recommendedValueChip.getBoundingClientRect().width;

            this.sliderLeftOffset =
                sliderBar.getBoundingClientRect().left - this.ownComponent.getBoundingClientRect().left;
            this.sliderWidth = sliderBar.getBoundingClientRect().width;
            const maxValue = 2;
            const leftOffsetOfChip =
                this.sliderLeftOffset + (this.props.recommendedIrrigationDuration / maxValue) * this.sliderWidth;
            let leftOffsetOfTextChip = leftOffsetOfChip - recommendedValueChipWidth / 2;
            if (leftOffsetOfTextChip < 0) {
                leftOffsetOfTextChip = 0;
            } else if (leftOffsetOfTextChip + recommendedValueChipWidth > this.sliderWidth) {
                leftOffsetOfTextChip = this.sliderWidth - recommendedValueChipWidth;
            }
            this.setState({
                leftOffsetOfChip: leftOffsetOfChip,
                leftOffsetOfTextChip: leftOffsetOfTextChip,
            });
        }
    }

    render() {
        const relativeIrrigationDemoBarWidthPercent = 50 + convertToPercent(this.state.currentRelativeValue) / 2;
        return (
            <div className={"relativeIrrigationDurationContainer"} id={this.uid}>
                <div className={"titleContainer"}>
                    <Typography className={"zoneDetailsSubtitles"}>Default Relative Irrigation Duration</Typography>

                    <SettingRelativeIrrigationDuration
                        style={{
                            position: "absolute",
                            right: 0,
                        }}
                    >
                        <HelpOutlineIcon className={"helpIconStyle"} />
                    </SettingRelativeIrrigationDuration>
                </div>
                <div className={"relativeIrrigationDurationSlider"}>
                    <Slider
                        value={convertToPercent(this.state.currentRelativeValue)} // default value
                        min={-100}
                        step={1}
                        max={100}
                        disabled={this.state.automaticallyUpdateIrrigationDuration}
                        onChange={(e) => {
                            const rawValue = convertToRawValue(e.target.value);
                            this.setState({
                                currentRelativeValue: rawValue,
                                currentTextValue: convertToString(rawValue),
                            });
                            if (this.props.onChange) {
                                this.props.onChange(rawValue);
                            }
                        }}
                        marks={irrigationDurationSliderMarks}
                    />
                    <Input
                        sx={{
                            "height": "40px",
                            "marginLeft": "20px",
                            "& .MuiInput-input": { width: "36px" },
                        }}
                        value={this.state.currentTextValue}
                        disabled={this.state.automaticallyUpdateIrrigationDuration}
                        onChange={(e) => {
                            let textValue = e.target.value;
                            textValue = textValue.replaceAll(/[^0-9\-+.]/g, "");
                            let numericValue = null;
                            try {
                                numericValue = parseFloat(textValue);
                                if (Number.isNaN(numericValue)) {
                                    numericValue = null;
                                }
                                if (numericValue !== null) {
                                    numericValue = clamp(numericValue, -100, 100);
                                }
                            } catch (error) {
                                console.warn(
                                    "Error: failed to parse numeric value in relative irrgation duration editor:",
                                    error,
                                );
                            }

                            this.setState({
                                currentRelativeValue:
                                    numericValue !== null
                                        ? convertToRawValue(numericValue)
                                        : this.state.currentRelativeValue,
                                currentTextValue: textValue,
                                // relativeIrrigationDuration: rawValue,
                                // curValue: input,
                            });
                            if (this.props.onChange && numericValue !== null) {
                                this.props.onChange(numericValue);
                            }
                        }}
                        onBlur={() => {
                            this.setState({
                                currentTextValue: convertToString(this.state.currentRelativeValue),
                            });
                        }}
                        endAdornment={<InputAdornment position={"end"}>%</InputAdornment>}
                        inputProps={{
                            min: 0,
                            max: 200,
                        }}
                    />
                </div>
                <div className={"recommendedValueMarker"} style={{ left: `${this.state.leftOffsetOfChip}px` }} />
                <Chip
                    className={"recommendedValueChip"}
                    style={{ left: `${this.state.leftOffsetOfTextChip}px` }}
                    label={`Recommended: ${
                        convertToPercent(this.props.recommendedIrrigationDuration) >= 0 ? "+" : ""
                    }${convertToPercent(this.props.recommendedIrrigationDuration)}%`}
                    onClick={() => {
                        const percentValue = convertToPercent(this.props.recommendedIrrigationDuration);
                        let newValue = percentValue.toString();
                        if (percentValue >= 1 && percentValue <= 100) {
                            newValue = `+${newValue.replace(/[^0-9.]/g, "")}`;
                        }
                        this.setState({
                            relativeIrrigationDuration: this.props.recommendedIrrigationDuration,
                            curValue: newValue,
                        });
                        if (this.props.onChange) {
                            this.props.onChange(this.props.recommendedIrrigationDuration);
                        }
                    }}
                />
                <div className={"exampleSchedulerContainer"}>
                    <div className={"exampleSchedulerTitle"}>
                        <CalendarTodayIcon className={"irrDurDemoCalendarIcon"} />
                        Example Scheduler
                    </div>
                    <div className={"irrigationDurationDemoContainer"}>
                        <div className={"thisZoneDemoTitle"}>This Zone's Duration</div>
                        <div className={"thisZoneDemoBarGrid"}>
                            <div
                                className={"thisZoneDemoBar"}
                                style={{ width: `${relativeIrrigationDemoBarWidthPercent}%` }}
                            />
                        </div>
                        <div className={"defaultZoneDemoTitle"}>Default Duration</div>
                        <div className={"defaultZoneDemoBarGrid"}>
                            <div className={"defaultZoneDemoBar"} />
                        </div>
                    </div>
                </div>

                {/* <FormControlLabel */}
                {/*    control={ */}
                {/*        <Switch */}
                {/*            checked={this.state.automaticallyUpdateIrrigationDuration} */}
                {/*            onChange={() => { */}
                {/*                let newState = !this.state.automaticallyUpdateIrrigationDuration; */}
                {/*                let percentValue = convertToPercent(this.props.recommendedIrrigationDuration); */}
                {/*                let newValue = percentValue.toString(); */}
                {/*                if (percentValue >= 1 && percentValue <= 100) { */}
                {/*                    newValue = `+${newValue.replace(/[^0-9.]/g, "")}`; */}
                {/*                } */}
                {/*                this.setState({ */}
                {/*                    automaticallyUpdateIrrigationDuration: newState, */}
                {/*                    currentRelativeValue: this.props.recommendedIrrigationDuration, */}
                {/*                    currentTextValue: convertToString(this.props.recommendedIrrigationDuration), */}
                {/*                }); */}
                {/*                if (this.props.onChange) { */}
                {/*                    this.props.onChange(this.props.recommendedIrrigationDuration); */}
                {/*                } */}
                {/*            }} */}
                {/*        /> */}
                {/*    } */}
                {/*    label="Automatically update to recommended value" */}
                {/*    labelPlacement="start" */}
                {/*    sx={{ */}
                {/*        "& .MuiFormControlLabel-label": { fontSize: "15px", lineHeight: 1.3 }, */}
                {/*    }} */}
                {/* /> */}
            </div>
        );
    }
}
