/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */

import "./IconDatePicker.scss";

import React from "react";

import source from "!!raw-loader!./IconDatePickerDemo.jsx";

import IconDatePicker from "./IconDatePicker";
import readme from "./README.md";

function DemoComponent() {
    const [startDate, setStartDate] = React.useState(new Date(Date.now()));
    return (
        <div>
            <IconDatePicker
                value={startDate}
                onChange={(v) => {
                    setStartDate(v);
                }}
                includeYear={true}
            />
            <IconDatePicker
                value={startDate}
                onChange={(v) => {
                    setStartDate(v);
                }}
                includeYear={false}
            />
        </div>
    );
}

const IconDatePickerDemo = {
    component: DemoComponent,
    name: "IconDatePicker",
    readme: readme,
    source: source,
};

export default IconDatePickerDemo;
