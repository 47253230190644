import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function CropIcon(props) {
    return (
        <SvgIcon viewBox={"30, 30, 182, 182 "} {...props}>
            <path
                d={`M 116.03,129.94
           C 70.18,152.18 47.45,108.00 31.27,70.36
             64.65,70.11 113.36,63.45 122.36,106.00
             130.55,62.18 184.36,69.27 213.27,70.36
             196.73,113.64 167.33,150.61 128.43,130.00
             128.15,131.37 128.50,136.25 128.50,138.31
             137.62,138.12 185.75,141.25 186.12,153.00
             192.25,153.62 199.75,153.62 205.62,155.62
             210.26,157.61 211.67,166.67 207.69,170.69
             205.07,173.32 201.40,172.99 198.00,173.00
             198.00,173.00 51.00,173.00 51.00,173.00
             48.11,172.99 44.74,173.30 42.34,171.40
             37.30,167.39 38.87,159.27 44.18,156.31
             47.35,154.55 58.53,154.20 62.12,152.75
             61.88,141.25 112.58,138.96 115.96,138.52
             115.96,135.27 116.00,132.30 116.03,129.94 Z
           M 57.88,83.12
           C 61.62,85.38 118.75,118.00 118.75,118.00
             117.11,68.00 65.62,75.00 39.00,75.00
             40.57,80.51 71.18,154.09 116.00,123.00
             116.00,123.00 58.88,89.62 54.62,87.00
             50.38,84.38 51.38,83.12 51.88,82.00
             52.38,80.88 54.12,80.88 57.88,83.12 Z
           M 205.45,74.91
           C 178.47,74.91 129.02,68.18 125.75,118.00
             125.75,118.00 184.82,83.91 188.18,82.18
             191.55,80.45 192.59,81.88 192.91,82.27
             193.19,82.67 194.45,84.64 191.09,86.82
             187.73,89.00 145.00,112.72 145.00,112.72
             139.67,115.75 132.96,118.50 129.00,123.00
             174.00,154.00 203.09,80.18 205.45,74.91 Z`}
            />
        </SvgIcon>
    );
}
