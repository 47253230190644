import "./MapEntityComponents/AOI.css";

import { GenerateUID } from "gardenspadejs/dist/general";
import { MasterIndex, SessionHandler } from "verdiapi";

import ZoneIcon from "../../../components/icons/ZoneIcon";
import { AreaOfInterestInfoCard } from "../../../components/specialized/infoCards/AreaOfInterestInfoCard";
import FocusContext from "../FocusContext";
import { addMapEntityPolygonToMap } from "../MapHelper";
import MapEntityBase from "./MapEntityBase";

export default class AreaofInterestMapEntity extends MapEntityBase {
    static infoCardClass = AreaOfInterestInfoCard;

    infoCardClass = AreaOfInterestInfoCard;

    /**
     *
     * @type {AreaOfInterest}
     */
    model = undefined;

    icon = ZoneIcon;

    /**
     * @param {Zone} aoi
     * @param {boolean} renderImmediately
     */
    constructor(aoi, renderImmediately = true) {
        super(aoi, GenerateUID("AOI"), false);
        this.model = aoi;
        const updateLatLongFromPolygon = () => {
            if (this.model.polygon && this.model.polygon.length > 0) {
                const result = getLatLongOfPolygon(this.model.polygon);
                this.lat = result.lat;
                this.long = result.long;
            }
        };
        try {
            if (this.model.polygon && this.model.polygon.length > 0) {
                updateLatLongFromPolygon();
                this.model.onChange.addListener(() => {
                    updateLatLongFromPolygon();
                    if (this.leafletElement) {
                        this.leafletElement.setLatLngs(this.model.polygon);
                    }
                });
            }
        } catch (e) {
            console.warn("Error setting center position of AOI:", e);
        }

        if (renderImmediately) {
            this.renderOnMap();
        }
    }

    renderOnMap() {
        if (!this.renderState.onMap && !this.renderState.beingRendered) {
            this.renderState.beingRendered = true;
            if (!SessionHandler.admin && MasterIndex.aoi.all.length < 2) {
                this.renderState.onMap = true;
                this.renderState.beingRendered = false;
                return;
            }
            addMapEntityPolygonToMap(this, this.model.polygon)
                .then((r) => {
                    this.leafletElement = r.leafletPolygon;
                    this.leafletElement.on("click", (e) => {
                        FocusContext.onInteraction(e, this);
                        this.leafletElement.bringToBack();
                    });
                    this.renderState.onMap = true;
                    this.renderState.beingRendered = false;
                    this.leafletElement.bringToBack();
                    this.updateFocusState();
                })
                .catch((e) => {
                    console.warn("Failed to render area of interest polygon");
                    console.warn(e);
                    this.renderState.beingRendered = false;
                    this.renderState.onMap = false;
                });
        }
    }

    updateFocusState() {
        super.updateFocusState();
        if (this.focusState === "focused") {
            this.domElements.forEach((de) => {
                if (de.tagName === "path") {
                    try {
                        de.parentElement.appendChild(de);
                    } catch (e) {
                        console.warn(e);
                    }
                }
            });
        }
        if (this.leafletElement) {
            this.leafletElement.setStyle({
                color: "#0083bd",
                weight: 2,
                fillOpacity: 0,
                fillColor: "#8D8D8D00",
            });
        }
    }
}

export function getLatLongOfPolygon(polygon) {
    const maxPoint = [-100000, -100000];
    const minPoint = [100000, 100000];
    polygon.forEach((vertice) => {
        maxPoint[0] = Math.max(maxPoint[0], vertice.lat);
        maxPoint[1] = Math.max(maxPoint[1], vertice.lng);
        minPoint[0] = Math.min(minPoint[0], vertice.lat);
        minPoint[1] = Math.min(minPoint[1], vertice.lng);
    });
    return {
        lat: (maxPoint[0] + minPoint[0]) / 2,
        long: (maxPoint[1] + minPoint[1]) / 2,
    };
}
