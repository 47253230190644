import "./VerificationInfoCardLine.scss";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ListItem, ListItemIcon } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";
import { isMobile } from "react-device-detect";
import { SessionHandler } from "verdiapi";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import GlobalOptions from "../../../utils/GlobalOptions";
import ReactComponentBase from "../../../utils/ReactComponentBase";
import { DeviceVerificationHelp } from "../HelpDialogues/DeviceVerificationHelp";
import ErrorTooltipIcon from "./ErrorTooltipIcon";
import SuccessTooltipIcon from "./SuccessTooltipIcon";
import UnverifiedTooltipIcon from "./UnverifiedTooltipIcon";
import { ValveOpenAndCloseVerify } from "./ValveOpenAndCloseVerify";
import { VerificationTroubleshootText } from "./VerificationTroubleshootText";

export default class SaplingVerification extends ReactComponentBase {
    render() {
        if (!GlobalOptions.showVerification) {
            return null;
        }
        if ((!this.props.device || !this.props.device.verificationInfo) && !this.props.verInfo) {
            return null;
        }
        if (!this.props.verInfo && DeviceConfigurationsByType[this.props.device.type]?.valveCount === 0) {
            return null;
        }
        if (
            !this.props.verInfo &&
            this.props.device?.saplingAutomationSettings?.automationEnabled &&
            !SessionHandler.admin
        ) {
            return null;
        }
        if (
            !this.props.verInfo &&
            this.props.device.saplingNetworkInfo?.version?.firmwareVersion &&
            this.props.device.saplingNetworkInfo?.version?.firmwareVersion < 4
        ) {
            return null;
        }
        const ver = this.props.device.verificationInfo || this.props.verInfo;

        const ValveOpenProps = {
            title: "Irrigate",
            lastVerification: ver.lastOpenVerification,
            lastVerificationSuccessful: ver.lastOpenVerificationSuccessful,
            lastReportedStateVerified: ver.lastOpenReportedStateVerified,
            lastFlowVerified: ver.lastOpenFlowVerified,
        };
        const ValveCloseProps = {
            title: "Close",
            lastVerification: ver.lastCloseVerification,
            lastVerificationSuccessful: ver.lastCloseVerificationSuccessful,
            lastReportedStateVerified: ver.lastCloseReportedStateVerified,
            lastFlowVerified: ver.lastCloseFlowVerified,
        };

        const successfulVerificationProps = {
            titleText: " Successful",
            Icon: SuccessTooltipIcon,
            tooltipText: "Success",
        };
        const failedVerificationProps = {
            titleText: " Failed",
            Icon: ErrorTooltipIcon,
            tooltipText: "Failed",
        };
        const incompleteVerificationProps = {
            titleText: " Incomplete",
            Icon: UnverifiedTooltipIcon,
            tooltipText: "Incomplete",
        };
        let OverallVerificationProps = {};

        const atLeastOneVerificationFailed =
            (ver.lastOpenVerificationSuccessful !== undefined && !ver.lastOpenVerificationSuccessful) ||
            (ver.lastCloseVerificationSuccessful !== undefined && !ver.lastCloseVerificationSuccessful);
        const incompleteVerification =
            (ver.lastOpenVerification === undefined && ver.lastCloseVerification === undefined) ||
            (ver.lastOpenVerificationSuccessful && ver.lastCloseVerification === undefined) ||
            (ver.lastOpenVerification === undefined && ver.lastCloseVerificationSuccessful);
        const bothVerificationsSuccessful = ver.lastOpenVerificationSuccessful && ver.lastCloseVerificationSuccessful;

        if (atLeastOneVerificationFailed) {
            OverallVerificationProps = failedVerificationProps;
        } else if (incompleteVerification) {
            OverallVerificationProps = incompleteVerificationProps;
        } else if (bothVerificationsSuccessful) {
            OverallVerificationProps = successfulVerificationProps;
        }

        const passedProps = { ...this.props };
        delete passedProps.listItem;
        delete passedProps.Target;

        if (this.props.listItem) {
            const showTroubleshooting = !this.props.hideTroubleshooting;
            return (
                <ListItem className={"VerificationInfoCardLine"}>
                    <div className={"VerificationInfoCardTitle"}>
                        <ListItemIcon className={"IconContainer"}>
                            <OverallVerificationProps.Icon tooltipText={OverallVerificationProps.tooltipText} />
                        </ListItemIcon>
                        <ListItemText primary={`Device Verification${OverallVerificationProps.titleText}`} />
                        <DeviceVerificationHelp className={"VerificationInfoHelpModal"}>
                            <HelpOutlineIcon className={"VerificationInfoHelpModalIcon"} />
                        </DeviceVerificationHelp>
                    </div>

                    <div className={`ValveOpenAndCloseVerifyContainer ${isMobile ? "Mobile" : ""}`}>
                        <div style={{ minWidth: "56px", flexShrink: 0 }} />
                        <ValveOpenAndCloseVerify valveState={ValveOpenProps} />
                        <ValveOpenAndCloseVerify valveState={ValveCloseProps} />
                        {showTroubleshooting && (
                            <VerificationTroubleshootText verificationInfo={this.props.device.verificationInfo} />
                        )}
                        {this.props.showFailReason && atLeastOneVerificationFailed && (
                            <Typography>{ver.reasonForFailure}</Typography>
                        )}
                    </div>
                </ListItem>
            );
        }
        return (
            <IconButton {...passedProps}>
                <OverallVerificationProps.Icon tooltipText={OverallVerificationProps.tooltipText} />
            </IconButton>
        );
    }
}
