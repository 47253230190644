import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function DraginoECIcon(props) {
    return (
        <SvgIcon viewBox={"0, 0, 8.2916508, 10.06577"} {...props}>
            <path
                style={{
                    fillRule: "evenodd",
                    strokeWidth: 0.464217,
                }}
                d={
                    "M 12.765625 2.3945312 C 10.239884 2.3945312 8.2070313 4.4293373 8.2070312 6.9550781 L 8.2070312 19.240234 L 10.103516 19.240234 L 10.103516 35.146484 L 10.800781 36.34375 L 11.496094 35.146484 L 11.496094 19.240234 L 15.107422 19.240234 L 15.107422 35.146484 L 15.802734 36.34375 L 16.498047 35.146484 L 16.498047 19.240234 L 20.109375 19.240234 L 20.109375 35.146484 L 20.804688 36.34375 L 21.5 35.146484 L 21.5 19.240234 L 23.125 19.240234 L 23.125 6.9550781 C 23.125 4.4293373 21.090194 2.3945312 18.564453 2.3945312 L 12.765625 2.3945312 z "
                }
                transform={"scale(0.26458333)"}
            />
        </SvgIcon>
    );
}
