export class URLParams {
    static _getAllParams(hashString) {
        const hashSearch = /#[^?/\\]*/.exec(hashString);
        let hash;
        if (hashSearch && hashSearch.length >= 1) {
            hash = hashSearch[0].replace("#", "");
        }
        const parameterPairs = {};
        if (hashString.includes("?")) {
            const paramString = /\?[^?/\\]*/.exec(hashString)[0].replace("?", "");
            if (paramString) {
                paramString.split("&").forEach((section) => {
                    const keyValue = section.split("=");
                    if (keyValue[0] && keyValue[1]) {
                        parameterPairs[keyValue[0]] = keyValue[1];
                    }
                });
            }
        }
        return {
            params: parameterPairs,
            hash: hash,
        };
    }

    static generateHashString(hash, params) {
        if (hash === undefined || hash === null) {
            hash = "";
        }
        let masterString = `#${hash}?`;
        Object.keys(params).forEach((pKey) => {
            if (params[pKey] !== undefined) {
                masterString += pKey;
                masterString += "=";
                masterString += params[pKey];
                masterString += "&";
            }
        });
        return masterString;
    }

    static getAllParams() {
        const hashString = window.location.hash;
        return this._getAllParams(hashString);
    }

    static setParam(param, value) {
        const allParams = this.getAllParams();
        allParams.params[param] = value;
        window.location.hash = this.generateHashString(allParams.hash, allParams.params);
    }

    static getParam(param) {
        const allParams = this.getAllParams();
        return allParams.params[param];
    }
}
