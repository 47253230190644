import "./fullsizeZoneOverview.css";
import "simplebar/dist/simplebar.min.css";

import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import * as React from "react";

import { GenerateUID } from "../../../utils/HelperFunctions";
import {
    getChipsFromDictionary,
    getMostRelevantSoilLayer,
    getYearsOfAge,
    statusDictionary,
} from "../../../utils/ZoneOverviewUtility";
import DepthProfile from "../../generic/DepthProfile/DepthProfile";
import NDVIChip from "../../generic/NDVIChip/NDVIChip";
import SimpleBarCompatability from "../../generic/SimpleBarCompatability/SimpleBarCompatability";
import TypographyWithMaxWidth from "../../generic/TypographyWithMaxWidth/TypographyWithMaxWidth";
import VarietalAvatar from "../../icons/VarietalAvatar";
import DynamicGraph from "../DynamicGraph/DynamicGraph";

function DivComp(props) {
    return <div {...props} />;
}
export default class FullsizeZoneOverview extends React.Component {
    _bodyElement = undefined;

    _headerElement = undefined;

    _ownElement = undefined;

    /**
     *
     * @type {{
     *  varietal: string,
     *  color: string,
     *  dateEstablished: Date,
     *  lastIrrigationTime: Date,
     *  lastIrrigationDuration: number,
     *  soilCompositions: [
     *      {
     *          depth: number,
     *          sand: number,
     *          silt: number,
     *          clay: number,
     *          capacity: number
     *      }
     *  ],
     *  lastMoistureReading: number,
     *  defaultSoilCapacity: number,
     *  lastNDVIReading: number,
     *  rootClone: string,
     *  vineClone: string,
     * }}
     */
    characteristics = {};

    endTimeForGraph;

    startTimeForGraph;

    constructor(props) {
        super(props);
        this.uid = GenerateUID("FullsizeZoneOverview");
        this.state = {
            bodyWidth: 500,
        };
        this.refreshCharacteristics();
        this.endTimeForGraph = new Date(Date.now());
        this.startTimeForGraph = new Date(this.endTimeForGraph - 1000 * 60 * 60 * 24 * 30);
        this.soilProfileMaxWidth = 400;
    }

    componentDidMount() {
        if (this.ownElement) {
            this.setState({
                bodyWidth: this.bodyElement.getBoundingClientRect().width,
            });
            this.bodyElement.addEventListener("resize", () => {
                this.setState({
                    bodyWidth: this.bodyElement.getBoundingClientRect().width,
                });
            });
            this.headerElement.addEventListener("resize", () => {
                this.forceUpdate();
            });
        }
        if (this.props.zone) {
            this.props.zone.onChange.addListener(() => {
                this.refreshCharacteristics();
                this.forceUpdate();
            });
        }
    }

    get bodyElement() {
        if (this._bodyElement) {
            return this._bodyElement;
        }
        this._bodyElement = document.getElementById(`ZoneOverviewBody${this.uid}`);
        return this._bodyElement;
    }

    get headerElement() {
        if (this._headerElement) {
            return this._headerElement;
        }
        this._headerElement = document.getElementById(`ZoneOverviewBody${this.uid}`);
        return this._headerElement;
    }

    get ownElement() {
        if (this._ownElement) {
            return this._ownElement;
        }
        this._ownElement = document.getElementById(this.uid);
        return this._ownElement;
    }

    refreshCharacteristics() {
        let recentMoistureReading;
        if (this.props.zone.moistureSensors && Array.isArray(this.props.zone.moistureSensors)) {
            const dataPoints = [];
            this.props.zone.moistureSensors.forEach((ms) => {
                const recentData = ms.recentData;
                if (ms.recentData) {
                    const recentDataPoints = [
                        recentData.moisture_watermark,
                        recentData.moisture_irrometer,
                        recentData.moisture,
                    ].filter((v) => v !== undefined);
                    dataPoints.push(...recentDataPoints);
                }
            });
            dataPoints.sort((b, a) => b?.date?.valueOf() - a?.date?.valueOf());
            recentMoistureReading = dataPoints[0]?.value / 100;
        }
        let AOINDVI = 0;
        if (
            this.props.zone &&
            this.props.zone.linkedAreaOfInterest &&
            this.props.zone.linkedAreaOfInterest.zonesInArea &&
            Array.isArray(this.props.zone.linkedAreaOfInterest.zonesInArea)
        ) {
            let NDVISum = 0;
            let numOfNDVIs = 0;
            this.props.zone.linkedAreaOfInterest.zonesInArea.forEach((zoneInAOI) => {
                if (zoneInAOI.recentData?.avgNDVI?.value) {
                    NDVISum += zoneInAOI.recentData?.avgNDVI?.value;
                    numOfNDVIs++;
                }
            });
            if (numOfNDVIs > 0) {
                AOINDVI = NDVISum / numOfNDVIs;
            }
        }
        this.characteristics = {
            zoneName: this.props.zone.name,
            varietal: this.props.zone.userMetadata?.varietal,
            color: "purple",
            dateEstablished: new Date(this.props.zone.userMetadata?.datePlanted),
            lastIrrigationTime: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
            lastIrrigationDuration: this.props.zone.recentData?.lastIrrigationDuration?.value,
            soilCompositions: this.props.zone.soilLayers || [],
            lastMoistureReading: recentMoistureReading,
            defaultSoilCapacity: 0.8,
            lastNDVIReading: this.props.zone.recentData?.avgNDVI?.value,
            areaAverageNDVI: AOINDVI,
            rootClone: this.props.zone.userMetadata?.rootClone,
            vineClone: this.props.zone.userMetadata?.vineClone,
            statusEffects: this.props.zone.recentData?.statusEffects?.value, // ["winterDamage", "flood", "test123", "heatDamage", "literallydoesnotexist"],
        };
    }

    render() {
        const yearsOfAge = getYearsOfAge(this.characteristics.dateEstablished) || undefined;
        const relevantSoilProfile = getMostRelevantSoilLayer(this.characteristics.soilCompositions);

        const inZoneCard = this.props.inZoneCard;
        const showZoneName = this.props.showZoneName;
        const condensedCharacteristics = this.state.bodyWidth > this.soilProfileMaxWidth && relevantSoilProfile;
        const vineOrRootClone = this.characteristics.vineClone || this.characteristics.rootClone;
        let ScrollWrapper = DivComp;
        let scrollWrapperStyle = { flexGrow: 1, flexShrink: 1, minHeight: 0, maxWidth: "100%" };
        if (!inZoneCard) {
            ScrollWrapper = SimpleBarCompatability;
            scrollWrapperStyle = { flexGrow: 1, flexShrink: 1, minHeight: 0, maxHeight: "30rem" };
        }
        return (
            <div
                id={this.uid}
                className={`fullsizeZoneOverview fullsizeZoneOverview--${
                    inZoneCard ? " " : "notInZoneCard "
                } fullsizeZoneOverview--${condensedCharacteristics ? "condensedChars" : "uncondensedChars"}`}
                // style={ this.props.style}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                onPointerMove={this.props.onPointerMove}
            >
                {this.props.showHeader && (
                    <div className={"headerContainer"}>
                        <div className={"varietalAvatarContainer"}>
                            <VarietalAvatar className={"varietalAvatar"} varietal={this.characteristics.varietal} />
                        </div>
                        <div className={"headerContent"}>
                            {showZoneName && (
                                <TypographyWithMaxWidth className={"zoneName"} MaxWidth={145} variant={"body1"}>
                                    {this.characteristics.zoneName}
                                </TypographyWithMaxWidth>
                            )}
                            <div className={"statusChips"}>
                                {getChipsFromDictionary(this.characteristics.statusEffects, statusDictionary)}
                            </div>
                        </div>
                    </div>
                )}
                <ScrollWrapper style={scrollWrapperStyle}>
                    <div
                        id={`ZoneOverviewBody${this.uid}`}
                        className={"FullsizeZoneOverviewBody"}
                        style={{ overflowX: "hidden" }}
                    >
                        <div className={"soilProfileWrapper"}>
                            {condensedCharacteristics && (
                                <Typography component={"div"} variant={"h4"}>
                                    Soil Texture
                                </Typography>
                            )}
                            <div className={condensedCharacteristics ? "condensedContainer" : ""}>
                                <div className={"currentValuesContainer"}>
                                    {yearsOfAge && (
                                        <div className={"ageContainer"}>
                                            <Typography className={"ageValueText"}>{yearsOfAge || "-"}</Typography>
                                            <Typography className={"ageText"}>Yrs</Typography>
                                        </div>
                                    )}
                                    {vineOrRootClone && (
                                        <div className={"cloneContainer"}>
                                            <Typography className={"cloneText"}>
                                                vine: {this.characteristics.vineClone || "-"}
                                                <Divider className={"cloneDivider"} />
                                                root: {this.characteristics.rootClone || "-"}
                                            </Typography>
                                        </div>
                                    )}
                                    {this.characteristics.lastNDVIReading && (
                                        <NDVIChip
                                            lastNDVIReading={this.characteristics.lastNDVIReading}
                                            areaAverageNDVI={this.characteristics.areaAverageNDVI}
                                            condensedCharacteristics={condensedCharacteristics}
                                        />
                                    )}
                                </div>
                                {relevantSoilProfile && (
                                    <div className={"depthProfileContainer"}>
                                        {!condensedCharacteristics && (
                                            <Typography component={"div"} variant={"h4"}>
                                                Soil Texture
                                            </Typography>
                                        )}
                                        <DepthProfile
                                            soilProfile={relevantSoilProfile}
                                            moistureLevel={this.characteristics.lastMoistureReading}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={"staticGraphContainer"}>
                            {/* <StaticZoneGraphComponent */}
                            {/*    startTime={this.startTimeForGraph} */}
                            {/*    endTime={this.endTimeForGraph || new Date(Date.now())} */}
                            {/*    zone={this.props.zone} */}
                            {/*    graphDataType={"sensoterra"} */}
                            {/*    startingWidth={300} */}
                            {/*    canvasHeight={150} */}
                            {/* /> */}
                            <DynamicGraph
                                startTime={this.startTimeForGraph}
                                endTime={this.endTimeForGraph || new Date(Date.now())}
                                zoneList={[this.props.zone]}
                                csvTitle={`${this.props?.zone?.name || "Zone"} Data`}
                                // sensorList={}
                                // deviceList={}
                                graphDataType={"sensoterra"}
                                startingWidth={300}
                                canvasHeight={150}
                                focusContext={this.props.focusContext}
                            />
                        </div>
                    </div>
                </ScrollWrapper>
            </div>
        );
    }
}
