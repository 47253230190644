import "./NDVIChip.css";

import { Typography } from "@mui/material";
import * as React from "react";

import { getNDVIColor } from "../../../utils/GetNDVIColor";

export default function NDVIChip({ areaAverageNDVI: areaNDVI, lastNDVIReading: zoneNDVI, ...props }) {
    return (
        <div className={"NDVIChipContainer"} {...props}>
            <div className={"NDVITitle"}>
                <Typography sx={{ fontSize: 12, color: "rgba(255, 255, 255)", lineHeight: 1 }}>NDVI</Typography>
            </div>
            <div className={"NDVIZoneValue"} style={{ backgroundColor: `${getNDVIColor(zoneNDVI)}` }}>
                <Typography className={"NDVIValueText"}>{zoneNDVI.toFixed(2)}</Typography>
                <Typography className={"NDVILocationText"}>Zone</Typography>
            </div>
            <div className={"NDVIAreaValue"} style={{ backgroundColor: `${getNDVIColor(areaNDVI)}` }}>
                <Typography className={"NDVIValueText"}>{areaNDVI ? areaNDVI.toFixed(2) : "-"}</Typography>
                <Typography className={"NDVILocationText"}>Area</Typography>
            </div>
        </div>
    );
}
