import "./DeviceStatus.scss";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { DeviceConfig } from "verditypes";

import BatteryStatusIcon from "../../icons/StatusIcons/BatteryStatusIcon";
import { OperationModeIcon } from "../../icons/StatusIcons/OperationModeIcon";
import SignalStrengthStatusIcon from "../../icons/StatusIcons/SignalStrengthStatusIcon";
import { ValveIcon } from "../SproutVerification/VerificationIcons";

export function DeviceStatus({ Target }) {
    const { t } = useTranslation(["specializedComponents"], { keyPrefix: "deviceStatus" });
    const deviceConfig = DeviceConfig.DeviceConfigurationsByType[Target.type];
    if (!deviceConfig) {
        console.warn(`${Target.type} is not a valid device config`);
        return null;
    }

    return (
        <div className={"DeviceBatteryNetworkState"}>
            <div className={"DeviceBatteryNetworkState__Title"}>
                <Typography variant={"h2"}>{t("status")}</Typography>
            </div>
            <div className={"DeviceBatteryNetworkState__smallSpacer titleRelated"} />
            <div className={"DeviceBatteryNetworkState__divider  titleRelated"} />
            <div className={"DeviceBatteryNetworkState__smallSpacer  titleRelated"} />
            <div className={"DeviceBatteryNetworkState__Battery"}>
                <BatteryStatusIcon
                    Target={Target}
                    showDetails={true}
                    className={"DeviceBatteryNetworkState__stackedItem"}
                />
            </div>
            <div className={"DeviceBatteryNetworkState__smallSpacer"} />
            <div className={"DeviceBatteryNetworkState__smallSpacer"} />
            <div className={"DeviceBatteryNetworkState__Network"}>
                <SignalStrengthStatusIcon
                    Target={Target}
                    showDetails={true}
                    className={"DeviceBatteryNetworkState__stackedItem"}
                />
            </div>
            <div className={"DeviceBatteryNetworkState__smallSpacer"} />
            <div className={"DeviceBatteryNetworkState__divider"} />

            {deviceConfig.valveCount === 1 && Target.valveState && (
                <>
                    <div className={"DeviceBatteryNetworkState__smallSpacer"} />
                    <div className={"DeviceBatteryNetworkState__State"}>
                        <div className={"DeviceBatteryNetworkState__stackedItem"}>
                            <IconButton>
                                <ValveIcon open={Target.valveState?.open?.[0]} />
                            </IconButton>

                            <Typography variant={"caption"}>{t("valve")}</Typography>
                            <Typography variant={"caption"}>
                                {Target.valveState?.open?.[0]
                                    ? t("open", { ns: "common", keyPrefix: "states" })
                                    : t("closed", { ns: "common", keyPrefix: "states" })}
                            </Typography>
                        </div>
                    </div>
                    <div className={"DeviceBatteryNetworkState__smallSpacer"} />
                </>
            )}

            {deviceConfig.valveCount === 2 && (
                <>
                    <div className={"DeviceBatteryNetworkState__smallSpacer"} />
                    <div className={"DeviceBatteryNetworkState__State"}>
                        {Target.valveState && (
                            <div className={"DeviceBatteryNetworkState__stackedItem"}>
                                <IconButton>
                                    <ValveIcon open={Target.valveState?.open?.[0]} text={"V1"} />
                                </IconButton>
                                <Typography variant={"caption"}>{t("valve")}</Typography>
                                <Typography variant={"caption"}>
                                    {Target.valveState?.open?.[0]
                                        ? t("open", { ns: "common", keyPrefix: "states" })
                                        : t("closed", { ns: "common", keyPrefix: "states" })}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <div className={"DeviceBatteryNetworkState__smallSpacer"} />
                    <div className={"DeviceBatteryNetworkState__smallSpacer"} />
                    <div className={"DeviceBatteryNetworkState__State"}>
                        {Target.valveState && (
                            <div className={"DeviceBatteryNetworkState__stackedItem"}>
                                <IconButton>
                                    <ValveIcon open={Target.valveState?.open?.[1]} text={"V2"} />
                                </IconButton>
                                <Typography variant={"caption"}>{t("valve")}</Typography>
                                <Typography variant={"caption"}>
                                    {Target.valveState?.open?.[1]
                                        ? t("open", { ns: "common", keyPrefix: "states" })
                                        : t("closed", { ns: "common", keyPrefix: "states" })}
                                </Typography>
                            </div>
                        )}
                    </div>
                    <div className={"DeviceBatteryNetworkState__smallSpacer"} />
                </>
            )}
            <div className={"DeviceBatteryNetworkState__divider"} />
            <div className={"DeviceBatteryNetworkState__smallSpacer"} />
            <OperationModeIcon
                Target={Target}
                showDetails={true}
                className={"DeviceBatteryNetworkState__stackedItem"}
            />
            <div className={"DeviceBatteryNetworkState__smallSpacer"} />
        </div>
    );
}
