import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import Papa from "papaparse";
import { MasterIndex, SessionHandler } from "verdiapi";
import { generateLabelTagFromEUID } from "verdiapi/dist/HelperFunctions/GenerateLabelText";
import { secondMs } from "verditypes/dist/timeConstants";

import { FILENAME_DATE_FORMAT, FILENAME_DATETIME_FORMAT, SOURCE_TYPE } from "../constants";

// Generate the string for devices to include in the filename
function generateDeviceStringForFilename(deviceId: string) {
    // Generate the short code
    const deviceShortCode = generateLabelTagFromEUID(deviceId.replace("eui-", ""));

    // Get the device name
    let deviceName = "";
    if (MasterIndex.irrigationDevice.byID[deviceId]) {
        deviceName = MasterIndex.irrigationDevice.byID[deviceId].name;
    } else if (MasterIndex.blockValve.byID[deviceId]) {
        deviceName = MasterIndex.blockValve.byID[deviceId].name;
    } else if (MasterIndex.thirdPartyDevice.byID[deviceId]) {
        deviceName = MasterIndex.thirdPartyDevice.byID[deviceId].name;
    }

    // Get the device label if it's not the same as the ID (the EUI is set as the name in VerdiAPI which needs to be fixed)
    const deviceLabel = deviceId === deviceName ? "" : deviceName;

    // Combine the label, ID, and short code into a single string (ignoring empty strings)
    return [`'${deviceLabel}'`, deviceId, deviceShortCode].filter(Boolean).join("_");
}

/**
 * Generates a filename for the exported data
 */
export function generateFileName(
    sources: { id: string; type: SOURCE_TYPE }[],
    dataTypeLabels: string[],
    startDate: Date,
    endDate: Date,
) {
    // Generate the source portion of the filename
    let sourcePortionOfFilename = "";
    // If multiple sources are selected, just use "multiple_sources"
    if (sources.length > 1) {
        sourcePortionOfFilename = `multiple_sources`;
    }
    // If a single source is selected, use the source type and ID
    else {
        const sourceType = sources[0].type;
        const sourceID = sources[0].id;

        // Get the source name
        let sourceName;
        switch (sourceType) {
            case SOURCE_TYPE.ACCOUNT:
                sourceName = `'${SessionHandler.currentUserObject.username}'`;
                break;
            case SOURCE_TYPE.FIELD:
                sourceName = `'${MasterIndex.aoi.byID[sourceID].name}'`;
                break;
            case SOURCE_TYPE.ZONE:
                sourceName = `'${MasterIndex.zone.byID[sourceID].name}'`;
                break;
            case SOURCE_TYPE.DEVICE:
                sourceName = generateDeviceStringForFilename(sourceID);
                break;
            default:
                sourceName = `'unknown source'`;
                break;
        }

        // Generate the source portion
        sourcePortionOfFilename = `${sourceType}_${sourceName}`;
    }

    // Generate the data types portion of the filename
    let dataTypesPortionOfFilename = "";
    // If multiple data types are selected, just use "multiple_data_types"
    if (dataTypeLabels.length > 1) {
        dataTypesPortionOfFilename = `multiple_data_types`;
    }
    // If a single data type is selected, use the data type name
    else {
        dataTypesPortionOfFilename = dataTypeLabels[0];
    }

    const startDateFormatted = getTimeString(startDate, FILENAME_DATE_FORMAT);
    const endDateFormatted = getTimeString(endDate, FILENAME_DATE_FORMAT);
    const currentDateTimeFormatted = getTimeString(new Date(), FILENAME_DATETIME_FORMAT);

    return `verdi_export_${sourcePortionOfFilename}_'${dataTypesPortionOfFilename}'_${startDateFormatted}_to_${endDateFormatted}_at_${currentDateTimeFormatted}.csv`;
}

/**
 *
 * @param {string} filename
 * @param {Array} rows
 * @param {Array} columnOrder
 *
 * @returns
 */

export function exportToCsv(filename: string, rows: any, columnOrder: any) {
    // Start the export timer
    const exportStartTime = Date.now();

    // Convert the data to CSV
    console.info(`DataExport: Exporting data to CSV... `);
    const csv = Papa.unparse(rows, {
        columns: columnOrder,
    });
    console.info(
        `DataExport: Exported data to CSV with ${rows.length} rows in ${(Date.now() - exportStartTime) / secondMs} seconds`,
    );

    // Create a blob from the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Download the blob
    // Check for IE 10+ support for msSaveBlob
    if ((navigator as any).msSaveBlob) {
        (navigator as any).msSaveBlob(blob, filename);
    } else {
        // Create a link element
        const link = document.createElement("a");

        // Check if the link element supports the download attribute
        if (link.download !== undefined) {
            // Start the object URL timer
            const createObjectStartTime = Date.now();
            console.info(`DataExport: Creating object URL`);

            // Create an object URL from the blob
            const url = URL.createObjectURL(blob);
            console.info(
                `DataExport: Created object URL in ${(Date.now() - createObjectStartTime) / secondMs} seconds`,
            );

            // Set the link element's attributes
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";

            // Add, click, and then remove the link element from the document body
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
