import * as React from "react";

function SvgComponent(props) {
    const propsCopy = { ...props };
    delete propsCopy.soilType;
    return (
        <svg
            width={612.957}
            id={"SoilTextureTriangle"}
            xmlnsXlink={"http://www.w3.org/1999/xlink"}
            xmlns={"http://www.w3.org/2000/svg"}
            viewBox={"0 0 612.95696 567.76807"}
            {...props}
        >
            <defs id={"defs"}>
                <pattern
                    xlinkHref={"#triangles"}
                    id={"pattern20526"}
                    patternTransform={"translate(-106.611 -12.733)"}
                />
                <pattern
                    id={"triangles"}
                    viewBox={"0 0 120 104"}
                    width={116}
                    height={100}
                    x={-6}
                    y={20}
                    patternUnits={"userSpaceOnUse"}
                >
                    <path
                        fill={"none"}
                        stroke={"#000"}
                        strokeWidth={1}
                        d={
                            "M30 104 90 0m0 104 30-52M0 52 30 0m0 0 60 104M90 0l30 52M0 52l30 52M-.908 26h122.431M-.927 78h122.715"
                        }
                        id={"path4"}
                        strokeOpacity={0.3}
                    />
                    <path
                        fill={"none"}
                        stroke={"#000"}
                        strokeWidth={1.5}
                        d={"M0 104 60 0m0 104L120 0M0 0l60 104M60 0l60 104M0 0h120M0 104h120M0 52h120"}
                        id={"path6"}
                        strokeOpacity={0.5}
                        strokeLinecap={"round"}
                    />
                </pattern>
            </defs>
            <pattern
                id={"pattern16039"}
                viewBox={"0 0 120 104"}
                width={116}
                height={100}
                x={-6}
                y={20}
                patternUnits={"userSpaceOnUse"}
            />
            <path
                id={"frame"}
                d={"m3.389 507.267 290-500 290 500z"}
                fill={"url(#triangles)"}
                stroke={"#000"}
                strokeWidth={1.5}
                style={{
                    opacity: 1,
                    fill: "url(#pattern20526)",
                    fillOpacity: 1,
                    stroke: "#000",
                    strokeWidth: 1.6,
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                }}
            />
            <g id={"soilTypeLimits"} transform={"translate(-106.611 -12.733)"}>
                <path className={"soilTypeLimit"} id={"sand_loamySand"} d={"m139 470 58 50"} />
                <path className={"soilTypeLimit"} id={"loamySand_sandyLoam"} d={"M153.5 445 284 520"} />
                <path className={"soilTypeLimit"} id={"sandyClayLoam_sandyLoam"} d={"M168 420h164.333"} />
                <path className={"soilTypeLimit"} id={"sandyClay_sandyClayLoam"} d={"M211.5 345h116"} />
                <path className={"soilTypeLimit"} id={"sandyClay_clay"} d={"m269.5 245 82.6 140.4"} />
                <path className={"soilTypeLimit"} id={"clayLoam_clay"} d={"M313 320h261"} />
                <path
                    className={"soilTypeLimit"}
                    id={"loam_limits"}
                    d={"M421.75 482.5H368.6L332.333 420l19.767-34.1h127.65"}
                />
                <path className={"soilTypeLimit"} id={"clay_siltyClay"} d={"M494.25 385.9 458 320l58-100"} />
                <path className={"soilTypeLimit"} id={"loam_siltLoam"} d={"m400 520 78.08-134.6h133.86"} />
                <path className={"soilTypeLimit"} id={"silt_siltLoam"} d={"m574 520 36.25-62.5h43.5"} />
            </g>
            <g id={"soilTriangleLegend"} transform={"translate(-106.611 -12.733)"}>
                <g id={"sandProportion"}>
                    <g transform={"translate(0 -7)"} id={"g116"}>
                        <g id={"sandProportionArrow"}>
                            <path className={"legendArrow"} d={"M593 574H207l9 6v-12l-9 6"} id={"path109"} />
                            <path className={"legendBg"} id={"rect111"} d={"M349.682 559.997h96.139v25.504h-96.139z"} />
                        </g>
                        <text className={"legend"} x={400} y={580} textAnchor={"middle"} id={"text114"}>
                            Sand %
                        </text>
                    </g>
                    <text
                        className={"proportionLegend"}
                        x={642}
                        y={528}
                        transform={"rotate(60 632 520)"}
                        id={"text118"}
                    >
                        10
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={584}
                        y={528}
                        transform={"rotate(60 574 520)"}
                        id={"text120"}
                    >
                        20
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={526}
                        y={528}
                        transform={"rotate(60 516 520)"}
                        id={"text122"}
                    >
                        30
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={468}
                        y={528}
                        transform={"rotate(60 458 520)"}
                        id={"text124"}
                    >
                        40
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={410}
                        y={528}
                        transform={"rotate(60 400 520)"}
                        id={"text126"}
                    >
                        50
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={352}
                        y={528}
                        transform={"rotate(60 342 520)"}
                        id={"text128"}
                    >
                        60
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={294}
                        y={528}
                        transform={"rotate(60 284 520)"}
                        id={"text130"}
                    >
                        70
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={236}
                        y={528}
                        transform={"rotate(60 226 520)"}
                        id={"text132"}
                    >
                        80
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={178}
                        y={528}
                        transform={"rotate(60 168 520)"}
                        id={"text134"}
                    >
                        90
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={120}
                        y={528}
                        transform={"rotate(60 110 520)"}
                        id={"text136"}
                    >
                        100
                    </text>
                </g>
                <g id={"siltProportion"}>
                    <g id={"siltProportionArrow"} transform={"rotate(-120 399.83 351.765)"}>
                        <path className={"legendArrow"} d={"M593 574H207l9 6v-12l-9 6"} id={"path139"} />
                        <path className={"legendBg"} id={"rect141"} d={"M360.724 565.538h78.251v17.941h-78.251z"} />
                    </g>
                    <text
                        className={"legend"}
                        x={400}
                        y={600}
                        textAnchor={"middle"}
                        transform={"rotate(60 800.51 582.705)"}
                        id={"text144"}
                    >
                        Silt %
                    </text>
                    <text className={"proportionLegend"} x={439} y={78} transform={"rotate(-60 429 70)"} id={"text146"}>
                        10
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={468}
                        y={128}
                        transform={"rotate(-60 458 120)"}
                        id={"text148"}
                    >
                        20
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={497}
                        y={178}
                        transform={"rotate(-60 487 170)"}
                        id={"text150"}
                    >
                        30
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={526}
                        y={228}
                        transform={"rotate(-60 516 220)"}
                        id={"text152"}
                    >
                        40
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={555}
                        y={278}
                        transform={"rotate(-60 545 270)"}
                        id={"text154"}
                    >
                        50
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={584}
                        y={328}
                        transform={"rotate(-60 574 320)"}
                        id={"text156"}
                    >
                        60
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={613}
                        y={378}
                        transform={"rotate(-60 603 370)"}
                        id={"text158"}
                    >
                        70
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={642}
                        y={428}
                        transform={"rotate(-60 632 420)"}
                        id={"text160"}
                    >
                        80
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={671}
                        y={478}
                        transform={"rotate(-60 661 470)"}
                        id={"text162"}
                    >
                        90
                    </text>
                    <text
                        className={"proportionLegend"}
                        x={700}
                        y={528}
                        transform={"rotate(-60 690 520)"}
                        id={"text164"}
                    >
                        100
                    </text>
                </g>
                <g id={"clayProportion"}>
                    <g id={"clayProportionArrow"} transform={"rotate(120 400.17 351.765)"}>
                        <path className={"legendArrow"} d={"M593 574H207l9 6v-12l-9 6"} id={"path167"} />
                        <path className={"legendBg"} id={"rect169"} d={"M354.857 558.441h87.655v26.801h-87.655z"} />
                    </g>
                    <text
                        className={"legend"}
                        x={400}
                        y={600}
                        textAnchor={"middle"}
                        transform={"rotate(-60 -.51 582.705)"}
                        id={"text172"}
                    >
                        Clay %
                    </text>
                    <text className={"proportionLegend"} x={129} y={478} textAnchor={"end"} id={"text174"}>
                        10
                    </text>
                    <text className={"proportionLegend"} x={158} y={428} textAnchor={"end"} id={"text176"}>
                        20
                    </text>
                    <text className={"proportionLegend"} x={187} y={378} textAnchor={"end"} id={"text178"}>
                        30
                    </text>
                    <text className={"proportionLegend"} x={216} y={328} textAnchor={"end"} id={"text180"}>
                        40
                    </text>
                    <text className={"proportionLegend"} x={245} y={278} textAnchor={"end"} id={"text182"}>
                        50
                    </text>
                    <text className={"proportionLegend"} x={274} y={228} textAnchor={"end"} id={"text184"}>
                        60
                    </text>
                    <text className={"proportionLegend"} x={303} y={178} textAnchor={"end"} id={"text186"}>
                        70
                    </text>
                    <text className={"proportionLegend"} x={332} y={128} textAnchor={"end"} id={"text188"}>
                        80
                    </text>
                    <text className={"proportionLegend"} x={361} y={78} textAnchor={"end"} id={"text190"}>
                        90
                    </text>
                    <text className={"proportionLegend"} x={390} y={28} textAnchor={"end"} id={"text192"}>
                        100
                    </text>
                </g>
            </g>
            <g id={"layer2"}>
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${props.soilType === "Clay" ? "SoilTriangleRegion--selected " : ""}`}
                    d={"M206.389 307.267h145l58-100-116-200-130.5 225 43.5 75"}
                    id={"ClayRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${
                        props.soilType === "Silty Clay" ? "SoilTriangleRegion--selected " : ""
                    }`}
                    d={"m409.389 207.267 58 100h-116z"}
                    id={"SiltyClayRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${
                        props.soilType === "Silty Clay Loam" ? "SoilTriangleRegion--selected " : ""
                    }`}
                    d={"m505.329 372.667-37.94-65.4h-116l36.25 65.9z"}
                    id={"SiltyClayLoamRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${
                        props.soilType === "Clay Loam" ? "SoilTriangleRegion--selected " : ""
                    }`}
                    d={"M206.389 307.267h145l36.25 65.9h-142.15l-39.1-65.9"}
                    id={"ClayLoamRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${
                        props.soilType === "Silt Loam" ? "SoilTriangleRegion--selected " : ""
                    }`}
                    d={"m505.329 372.667 41.81 72.1h-43.5l-36.25 62.5h-174l79.75-134.1 132.19-.5"}
                    id={"SiltyLoamRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${props.soilType === "Silt" ? "SoilTriangleRegion--selected " : ""}`}
                    d={"m583.389 507.267-36.25-62.5h-43.5l-36.25 62.5z"}
                    id={"SiltRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${props.soilType === "Loam" ? "SoilTriangleRegion--selected " : ""}`}
                    d={"m245.489 373.167 125.98-.5-56.33 97.1h-53.15l-36.267-62.5 19.767-34.1"}
                    id={"MediumLoamRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${
                        props.soilType === "Sandy Clay" ? "SoilTriangleRegion--selected " : ""
                    }`}
                    d={"m162.889 232.267 58 100h-116l58-100"}
                    id={"SandyClayRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${
                        props.soilType === "Sandy Clay Loam" ? "SoilTriangleRegion--selected " : ""
                    }`}
                    d={"m245.489 373.167-24.6-40.9h-116l-43.5 75h164.333z"}
                    id={"SandyClayLoamRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${
                        props.soilType === "Sandy Loam" ? "SoilTriangleRegion--selected " : ""
                    }`}
                    d={"m225.722 407.267 36.267 62.5h53.15l-21.75 37.5h-116l-130.5-75 14.5-25h164.333"}
                    id={"SandyLoamRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${
                        props.soilType === "Loamy Sand" ? "SoilTriangleRegion--selected " : ""
                    }`}
                    d={"m46.889 432.267 130.5 75h-87l-58-50z"}
                    id={"LoamySandRegion"}
                />
                <path
                    style={{
                        stroke: "#000",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                    className={`SoilTriangleRegion ${props.soilType === "Sand" ? "SoilTriangleRegion--selected " : ""}`}
                    d={"m3.389 507.267 29-50 58 50h-87"}
                    id={"SandRegion"}
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
