import "./CircleStepper.scss";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Input } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { clamp, getFirstDefined } from "gardenspadejs/dist/general";
import React from "react";

export function CircleStepper({ className, controlled, defaultValue, horizontalView, min, onChange, value }) {
    const minValue = getFirstDefined(min, 1);
    const [curValue, setCurValue] = React.useState(getFirstDefined(value, defaultValue, minValue));
    const [textValue, setTextValue] = React.useState(false);
    //
    // let v = curValue;
    // if (controlled) {
    //     v = getFirstDefined(value, curValue);
    // }
    return (
        <div className={`RankStepperRoot RankStepperRoot--${className || ""}${horizontalView ? "horizontalView" : ""}`}>
            <div className={"RankStepperControls"}>
                <IconButton
                    className={"UpButton"}
                    onClick={() => {
                        const newValue = getFirstDefined(value, curValue) + 1;
                        if (onChange) {
                            onChange(newValue);
                        }
                        setCurValue(newValue);
                    }}
                >
                    {horizontalView ? <ChevronRightIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
                <div className={"RankStepperIcon"}>
                    <Input
                        className={"RankStepperIconInput"}
                        disableUnderline
                        value={textValue !== false ? textValue : curValue}
                        onChange={(e) => {
                            let newTextValue = e.target.value;
                            newTextValue = newTextValue.replaceAll(/[^0-9]/g, "");
                            let numericalValue = null;
                            try {
                                numericalValue = parseFloat(newTextValue);
                                if (Number.isNaN(numericalValue)) {
                                    numericalValue = null;
                                }
                                if (numericalValue !== null) {
                                    numericalValue = clamp(numericalValue, 1, 1000);
                                }
                            } catch (error) {
                                console.warn("Error with circle stepper: ", error);
                            }
                            setCurValue(numericalValue);
                            setTextValue(newTextValue);
                        }}
                        onBlur={() => {
                            if (onChange) {
                                onChange(curValue);
                            }
                            setTextValue(false);
                        }}
                    />
                </div>
                <IconButton
                    className={"DownButton"}
                    onClick={() => {
                        const newValue = Math.max(getFirstDefined(value, curValue) - 1, minValue);
                        if (onChange) {
                            onChange(newValue);
                        }
                        setCurValue(newValue);
                    }}
                    disabled={getFirstDefined(value, curValue) <= minValue}
                >
                    {horizontalView ? <ChevronLeftIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </div>
        </div>
    );
}
