import "./DeviceMarkerIcon.scss";

import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function BlockValveMarkerIcon(props) {
    return (
        <SvgIcon onClick={props.onClick} viewBox={"-4, -4, 32, 32"} {...props} className={"MarkerIcon"}>
            <ellipse
                id={"path816"}
                cx={"11.998995"}
                cy={"11.999647"}
                rx={"11.996995"}
                ry={"11.996996"}
                className={"MarkerIconRim"}
            />
            <ellipse
                id={"path868"}
                cx={"11.998995"}
                cy={"11.999647"}
                rx={"11.996995"}
                ry={"11.996996"}
                className={"MarkerIconOuterCircle"}
            />
            <path
                id={"path874"}
                style={{ fill: "var(--iconBackgroundColor, #ffffff)" }}
                d={
                    "M 8.7736245,2.6778728 A 9.8686352,9.8669908 0 0 0 2.1285522,12.003402 9.8686352,9.8669908 0 0 0 8.7736245,21.32893 V 18.613334 H 7.1334148 v -2.92902 H 8.0532551 V 8.3333414 H 7.1334148 V 5.4048379 h 1.6402097 z m 6.5215655,0.025838 v 2.701127 h 1.639693 V 8.3333414 H 15.94683 v 1.9600866 l -4.437972,0.0036 c -0.574939,4.88e-4 -1.037635,0.463757 -1.037146,1.038696 l 0.0015,1.336869 c 4.9e-4,0.574939 0.463758,1.037636 1.038697,1.037146 l 4.434871,-0.0036 v 1.978174 h 0.988053 v 2.92902 H 15.29519 v 2.689758 a 9.8686352,9.8669908 0 0 0 6.570658,-9.29969 9.8686352,9.8669908 0 0 0 -6.570658,-9.2996902 z m 3.828707,8.2144852 c 0.199617,-1.69e-4 0.360532,0.160569 0.360702,0.360185 l 0.001,1.436088 c 1.7e-4,0.199616 -0.160051,0.360532 -0.359668,0.360702 l -7.661031,0.0062 c -0.199616,1.7e-4 -0.360532,-0.160052 -0.360701,-0.359668 l -10e-4,-1.436088 c -1.7e-4,-0.199616 0.160569,-0.360532 0.360185,-0.360701 z m -7.123079,0.47594 a 0.60611284,0.60611284 0 0 0 -0.510564,0.277503 0.60611284,0.60611284 0 0 0 0.181385,0.838192 0.60611284,0.60611284 0 0 0 0.838192,-0.181901 0.60611284,0.60611284 0 0 0 -0.181901,-0.837676 0.60611284,0.60611284 0 0 0 -0.327112,-0.09612 z"
                }
                className={"MarkerIconInnerCircle"}
            />
            <ellipse id={"path824"} cx={"22"} cy={"2"} rx={"5"} ry={"5"} className={"AlertBubble"} />
        </SvgIcon>
    );
}
