import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import {
    ComboBox,
    ComboBoxOption,
    ComboBoxTagGroup,
    separateOptionsIntoGroups,
} from "src/components/generic/ComboBox/ComboBox";
import { MasterIndex, SessionHandler } from "verdiapi";
import { generateLabelTagFromEUID } from "verdiapi/dist/HelperFunctions/GenerateLabelText";
import { DeviceBase } from "verdiapi/dist/Models/Devices/DeviceBase";

import { SOURCE_TYPE, SourceIdOption, SourceTypeLabels } from "../constants";
import SelectAllButtonGroup from "./SelectAllButtonGroup";

type SourceType = SOURCE_TYPE | "all";

const SOURCE_SELECTOR_STRING_DELIMITER = "  •  ";

// Generate the labels for devices to display in the source selector
function generateDeviceLabelForSourceSelector(device: DeviceBase) {
    // Get the device ID and generate the short code
    const deviceId = device.id;

    // Use try-catch to generateLabelTagFromEUID because it throws on non-standard ones like eui-testsensorreg
    const deviceShortCode = (() => {
        try {
            return generateLabelTagFromEUID(deviceId.replace("eui-", ""));
        } catch (e) {
            console.warn(`Failed to generate short code for device ${deviceId}`);
            return "";
        }
    })();

    // Get the device label if it's not the same as the ID (the EUI is set as the name in VerdiAPI which needs to be fixed)
    const deviceLabel = deviceId === device.name ? "" : device.name;

    // Combine the label, ID, and short code into a single string (ignoring empty strings)
    return [deviceLabel, deviceId, deviceShortCode].filter(Boolean).join(SOURCE_SELECTOR_STRING_DELIMITER);
}

export function generateSourceIdOptions(sourceType: SourceType): SourceIdOption[] {
    if (!SessionHandler.currentUserObject || !MasterIndex) {
        return [];
    }

    const sourceIdsByType = {
        [SOURCE_TYPE.ACCOUNT]: [
            {
                group: SOURCE_TYPE.ACCOUNT,
                value: SessionHandler.currentUserObject.id,
                label: SessionHandler.currentUserObject.username,
            },
        ],
        [SOURCE_TYPE.FIELD]: MasterIndex.aoi.all
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((aoi) => ({
                group: SOURCE_TYPE.FIELD,
                value: aoi.id,
                label: aoi.name,
            })),
        [SOURCE_TYPE.ZONE]: MasterIndex.zone.all
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((zone) => ({
                group: SOURCE_TYPE.ZONE,
                value: zone.id,
                label: [zone?.linkedAreaOfInterest?.name, zone.name]
                    .filter(Boolean)
                    .join(SOURCE_SELECTOR_STRING_DELIMITER),
            })),
        [SOURCE_TYPE.DEVICE]: [
            ...MasterIndex.irrigationDevice.all,
            ...MasterIndex.blockValve.all,
            ...MasterIndex.thirdPartyDevice.all,
        ]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((device) => ({
                group: SOURCE_TYPE.DEVICE,
                value: device.id,
                label: generateDeviceLabelForSourceSelector(device),
            })),
    };

    if (sourceType === "all") {
        return [
            ...sourceIdsByType[SOURCE_TYPE.ACCOUNT],
            ...sourceIdsByType[SOURCE_TYPE.FIELD],
            ...sourceIdsByType[SOURCE_TYPE.ZONE],
            ...sourceIdsByType[SOURCE_TYPE.DEVICE],
        ];
    }
    return sourceIdsByType[sourceType] || [];
}

interface SourceSelectionProps {
    sources: ComboBoxOption[];
    setSources: (newSources: ComboBoxOption[]) => void;
}
export function SourceSelector({ sources, setSources }: SourceSelectionProps) {
    const [sourceOptions, setSourceOptions] = useState<SourceIdOption[]>(generateSourceIdOptions("all"));

    const onChangeSource = (value: ComboBoxOption[] | null) => {
        if (value) {
            setSources(value);
        }
    };

    // Initialize sourceOptions after initial account load
    // since they depend on SessionHandler.currentUserObject
    useEffect(() => {
        const setSourceOptionsFromSession = () => {
            const options = generateSourceIdOptions("all");
            if (options.length > 0) {
                setSourceOptions(options);
            }
        };

        SessionHandler.onSessionLoad.addListener(setSourceOptionsFromSession);

        return () => {
            SessionHandler.onSessionLoad.removeListener(setSourceOptionsFromSession);
        };
    }, []);

    return (
        <Box>
            <Typography variant={"h4"}>Data Sources</Typography>

            <Typography variant={"subtitle1"} sx={{ mb: 2 }}>
                Data sources without data for the specified date range and data types will not be exported.
            </Typography>

            <Box sx={{ mt: 6 }}>
                <SelectAllButtonGroup
                    sx={{ mb: 6 }}
                    onClickSelectAll={() => {
                        setSources(sourceOptions);
                    }}
                    onClickClear={() => setSources([])}
                />
                <ComboBox
                    sx={{ maxWidth: 450 }}
                    loading={sourceOptions.length === 0}
                    groupBy={(option) => option.group || ""}
                    multiple={true}
                    onChange={onChangeSource}
                    value={sources}
                    defaultRenderInputLabel={"Data Sources"}
                    options={sourceOptions}
                    renderTags={() => null}
                    clearable={false}
                    placeholder={"Search data sources..."}
                    groupLabels={SourceTypeLabels}
                />

                <ComboBoxTagGroup
                    tags={separateOptionsIntoGroups({
                        options: sources,
                        pluralizeGroupLabels: true,
                        groupLabels: SourceTypeLabels,
                    })}
                    onDelete={(value) => setSources(sources.filter((dt) => dt.value !== value.value))}
                    sx={{ mt: 2 }}
                />
            </Box>
        </Box>
    );
}
