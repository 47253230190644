/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */

import "./JSONDropdown.scss";

import React from "react";

import source from "!!raw-loader!./JSONDropdownDemo.jsx";

import JSONDropdown from "./JSONDropdown";
import readme from "./README.md";

function DemoComponent() {
    return (
        <>
            <JSONDropdown
                title={"Test title"}
                body={{
                    testObjectName: "Test Name",
                    curDate: new Date(Date.now()),
                    subObject: {
                        subObjectName: "Test name",
                        subObjectQuantity: 25,
                    },
                }}
            />
        </>
    );
}

const JSONDropdownDemo = {
    component: DemoComponent,
    name: "JSONDropdown",
    readme: readme,
    source: source,
};

export default JSONDropdownDemo;
