import "./TotalZoneFlowRateCalculator.css";
import "./ZoneProfileEditor.css";

import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Chip from "@mui/material/Chip";
import * as React from "react";

import { LengthUnitTextField } from "../DeprecatedInputs/LengthUnitTextField";
import { InputtingZoneDetails } from "../HelpDialogues/ZoneProfileEditor/InputtingZoneDetails";

export default class TotalZoneFlowRateCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emitterSpacing: 0,
            emitterFlowRate: 0,
            lengthOfTubing: 0,
        };
    }

    render() {
        let result = 0;
        let resultText = "?";
        if (this.state.emitterFlowRate && this.state.emitterSpacing && this.state.lengthOfTubing) {
            result =
                Math.round(
                    ((this.state.emitterFlowRate * this.state.lengthOfTubing) / this.state.emitterSpacing) * 100,
                ) / 100;
            resultText = `${result} LPH`;
        }

        const handleChipClick = () => {
            if (this.props.onChange) {
                this.props.onChange(result);
            }
        };

        return (
            <div className={"TotalZoneFlowRateCalculatorContainer"}>
                {/* <Typography variant={"button"}>Calculator</Typography> */}
                {/* <div className={"TotalZoneFlowRateCalculatorRow"}> */}
                <LengthUnitTextField
                    className={"emitterSpacingTextField"}
                    size={"small"}
                    label={"Emitter Spacing"}
                    // helperTooltipText={"Dist. between emitters"}
                    defaultValue={0}
                    onChange={(e) => {
                        this.setState({
                            emitterSpacing: e,
                        });
                    }}
                    useCase={"EmitterSpacing"}
                />
                <LengthUnitTextField
                    className={"emitterFlowRateTextField"}
                    size={"small"}
                    label={"Emitter Flow Rate"}
                    unitCategory={"flowRate"}
                    // helperTooltipText={"Dist. between emitters"}
                    defaultValue={0}
                    onChange={(e) => {
                        this.setState({
                            emitterFlowRate: e,
                        });
                    }}
                    useCase={"EmitterFlowRate"}
                />
                {/* </div> */}
                {/* <div className={"TotalZoneFlowRateCalculatorRow"}> */}
                <LengthUnitTextField
                    className={"lengthOfTubingTextField"}
                    size={"small"}
                    label={"Tubing Length"}
                    // helperTooltipText={"Dist. between emitters"}
                    defaultValue={0}
                    onChange={(e) => {
                        this.setState({
                            lengthOfTubing: e,
                        });
                    }}
                    useCase={"TubingLength"}
                />
                <div className={"TotalZoneFlowRateCalculatorResult"}>
                    <Chip
                        className={"resultChip"}
                        icon={<CheckIcon />}
                        label={`Result: ${resultText}`}
                        onClick={handleChipClick}
                    />
                    <InputtingZoneDetails>
                        <HelpOutlineIcon className={"helpIconStyle"} />
                    </InputtingZoneDetails>
                </div>
                {/* </div> */}
            </div>
        );
    }
}
