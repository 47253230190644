import "./SaplingAutomationConfiguration.scss";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    Select,
    TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { clamp, getFirstDefined } from "gardenspadejs/dist/general";
import React from "react";
import { IrrigationDeviceSensor } from "verdiapi/dist/Models/Devices/IrrigationDeviceSensor";

import ReactComponentBase from "../../../../utils/ReactComponentBase";

export default class SaplingAutomationConfiguration extends ReactComponentBase {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            automationSensor: -1,
            minRaw: undefined,
            maxRaw: undefined,
        };
    }

    componentDidMount() {
        this.state.automationSensor = this.props.device?.saplingAutomationSettings?.automationEnabled
            ? this.props.device?.saplingAutomationSettings?.targetSensor
            : -1;
        this.updateMinMaxTextFields();
    }

    async save() {
        if (!this.props.device.saplingAutomationSettings) {
            this.props.device.saplingAutomationSettings = {};
        }
        this.props.device.saplingAutomationSettings.targetSensor =
            this.state.automationSensor === -1 ? 0 : this.state.automationSensor;
        this.props.device.saplingAutomationSettings.automationEnabled = this.state.automationSensor !== -1;
        this.props.device.saplingAutomationSettings.targetRawRange = [
            getFirstDefined(this.state.minRaw, this.props.device?.saplingAutomationSettings?.targetRawRange?.[0], 5),
            getFirstDefined(this.state.maxRaw, this.props.device?.saplingAutomationSettings?.targetRawRange?.[1], 200),
        ];
        delete this.props.device.saplingAutomationSettings._id;
        return this.props.device.edit({
            saplingAutomationSettings: this.props.device.saplingAutomationSettings,
        });
    }

    convertReadingToRawValue(reading, sensorDescription) {
        if (!sensorDescription) {
            try {
                sensorDescription = this.props.device.connectedSensors[this.state.automationSensor];
            } catch (e) {
                console.warn("error finding connected sensor:", e);
            }
            if (!sensorDescription) {
                return clamp(reading, 0, 255);
            }
        }
        let currentGuess = 0;
        let currentGuessDelta = Math.abs(reading - this.convertRawValueToReading(0, sensorDescription));
        for (let i = 0; i < 255; i++) {
            const newGuessDelta = Math.abs(reading - this.convertRawValueToReading(i, sensorDescription));
            if (newGuessDelta < currentGuessDelta) {
                currentGuess = i;
                currentGuessDelta = newGuessDelta;
            }
        }
        return currentGuess;
    }

    convertRawValueToReading(rawValue, sensorDescription) {
        if (!sensorDescription) {
            try {
                sensorDescription = this.props.device.connectedSensors[this.state.automationSensor];
            } catch (e) {
                console.warn("error finding connected sensor:", e);
            }
            if (!sensorDescription) {
                return rawValue;
            }
        }
        return IrrigationDeviceSensor.convertRawValueToReading(rawValue, sensorDescription);
        // let cacheKey =
        //     rawValue.toString() +
        //     ":" +
        //     `${sensorDescription.dataType}${(sensorDescription.polynomialPostProcessing || "").toString()}`;
        // if (cachedConversions[cacheKey]) {
        //     return cachedConversions[cacheKey];
        // }
        // let transformedValue = rawValue;
        // let dataType = sensorDescription.dataType;
        // //Other moisture sensors measure 0 to 100, so we should keep it consistent.
        // if (["moisture_watermark"].includes(dataType)) {
        //     transformedValue = (rawValue / 255) * 100;
        // } else if (["moisture_irrometer"].includes(dataType)) {
        //     transformedValue =  23.5 * (254 - rawValue) / 50.8 - 11.75 //((-1 * rawValue) / 254) * 100 + 254;
        // } else if (["v1flowmeter_1", "v1flowmeter_2", "v1flowmeter"].includes(dataType)) {
        //     transformedValue = rawValue;
        // } else if (["v1transducer"].includes(dataType)) {
        //     transformedValue = rawValue;
        // }
        // if (
        //     sensorDescription &&
        //     sensorDescription.polynomialPostProcessing &&
        //     sensorDescription.polynomialPostProcessing.length > 1
        // ) {
        //     let total = 0;
        //     for (let i = 0; i < sensorDescription.polynomialPostProcessing.length; i++) {
        //         total += sensorDescription.polynomialPostProcessing[i] * Math.pow(transformedValue, i);
        //     }
        //     transformedValue = total;
        // }
        // if (Object.keys(cachedConversions).length > 2000) {
        //     cachedConversions = {};
        // }
        // cachedConversions[cacheKey] = transformedValue;
        // return transformedValue;
    }

    updateMinMaxTextFields(minRaw = undefined, maxRaw = undefined) {
        minRaw = getFirstDefined(
            minRaw,
            this.state.minRaw,
            this.props.device?.saplingAutomationSettings?.targetRawRange?.[0],
            5,
        );
        maxRaw = getFirstDefined(
            maxRaw,
            this.state.maxRaw,
            this.props.device?.saplingAutomationSettings?.targetRawRange?.[1],
            200,
        );
        this.setState({
            minRaw: minRaw,
            maxRaw: maxRaw,
            minMoisture: Math.round(this.convertRawValueToReading(minRaw) * 10) / 10,
            maxMoisture: Math.round(this.convertRawValueToReading(maxRaw) * 10) / 10,
        });
    }

    render() {
        const handleClose = () => {
            this.setState({ dialogOpen: false });
        };
        const handleSave = async () => {
            this.setState({ saving: "saving" });
            this.save()
                .then(() => {
                    this.setState({ saving: "saved!" });
                    setTimeout(() => {
                        handleClose();
                    }, 100);
                })
                .catch(() => {
                    this.setState({ saving: "save Failed!" });
                    setTimeout(() => {
                        this.setState({ saving: undefined });
                    }, 500);
                });
        };
        let dropDownOptions = [];
        if (Array.isArray(this.props.device?.connectedSensors)) {
            dropDownOptions = this.props.device.connectedSensors.map((cz, i) => ({
                title: `S${i + 1}: ${cz.sensorType}`,
                index: i,
            }));
        }
        const onMinMaxChange = (field, v) => {
            v = v.replace(/[a-z][A-Z]+/, "");
            const newState = {};
            newState[field] = v;
            this.setState(newState);
        };

        let activeSensor;
        let endAdornmentText = "";

        try {
            activeSensor = this.props.device.connectedSensors[this.state.automationSensor];
        } catch (e) {
            console.warn(e);
        }
        if (activeSensor) {
            if (activeSensor?.dataType === "moisture_watermark") {
                endAdornmentText = "%";
            } else if (activeSensor?.dataType === "moisture_irrometer") {
                endAdornmentText = "KPa";
            }
        }
        return (
            <div>
                <Button
                    onClick={() => {
                        this.setState({ dialogOpen: true });
                    }}
                >
                    Change Automation Settings ({this.state.automationSensor >= 0 ? "on" : "off"})
                </Button>
                <Dialog fullWidth={true} maxWidth={"md"} open={this.state.dialogOpen} onClose={handleClose}>
                    <DialogTitle>Optional sizes</DialogTitle>
                    <DialogContent className={"AutomationConfigWindow"}>
                        <Box
                            noValidate
                            className={"AutomationInputForm"}
                            component={"form"}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                m: "auto",
                                width: "fit-content",
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id={`${this.uid}automationSensorLabel`}>Automation Sensor</InputLabel>
                                <Select
                                    labelId={`${this.uid}automationSensorLabel`}
                                    value={this.state.automationSensor}
                                    label={"Automation Sensor"}
                                    onChange={(e) => {
                                        this.setState({
                                            automationSensor: e.target.value,
                                        });
                                        this.state.automationSensor = e.target.value;
                                        this.updateMinMaxTextFields();
                                    }}
                                >
                                    <MenuItem value={-1}>Automation off</MenuItem>
                                    {dropDownOptions.map((option) => (
                                        <MenuItem value={option.index}>{option.title}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    m: "auto",
                                    width: "100%",
                                }}
                            >
                                <TextField
                                    disabled={activeSensor === undefined}
                                    label={"Min Moisture"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={"end"}>{endAdornmentText}</InputAdornment>
                                        ),
                                    }}
                                    value={this.state.minMoisture || ""}
                                    onChange={(e) => {
                                        onMinMaxChange("minMoisture", e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        const v = parseFloat(e.target.value.replace(/[a-z][A-Z]+/, ""));
                                        this.updateMinMaxTextFields(this.convertReadingToRawValue(v));
                                    }}
                                    variant={"outlined"}
                                />
                                <TextField
                                    disabled={activeSensor === undefined}
                                    label={"Max Moisture"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={"end"}>{endAdornmentText}</InputAdornment>
                                        ),
                                    }}
                                    value={this.state.maxMoisture || ""}
                                    onChange={(e) => {
                                        onMinMaxChange("maxMoisture", e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        const v = parseFloat(e.target.value.replace(/[a-z][A-Z]+/, ""));
                                        this.updateMinMaxTextFields(undefined, this.convertReadingToRawValue(v));
                                    }}
                                    variant={"outlined"}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>

                        <Button onClick={handleSave} color={"primary"}>
                            {this.state.saving ? this.state.saving : "Save"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
