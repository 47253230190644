export const moistureAlertSliderMarks = [
    {
        value: 0,
        label: "0%",
    },
    {
        value: 0.25,
        label: "25%",
    },
    {
        value: 0.5,
        label: "50%",
    },
    {
        value: 0.75,
        label: "75%",
    },
    {
        value: 1.0,
        label: "100%",
    },
];

export const irrigationDurationSliderMarks = [
    {
        value: -100,
        label: "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0-100%",
    },
    {
        value: -50,
        label: "-50%",
    },
    {
        value: 0,
        label: "0%",
    },
    {
        value: 50,
        label: "+50%",
    },
    {
        value: 100,
        label: "+100%\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0",
    },
];
