import AirIcon from "@mui/icons-material/Air";
import BoltIcon from "@mui/icons-material/Bolt";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { ComponentType } from "react";
import { SensorConfig } from "verditypes";
import { dayMs } from "verditypes/dist/timeConstants";

import PressureTransducerIcon from "../../icons/PressureTransducerIcon";
import TensiometerIcon from "../../icons/TensiometerIcon";
import WatermarkIcon from "../../icons/WatermarkIcon";

type SensorCategory = SensorConfig.SensorCategory;

export type DynamicGraphSensorCategoryOptions = {
    label: string;
    unit: string;
    hardZero: boolean;
    interpolation: "square" | "linear";
    invert: boolean;
    typicalRange: [number, number];
    YPositionMultiplier: number;
    smoothingVarianceThreshold: number;
    conversionEquation?: number[];
    maxInterpolationTime: number;
    icon: ComponentType;
};

/**
 * For each category of sensor, how should we display the graph.
 */
export const graphOptionsBySensorCategory: Partial<Record<SensorCategory, DynamicGraphSensorCategoryOptions>> = {
    liquidFlow: {
        label: "Flow Rate",
        unit: "LPM",
        hardZero: true,
        interpolation: "square",
        invert: false,
        typicalRange: [0, 5],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 1.2,
        maxInterpolationTime: dayMs,
        icon: AirIcon,
    },
    airTemperature: {
        label: "Temperature",
        unit: "°C",
        hardZero: true,
        interpolation: "linear",
        invert: false,
        typicalRange: [10, 45],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 0,
        maxInterpolationTime: dayMs,
        icon: ThermostatIcon,
    },
    plantTemperature: {
        label: "Plant Temp.",
        unit: "°C",
        hardZero: true,
        interpolation: "linear",
        invert: false,
        typicalRange: [10, 45],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 0,
        maxInterpolationTime: dayMs,
        icon: ThermostatIcon,
    },
    soilTemperature: {
        label: "Soil Temp.",
        unit: "°C",
        hardZero: true,
        interpolation: "linear",
        invert: false,
        typicalRange: [10, 45],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 0,
        maxInterpolationTime: dayMs,
        icon: ThermostatIcon,
    },
    liquidPressure: {
        label: "Pressure",
        unit: "PSI",
        hardZero: true,
        interpolation: "square",
        invert: false,
        typicalRange: [10, 45],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 2,
        maxInterpolationTime: dayMs,
        conversionEquation: [0, 0.145038],
        icon: PressureTransducerIcon,
    },
    volumetricSoilMoisture: {
        label: "Vol. Moisture",
        unit: "%",
        hardZero: true,
        interpolation: "linear",
        invert: false,
        typicalRange: [0, 30],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 1.0,
        maxInterpolationTime: dayMs,
        icon: WatermarkIcon,
    },
    soilTension: {
        label: "Soil Tension",
        unit: "KPa",
        hardZero: true,
        interpolation: "linear",
        invert: true,
        typicalRange: [0, 20],
        YPositionMultiplier: -1,
        smoothingVarianceThreshold: 1.5,
        maxInterpolationTime: dayMs,
        icon: TensiometerIcon,
    },
    soilElectricalConductivity: {
        label: "Soil EC",
        unit: "μS/cm",
        hardZero: true,
        interpolation: "linear",
        invert: false,
        typicalRange: [100, 800],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 1.5,
        maxInterpolationTime: dayMs,
        icon: BoltIcon,
    },
    precipitation: {
        label: "Precipitation",
        unit: "mm",
        hardZero: true,
        interpolation: "linear",
        invert: false,
        typicalRange: [0, 30],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 1.5,
        maxInterpolationTime: dayMs,
        conversionEquation: [0, 1000],
        icon: LocalDrinkIcon,
    },
    liquidTankLevel: {
        label: "Tank level",
        unit: "ft",
        hardZero: true,
        interpolation: "linear",
        invert: false,
        typicalRange: [0, 10],
        YPositionMultiplier: 1,
        smoothingVarianceThreshold: 1.5,
        maxInterpolationTime: dayMs,
        conversionEquation: [0, 3.28084],
        icon: LocalDrinkIcon,
    },
};
