import * as VerdiAPI from "verdiapi";

export function setGoogleAnalyticsTags() {
    if (!VerdiAPI.SessionHandler.admin) {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        gtag("config", "G-VNS3BXM4PJ", {
            user_id: VerdiAPI.SessionHandler.admin ? "ADMIN" : VerdiAPI.SessionHandler.curUser,
            groups: VerdiAPI.SessionHandler.admin ? "ADMIN" : "customer",
        });
        // @ts-ignore
        // eslint-disable-next-line no-undef
        gtag("set", {
            user_id: VerdiAPI.SessionHandler.admin ? "ADMIN" : VerdiAPI.SessionHandler.curUser,
            client_id: VerdiAPI.SessionHandler.admin ? "ADMIN" : VerdiAPI.SessionHandler.curUser,
            admin: VerdiAPI.SessionHandler.admin,
            user_agent: window.navigator.userAgent,
        });
    }
}
