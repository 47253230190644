import "./TrendGraphBase.scss";

import { Graphics, Stage } from "@pixi/react";
import gsap from "gsap";
import * as React from "react";
import { EventHandler } from "verdiapi";

import { GenerateUID } from "../../../../utils/HelperFunctions";
import { DynamicGraphContext } from "../DynamicGraphUtility";
import TrendGraphChips from "../TrendGraphChips";
import { HTMLDynamicGraphCursor } from "./HTMLDynamicGraphCursor";
import TrendGraphBackground from "./TrendGraphBackground";
import TrendGraphCursorLine from "./TrendGraphCursorLine";
import TrendGraphLine from "./TrendGraphLine";

gsap.ticker.fps(60);

let initialPinchDistance = null;
let initialPinchTimes = null;

const min = 1000 * 60;
const hour = min * 60;
const day = hour * 24;

export default class TrendGraphBase extends React.Component {
    graphBackgroundGraphic;

    graphBackgroundShaderUniforms = {
        startTime: 0,
        endTime: 0,
        blockOffsets: [0, 0, 0, 0],
        blockSelector: [0, 0, 0, 0],
        blockWidths: [60 * 12, 60 * 24, 60 * 24 * 7, 60 * 24 * 31, 60 * 24 * 100000],
        frameDimensions: [0, 0],
    };

    isDragging = false;

    lastMouseDownCoords = [];

    lastMouseDownDateRange = [];

    mouseMoveEventHandler;

    constructor(props) {
        super(props);
        this.state = {};
        this.interactive = true;
        this.sunriseHour = 6;
        this.sunsetHour = 18;
        this.mouseMoveEventHandler = new EventHandler();
        this.uid = GenerateUID("TrendGraphBase");
    }

    componentDidMount() {
        this.graphBackgroundShaderUniforms.startTime = this.convertTimeStampToShaderStamp(
            this.context.startTime.valueOf(),
        );

        gsap.ticker.remove(this.onTickFunction);
        this.onTickFunction = () => {
            const rangeSelected = this.context.endTime.valueOf() - this.context.startTime.valueOf();
            let blockToUse;
            if (rangeSelected > day * 30) {
                blockToUse = 3;
            } else if (rangeSelected > day * 10) {
                blockToUse = 2;
            } else if (rangeSelected > day * 5) {
                blockToUse = 1;
            } else {
                blockToUse = 0;
            }
            const blockSel = this.graphBackgroundShaderUniforms.blockSelector;
            for (let i = 0; i < blockSel.length; i++) {
                if (i !== blockToUse) {
                    blockSel[i] = Math.max(blockSel[i] - 0.1, 0);
                } else {
                    blockSel[i] = Math.min(blockSel[i] + 0.1, 1);
                }
            }
            this.graphBackgroundShaderUniforms.startTime =
                (this.graphBackgroundShaderUniforms.startTime +
                    this.convertTimeStampToShaderStamp(this.context.startTime.valueOf()) * 3) /
                4;
            this.graphBackgroundShaderUniforms.endTime =
                (this.graphBackgroundShaderUniforms.endTime +
                    this.convertTimeStampToShaderStamp(this.context.endTime.valueOf()) * 3) /
                4;
        };
        gsap.ticker.add(this.onTickFunction);
        this.graphBackgroundShaderUniforms.endTime = this.convertTimeStampToShaderStamp(this.context.endTime.valueOf());
        this.context.onFocusedLinesChanged.addListener(() => {
            setTimeout(() => {
                this.state.stage.stage.sortChildren();
            }, 50);
        }, this.uid);
        this.context.onDateRangeChange.addListener(() => {
            this.forceUpdate();
        }, this.uid);
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
        if (this.handleWheel && this.state?.stage?.renderer?.view) {
            this.state?.stage?.renderer?.view.removeEventListener("wheel", this.handleWheel, { passive: false });
        }
        gsap.ticker.remove(this.onTickFunction);
    }

    onTickFunction;

    convertTimeStampToShaderStamp = (v) => v / 60_000;

    render() {
        const dynamicWidth = this.props.modalOpen === true ? (90 * window.innerWidth) / 100 : this.props.width;
        const dynamicHeight = this.props.modalOpen === true ? (60 * window.innerHeight) / 100 : this.props.height;
        this.graphBackgroundShaderUniforms.blockSelector[0] = 1.0; // duration < 5 * 1000 * 60 * 60 * 24 ? 1 : 0;
        this.graphBackgroundShaderUniforms.blockSelector[1] = 0.0;
        // //this seems to define the starting point of the block based on how far it is from 6 o'clock at the rightmost edge of the
        // //graph. I don't know why 7 works better than 6 for this
        // This appears to factor in our time zone to the above to figure out the true offset of the bars at the far right edge of the
        // graph
        this.graphBackgroundShaderUniforms.blockOffsets[0] = -1 * this.context.startTime.getTimezoneOffset() - 6 * 60; // + 60 * offsetHourByCurrentTime;
        this.graphBackgroundShaderUniforms.blockOffsets[1] = -1 * this.context.startTime.getTimezoneOffset();
        this.graphBackgroundShaderUniforms.blockOffsets[2] = -1 * this.context.startTime.getTimezoneOffset();
        this.graphBackgroundShaderUniforms.blockOffsets[3] = -1 * this.context.startTime.getTimezoneOffset();
        this.graphBackgroundShaderUniforms.frameDimensions[0] = dynamicWidth;
        this.graphBackgroundShaderUniforms.frameDimensions[1] = dynamicHeight;
        const commonProps = {
            width: dynamicWidth,
            height: dynamicHeight,
            startTime: this.context.startTime,
            endTime: this.context.endTime,
            graphDataType: this.props.graphDataType,
            sunriseHour: this.sunriseHour,
            sunsetHour: this.sunsetHour,
        };

        const onZoom = (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            }
            const wheel = e.deltaY < 0 ? 1 : -1;
            const zoomIntensity = 0.2;
            const zoom = 1 / Math.exp(wheel * zoomIntensity);
            const pointToShrinkAbout = this.context.cursorTime.valueOf();
            const newDateRange = [
                new Date((this.context.dateRange[0] - pointToShrinkAbout) * zoom + pointToShrinkAbout),
                new Date((this.context.dateRange[1] - pointToShrinkAbout) * zoom + pointToShrinkAbout),
            ];

            // if we are at max zoom and zooming out more, just return
            if (
                this.context.dateRange[1].valueOf() - this.context.dateRange[0].valueOf() >= day * 365 &&
                newDateRange[1].valueOf() - newDateRange[0].valueOf() > day * 365
            ) {
                return;
            }

            this.context.dateRange[0] = newDateRange[0];
            this.context.dateRange[1] = newDateRange[1];

            // if we are hitting max zoom, then keep the same center, but cap the zooming behaviour.
            if (this.context.dateRange[1].valueOf() - this.context.dateRange[0].valueOf() > day * 365) {
                const center = (this.context.dateRange[0].valueOf() + this.context.dateRange[1].valueOf()) / 2;
                this.context.dateRange[0] = new Date(center - (day * 365) / 2);
                this.context.dateRange[1] = new Date(center + (day * 365) / 2);
            }
            this.context.onDateRangeChange.trigger();
        };

        const getRelativeTouchPosition = (x, parent) => {
            let location = x;
            while (parent) {
                location -= parent.offsetLeft - parent.scrollLeft + parent.clientLeft;
                parent = parent.offsetParent;
            }
            return location;
        };
        const getEventLocation = (e) => {
            let location;

            if (e.touches && e.touches.length >= 1) {
                location = getRelativeTouchPosition(e.touches[0].clientX, e.touches[0].target);
            } else if (e?.nativeEvent?.offsetX) {
                location = e?.nativeEvent?.offsetX;
            } else if (e.clientX) {
                location = e.clientX - (e?.target?.clientLeft + e?.target?.offsetLeft || 0);
            }
            return location;
        };

        const onPointerDown = (e) => {
            this.isDragging = true;
            const eventLocation = getEventLocation(e);
            this.lastMouseDownCoords = [eventLocation, 1];
            this.lastMouseDownDateRange = [...this.context.dateRange];
        };

        const onPointerUp = () => {
            this.isDragging = false;
            initialPinchDistance = null;
        };

        const onPointerMove = (e) => {
            if (this.isDragging) {
                const newPos = getEventLocation(e);
                const oldPos = this.lastMouseDownCoords[0];
                const offset = newPos - oldPos;
                const msPerPixel =
                    (this.context.dateRange[0].valueOf() - this.context.dateRange[1].valueOf()) / dynamicWidth;
                this.context.dateRange[0] = new Date(this.lastMouseDownDateRange[0].valueOf() + offset * msPerPixel);
                this.context.dateRange[1] = new Date(this.lastMouseDownDateRange[1].valueOf() + offset * msPerPixel);
                this.context.onDateRangeChange.trigger();
            }
        };

        const handlePinch = (e) => {
            e.preventDefault();
            const touches = [
                getRelativeTouchPosition(e.touches[0].clientX, e.touches[0].target),
                getRelativeTouchPosition(e.touches[1].clientX, e.touches[1].target),
            ];
            const touch1 = Math.min(...touches);
            const touch2 = Math.max(...touches);
            const currentDistance = (touch1 - touch2) ** 2;
            if (initialPinchDistance == null) {
                const msPerPixel =
                    (this.context.dateRange[1].valueOf() - this.context.dateRange[0].valueOf()) / dynamicWidth;
                initialPinchTimes = [
                    this.context.dateRange[0].valueOf() + touch1 * msPerPixel,
                    this.context.dateRange[0].valueOf() + touch2 * msPerPixel,
                ];
                initialPinchDistance = currentDistance;
            }
            const newMsPerPixel = (initialPinchTimes[1] - initialPinchTimes[0]) / (touch2 - touch1);
            const newStartTime = initialPinchTimes[0] - touch1 * newMsPerPixel;
            this.context.dateRange[0] = new Date(newStartTime);
            this.context.dateRange[1] = new Date(newStartTime + newMsPerPixel * dynamicWidth);
            this.context.onDateRangeChange.trigger();
        };

        const handleTouch = (e, singleTouchHandler) => {
            if (e.preventDefault && !e.defaultPrevented) {
                try {
                    e.preventDefault();
                } catch (error) {
                    console.warn("Touch handling error in trend graph base:", error);
                }
            }
            if (e.touches.length === 1) {
                singleTouchHandler(e);
            } else if (e.type === "touchmove" && e.touches.length === 2) {
                this.isDragging = false;
                handlePinch(e);
            }
        };

        return (
            <div>
                <Stage
                    id={"stage"}
                    className={"trendGraphBase"}
                    onMouseDown={() => onPointerDown}
                    onTouchStart={(e) => handleTouch(e, onPointerDown)}
                    onMouseUp={() => onPointerUp}
                    onTouchEnd={(e) => handleTouch(e, onPointerUp)}
                    onMouseMove={() => onPointerMove}
                    onWheel={(e) => onZoom(e)}
                    onTouchMove={(e) => handleTouch(e, onPointerMove)}
                    onPointerDown={(e) => onPointerDown(e)}
                    onPointerMove={(e) => {
                        this.mouseMoveEventHandler.trigger(e);
                        onPointerMove(e);
                    }}
                    onPointerUp={(e) => onPointerUp(e)}
                    width={dynamicWidth}
                    height={dynamicHeight}
                    style={{
                        position: "absolute",
                        background: "transparent",
                        zIndex: 1,
                        borderLeft: "0.5px solid rgb(220,220,220)",
                        borderRight: "0.5px solid rgb(220,220,220)",
                        touchAction: "pinch-zoom",
                    }}
                    renderOnComponentChange={true}
                    onMount={(arg1) => {
                        const interactionManager = arg1.renderer.plugins.interaction;
                        interactionManager.autoPreventDefault = false;
                        arg1.renderer.view.style.touchAction = "auto";
                        if (this.handleWheel) {
                            if (this.state?.stage?.renderer?.view) {
                                this.state?.stage?.renderer?.view.removeEventListener("wheel", this.handleWheel, {
                                    passive: false,
                                });
                            }
                            arg1.renderer.view.removeEventListener("wheel", this.handleWheel, { passive: false });
                        }
                        this.handleWheel = onZoom;
                        arg1.renderer.view.addEventListener("wheel", this.handleWheel, { passive: false });
                        this.setState({ stage: arg1 });
                        arg1.stage.sortableChildren = true;
                    }}
                    options={{
                        backgroundAlpha: 0,
                        interactive: this.interactive,
                        antialias: true,
                        sortableChildren: true,
                    }}
                >
                    <DynamicGraphContext.Provider value={this.context}>
                        <Graphics
                            draw={(g) => {
                                if (!this.graphBackgroundGraphic) {
                                    this.graphBackgroundGraphic = g;
                                    g.zIndex = 0;
                                    this.graphBackgroundGraphic.drawRect(0, 0, 1000, 500);
                                    this.graphBackgroundGraphic.endFill();
                                }
                                this.graphBackgroundGraphic.x = 0;
                                this.graphBackgroundGraphic.y = 0;
                                this.graphBackgroundGraphic.width = window.innerWidth;
                                this.graphBackgroundGraphic.height = window.innerHeight;
                            }}
                        />

                        {Array.isArray(this.props.sensors) &&
                            this.props.sensors.map((sensor) => {
                                if (sensor.visible) {
                                    sensor.test = true;
                                    return (
                                        <TrendGraphLine
                                            key={`staticTrendLine--${sensor.id}${sensor.dataType}`}
                                            {...commonProps}
                                            sensor={sensor}
                                            addToSensor={(options) => {
                                                Object.assign(sensor, options);
                                            }}
                                            interactive={this.interactive}
                                        />
                                    );
                                }
                                return null;
                            })}

                        <TrendGraphCursorLine
                            allLines={this.props.sensors}
                            height={dynamicHeight}
                            isModalOpen={this.props.modalOpen}
                            mouseMoveEvent={this.mouseMoveEventHandler}
                            getCursorStringAsFunctionOfX={(x) => {
                                const timePerPixel =
                                    (this.context.endTime.valueOf() - this.context.startTime.valueOf()) / dynamicWidth;
                                const cursorTime = new Date(this.context.startTime.valueOf() + timePerPixel * x);
                                if (this.props.onChange) {
                                    this.props.onChange(cursorTime);
                                }
                                return cursorTime;
                            }}
                        />
                    </DynamicGraphContext.Provider>
                </Stage>
                {this.props.displayBackground && (
                    <div>
                        <TrendGraphBackground
                            sensors={this.props.sensors}
                            {...commonProps}
                            days={dynamicWidth / (this.numOfDays * 2) < 35}
                            modalOpenHeight={(60 * window.innerHeight) / 100}
                        />
                    </div>
                )}
                <div className={"DynamicGraphXAxisRail"} style={{ width: `${dynamicWidth}px` }}>
                    <HTMLDynamicGraphCursor />
                </div>
                <div style={{ width: `${dynamicWidth}px` }}>
                    <TrendGraphChips parentGraphID={this.props.parentGraphID} />
                </div>
            </div>
        );
    }
}
TrendGraphBase.contextType = DynamicGraphContext;

// TrendGraphBase.propTypes = {
//     height: PropTypes.number,
//     width: PropTypes.number,
//     graphDataType: PropTypes.string,
//     displayBackground: PropTypes.bool,
//     sensors: PropTypes.array,
//     onChange: PropTypes.func,
//     parentGraphID: PropTypes.string,
// };
