import "./LoadingSpinner.scss";

import Typography from "@mui/material/Typography";
import { GenerateUID } from "gardenspadejs/dist/general";
import React from "react";

import LoadingSpinnerSVGComponent from "./LoadingSpinnerSVGComponent";

export default class LoadingSpinner extends React.Component {
    animatedSVGID = undefined;

    className = "LoadingSpinner LoadingSpinner--doneLoading";

    doneLoadingName = "LoadingSpinner LoadingSpinner--doneLoading";

    player = undefined;

    stillLoadingName = "LoadingSpinner LoadingSpinner--loading";

    constructor(props) {
        super(props);
        this.uid = GenerateUID("LoadingSpinner").replaceAll("#", "_");
        this.state = {};
        this.animatedSVGID = "eqgSEWsQAxF1"; // this.uid.replaceAll("#", "") + "dsas";
    }

    componentDidMount() {
        const elementByID = document.getElementById(this.uid).querySelector("#eqgSEWsQAxF1");
        if (elementByID) {
            elementByID.addEventListener("ready", (e) => {
                if (e.target.svgatorPlayer) {
                    this.player = e.target.svgatorPlayer;
                    e.target.svgatorPlayer.on("keyframe", () => {
                        this.handleFrame();
                    });
                    this.handleFrame();
                }
            });
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.loading !== this.props.loading) {
            setTimeout(() => {
                this.handleFrame();
            }, 10);
            setTimeout(() => {
                this.handleFrame();
            }, 50);
            window.requestAnimationFrame(() => {
                this.handleFrame();
            });
        }
        if (nextProps.loading) {
            this.className = this.stillLoadingName;
        } else if (this.player) {
            if (!this.player.isPlaying) {
                this.className = this.doneLoadingName;
            }
            if (this.player.currentTime > 4300) {
                this.className = this.doneLoadingName;
            }
        }

        return true;
    }

    handleFrame() {
        if (!this.player) {
            console.info("no player for load animation");
            return;
        }
        let curTime = this.player.currentTime;
        let playing = this.player.isPlaying;
        const isReversed = this.player.isReversed;
        if (this.props.loading) {
            if (isReversed) {
                curTime = this.player.duration - curTime;
            }
            if (!playing) {
                if (curTime > 4300) {
                    this.player.set("direction", 1);
                    this.player.seekTo(0);
                }
                this.player.play();

                playing = true;
            }
            if (curTime > 2700 && curTime < 3300 && playing) {
                this.player.set("direction", 1);
                this.player.seekTo(1700);
            }
            if (curTime > 3350 && curTime < 4300 && playing && !isReversed) {
                this.player.set("direction", -1);
                this.player.seekTo(this.player.duration - curTime);
            }
            if (curTime > 4300) {
                this.player.set("direction", 1);
                this.player.seekTo(0);
            }
        }
        if (!this.props.loading) {
            if (isReversed) {
                this.player.set("direction", 1);
                this.player.seekTo(this.player.duration - curTime);
            }
            if (!isReversed && curTime > 1500 && curTime < 2000) {
                this.player.set("direction", 1);
                this.player.seekTo(3300);
            }
            if (curTime > 4300 && this.className !== this.doneLoadingName) {
                this.className = this.doneLoadingName;
                this.forceUpdate();
            }
        }
    }

    render() {
        const className = this.className;
        return (
            <div className={className} id={this.uid}>
                <div className={`LoadingSpinnerText`}>
                    <Typography variant={"h4"}>Loading...</Typography>
                    <Typography variant={"body2"}>{this.props.reason || "Fetching Data"}</Typography>
                </div>
                <LoadingSpinnerSVGComponent id={this.animatedSVGID} />
            </div>
        );
    }
}
