import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React from "react";

import { LightTooltip } from "../../generic/LightTooltip/LightTooltip";

export const threeDays = 1000 * 60 * 60 * 24 * 3;
export const oneWeek = 1000 * 60 * 60 * 24 * 7;
export const twoWeeks = 1000 * 60 * 60 * 24 * 14;
export const threeWeeks = 1000 * 60 * 60 * 24 * 21;

export default function StaleTooltipIcon(props) {
    const lastVerifiedResults = {
        tooltipText: "Stale",
        icon: AccessTimeIcon,
        iconColor: "",
    };

    const lastVerification = new Date(props.lastVerification).getTime();
    const timeSinceLastVerification = Date.now() - lastVerification.valueOf();

    if (lastVerification) {
        if (timeSinceLastVerification >= threeDays && timeSinceLastVerification < oneWeek) {
            lastVerifiedResults.tooltipText = "Stale (3-7 days)";
            lastVerifiedResults.iconColor = "var(--stale-icon-light)";
        } else if (timeSinceLastVerification >= oneWeek && timeSinceLastVerification < twoWeeks) {
            lastVerifiedResults.tooltipText = "Stale (1-2 weeks)";
            lastVerifiedResults.iconColor = "var(--stale-icon-medium)";
        } else if (timeSinceLastVerification >= twoWeeks && timeSinceLastVerification < threeWeeks) {
            lastVerifiedResults.tooltipText = "Stale (2-3 weeks)";
            lastVerifiedResults.iconColor = "var(--stale-icon-dark)";
        } else if (timeSinceLastVerification >= threeWeeks) {
            lastVerifiedResults.tooltipText = "Stale (more than 3 weeks, potentially outdated)";
            // lastVerifiedResults.icon = SyncProblemIcon;
            // lastVerifiedResults.iconColor = "var(--error-icon-main)";
            lastVerifiedResults.iconColor = "var(--stale-icon-dark)";
        }
    }

    const passedProps = { ...props };
    delete passedProps.Target;

    return (
        <LightTooltip title={lastVerifiedResults.tooltipText} {...passedProps}>
            {/* <IconButton > */}
            <lastVerifiedResults.icon
                style={{
                    color: lastVerifiedResults.iconColor,
                    width: "1.2rem",
                    marginLeft: "0.1rem",
                    marginBottom: "0.1rem",
                }}
            />
            {/* </IconButton> */}
        </LightTooltip>
    );
}
