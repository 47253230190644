import { MasterIndex, SessionHandler } from "verdiapi";
import { secondMs } from "verdiapi/dist/HelperFunctions";

import FocusContext from "../mapManagement/FocusContext";

/**
 * Devices will be polled at AT MOST these frequencies
 *
 * All polling in this service is based off the below mostRecentPoll time, so the most
 * frequent polling hook set will override all other less frequent ones
 */
const POLLING_FREQUENCIES = {
    INFOCARD_OPEN: 30 * secondMs,
    MANUALMODE_TRANSITIONING: 15 * secondMs,
    DEFAULT: 60 * secondMs,
} as const;

let mostRecentPollTime = new Date();
SessionHandler.onSessionLoad.addListener(() => {
    mostRecentPollTime = new Date();
});

const buffer = 2 * secondMs;
const shouldPoll = (pollingFrequency: number) =>
    new Date().getTime() - mostRecentPollTime.getTime() + buffer >= pollingFrequency;

interface RefreshChangedDevicesParams {
    includeRecentData?: boolean;
    includeUpdatedDevices?: boolean;
    includeFocusedDevices?: boolean;
    includeSpecificDeviceID?: string;
}

/**
 * Refresh all the devices that have changed since the last poll
 *
 * @param includeRecentData - Include most recent data points
 * @param includeUpdatedDevices - Include key fields from devices that have been updated since the last poll
 * @param includeFocusedDevices - Include all data from device(s) that are focused
 * @param includeSpecificDeviceID - node_id to force to be included
 * @return {Promise<void>}
 */
const refreshChangedDevices = async ({
    includeRecentData = true,
    includeUpdatedDevices = true,
    includeFocusedDevices = true,
    includeSpecificDeviceID = undefined,
}: RefreshChangedDevicesParams) => {
    const devicesToInclude =
        includeFocusedDevices && Boolean(FocusContext.activeContext.focused?.id)
            ? [FocusContext.activeContext.focused?.id]
            : [];
    if (includeSpecificDeviceID && !devicesToInclude.includes(includeSpecificDeviceID)) {
        devicesToInclude.push(includeSpecificDeviceID);
    }

    try {
        const newData = await queryChangedData({
            lastPollTime: mostRecentPollTime,
            includeRecentData,
            includeUpdatedDevices,
            deviceIds: devicesToInclude,
        });

        await updateMasterIndexFromJson(newData);
        mostRecentPollTime = new Date(newData?.timeOfPoll ?? new Date());
    } catch (error) {
        console.error("Error polling devices", error);
    }
};

interface QueryChangedDataParams {
    lastPollTime: Date;
    includeRecentData: boolean;
    includeUpdatedDevices: boolean;
    deviceIds: string[];
}
const queryChangedData = ({
    lastPollTime,
    includeRecentData,
    includeUpdatedDevices,
    deviceIds,
}: QueryChangedDataParams) =>
    SessionHandler.doFetch(`pollForUpdates`, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        method: "POST",
        redirect: "follow",
        body: JSON.stringify({
            includeRecentData,
            includeUpdatedDevices,
            lastPollTime,
            includeSpecificDevices: deviceIds,
        }),
    })
        .then((response) => response.json())
        .catch(async (error) => {
            console.error(
                "Error polling devices",
                error,
                "Params:",
                lastPollTime,
                includeRecentData,
                includeUpdatedDevices,
                deviceIds,
            );
        });

const updateMasterIndexFromJson = (json: any) => {
    if (json?.deviceUpdates) {
        json.deviceUpdates.forEach((deviceUpdate: any) => {
            const deviceObject =
                MasterIndex.irrigationDevice.byID[deviceUpdate.node_id] ??
                MasterIndex.blockValve.byID[deviceUpdate.node_id];
            if (!deviceObject) {
                return;
            }
            deviceObject?.assignFromJSON(deviceUpdate);
            deviceObject.lastRefreshed = new Date();

            deviceObject.onChange.trigger({});
        });
    }
};

export { shouldPoll, refreshChangedDevices, POLLING_FREQUENCIES };
