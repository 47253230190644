import "./DeviceIconComponent.css";
import "./MapMarker.css";
import "../../MapEntityStatusStyles.scss";

import React, { Component } from "react";
import { EventHandler } from "verdiapi";
import { RisingEdgeBufferedAction } from "verdiapi/dist/HelperFunctions";

import { GenerateUID } from "../../../../utils/HelperFunctions";

const onNewActiveIcon = new EventHandler();
export default class DeviceIconComponent extends Component {
    lastMousePosition = [0, 0];

    lastWasInHitbox = false;

    resetCount = 0;

    _ownElement = undefined;

    constructor(props) {
        super(props);
        this.state = {
            isHoverOpen: false,
            isFullDetailsOpen: false,
        };
        this.uid = GenerateUID("DeviceIconComponent");
        this.cursorStateChangeAction = new RisingEdgeBufferedAction(() => {
            if (!this.ownElement) {
                return;
            }
            if (this.state.isHoverOpen && !this.isMouseInHitbox()) {
                this.setState({
                    isHoverOpen: false,
                    isFullDetailsOpen: false,
                });
                this.resetCount++;
            }
            if (!this.state.isHoverOpen && this.isMouseInHitbox()) {
                this.setState({
                    isHoverOpen: true,
                });
                // Only if the hover hasnt' been closed for the duration of the timeout
                const tempResetCount = this.resetCount;
                setTimeout(() => {
                    if (tempResetCount === this.resetCount && this.state.isHoverOpen) {
                        this.setState({
                            isFullDetailsOpen: true,
                        });
                    }
                }, 600);
                onNewActiveIcon.trigger({ activeComponent: this });
            }
        }, 200);
        onNewActiveIcon.addListener((e) => {
            if (e && e.activeComponent !== this && this.state.isHoverOpen) {
                this.setState({
                    isHoverOpen: false,
                    isFullDetailsOpen: false,
                });
                this.resetCount++;
            }
        });
        this.cursorStateChangeAction.risingEdge = false;
        this.cursorStateChangeAction.waitForQuiet = true;

        this.cursorMoveAction = new RisingEdgeBufferedAction(() => {
            if (!this.ownElement) {
                return;
            }
            const wasInHitbox = this.lastWasInHitbox;
            const isInHitbox = this.isMouseInHitbox();

            if (wasInHitbox !== isInHitbox) {
                this.cursorStateChangeAction.trigger();
            }
            this.lastWasInHitbox = isInHitbox;
        }, 200);
    }

    get ownElement() {
        if (this._ownElement) {
            return this._ownElement;
        }
        this._ownElement = document.getElementById(this.uid);
        return this._ownElement;
    }

    isMouseInHitbox() {
        if (!this.ownElement) {
            return false;
        }
        const originalHitbox = this.ownElement.getBoundingClientRect();
        let markerHitbox = null;
        const query = this.ownElement.getElementsByClassName("MarkerIcon")[0];
        if (query) {
            markerHitbox = query.getBoundingClientRect();
        }

        const checkInHitbox = (hitbox) => {
            if (hitbox === null) {
                return false;
            }
            return !(
                this.lastMousePosition[0] < hitbox.left ||
                this.lastMousePosition[0] > hitbox.right ||
                this.lastMousePosition[1] > hitbox.bottom ||
                this.lastMousePosition[1] < hitbox.top
            );
        };
        return checkInHitbox(originalHitbox) || checkInHitbox(markerHitbox);
    }

    render() {
        const mouseMoveHandler = (e) => {
            this.lastMousePosition = [e.pageX, e.pageY];
            this.cursorMoveAction.trigger();
        };
        return (
            <div
                id={this.uid}
                className={`DeviceIconComponentRoot ${this.props.enlarged ? "DeviceIconComponentRoot--enlarged" : ""}`}
                onMouseEnter={mouseMoveHandler}
                onMouseLeave={mouseMoveHandler}
                onPointerMove={mouseMoveHandler}
            >
                {this.props.icon({
                    onMouseEnter: mouseMoveHandler,
                    onMouseLeave: mouseMoveHandler,
                    onPointerMove: mouseMoveHandler,
                })}
                <div
                    className={`deviceIconOnHover deviceIconOnHover--${this.state.isHoverOpen ? "visible" : "hidden"}
                    ${this.state.isFullDetailsOpen ? " deviceIconOnHover--fullDetails" : ""}`}
                >
                    {this.props.tooltipContents}
                </div>
            </div>
        );
    }
}
