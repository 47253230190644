import { Edit as EditIcon } from "@mui/icons-material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { RisingEdgeBufferedAction } from "verdiapi/dist/HelperFunctions";

import DeviceStatusText from "./DeviceStatusText";

export class DeviceInfoCardHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
        };
        this.onTextFieldChange = new RisingEdgeBufferedAction((value) => {
            this.props.Target.edit({ name: value })
                .then(() => {
                    this.props.enqueueSnackbar("Saved Device name");
                    this.forceUpdate();
                })
                .catch((e) => {
                    console.warn(e);
                    this.props.enqueueSnackbar("Could not save Device name", { variant: "error" });
                });
        }, 1000);
        this.onTextFieldChange.risingEdge = false;
        this.onTextFieldChange.waitForQuiet = true;
    }

    render() {
        let name = `Irrigation Device ${this.props.Target.id}`;
        if (this.props.Target.type === "msense") {
            name = `Moisture Sense. ${this.props.Target.id}`;
        }
        if (this.props.Target.name) {
            name = this.props.Target.name;
        }
        if (this.props.Target.label) {
            name = this.props.Target.label;
        }
        if (!this.state.editing) {
            return (
                <div
                    style={{
                        // fixes sub pixel bug on icons.
                        minHeight: "1.375rem",
                    }}
                >
                    <IconButton
                        onClick={() => {
                            this.setState({ editing: true });
                        }}
                        size={"small"}
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            float: "right",
                        }}
                    >
                        <EditIcon
                            fontSize={"small"}
                            style={{
                                fontSize: "1rem",
                            }}
                        />
                    </IconButton>
                    <Typography variant={"body2"} component={"span"} color={"textPrimary"}>
                        {name}
                    </Typography>
                    <Typography variant={"subtitle2"} component={"span"} color={"textSecondary"}>
                        {" - "}
                    </Typography>
                    <DeviceStatusText statusText={this.props.statusText} warning={this.props.warning} />
                    {/* <Typography */}
                    {/*    variant="subtitle2" */}
                    {/*    component="span" */}
                    {/*    className={this.props.warning ? "WarningText" : ""} */}
                    {/*    color="textSecondary" */}
                    {/* > */}
                    {/*    {statusText} */}
                    {/* </Typography> */}
                </div>
            );
        }
        return (
            <div>
                <TextField
                    style={{
                        width: "100%",
                    }}
                    defaultValue={name}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    variant={"standard"}
                    onChange={(e) => {
                        const a = { ...e };
                        this.onTextFieldChange.trigger(a.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"}>
                                <EditIcon />
                                <Button
                                    onClick={() => {
                                        this.setState({ editing: false });
                                    }}
                                >
                                    Done
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        );
    }
}
