import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function DualValveSeedIcon(props) {
    return (
        <SvgIcon viewBox={"0, 0, 36, 20"} {...props}>
            <path
                style={{
                    fillOpacity: 1,
                    strokeWidth: 0.219213,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                }}
                d={
                    "M 53.544922 0.109375 L 50.849609 1.0957031 L 50.849609 3.7714844 L 48.982422 3.7714844 L 48.982422 5.8847656 L 48.982422 7.9980469 L 50.849609 7.9980469 L 50.849609 10.675781 L 53.544922 11.660156 L 61.943359 11.660156 L 70.341797 11.660156 L 73.037109 10.675781 L 73.037109 7.9980469 L 74.902344 7.9980469 L 74.902344 5.8847656 L 74.902344 3.7714844 L 73.037109 3.7714844 L 73.037109 1.0957031 L 70.341797 0.109375 L 61.943359 0.109375 L 53.544922 0.109375 z "
                }
                transform={"matrix(0.26458333,0,0,0.26458333,2.5096702,1.962734)"}
            />
            <path
                style={{
                    fillRule: "evenodd",
                    strokeWidth: 0.464217,
                }}
                d={
                    "M 43.183594 18.140625 C 39.795383 18.140625 37.068359 20.867647 37.068359 24.255859 L 37.068359 28.75 L 25.324219 28.75 L 25.324219 42.128906 L 37.068359 42.128906 L 37.068359 46.621094 C 37.068359 50.009308 39.795383 52.738281 43.183594 52.738281 L 80.703125 52.738281 C 84.091335 52.738281 86.818359 50.009308 86.818359 46.621094 L 86.818359 42.128906 L 98.560547 42.128906 L 98.560547 28.75 L 86.818359 28.75 L 86.818359 24.255859 C 86.818359 20.867647 84.091335 18.140625 80.703125 18.140625 L 43.183594 18.140625 z M 21.335938 26.226562 L 6.8144531 28.878906 L 6.8144531 31.025391 L 0.12304688 32.414062 L 0.12304688 35.439453 L 0.12304688 38.462891 L 6.8144531 39.853516 L 6.8144531 42 L 21.335938 44.650391 L 21.335938 35.439453 L 21.335938 26.226562 z M 102.30078 26.226562 L 102.30078 35.439453 L 102.30078 44.650391 L 116.82227 42 L 116.82227 39.853516 L 123.51367 38.462891 L 123.51367 35.439453 L 123.51367 32.414062 L 116.82227 31.025391 L 116.82227 28.878906 L 102.30078 26.226562 z M 22.472656 26.353516 L 22.472656 35.439453 L 22.472656 44.525391 L 23.734375 44.525391 L 23.734375 35.439453 L 23.734375 26.353516 L 22.472656 26.353516 z M 99.900391 26.353516 L 99.900391 35.439453 L 99.900391 44.525391 L 101.16406 44.525391 L 101.16406 35.439453 L 101.16406 26.353516 L 99.900391 26.353516 z "
                }
                transform={"matrix(0.26458333,0,0,0.26458333,2.5096702,1.962734)"}
            />
            <rect
                style={{
                    fillRule: "evenodd",
                    strokeWidth: 0.264538,
                }}
                width={"6.03403"}
                height={"1.3535839e-13"}
                x={"18.898804"}
                y={"-4.1608753"}
                ry={"6.7679193e-14"}
            />
        </SvgIcon>
    );
}
