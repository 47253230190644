export const languages = {
    "default": "en-CA",
    "en-CA": "en-CA",
    "en-US": "en-US",
    "es-MX": "es-MX",
    "fr-CA": "fr-CA",
    "pa-IN": "pa-IN",
    "pt-PT": "pt-PT",
    "ja-JP": "ja-JP",
};

// These are the names of the translation files to reference without the .json prefix
export const namespaces = {
    common: "common",
    genericComponents: "genericComponents",
    specializedComponents: "specializedComponents",
    modules: "modules",
    pages: "pages",
    icons: "icons",
};
