import React from "react";

import FocusContext from "../mapManagement/FocusContext";
import LoadingSpinner from "./LoadingSpinner";

export default class GloballyControlledLoadingSpinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            reason: undefined,
            curKey: undefined,
        };
    }

    componentDidMount() {
        FocusContext.onLoadStatusChange.addListener(() => {
            const shouldBeLoading = this.getIdealLoadingState();
            if (this.state.curKey && FocusContext._activeLoadHandlers[this.state.curKey]) {
                return;
            }
            if (!this.state.loading && !shouldBeLoading) {
                return;
            }
            if (shouldBeLoading) {
                const newKey = Object.keys(FocusContext._activeLoadHandlers)[0];
                this.setState({
                    loading: true,
                    curKey: newKey,
                    reason: FocusContext._activeLoadHandlers[newKey],
                });
            } else {
                this.setState({
                    loading: false,
                    curKey: undefined,
                    reason: "done",
                });
            }
        });
    }

    getIdealLoadingState() {
        return Object.keys(FocusContext._activeLoadHandlers).length > 0;
    }

    render() {
        // some kind of react internal race condition means this somehow ends up in the wrong
        // state and so we need to trigger a re-calc when that happens.
        if (this.state.loading !== this.getIdealLoadingState()) {
            FocusContext.onLoadStatusChange.trigger({});
        }
        return <LoadingSpinner loading={this.state.loading} reason={this.state.reason} />;
    }
}
