import { Typography } from "@mui/material";
import { BufferedAction } from "gardenspadejs/dist/general";
import React from "react";
import { createRoot } from "react-dom/client";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import BlockValveMarkerIcon from "../../../components/icons/MapIcons/BlockValveMarkerIcon";
import DeviceMarkerIcon from "../../../components/icons/MapIcons/DeviceMarkerIcon";
import MoistureSensorMarkerIcon from "../../../components/icons/MapIcons/MoistureSensorMarkerIcon";
import TensiometerMarkerIcon from "../../../components/icons/MapIcons/TensiometerMarkerIcon";
import WatermarkMarkerIcon from "../../../components/icons/MapIcons/WatermarkMarkerIcon";
import BatteryStatusIcon from "../../../components/icons/StatusIcons/BatteryStatusIcon";
import SignalStrengthStatusIcon from "../../../components/icons/StatusIcons/SignalStrengthStatusIcon";
import { FunctionalDeviceInfoCard } from "../../../components/specialized/infoCards/DeviceInfoCard/FunctionalDeviceInfoCard";
import SaplingVerification from "../../../components/specialized/SaplingVerification/SaplingVerification";
import { GenerateUID } from "../../../utils/HelperFunctions";
import FocusContext from "../FocusContext";
import { addMapEntityIconToMap } from "../MapHelper";
import MapEntityBase from "./MapEntityBase";
import DeviceIconComponent from "./MapEntityComponents/DeviceIconComponent";

const allDeviceEntities = new Set();
const findClosestIcons = new BufferedAction(
    () => {
        const locationTable = {};
        const allDeviceList = Array.from(allDeviceEntities);
        const perms = [
            [0, 0],
            [0, 1],
            [0, -1],
            [1, 0],
            [1, 1],
            [1, -1],
            [-1, 0],
            [-1, 1],
            [-1, -1],
        ];
        allDeviceList.forEach((de) => {
            perms.forEach((permutation) => {
                const key = `${Math.round(de.lat * 5000) + permutation[0]}|${
                    Math.round(de.long * 5000) + permutation[1]
                }`;
                if (Array.isArray(locationTable[key])) {
                    locationTable[key].push(de);
                } else {
                    locationTable[key] = [de];
                }
            });
        });

        const devicesToUpdate = [];
        allDeviceList.forEach((de) => {
            const key = `${Math.round(de.lat * 5000)}|${Math.round(de.long * 5000)}`;
            const candidates = locationTable[key];
            let closestDistance = 100000000;
            candidates.forEach((candidate) => {
                if (candidate !== de) {
                    const d = calcCrow(de.lat, de.long, candidate.lat, candidate.long);
                    if (d < closestDistance) {
                        closestDistance = d;
                    }
                }
            });
            if (Math.abs(de.distanceToNearest - closestDistance) > 2) {
                de.distanceToNearest = closestDistance;
                devicesToUpdate.push(de);
            }
        });
        if (devicesToUpdate.length > 0) {
            window.requestAnimationFrame(() => {
                devicesToUpdate.forEach((d) => {
                    d.updateFocusState();
                });
            });
        }
    },
    500,
    false,
    true,
);
export default class DeviceMapEntity extends MapEntityBase {
    static infoCardClass = FunctionalDeviceInfoCard;

    distanceToNearest = 100000000;

    /**
     *@type {HistoricalDataBase}
     */
    moistureDataBase;

    infoCardClass = FunctionalDeviceInfoCard;

    warningText = undefined;

    warningToShow = undefined;

    manualModeWarning = false;

    /**
     *  @type {irrigationDevice}
     */
    model;

    sensorOutOfDate = false;
    /**
     * @param {irrigationDevice} IrrigationDevice
     */

    get type() {
        return this.model.type || this.model.deviceType;
    }

    get DOMClassNames() {
        const initialClassNames = super.DOMClassNames;
        if (this.manualModeWarning) {
            initialClassNames.push("ME_ManualMode");
        }
        return initialClassNames;
    }

    constructor(IrrigationDevice, renderImmediately = true) {
        super(IrrigationDevice, GenerateUID("DevE"), false);

        this.lat = this.model.lat;
        this.long = this.model.long;
        allDeviceEntities.add(this);
        this.setWarnings();
        this.setColor();
        this.pickIcon();
        if (renderImmediately) {
            this.renderOnMap();
        }
        findClosestIcons.trigger();
        IrrigationDevice.onChange.addListener(() => {
            this.setWarnings();
            this.updateIfClassNamesChanged();
        });
    }

    setWarnings() {
        if (DeviceConfigurationsByType[this.type]?.generation === "seed") {
            return;
        }
        if (this.model.createdAt && Date.now() - this.model.createdAt.valueOf() < 2 * 1000 * 60 * 60) {
            return;
        }
        if (this.model.type && this.model.type === "msense") {
            let sensorOutOfDate = false;
            if (this.model.recentData && this.model.recentData.moisture) {
                if (
                    Math.abs(Date.now() - new Date(this.model.recentData.moisture.date).valueOf()) >
                    1000 * 60 * 60 * 24 * 5
                ) {
                    sensorOutOfDate = true;
                }
            }
            if (
                this.model.recentData &&
                this.model.recentData.moisture &&
                this.model.acceptableValueRange &&
                Array.isArray(this.model.acceptableValueRange) &&
                this.model.acceptableValueRange.length === 2
            ) {
                if (this.model.recentData.moisture.value > this.model.acceptableValueRange[1]) {
                    this.status = "Wet";
                } else if (this.model.recentData.moisture.value < this.model.acceptableValueRange[0]) {
                    this.status = "Dry";
                }
            }
            this.sensorOutOfDate = sensorOutOfDate;

            if (sensorOutOfDate) {
                this.status = "Warning";
            }
        }

        /*
         * This feature means when you type one of several things into the notes field, the device shows up differently.
         * This is specifically for Cassin as a short term fix, it should be migrated to some kind of proper feature.
         *
         * suppresswarnings:all means we want to not show warnings for this device. This isn't a feature we should use
         * on a regular basis
         *
         * disabledevice:true suppresses warnings, but also makes the device gray to indicate that it isn't currently in
         * use, this is much better to use.
         *
         */
        let suppressWarnings = false;
        if (this.model.notes) {
            if (this.model.notes.toLowerCase().includes("suppresswarnings:all")) {
                suppressWarnings = true;
            }
            if (this.model.notes.toLowerCase().includes("disabledevice:true")) {
                suppressWarnings = true;
                this.customColor = "gray";
            }
        }

        // A lot of code here is just to seperate out manual override warnings
        // we are temporarily making the manual mode warnings present differently, this will be changed in
        // the future.
        let noManualWarnings = [...(this.model.warnings || [])];
        noManualWarnings = noManualWarnings.filter((w) => w.warningType !== "Manual Override Engaged");
        this.manualModeWarning = (this.model.warnings || []).some((w) => w.warningType === "Manual Override Engaged");
        if (noManualWarnings.length > 0 && !suppressWarnings) {
            if (noManualWarnings[0].warningLevel > 0) {
                this.status = noManualWarnings[0].warningLevel > 1 ? "Error" : "Warning";
                this.warningText = noManualWarnings[0].warningTitle;
                this.warningToShow = noManualWarnings[0];
            }
        }

        if (!this.warningText && this.model.notes) {
            if (this.model.notes.toLowerCase().includes("warning:")) {
                console.info(`found notes warning for device ${this.model.name}`);
                this.warningText = "check notes";
                this.status = "Warning";
                this.warningToShow = {
                    warningType: "custom warning",
                    warningTitle: "check notes",
                    warningLevel: 1,
                    warningTypePriority: 1,
                };
            } else if (this.model.notes.toLowerCase().includes("error:")) {
                this.warningText = "check notes";
                this.status = "Error";
                this.warningToShow = {
                    warningType: "custom error",
                    warningTitle: "check notes",
                    warningLevel: 2,
                    warningTypePriority: 1,
                };
            }
        }
    }

    setColor() {
        if (this.model.notes) {
            const colors = ["blue", "teal", "yellow", "purple", "pink", "brown", "indigo"];
            colors.forEach((c) => {
                if (this.model.notes.toLowerCase().includes(`color:${c}`)) {
                    this.customColor = c;
                }
            });
        }
    }

    pickIcon() {
        const deviceType = this.type;
        this.icon = DeviceMarkerIcon;
        if (DeviceConfigurationsByType[deviceType]?.valveType === "block") {
            this.icon = BlockValveMarkerIcon;
        } else if (deviceType === "msense") {
            this.icon = MoistureSensorMarkerIcon;
        } else if (deviceType === "moisture2v") {
            this.icon = DeviceMarkerIcon;
        }
        if (DeviceConfigurationsByType[deviceType]?.valveCount === 0) {
            if (this.model.connectedSensors && this.model.connectedSensors.some((s) => s.sensorType === "irrometer")) {
                this.icon = TensiometerMarkerIcon;
            } else if (
                this.model.connectedSensors &&
                this.model.connectedSensors.some((s) => s.sensorType === "watermark")
            ) {
                this.icon = WatermarkMarkerIcon;
            }
        }
    }

    renderOnMap() {
        if (!this.renderState.onMap && !this.renderState.beingRendered) {
            this.renderState.beingRendered = true;
            addMapEntityIconToMap(this, this.lat, this.long)
                .then((r) => {
                    let enlarged = false;
                    this.domElements.push(r.domElement);
                    // let mouseOverText = undefined;

                    let labelTitleText = this.model.labelText || this.model.name || this.model.label;
                    if (this.model.type && this.model.type === "msense") {
                        if (!labelTitleText) {
                            labelTitleText = "Moist. Sensor";
                        }
                        enlarged = true;
                    }

                    this.anchor = r.domElement;
                    r.domElement.classList.add(...this.DOMClassNames);
                    this.leafletElement = r.leafletMarker;
                    let labelContent;

                    const labelTitle = <Typography component={"div"}>{labelTitleText}</Typography>;
                    let basicLabelTitle = <div className={"DeviceIconTooltipLabelTitle"}>{labelTitle}</div>;
                    if (this.model.type && this.model.type !== "msense") {
                        basicLabelTitle = (
                            <div className={"DeviceIconTooltipLabelTitle"}>
                                {labelTitle} <div className={"flexFill"} />{" "}
                                <BatteryStatusIcon size={"small"} Target={this.model} />{" "}
                                <SignalStrengthStatusIcon size={"small"} Target={this.model} />{" "}
                                <SaplingVerification size={"small"} device={this.model} />
                            </div>
                        );
                    } else if (this.model.type && this.model.type === "msense") {
                        let reading = "N/A";
                        if (this.sensorOutOfDate) {
                            reading = "N/A";
                        } else if (this.model.recentData.moisture) {
                            let valueText = this.model.recentData.moisture.value;
                            if (!Number.isNaN(valueText)) {
                                // if value text is a number
                                valueText = Math.round(this.model.recentData.moisture.value);
                            }
                            reading = `${valueText}%`;
                        }
                        basicLabelTitle = (
                            <div className={"DeviceIconTooltipLabelTitle"}>
                                {labelTitle} <div className={"flexFill"} />
                                <Typography component={"div"} className={"MoistureSenseReading"}>
                                    {" "}
                                    {reading}{" "}
                                </Typography>
                            </div>
                        );
                    }
                    if (this.warningText) {
                        enlarged = true;
                        labelContent = (
                            <>
                                {basicLabelTitle}
                                <Typography className={"deviceIconWarningText"} component={"div"}>
                                    {this.warningText}{" "}
                                </Typography>
                            </>
                        );
                    } else {
                        // using fragment to make it JSX instead of (potentially) a string
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        labelContent = <>{basicLabelTitle}</>;
                    }
                    if (this.model.notes) {
                        labelContent = (
                            <>
                                {labelContent}
                                <Typography
                                    className={`deviceNotesTooltipText ${
                                        this.warningText ? "deviceNotesTooltipText--warning" : ""
                                    } `}
                                    component={"div"}
                                >
                                    {this.model.notes}{" "}
                                </Typography>
                            </>
                        );
                    }
                    const root = createRoot(r.domElement);
                    root.render(
                        <DeviceIconComponent
                            icon={(props) => (
                                <this.icon
                                    {...props}
                                    model={this.model}
                                    onClick={(e) => {
                                        FocusContext.onInteraction(e, this);
                                    }}
                                />
                            )}
                            tooltipContents={labelContent}
                            enlarged={enlarged}
                        />,
                    );
                    this.renderState.onMap = true;
                    this.renderState.beingRendered = false;
                    this.updateFocusState();
                })
                .catch(() => {
                    this.renderState.beingRendered = false;
                    this.renderState.onMap = false;
                });
        }
    }

    updateFocusState() {
        this.statusOptions["--nearestNeighbour"] = this.distanceToNearest;
        if (this.focusState === "focused") {
            this.statusOptions["--icon-rim-transform"] = "scale(1.2)";
        } else {
            this.statusOptions["--icon-rim-transform"] = "scale(0)";
        }
        if (Array.isArray(this.model.valveStates)) {
            if (this.model.valveStates.includes("open")) {
                this.statusOptions["--icon-rim-transform"] = "scale(1.2)";
                this.statusOptions["--icon-rim-fill-color"] = "#42a5f5";
            } else {
                this.statusOptions["--icon-rim-fill-color"] = "#ffffff";
            }
        }

        super.updateFocusState();
    }

    remove() {
        super.remove();
        allDeviceEntities.delete(this);
    }
}

/**
 * Calculates the distance between two pairs of coordinates as the crow flies in meters
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @return {number}
 */
function calcCrow(lat1, lon1, lat2, lon2) {
    const R = 6371000; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
}

// Converts numeric degrees to radians
function toRad(Value) {
    return (Value * Math.PI) / 180;
}
