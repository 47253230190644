import Avatar from "@mui/material/Avatar";
import * as React from "react";

import { getGrapeColorFromName } from "../../utils/GetGrapeColorFromName";
import { toProperCase } from "../../utils/ToProperCase";

export default function VarietalAvatar({ varietal, style, ...passedProps }) {
    varietal = toProperCase((varietal || "Na").trim());
    let titleText = varietal;
    let twoLetterCode = "Na";
    if (varietal.includes(" ")) {
        twoLetterCode = `${varietal.split(" ")[0][0]}${varietal.split(" ")[1][0]}`;
    } else {
        twoLetterCode = `${varietal[0]}${varietal[1]}`;
    }
    let varietalColor = getGrapeColorFromName(varietal);
    if (varietal === "Na") {
        varietalColor = null;
        titleText = "varietal not specified";
    }
    style = {
        backgroundColor: varietalColor,
        ...(style || {}),
    };

    return (
        <Avatar style={style} {...passedProps} title={titleText}>
            {twoLetterCode}
        </Avatar>
    );
}
