import "./NumericFieldWithUnit.scss";

import { Select, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { BufferedAction, GenerateUID } from "gardenspadejs/dist/general";
import * as math from "mathjs";
import React, { useEffect, useMemo } from "react";
import { DataTypeConfig, SensorConfig } from "verditypes";

let unitByUseCase = {};

function updateCookie() {
    localStorage.setItem("defaultUnits2", JSON.stringify(unitByUseCase));
}
let cookieLoaded = false;
function updateFromCookie() {
    cookieLoaded = true;
    try {
        const content = localStorage.getItem("defaultUnits2");
        if (content) {
            const temp = JSON.parse(content);
            if (typeof temp === "object" && temp !== null) {
                unitByUseCase = temp;
            }
        }
    } catch (e) {
        console.warn(e);
    }
}

export function NumericFieldWithUnit({
    value,
    helperText,
    error,
    onChange,
    showUnits,
    label,
    isRange,
    sensorType,
    databaseUnit,
    alternateUnits,
    bufferUpdates,
    unitUsecase,
    singleFieldTitle,
    highFieldTitle,
    lowFieldTitle,
    onBlur,
    unitOverrideString,
    style,
    className,
}) {
    // makes sure that the user settings get loaded from local storage on first use of this component
    if (!cookieLoaded) {
        updateFromCookie();
    }

    const id = React.useMemo(() => {
        GenerateUID("NumericFieldWithUnit");
    }, []);

    const dataTypeInfo =
        DataTypeConfig.DataTypeConfigurations[SensorConfig.SensorConfigurations[sensorType]?.parsedDataType];

    // set the unit usecase string to find out what unit users have preferrred for this use case.
    let unitUsecaseString = unitUsecase;
    // if there is no unit usecase string provided, fallback to the default for the given sensor type
    if (!unitUsecaseString && sensorType) {
        unitUsecaseString = `Sensor: ${sensorType}`;
    }

    if (dataTypeInfo) {
        alternateUnits = alternateUnits || [dataTypeInfo.databaseUnit, ...(dataTypeInfo.alternateUnits || [])];
        databaseUnit = databaseUnit || dataTypeInfo.databaseUnit;
    }
    alternateUnits = alternateUnits || ["m"];
    databaseUnit = databaseUnit || "m";

    /**
     * Turns a number into a "normal" string in the unit of choice. This eliminates long trailing decimals to make
     * things more readable
     * @param input
     * @param outputUnit
     * @return {string}
     */
    const normalizeNumber = (input, outputUnit) => {
        const result = DataTypeConfig.renderData(input, {
            originalUnit: databaseUnit,
            preferredUnit: outputUnit,
            includeUnit: false,
        });
        console.info(`rendering ${input} ${databaseUnit} as ${result} ${outputUnit}`);
        return result;
    };

    // find the default unit based on the user use case
    let defaultUnit = dataTypeInfo?.defaultUserUnit || alternateUnits[0];
    if (
        unitUsecaseString &&
        unitByUseCase[unitUsecaseString] &&
        alternateUnits.includes(unitByUseCase[unitUsecaseString])
    ) {
        defaultUnit = unitByUseCase[unitUsecaseString];
    }
    const [getUnit, setUnit] = React.useState(defaultUnit);
    // eslint-disable-next-line no-nested-ternary
    const [getLowValue, setLowValue] = React.useState(value !== undefined ? (isRange ? value[0] : value) : 0);
    // eslint-disable-next-line no-nested-ternary
    const [getHighValue, setHighValue] = React.useState(value !== undefined ? (isRange ? value[1] : value) : 0);
    const [getLowValueText, setLowValueText] = React.useState(normalizeNumber(getLowValue, getUnit));
    const [getHighValueText, setHighValueText] = React.useState(normalizeNumber(getHighValue, getUnit));

    /**
     * This is a ref to the last values we synced on. Whenever changes are passed to or inherited from the parent,
     * this updates to reflect that.
     * @type {React.MutableRefObject<*>}
     */
    const refOfLastPassedValue = React.useRef(value);
    /**
     * Turn the value (either an array, or a single value) to a lsit of dependancies that work for either
     * @param x
     * @return {[arg is any[], *, *]}
     */
    const valueToDepList = (x) => [Array.isArray(x), Array.isArray(x) ? x[0] : x, Array.isArray(x) ? x[1] : x];
    //
    /**
     * checks if the value a is different from b.
     * e.g. [1, 0] and [1, 0] will return false, but [1, 0] and [1, 1] will return true
     * @param a
     * @param b
     * @return {boolean}
     */
    const areValuesMismatched = (a, b) => {
        const depListB = valueToDepList(b);
        return valueToDepList(a).some((v, i) => depListB[i] !== v);
    };

    // we want to memoize the buffered action, which means we need to be able to retrieve the current onChange function,
    // even if the onChange function itself changes. We also need to make sure that the onCHange function sends the most
    // up to date low and high values, even if the function calling onChange is out of date.
    const onChangeFunction = React.useRef(() => {});
    onChangeFunction.current = () => {
        if (onChange) {
            const e = { value: isRange ? [getLowValue, getHighValue] : getLowValue };
            // if the values are the same values we just recieved, we don't need to send them.
            // this avoids infinite loop scenarios
            if (areValuesMismatched(e.value, refOfLastPassedValue.current)) {
                onChange(e);
                // since this will sync us with the parent, we update this
                refOfLastPassedValue.current = e.value;
            }
        }
    };

    // effect to track when the actual threshold changes and buffered action to prevent overzealous rendering
    const triggerOnChange = useMemo(() => {
        const bufAction = new BufferedAction(
            () => {
                onChangeFunction.current();
            },
            50,
            true,
            false,
        );
        return () => {
            if (bufferUpdates) {
                bufAction.trigger();
            } else {
                onChangeFunction.current();
            }
        };
    }, []);
    useEffect(() => {
        triggerOnChange();
    }, [getLowValue, getHighValue]);

    // update self when props update
    useEffect(
        () => {
            // if one or more elements of both the cur and last sent dep list do not match, then there has been a change
            // to the value that should be reflected in the render
            const someMismatch = areValuesMismatched(value, refOfLastPassedValue.current);
            if (someMismatch) {
                // sets the values to the newly discovered ones.
                if (isRange) {
                    setLowValue(value[0]);
                    setLowValueText(normalizeNumber(value[0], getUnit));
                    setHighValueText(normalizeNumber(value[1], getUnit));
                    setHighValue(value[1]);
                } else {
                    setLowValue(value);
                    setLowValueText(normalizeNumber(value, getUnit));
                }
            }
            refOfLastPassedValue.current = value;
        },
        // run this hook when the value prop changes.
        valueToDepList(value),
    );
    let realLabel = label;
    if (!label) {
        realLabel = null;
    } else if (typeof label === "string" || label instanceof String) {
        realLabel = <Typography variant={"h6"}>{label}</Typography>;
    }

    return (
        <div
            className={`NumericFieldWithUnit ${error ? "NumericFieldWithUnit--error" : ""} ${className || ""}`}
            onBlur={onBlur}
            style={style}
        >
            <div className={"NumericFieldWithUnit__Header"} />
            <div className={"NumericFieldWithUnit__Body"}>
                {label && <div className={"NumericFieldWithUnit__Identifier"}>{realLabel}</div>}
                <div className={"NumericFieldWithUnit__FirstValue NumericFieldWithUnit__TextInput"}>
                    <TextField
                        error={Boolean(error)}
                        label={isRange ? lowFieldTitle : singleFieldTitle || null}
                        value={getLowValueText}
                        onChange={(e) => {
                            let filteredValue = e.target.value.replaceAll(/[^\d.-]/g, "");
                            filteredValue = filteredValue.replace(".", "&");
                            filteredValue = filteredValue.replaceAll(".", "");
                            filteredValue = filteredValue.replace("&", ".");
                            const float = parseFloat(filteredValue);
                            if (!Number.isNaN(float)) {
                                setLowValue(math.unit(float, getUnit).toNumber(databaseUnit));
                            }
                            setLowValueText(filteredValue);
                        }}
                        onBlur={() => {
                            setLowValueText(normalizeNumber(getLowValue, getUnit));
                        }}
                        // helperText={"Min"}
                        className={"NumericFieldWithUnit__TextInputActual"}
                    />
                </div>
                {isRange && (
                    <div className={"NumericFieldWithUnit__Dash"}>
                        <Typography variant={"h5"}>-</Typography>
                    </div>
                )}
                {isRange && (
                    <div className={"NumericFieldWithUnit__SecondValue NumericFieldWithUnit__TextInput"}>
                        <TextField
                            label={isRange ? highFieldTitle : null}
                            value={getHighValueText}
                            onChange={(e) => {
                                let filteredValue = e.target.value.replaceAll(/[^\d.-]/g, "");
                                filteredValue = filteredValue.replace(".", "&");
                                filteredValue = filteredValue.replaceAll(".", "");
                                filteredValue = filteredValue.replace("&", ".");
                                const float = parseFloat(filteredValue);
                                if (!Number.isNaN(float)) {
                                    setHighValue(math.unit(float, getUnit).toNumber(databaseUnit));
                                }
                                setHighValueText(filteredValue);
                            }}
                            onBlur={() => {
                                setHighValueText(normalizeNumber(getHighValue, getUnit));
                            }}
                            // helperText={"Max"}
                            className={"NumericFieldWithUnit__TextInputActual"}
                        />
                    </div>
                )}
                {showUnits && (
                    <div className={"NumericFieldWithUnit__UnitDropdown"}>
                        <FormControl>
                            {/* <InputLabel id={id + "unitLabel"}>Unit</InputLabel> */}
                            <Select
                                // size={passedProps.size}
                                labelId={`${id}unitLabel`}
                                value={unitOverrideString ? "m" : getUnit}
                                label={"Unit"}
                                onChange={(e) => {
                                    const newUnit = e.target.value;
                                    setUnit(newUnit);
                                    setHighValueText(normalizeNumber(getHighValue, newUnit));
                                    setLowValueText(normalizeNumber(getLowValue, newUnit));
                                    unitByUseCase[unitUsecaseString] = newUnit;
                                    updateCookie();
                                }}
                            >
                                {!unitOverrideString &&
                                    alternateUnits.map((o) => (
                                        <MenuItem value={o} key={o}>
                                            {DataTypeConfig.renderUnit(o || "")}
                                        </MenuItem>
                                    ))}
                                {unitOverrideString && (
                                    <MenuItem value={"m"} key={"unitOverrideString"}>
                                        {unitOverrideString}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                )}
            </div>
            <div className={"NumericFieldWithUnit__Footer"}>
                {helperText && !error && (
                    <Typography variant={"caption"} className={"helperText"}>
                        {helperText}
                    </Typography>
                )}
                {error && (
                    <Typography variant={"caption"} className={"helperText helperText--error"}>
                        {error}
                    </Typography>
                )}
            </div>
        </div>
    );
}
NumericFieldWithUnit.defaultProps = {
    sigFigs: 3,
    showUnits: true,
    updateMode: "valueChange",
    highFieldTitle: "Max",
    lowFieldTitle: "Min",
};
