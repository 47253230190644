import { OptionsObject, useSnackbar as notistackUseSnackbar } from "notistack";

import { SnackbarDismissButton } from "./SnackbarDismissButton";

interface EnqueueSnackbarProps extends OptionsObject {
    autoHideDuration?: number; // to prevent auto-hide use persist prop instead of null
}

export function useSnackbar() {
    const { enqueueSnackbar, closeSnackbar } = notistackUseSnackbar();

    const enqueueCustomSnackbar = (snackbarText: string, options?: EnqueueSnackbarProps) => {
        const { variant = "default", action, autoHideDuration, persist, ...rest } = options || {};
        const shouldPersist = persist ?? variant === "error"; // By default, variant = 'error' does not auto-dismiss

        // Show action if provided, otherwise if persist show dismiss button, else no action
        const dismissButton = shouldPersist ? SnackbarDismissButton : undefined;
        const snackbarAction = action ?? dismissButton;

        enqueueSnackbar(snackbarText, {
            autoHideDuration: autoHideDuration,
            variant: variant,
            action: snackbarAction,
            persist: shouldPersist,
            ...rest,
        });
    };

    return {
        enqueueSnackbar: enqueueCustomSnackbar,
        closeSnackbar,
    };
}

// HOC to support legacy class components
export function withSnackbar(Component: React.ComponentType) {
    return function ComponentWithSnackbar(props: any) {
        const { enqueueSnackbar, closeSnackbar } = useSnackbar();
        return <Component {...props} enqueueSnackbar={enqueueSnackbar} closeSnackbar={closeSnackbar} />;
    };
}
