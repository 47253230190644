/*
 * This is a document meant to contain any palette variables for things like primary colours or whatever.
 * */
import { green, grey, lightGreen, yellow } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const LogoColour = "#82c926";

/**
 * Primary or main theme colour
 * @type {{light: string, dark: string, main: string, text: string}}
 */
export const Primary = {
    main: lightGreen[500],
    dark: "#5a9216",
    light: "#bef67a",
    text: "#333333",
};
/**
 * Secondary or accent colour
 * @type {{light: string, dark: string, main: string, text: string}}
 */
export const Secondary = {
    main: "#26c6da",
    dark: "#0095a8",
    light: "#6ff9ff",
    text: "#000000",
};
export const ButtonStateColours = {
    Warning: {
        main: yellow["800"],
        dark: "#c17900",
        light: "#ffd95a",
        text: "#000000",
    },
    Error: {
        main: "#b00020",
        dark: "#790000",
        light: "#e94948",
        text: "#FFFFFF",
    },
    Engaged: {
        main: green[700],
        dark: "#00600f",
        light: "#6abf69",
        text: "#000000",
    },

    StandBy: Primary,
    Sleep: {
        main: lightGreen[300],
        dark: "#7da453",
        light: "#e1ffb1",
        text: "#000000",
    },
    Disabled: {
        main: grey[400],
        dark: "#8d8d8d",
        light: "#efefef",
        text: "#000000",
    },
    Off: {
        main: grey[700],
        dark: "#373737",
        light: "#8e8e8e",
        text: "#FFFFFF",
    },
    Unknown: {
        main: grey[400],
        dark: "#8d8d8d",
        light: "#efefef",
        text: "#000000",
    },
};
// ButtonStateColours.Unknown = ButtonStateColours.Unknown;
// ButtonStateColours.Warning = ButtonStateColours.Warning;
// ButtonStateColours.Error = ButtonStateColours.Error;
// ButtonStateColours.Engaged = ButtonStateColours.Engaged;
// ButtonStateColours.StandBy = ButtonStateColours.StandBy;
// ButtonStateColours.Sleep = ButtonStateColours.Sleep;
// ButtonStateColours.Disabled = ButtonStateColours.Disabled;
// ButtonStateColours.Off = ButtonStateColours.Off;

const theme = createTheme({
    palette: {
        primary: Primary,
        secondary: Secondary,
        warning: ButtonStateColours.Warning,
        info: {
            main: "#e0e0e0",
            dark: "#8d8d8d",
            light: "#efefef",
            text: "#000000",
        },
        dark: {
            main: "#373737",
            dark: "#1c1c1c",
            light: "#8d8d8d",
            text: "#ffffff",
        },
        setIrrigation: {
            main: "#ad4ac3",
            dark: "#761b8f",
            light: "#c679dc",
            text: "#ffffff",
        },
    },
    typography: {
        h1: {
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontWeight: 300,
            fontSize: "2.8rem",
            lineHeight: 1.167,
            letterSpacing: "-0.015em",
        },
        h2: {
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontWeight: 300,
            fontSize: "1.7rem",
            lineHeight: 1.1,
            letterSpacing: "0.0015em",
        },
        h3: {
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontWeight: 500,
            fontSize: "1.5rem",
            lineHeight: 1.15,
            letterSpacing: "0.0015em",
        },
        h4: {
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.2,
            letterSpacing: "0.0075em",
        },
        h5: {
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontWeight: 400,
            fontSize: "1.25rem",
            lineHeight: 1.2,
            letterSpacing: "0.0075em",
        },
        h6: {
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontWeight: 600,
            fontSize: "1rem",
            lineHeight: 1.2,
            opacity: 0.9,
            letterSpacing: "0.0075em",
        },
        overline: {
            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            fontWeight: 800,
            fontSize: "0.8rem",
            opacity: 0.95,
            letterSpacing: "0.11em",
        },
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: "0.7rem",
                    paddingRight: "0.7rem",
                },
            },
        },
    },
});

export const MUItheme = createTheme(theme, {
    components: {
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: theme.palette.common.white,
                    },
                    "&.MuiPickersDay-today": {
                        border: `${theme.palette.primary.main} 2px solid`,
                    },
                },
            },
        },
    },
});
