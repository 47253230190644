import "./FiniteStateGraph.scss";

import { BufferedAction, GenerateUID } from "gardenspadejs/dist/general";
import React, { useEffect, useMemo, useState } from "react";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { PacketEncodings } from "verditypes";

import { FiniteStateGraphContext, FiniteStateGraphContextClass } from "./FiniteStateGraphContext";
import { FSGBasicTooltip } from "./FSGTooltips";

/**
 *
 * @param {({date: Date} & Record<string, string>)[]} dataPoints
 * @param {Date} startTime
 * @param {Date} endTime
 * @param cursorComponent
 * @param {string[]} relevantKeys
 */
export function FiniteStateGraphBase({
    startTime,
    endTime,
    TooltipComponent,
    className,
    style,
    children,
    showTooltip = true,
}) {
    const fsgContext = React.useContext(FiniteStateGraphContext);
    if (!startTime) {
        startTime = fsgContext.startTime;
    }
    if (!endTime) {
        endTime = fsgContext.endTime;
    }

    const uid = useMemo(() => GenerateUID("FiniteStateGraph"), []);

    const [getOwnElement, setOwnElement] = useState(undefined);
    useEffect(() => {
        setOwnElement(document.getElementById(uid));
    }, []);
    const [getLastPointerMove, setLastPointerMove] = useState(-1);
    const [getXPos, setXPos] = useState(-1);
    const [getTooltipActive, setTooltipActive] = useState(false);
    const [getWidth, setWidth] = useState(1);
    const TooltipElement = TooltipComponent || FSGBasicTooltip;
    const timeOfTooltip = new Date(
        startTime.valueOf() + (getXPos / getWidth) * (endTime.valueOf() - startTime.valueOf()),
    );
    const body = (
        <div
            key={uid}
            id={uid}
            className={`FiniteStateGraphRoot ${className || ""}`}
            style={style || null}
            onPointerMove={(e) => {
                if (Math.abs(getLastPointerMove - Date.now()) > 30) {
                    if (e.clientX) {
                        if (!getOwnElement) {
                            setOwnElement(document.getElementById(uid));
                            return;
                        }
                        const boundingRect = getOwnElement.getBoundingClientRect();
                        setWidth(boundingRect.width);
                        setXPos(e.clientX - boundingRect.x);

                        setLastPointerMove(Date.now());
                    }
                }
                setTooltipActive(true);
            }}
            onPointerLeave={() => {
                setTimeout(() => {
                    setTooltipActive(false);
                }, 3);
                setTooltipActive(false);
            }}
        >
            {showTooltip && (
                <TooltipElement
                    xPosition={getXPos}
                    time={timeOfTooltip}
                    showTooltip={getTooltipActive && showTooltip}
                    percentageXPosition={getXPos / getWidth}
                />
            )}
            <div className={"FSGChunkWrapper"}>{children}</div>
        </div>
    );

    if (!fsgContext) {
        const context = React.useMemo(() => new FiniteStateGraphContextClass(), []);
        context.startTime = startTime;
        context.endTime = endTime;
        return <FiniteStateGraphContext.Provider value={context}>{body}</FiniteStateGraphContext.Provider>;
    }
    // fsgContext.startTime = startTime;
    // fsgContext.endTime = endTime;
    return body;
}
export function RenderChunks({ chunks, st, et, enlargementFactor = 1 }) {
    return (
        <>
            {chunks.map((chunk) => {
                let className = " FiniteStateGraph__Chunk ";
                chunk.chunkKey.split(" ").forEach((chunkKey) => {
                    className += ` FiniteStateGraph__Chunk--${chunkKey} `;
                    className += ` FiniteStateGraph__Chunk--${chunkKey}_${chunk.chunkValue} `;
                });
                let percentageWidth = chunk.duration / (et - st);
                if (enlargementFactor) {
                    let newTotal = 0;
                    if (percentageWidth > 0) {
                        newTotal += Math.min(percentageWidth, 0.0005) * 10;
                        percentageWidth -= 0.0005;
                    }
                    if (percentageWidth > 0) {
                        newTotal += Math.min(percentageWidth, 0.005) * 3;
                        percentageWidth -= 0.005;
                    }
                    if (percentageWidth > 0) {
                        newTotal += Math.min(percentageWidth, 0.01) * 1.5;
                        percentageWidth -= 0.01;
                    }
                    if (percentageWidth > 0) {
                        newTotal += Math.min(percentageWidth, 0.05) * 1.2;
                        percentageWidth -= 0.05;
                    }
                    if (percentageWidth > 0) {
                        newTotal += Math.min(percentageWidth, 0.1) * 1;
                        percentageWidth -= 0.1;
                    }
                    if (percentageWidth > 0) {
                        newTotal += percentageWidth * 1;
                        percentageWidth -= percentageWidth;
                    }
                    // if(percentageWidth < 0.005){
                    //     percentageWidth = percentageWidth * 10
                    // }
                    // if(percentageWidth < 0.01){
                    //     percentageWidth = (percentageWidth * 10
                    // }
                    // else if(percentageWidth < 0.05){
                    //     percentageWidth = (percentageWidth - 0.01) * 3 + 0.01 * 10;
                    // }
                    // else if(percentageWidth < 0.1){
                    //     percentageWidth = (percentageWidth - 0.01 - 0.05) * 1.2 + 0.01 * 10 + .04 * 3;
                    // }
                    // else{
                    //     percentageWidth = (percentageWidth - 0.01 - 0.05 - 0.1) * 1.5 + 0.01 * 10 + .04 * 3 + .05 * 1.2
                    // }
                    percentageWidth = Math.max(
                        newTotal,
                        // Math.log10(percentageWidth * 85 + 1) * .16 ,
                        // Math.log2(percentageWidth * 157 + 1) * .023 ,
                        percentageWidth, // * enlargementFactor,
                    ); // Math.log2(percentageWidth + 1) * enlargementFactor
                }
                chunk.percentageStart = (chunk.startTime - st) / (et - st);
                chunk.percentageWidth = percentageWidth;
                chunk.percentageEnd = chunk.percentageStart + percentageWidth;
                return (
                    <div
                        key={`${chunk.percentageStart}_${chunk.chunkKey}`}
                        className={className}
                        style={{
                            left: `${chunk.percentageStart * 100}%`,
                            width: `${chunk.percentageWidth * 100}%`,
                        }}
                        title={chunk.title}
                    />
                );
            })}
        </>
    );
}
export function FSGElementBase({
    dataSources,
    dataTypes,
    processDataFunction,
    startTime,
    endTime,
    chunkName,
    relKeys,
    chunkFilter,
    enlargementFactor = 1,
}) {
    const [getLoadedChunks, setLoadedChunks] = React.useState([]);

    const uid = React.useMemo(() => GenerateUID("FSGElement"), []);

    const fsgContext = React.useContext(FiniteStateGraphContext);
    React.useEffect(
        () => () => {
            delete fsgContext.chunksByFSGElementID[uid];
        },
        [],
    );

    const historicalDatabase = React.useMemo(
        () => new HistoricalDataBase(dataSources, dataTypes, 2000, { worryAboutPosition: false }),
        [dataSources?.toString()],
    );
    const bufferredDatabaseUpdate = React.useMemo(
        () =>
            new BufferedAction(
                async () => {
                    const originalAmountOfData = historicalDatabase?.data?.value?.length || 0;
                    await historicalDatabase.getData(
                        startTime.valueOf() - 1000 * 60 * 60 * 24 * 6,
                        endTime.valueOf() + 1000 * 60 * 60 * 24 * 6,
                    );
                    if (
                        historicalDatabase.data.value &&
                        historicalDatabase.data.value.length !== originalAmountOfData &&
                        historicalDatabase.data.value.length > 0
                    ) {
                        const parsedData = processDataFunction(historicalDatabase);
                        let chunks = breakIntoChunks(parsedData, [chunkName, ...(relKeys || [])], startTime, endTime);
                        if (chunkFilter) {
                            chunks = chunkFilter(chunks);
                        }
                        setLoadedChunks(chunks);
                        fsgContext.chunksByFSGElementID[uid] = chunks;
                    }
                },
                300,
                true,
                false,
            ),
        [historicalDatabase],
    );

    useEffect(() => {
        bufferredDatabaseUpdate.trigger();
    }, [startTime.valueOf(), endTime.valueOf(), dataSources?.toString()]);

    return (
        <RenderChunks
            chunks={getLoadedChunks}
            st={startTime.valueOf()}
            et={endTime.valueOf()}
            enlargementFactor={enlargementFactor}
        />
    );
}

export function FSGScheduledValve({
    startTime,
    endTime,
    relZones,
    chunkName = "ScheduledValve",
    enlargementFactor = 1,
}) {
    return (
        <FSGElementBase
            dataTypes={["zoneIrrigationScheduled"]}
            dataSources={relZones}
            startTime={startTime}
            endTime={endTime}
            chunkName={chunkName}
            enlargementFactor={enlargementFactor}
            processDataFunction={(database) => {
                const data = [...database.data.value];
                const cursor = { ...data[0].data };
                let date = data[0].date;
                const cursors = [];
                const parsedData = [];
                for (let i = 0; i < data.length; i++) {
                    Object.assign(cursor, data[i].data);
                    date = data[i].date;
                    const maxValue = Math.max(
                        0,
                        0,
                        ...Object.values(cursor).filter(
                            (v) => typeof v === "number" && !Number.isNaN(v) && v !== null && v !== undefined,
                        ),
                    );
                    const parsedDataPoint = {
                        date: new Date(date.valueOf()),
                    };
                    cursors.push({ ...cursor });
                    // if the max valuye is less than 0.2 it's closed, greater than 0.7 and it's open. Between
                    // the two and it's unknown
                    // eslint-disable-next-line no-nested-ternary
                    parsedDataPoint[chunkName] = scheduledValveStateRawDataToString(maxValue);
                    parsedData.push(parsedDataPoint);
                }
                return parsedData;
            }}
        />
    );
    // return <RenderChunks chunks={getLoadedChunks} st={startTime.valueOf()} et={endTime.valueOf()}/>
    // renderChunks(getLoadedChunks, startTime.valueOf(), endTime.valueOf());
}

/**
 * Chunk values will be either "open" and "closed" or "open", "closed", "open-manual", "closed-manual"
 * @param startTime
 * @param endTime
 * @param deviceEUI
 * @param valveIndex
 * @param distinguishManualMode
 * @param chunkName
 * @return {*}
 * @constructor
 */
export function FSGActualValve({
    startTime,
    endTime,
    deviceEUI,
    valveIndex,
    distinguishManualMode,
    chunkName,
    enlargementFactor = 1,
}) {
    return (
        <FSGElementBase
            dataTypes={["valveState"]}
            dataSources={[deviceEUI]}
            startTime={startTime}
            endTime={endTime}
            enlargementFactor={enlargementFactor}
            chunkName={chunkName}
            processDataFunction={(database) => {
                const data = [...database.data.value];

                let date = data[0].date;
                const parsedData = [];
                for (let i = 0; i < data.length; i++) {
                    date = new Date(data[i].date?.valueOf() || 0);
                    const dpClone = { ...data[i].data };
                    delete dpClone.date;
                    const valveState = Object.values(dpClone)[0];
                    if (valveState !== undefined) {
                        const parsedValveState = PacketEncodings.ValveState.decode(valveState);
                        let vStateString = parsedValveState.open[valveIndex] ? "open" : "closed";
                        if (distinguishManualMode && parsedValveState.manualOverrideEngaged) {
                            vStateString = `${vStateString}-manual`;
                        }
                        const parsedDataPoint = {
                            date: new Date(date.valueOf()),
                        };
                        parsedDataPoint[chunkName] = vStateString;
                        parsedData.push(parsedDataPoint);
                    }
                }
                return parsedData;
            }}
        />
    );
}

/**
 * Chunk values will be either "open" and "closed" or "open", "closed", "open-manual", "open-closed"
 * @param startTime
 * @param endTime
 * @param deviceEUI
 * @param valveIndex
 * @param distinguishManualMode
 * @param chunkName
 * @return {*}
 * @constructor
 */
export function FSGZoneOpenState({ startTime, endTime, zoneID, chunkName, enlargementFactor = 1 }) {
    return (
        <FSGElementBase
            dataTypes={["valveOpenPercent"]}
            dataSources={[zoneID]}
            startTime={startTime}
            endTime={endTime}
            enlargementFactor={enlargementFactor}
            chunkName={chunkName}
            processDataFunction={(database) => {
                const data = [...database.data.value];

                let date = data[0].date;
                const parsedData = [];
                for (let i = 0; i < data.length; i++) {
                    date = new Date(data[i].date?.valueOf() || 0);
                    const dpClone = { ...data[i].data };
                    delete dpClone.date;
                    const valveOpenPercentage = Object.values(dpClone)[0];
                    if (valveOpenPercentage !== undefined) {
                        let stateString = "none";
                        if (valveOpenPercentage > 0.98) {
                            stateString = "openFull";
                        } else if (valveOpenPercentage > 0.8) {
                            stateString = "mostlyOpen";
                        } else if (valveOpenPercentage > 0.4) {
                            stateString = "partiallyOpen";
                        } else if (valveOpenPercentage > 0.1) {
                            stateString = "slightlyOpen";
                        } else {
                            stateString = "closed";
                        }
                        // let parsedValveState = PacketEncodings.ValveState.decode(valveState)
                        // let vStateString = parsedValveState.open[valveIndex] ? "open" : "closed";
                        // if(distinguishManualMode && parsedValveState.manualOverrideEngaged){
                        //     vStateString = vStateString + "-manual"
                        // }
                        const parsedDataPoint = {
                            date: new Date(date.valueOf()),
                        };
                        parsedDataPoint[chunkName] = stateString;
                        parsedData.push(parsedDataPoint);
                    }
                }
                return parsedData;
            }}
        />
    );
}

/**
 * Chunk values will be either "open" and "closed" or "open", "closed", "open-manual", "open-closed"
 * @param startTime
 * @param endTime
 * @param deviceEUI
 * @param valveIndex
 * @param distinguishManualMode
 * @param chunkName
 * @return {*}
 * @constructor
 */
export function FSGOnlineState({ startTime, endTime, sourceID, chunkName = "ZoneOnlineState", enlargementFactor = 1 }) {
    return (
        <FSGElementBase
            dataTypes={["isOnline"]}
            dataSources={[sourceID]}
            startTime={startTime}
            endTime={endTime}
            enlargementFactor={enlargementFactor}
            chunkName={chunkName}
            processDataFunction={(database) => {
                const data = [...database.data.value];

                let date = data[0].date;
                const parsedData = [];
                for (let i = 0; i < data.length; i++) {
                    date = new Date(data[i].date?.valueOf() || 0);
                    const dpClone = { ...data[i].data };
                    delete dpClone.date;
                    const isOnlineState = Object.values(dpClone)[0];
                    if (isOnlineState !== undefined) {
                        let stateString = "none";
                        let title;
                        if (isOnlineState > 0.9) {
                            stateString = "online";
                            title = "Online";
                        } else if (isOnlineState > 0.5) {
                            stateString = "partiallyOffline";
                            title = "Partially Offline";
                        } else if (isOnlineState > -0.1) {
                            stateString = "offline";
                            title = "Offline";
                        } else {
                            stateString = "unknown";
                        }
                        // let parsedValveState = PacketEncodings.ValveState.decode(valveState)
                        // let vStateString = parsedValveState.open[valveIndex] ? "open" : "closed";
                        // if(distinguishManualMode && parsedValveState.manualOverrideEngaged){
                        //     vStateString = vStateString + "-manual"
                        // }
                        const parsedDataPoint = {
                            date: new Date(date.valueOf()),
                        };
                        parsedDataPoint[chunkName] = stateString;
                        parsedDataPoint.title = title;
                        parsedData.push(parsedDataPoint);
                    }
                }
                return parsedData;
            }}
        />
    );
}

/**
 * Chunk values will be either "open" and "closed" or "open", "closed", "open-manual", "open-closed"
 * @param startTime
 * @param endTime
 * @param deviceEUI
 * @param valveIndex
 * @param distinguishManualMode
 * @param chunkName
 * @return {*}
 * @constructor
 */
export function FSGActualScheduledAndSuccessValveState({
    startTime,
    endTime,
    deviceEUI,
    relZones,
    valveIndex,
    distinguishManualMode,
    chunkName,
    className,
    enlargementFactor = 1,
}) {
    const valveStateChunkName = "ActualValve";
    const scheduledIrrigationChunkName = "ScheduledValve";
    const correctStateChunkName = "CorrectState";
    return (
        <div className={` FSGActualScheduledAndSuccessValveState ${className || ""}`}>
            <FSGElementBase
                dataTypes={["valveState", "zoneIrrigationScheduled"]}
                dataSources={[deviceEUI, ...(relZones || [])]}
                startTime={startTime}
                endTime={endTime}
                enlargementFactor={enlargementFactor}
                chunkName={chunkName}
                relKeys={[valveStateChunkName, scheduledIrrigationChunkName, correctStateChunkName]}
                processDataFunction={(database) => {
                    const data = [...database.data.value];
                    const cursor = { ...data[0].data }; // rename explicit
                    let date = data[0].date;
                    const parsedData = [];

                    for (let i = 0; i < data.length; i++) {
                        Object.assign(cursor, data[i].data);
                        date = new Date(data[i].date?.valueOf() || 0);

                        const valveStateKey = Object.keys(data[i].data).filter((k) => k.includes("valveState"))[0];

                        const parsedDataPoint = {
                            date: new Date(date.valueOf()),
                        };

                        if (valveStateKey !== undefined) {
                            const valveState = cursor[valveStateKey];

                            const parsedValveState = PacketEncodings.ValveState.decode(valveState);
                            cursor.valveState = parsedValveState;

                            let vStateString = parsedValveState.open[valveIndex] ? "open" : "closed";

                            if (distinguishManualMode && parsedValveState.manualOverrideEngaged) {
                                vStateString = `${vStateString}-manual`;
                            }

                            parsedDataPoint[valveStateChunkName] = vStateString;
                        }
                        // one leading zero to avoid negative infinity
                        const irrigationScheduledValue = Math.max(
                            0,
                            // go through all of the irriationScheduled data values and take the maximum, so if any zone is irrigating it will return 1
                            // 1: irrigation scheduled, 0: no irrigation scheduled, 0.5 is irrigation scheduled/unscheduled at the last minute, unknown intended state
                            ...Object.keys(cursor)
                                .filter((k) => k.includes("zoneIrrigationScheduled"))
                                .map((k) => cursor[k]),
                        );
                        // functionalize
                        parsedDataPoint[scheduledIrrigationChunkName] =
                            scheduledValveStateRawDataToString(irrigationScheduledValue);

                        if (cursor.valveState) {
                            const correctState =
                                cursor.valveState.manualOverrideEngaged ||
                                parsedDataPoint[scheduledIrrigationChunkName] === "unknown" ||
                                (cursor.valveState.open[0] &&
                                    parsedDataPoint[scheduledIrrigationChunkName] === "open") ||
                                (!cursor.valveState.open[0] &&
                                    parsedDataPoint[scheduledIrrigationChunkName] === "closed");
                            parsedDataPoint[correctStateChunkName] = correctState ? "correct" : "incorrect";
                        }

                        parsedData.push(parsedDataPoint);
                    }
                    return parsedData;
                }}
                chunkFilter={(chunks) => {
                    chunks.forEach((chunk) => {
                        if (
                            chunk.chunkKey === correctStateChunkName &&
                            chunk.chunkValue === "incorrect" &&
                            chunk.duration < 1000 * 60 * 1
                        ) {
                            chunk.chunkValue = "slightLate";
                        } else if (
                            chunk.chunkKey === correctStateChunkName &&
                            chunk.chunkValue === "incorrect" &&
                            chunk.duration < 1000 * 60 * 5
                        ) {
                            chunk.chunkValue = "innaccurate";
                        }
                    });
                    return chunks;
                }}
            />
        </div>
    );
}

/**
 *
 * @param dataPoints
 * @param relevantKeys
 * @param startTime
 * @param endTime
 * @return {{
 *      chunkKey: string,
        chunkValue: string,
        startTime: number,
        endTime: number,
        duration:  number
 * }[]}
 */
function breakIntoChunks(dataPoints, relevantKeys, startTime, endTime) {
    if (dataPoints.length === 0) {
        return [];
    }

    // initialize to the start of relevant time
    const cursor = { ...dataPoints[0] };
    const lastValueChangeByKey = {};

    let i = 0;
    for (; i < dataPoints.length - 1 && dataPoints[i + 1]?.date?.valueOf() < startTime; i++) {
        const datapointIndex = i;
        relevantKeys.forEach((relKey) => {
            if (cursor[relKey] && cursor[relKey] !== dataPoints[datapointIndex][relKey]) {
                lastValueChangeByKey[relKey] = new Date(dataPoints[datapointIndex].date.valueOf());
            }
        });
        Object.assign(cursor, dataPoints[i]);
    }

    // begin generating chunks
    const chunks = [];
    for (; i < dataPoints.length - 1 && dataPoints[i + 1]?.date?.valueOf() < endTime.valueOf(); i++) {
        const datapointIndex = i;
        relevantKeys.forEach((relKey) => {
            if (cursor[relKey] && cursor[relKey] !== dataPoints[datapointIndex][relKey]) {
                if (lastValueChangeByKey[relKey]) {
                    chunks.push({
                        chunkKey: relKey,
                        chunkValue: cursor[relKey],
                        title: dataPoints[datapointIndex - 1]?.title,
                        startTime: lastValueChangeByKey[relKey].valueOf(),
                        endTime: dataPoints[datapointIndex].date.valueOf(),
                        duration: dataPoints[datapointIndex].date.valueOf() - lastValueChangeByKey[relKey].valueOf(),
                    });
                }
                lastValueChangeByKey[relKey] = new Date(dataPoints[datapointIndex].date.valueOf());
            }
        });
        Object.assign(cursor, dataPoints[datapointIndex]);
    }

    // resolve all chunks
    for (; i < dataPoints.length && Object.keys(lastValueChangeByKey).length > 0; i++) {
        const datapointIndex = i;
        relevantKeys.forEach((relKey) => {
            if (cursor[relKey] && cursor[relKey] !== dataPoints[datapointIndex][relKey]) {
                if (lastValueChangeByKey[relKey]) {
                    chunks.push({
                        chunkKey: relKey,
                        chunkValue: cursor[relKey],
                        title: dataPoints[datapointIndex - 1]?.title,
                        startTime: lastValueChangeByKey[relKey].valueOf(),
                        endTime: dataPoints[datapointIndex].date.valueOf(),
                        duration: dataPoints[datapointIndex].date.valueOf() - lastValueChangeByKey[relKey].valueOf(),
                    });
                    delete lastValueChangeByKey[relKey];
                }
            }
        });
        Object.assign(cursor, dataPoints[datapointIndex]);
    }
    // clean up any chunks that are persistant to end of data
    Object.keys(lastValueChangeByKey).forEach((relKey) => {
        const chunkEndTime = Math.max(cursor.date.valueOf(), endTime.valueOf());
        chunks.push({
            chunkKey: relKey,
            chunkValue: cursor[relKey],
            startTime: lastValueChangeByKey[relKey].valueOf(),
            title: dataPoints[i - 1]?.title,
            chunkEndTime,
            duration: chunkEndTime - lastValueChangeByKey[relKey].valueOf(),
        });
    });
    return chunks;
}
// Actual valve sounds like actual valve not like verdi device intended state.
// historical verification
// attatch live more firmly to it's tab

/**
 *
 * turns a raw number between 0 and 1 into a string describing scheduled valve state.
 * 0.0 - 0.2 : closed
 * 0.2 - 0.7 : unknown
 * 0.7 - 1.0 : open
 * @param rawNumber
 * @return {"closed" | "unknown" | "open}
 */
export function scheduledValveStateRawDataToString(rawNumber) {
    // if the max valuye is less than 0.2 it's closed, greater than 0.7 and it's open. Between
    // the two and it's unknown
    // eslint-disable-next-line no-nested-ternary
    return rawNumber < 0.2 ? "closed" : rawNumber < 0.7 ? "unknown" : "open";
}
