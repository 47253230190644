import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

import { AdminCreationWidet } from "./components/AdminCreationWidget";
import { AdminUserTable } from "./components/AdminUserTable";
import { DeleteUserWidget } from "./components/DeleteUserWidget";
import { ResetPasswordWidget } from "./components/ResetPasswordWidget";
import { SetPasswordExpirationWidget } from "./components/SetPasswordExpirationWidget";
import { useAdminManager } from "./hooks/useAdminManager";
import data from "./mockAdminData.json";

export function AdminUserManager() {
    const isSuperAdmin = false; // TODO  add real superadmin check when backend is ready

    const { allAdmins, fetchAllAdmins, currentUser } = useAdminManager();
    return (
        <Box sx={{ p: 6, overflowY: "auto" }} component={"main"} width={"100vw"} height={"100vh"}>
            <Stack mt={2} mb={8} gap={12} direction={{ xs: "column", md: "row" }}>
                <AdminCreationWidet fetchAllAdmins={fetchAllAdmins} />
                <ResetPasswordWidget
                    ownAdminUsername={currentUser?.adminUsername ?? "Your Admin Account"}
                    title={"Reset my password"}
                    bodyText={
                        "Your current password will be invalidated and you will be assigned a new password in the following modal popup."
                    }
                />
            </Stack>
            <Box mb={12}>
                <AdminUserTable data={allAdmins} />
            </Box>
            {isSuperAdmin && (
                <>
                    <Divider sx={{ marginTop: 2, marginBottom: 8 }} />
                    <Stack mt={5} mb={8} gap={12}>
                        <Stack>
                            <Typography variant={"h2"}>SuperAdmin actions</Typography>
                            <Typography variant={"body1"}>
                                If you can see this, it means your account has SuperAdmin privileges. In addition to
                                having access to the tools of normal admins, you may also use the tools below to perform
                                operations on other admin's accounts. However, you may not affect the accounts of other
                                SuperAdmins. Changes to another SuperAdmin account require a manual DB change by a
                                privileged member of the team, such as the CEO or CTO. Please contact them directly for
                                such requests.
                            </Typography>
                        </Stack>
                        <Grid container spacing={8}>
                            <Grid item md={6} lg={4}>
                                <ResetPasswordWidget
                                    showUserSelect
                                    allAdmins={data.map((admin) => admin.username)}
                                    title={"Reset an admin's password"}
                                    bodyText={
                                        "This user's current password will be invalidated and will be assigned a new password in the following modal popup."
                                    }
                                />
                            </Grid>
                            <Grid item md={6} lg={4}>
                                <SetPasswordExpirationWidget allAdmins={data.map((admin) => admin.username)} />
                            </Grid>
                            <Grid item md={6} lg={4}>
                                <DeleteUserWidget allAdmins={data.map((admin) => admin.username)} />
                            </Grid>
                        </Grid>
                    </Stack>
                </>
            )}
        </Box>
    );
}
