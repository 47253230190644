import "./SensorSettings.css";
import "./SensorSettingsBase.scss";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LinkIcon from "@mui/icons-material/Link";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { EventHandler, MasterIndex, SessionHandler } from "verdiapi";

import GlobalOptions from "../../../../../utils/GlobalOptions";
import { NumericFieldWithUnit } from "../../../../generic/NumericFieldWithUnit/NumericFieldWithUnit";
import { LengthUnitTextField } from "../../../DeprecatedInputs/LengthUnitTextField";
import { ValveCloseDroplet, ValveOpenDroplet } from "../../../SproutVerification/VerificationIcons";

const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize={"small"} />;
const checkedIcon = <CheckBoxIcon fontSize={"small"} />;
export default class SensorSettingsBase extends React.Component {
    acceptableRangeUnitCategory = "pressure";

    acceptableValueRangeResetEvent = new EventHandler();

    changes = {};

    defaultAcceptableValueRange = [0, 100];

    defaultValues = {
        acceptableRangeByValveState: [[0, 100], [], [], [0, 100]],
    };

    sensorIndex = 0;

    title = "Sensor Settings:";

    valuesFromDevice = {};

    constructor(props) {
        super(props);
        if (this.props.device) {
            this.sensorIndex = this.props.device.connectedSensors.indexOf(this.props.sensor);
        }
        this.valuesFromDevice = this.loadSettingsFromDevice(this.props.device || undefined, this.props.sensor);
    }

    componentDidMount() {
        this.valuesFromDevice = this.loadSettingsFromDevice(this.props.device || undefined, this.props.sensor);
        this.setState({});
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.sensor !== this.props.sensor) {
            this.valuesFromDevice = this.loadSettingsFromDevice(this.props.device || undefined, this.props.sensor);
        }
        if (nextProps.open !== this.props.open) {
            this.valuesFromDevice = this.loadSettingsFromDevice(this.props.device || undefined, this.props.sensor);
        }
        return true;
    }

    componentWillUnmount() {
        this.defaultValues = {};
        this.changes = {};
    }

    handleClose(save) {
        // save actually happens in microvalve status object
        if (save) {
            const values = this.getValues();
            this.writeSettingsToDevice(values, undefined, this.props.sensor);
        }
        this.valuesFromDevice = this.loadSettingsFromDevice(this.props.device || undefined, this.props.sensor);
        this.changes = {};
        this.acceptableValueRangeResetEvent.trigger({
            min: Array.isArray(this.valuesFromDevice.acceptableValueRange)
                ? this.valuesFromDevice.acceptableValueRange[0]
                : this.defaultAcceptableValueRange[0],
            max: Array.isArray(this.valuesFromDevice.acceptableValueRange)
                ? this.valuesFromDevice.acceptableValueRange[1]
                : this.defaultAcceptableValueRange[1],
        });
        if (this.props.onClose) {
            this.props.onClose({}, save);
        }
    }

    onChange(args) {
        this.changes = Object.assign(this.changes, args || {});
        this.setState({});
    }

    getVerificationRangeSettings(values) {
        const featureEnabled = values.useForVerification;
        const valveOffRange = values.acceptableRangeByValveState?.[0] || [0, 100];
        const valveOnRange = values.acceptableRangeByValveState?.[3] || [0, 100];

        return (
            <div className={"SettingsItem SettingsItemVerificationRange"}>
                <div className={"AcceptableVerificationRangeSwitch"}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={featureEnabled}
                                onChange={(e, v) => {
                                    if (v) {
                                        this.onChange({
                                            useForVerification: true,
                                        });
                                    } else {
                                        this.onChange({
                                            useForVerification: false,
                                        });
                                    }
                                }}
                            />
                        }
                        label={
                            <>
                                <Typography variant={"body1"} style={{ lineHeight: 1.0 }}>
                                    Use sensor for verification
                                </Typography>
                                <Typography variant={"caption"}>
                                    Checks this sensor is within specified values when verifying the device is operating
                                    correctly
                                </Typography>
                            </>
                        }
                    />
                    <NotificationsActiveIcon />
                </div>
                <div className={"AcceptableVerificationRangeSettings"}>
                    <NumericFieldWithUnit
                        sensorType={this.props.sensor?.sensorType}
                        isRange={true}
                        value={valveOffRange}
                        label={
                            <div className={"ValveStateIdentifier"}>
                                <ValveCloseDroplet />
                                <Typography variant={"body2"} className={"StackedText"} component={"div"}>
                                    {" "}
                                    Valve <br /> Close
                                </Typography>
                            </div>
                        }
                        onChange={(e) => {
                            values.acceptableRangeByValveState[0b00] = e.value;
                            this.onChange({ acceptableRangeByValveState: values.acceptableRangeByValveState });
                        }}
                    />
                    {valveOffRange[0] > valveOffRange[1] && (
                        <Typography variant={"caption"} className={"AcceptableValueRangeError"}>
                            Error, Maximum should be larger than minimum!
                        </Typography>
                    )}
                    <NumericFieldWithUnit
                        sensorType={this.props.sensor?.sensorType}
                        isRange={true}
                        value={valveOnRange || [0, 100]}
                        label={
                            <div className={"ValveStateIdentifier"}>
                                <ValveOpenDroplet />
                                <Typography variant={"body2"} className={"StackedText"} component={"div"}>
                                    {" "}
                                    Valve <br /> Open
                                </Typography>
                            </div>
                        }
                        onChange={(e) => {
                            values.acceptableRangeByValveState[0b11] = e.value;
                            this.onChange({ acceptableRangeByValveState: values.acceptableRangeByValveState });
                        }}
                    />
                    {valveOnRange[0] > valveOnRange[1] && (
                        <Typography variant={"caption"} className={"AcceptableValueRangeError"}>
                            Error, Maximum should be larger than minimum!
                        </Typography>
                    )}
                </div>
                <Typography variant={"caption"}>
                    Note: This only alerts you to the failure and does <b>not</b> take any action to correct the problem
                </Typography>
            </div>
        );
    }

    getValues() {
        return {
            zones: [],
            ...this.defaultValues,
            ...this.valuesFromDevice,
            ...this.changes,
        };
    }

    getAcceptableRangeSettings(values) {
        const featureEnabled = Array.isArray(values.acceptableValueRange);
        const error = featureEnabled && values.acceptableValueRange[1] < values.acceptableValueRange[0];

        const highSensorReadingEnabled =
            (SessionHandler.currentUserObject?.SMSSettings?.perWarningSettings?.["high sensor reading"] || 0) > 1;
        const lowSensorReadingEnabled =
            (SessionHandler.currentUserObject?.SMSSettings?.perWarningSettings?.["low sensor reading"] || 0) > 1;
        return (
            <div className={"SettingsItem SettingsItemAcceptableRange"}>
                <div className={"SensorThresholdProtectionSwitch"}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={featureEnabled}
                                onChange={(e, v) => {
                                    if (v) {
                                        this.onChange({
                                            acceptableValueRange: [...this.defaultAcceptableValueRange],
                                        });
                                    } else {
                                        this.onChange({
                                            acceptableValueRange: null,
                                        });
                                    }

                                    this.acceptableValueRangeResetEvent.trigger({
                                        min: this.defaultAcceptableValueRange[0],
                                        max: this.defaultAcceptableValueRange[1],
                                    });
                                }}
                            />
                        }
                        label={
                            <>
                                <Typography variant={"body1"} style={{ lineHeight: 1.0 }}>
                                    Enable Sensor Threshold Protection
                                </Typography>
                                <Typography variant={"caption"}>
                                    Sends alert when the sensor falls outside of specified range
                                </Typography>
                            </>
                        }
                    />
                    <NotificationsActiveIcon />
                </div>
                <div className={"SensorThresholdProtectionSettings"}>
                    <LengthUnitTextField
                        label={"Minimum Acceptable Value"}
                        resetEvent={this.acceptableValueRangeResetEvent}
                        resetEventKey={"min"}
                        useCase={`SettingLimits--${this.acceptableRangeUnitCategory}`}
                        unitCategory={this.acceptableRangeUnitCategory}
                        disabled={!featureEnabled}
                        defaultValue={
                            featureEnabled ? values.acceptableValueRange[0] : this.defaultAcceptableValueRange[0]
                        }
                        onChange={(newValue) => {
                            if (featureEnabled) {
                                const acceptableRange = values.acceptableValueRange;
                                if (Array.isArray(acceptableRange)) {
                                    values.acceptableValueRange[0] = newValue;
                                }
                                this.onChange({
                                    acceptableValueRange: values.acceptableValueRange,
                                });
                            }
                        }}
                        error={error}
                    />
                    <LengthUnitTextField
                        label={"Maximum Acceptable Value"}
                        resetEvent={this.acceptableValueRangeResetEvent}
                        resetEventKey={"max"}
                        useCase={`SettingLimits--${this.acceptableRangeUnitCategory}`}
                        unitCategory={this.acceptableRangeUnitCategory}
                        disabled={!featureEnabled}
                        defaultValue={
                            featureEnabled ? values.acceptableValueRange[1] : this.defaultAcceptableValueRange[1]
                        }
                        onChange={(newValue) => {
                            if (featureEnabled) {
                                const acceptableRange = values.acceptableValueRange;
                                if (Array.isArray(acceptableRange)) {
                                    values.acceptableValueRange[1] = newValue;
                                }
                                this.onChange({
                                    acceptableValueRange: values.acceptableValueRange,
                                });
                            }
                        }}
                        error={error}
                    />
                </div>
                <Typography variant={"caption"}>
                    Note: This only sends alerts and does <b>not</b> take any action to correct the problem
                </Typography>
                {error && (
                    <Typography variant={"caption"} className={"AcceptableValueRangeError"}>
                        Error, Maximum should be larger than minimum!
                    </Typography>
                )}
                {featureEnabled && highSensorReadingEnabled && !lowSensorReadingEnabled && (
                    <Typography variant={"caption"} className={"AcceptableValueRangeError"}>
                        Warning: Low sensor reading alerts are disabled. Fix this in Settings to receive low sensor
                        reading alerts
                        <br />
                        Settings {">"} SMS Alert Settings{">"}Per-Alert Settings
                    </Typography>
                )}
                {featureEnabled && lowSensorReadingEnabled && !highSensorReadingEnabled && (
                    <Typography variant={"caption"} className={"AcceptableValueRangeError"}>
                        Warning: High sensor reading alerts are disabled. Fix this in Settings to receive high sensor
                        reading alerts
                        <br />
                        Settings{">"}SMS Alert Settings{">"}Per-Alert Settings
                    </Typography>
                )}
                {featureEnabled && !lowSensorReadingEnabled && !highSensorReadingEnabled && (
                    <Typography variant={"caption"} className={"AcceptableValueRangeError"}>
                        Warning: Sensor reading alerts are disabled in Settings. Enable them there if you wish to
                        receive alerts.
                        <br />
                        Settings{">"}SMS Alert Settings{">"}Per-Alert Settings
                    </Typography>
                )}
            </div>
        );
    }

    loadSettingsFromDevice(device, sensor) {
        const connectedZoneIDsList = sensor.connectedZoneIDs || ["default"];
        const settings = {
            zones: connectedZoneIDsList
                .map((zid) => {
                    const zone = MasterIndex.zone.byID[zid];
                    if (zone) {
                        return this.zoneToEntry(zone);
                    }
                    if (zid === "default") {
                        return this.zoneToEntry(zid);
                    }
                    return undefined;
                })
                .filter((e) => e !== undefined),
            acceptableValueRange: Array.isArray(sensor.acceptableValueRange) ? sensor.acceptableValueRange : null,
            useForVerification: sensor.useForVerification,
        };
        if (Array.isArray(sensor.acceptableRangeByValveState)) {
            settings.acceptableRangeByValveState = sensor.acceptableRangeByValveState;
        }
        return settings;
    }

    // eslint-disable-next-line class-methods-use-this
    writeSettingsToDevice(values, device, sensor) {
        sensor.connectedZoneIDs = values.zones.map((entry) => entry.id).filter((e) => e !== undefined);
        sensor.acceptableValueRange = values.acceptableValueRange;
        if (!sensor.acceptableValueRange) {
            sensor.acceptableValueRange = undefined;
        }
        sensor.useForVerification = values.useForVerification;
        sensor.acceptableRangeByValveState = values.acceptableRangeByValveState;
        if (Array.isArray(sensor.acceptableValueRange)) {
            if (sensor.acceptableValueRange[0] > sensor.acceptableValueRange[1]) {
                sensor.acceptableValueRange = [sensor.acceptableValueRange[1], sensor.acceptableValueRange[0]];
            }
        }
    }

    zoneToEntry(z) {
        let tag;
        if (z === "default") {
            let label = "default";
            try {
                if (this.props.device.connectedZoneIDs && Array.isArray(this.props.device.connectedZoneIDs)) {
                    if (this.props.device.blockValve) {
                        label = "default (all connected)";
                    } else if (this.props.device.singleValve) {
                        const connectedZone = MasterIndex.zone.byID[this.props.device.connectedZoneIDs[0]];
                        label = `default (${connectedZone.name})`;
                    } else if (this.props.device.doubleValve && this.sensorIndex !== -1) {
                        const connectedZone =
                            MasterIndex.zone.byID[this.props.device.connectedZoneIDs[this.sensorIndex]];
                        label = `default (${connectedZone.name})`;
                    }
                }
            } catch (e) {
                console.warn("couldn't generate name of default connected zone");
            }

            return {
                tag: "V",
                label: label,
                id: "default",
                default: true,
            };
        }

        if (this.props.device) {
            if (this.props.device.connectedZoneIDs && Array.isArray(this.props.device.connectedZoneIDs)) {
                const indexInConnectedZones = this.props.device.connectedZoneIDs.indexOf(z.id);
                if (indexInConnectedZones !== -1) {
                    if (this.props.device.doubleValve) {
                        tag = `V${indexInConnectedZones + 1}`;
                    } else if (this.props.device.blockValve || this.props.device.singleValve) {
                        tag = <LinkIcon fontSize={"small"} />;
                    }
                }
            }
        }
        return {
            tag: tag,
            label: z.name,
            id: z.id,
        };
    }

    // eslint-disable-next-line class-methods-use-this
    renderSupplementalSettings() {
        return <></>;
    }

    render() {
        const defaultZoneOptions = new Set(this.props.defaultZoneOptions || []);
        defaultZoneOptions.add("default");
        if (this.props.device && Array.isArray(this.props.device.zonesByValve)) {
            this.props.device.zonesByValve.forEach((z) => {
                if (z) {
                    defaultZoneOptions.add(z);
                }
            });
        }
        const mapFunction = (z) => this.zoneToEntry(z);
        let zoneOptions = MasterIndex.zone.all.filter((z) => !defaultZoneOptions.has(z)).map(mapFunction);
        zoneOptions = [
            ...Array.from(defaultZoneOptions)
                .map(mapFunction)
                .map((o) => {
                    o.group = "Recommended:";
                    return o;
                }),
            ...zoneOptions.map((o) => {
                o.group = "All:";
                return o;
            }),
        ];
        // set values based on changes, then device values, then default values.
        const values = this.getValues();
        return (
            <div>
                <Dialog
                    className={"SensorSettings"}
                    open={this.props.open}
                    maxWidth={"md"}
                    fullScreen={GlobalOptions.fullScreenDialogs}
                    fullWidth={false}
                    onClose={() => {
                        this.handleClose(false);
                    }}
                >
                    <DialogTitle>{this.title}</DialogTitle>
                    <DialogContent>
                        <div className={"SettingsItem SettingsItemZoneSelect"}>
                            <Typography>Zone: </Typography>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                componentsProps={{
                                    popper: {
                                        componentsProps: {
                                            root: {
                                                className: "ZoneSelectAutocompletePopper",
                                            },
                                        },
                                    },
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                groupBy={(option) => option.group}
                                options={zoneOptions}
                                value={values.zones}
                                onChange={(e, newValue) => {
                                    const newValueContainsDefault = newValue.some((zoneEntry) => zoneEntry.default);
                                    const oldValueContainedDefault = (values.zones || []).some(
                                        (zoneEntry) => zoneEntry.default,
                                    );
                                    if (newValueContainsDefault && !oldValueContainedDefault) {
                                        newValue = [this.zoneToEntry("default")];
                                    } else if (
                                        oldValueContainedDefault &&
                                        (!newValueContainsDefault || newValue.length > 1)
                                    ) {
                                        newValue = newValue.filter((zoneEntry) => !zoneEntry.default);
                                    }
                                    this.onChange({
                                        zones: newValue,
                                    });
                                }}
                                className={"ZoneSelectAutocomplete"}
                                renderOption={(props, option, { selected }) => {
                                    if (props.className) {
                                        props.className += " SensorSettingsZoneSelectEntry ";
                                    }
                                    if (option.default) {
                                        props.className += " SensorSettingsZoneSelectEntry--default ";
                                    }
                                    return (
                                        <li {...props}>
                                            <Checkbox
                                                icon={uncheckedIcon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            <Typography className={"ZoneSelectEntryBody"} component={"div"}>
                                                {option.label}
                                            </Typography>

                                            {option.tag && <div className={"ZoneSelectEntryTag"}>{option.tag}</div>}
                                        </li>
                                    );
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={"Zone"} />}
                            />
                        </div>
                        {this.getAcceptableRangeSettings(values)}
                        {this.getVerificationRangeSettings(values)}
                        {this.renderSupplementalSettings(values)}
                        {/* <div> */}
                        {/*    <Typography>Set Alert threshold: </Typography> */}
                        {/* </div> */}
                        {/* <div> */}
                        {/*    <Typography>Set Alert threshold: </Typography> */}
                        {/* </div> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose(false)}>Cancel</Button>
                        <Button onClick={() => this.handleClose(true)}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
