import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function BlockValveIcon(props) {
    return (
        <SvgIcon viewBox={"0, 0, 36, 20"} {...props}>
            <path
                style={{
                    fillOpacity: 1,
                    strokeWidth: 0,
                }}
                id={"rect842"}
                d={
                    "m 28.790083,11.584602 h 2.616972 v 0.552018 h -2.616972 z m 0.449793,-0.580279 h 1.717388 v 0.408902 H 29.239876 Z M 28.718923,7.0507955 a 0.13251324,0.13251324 0 0 0 -0.132809,0.1328084 v 3.5372521 a 0.13251324,0.13251324 0 0 0 0.132809,0.132808 h 2.759521 a 0.13251324,0.13251324 0 0 0 0.132808,-0.132808 V 7.1836039 A 0.13251324,0.13251324 0 0 0 31.478444,7.0507955 Z M 25.306641,3.5703125 9.0253906,3.6035156 h -0.00195 c -3.0192221,0.019983 -4.779567,2.1001247 -4.84375,4.2011719 -0.032091,1.0505236 0.3408435,2.1127623 1.1269531,2.9023435 0.7861096,0.789582 1.9762996,1.280851 3.4648438,1.263672 L 8.7558594,10.707031 C 7.5456445,10.720998 6.731488,10.34515 6.203125,9.8144531 5.674762,9.2837566 5.4230598,8.572199 5.4453125,7.84375 5.4898047,6.3872856 6.5589498,4.8844272 9.0292969,4.8671875 l 16.3027341,-0.03125 0.0059,-0.00195 c 1.603238,-0.041318 2.717409,0.069056 3.302734,0.3535156 0.585325,0.2844595 0.804922,0.6343543 0.826172,1.6601562 l 1.263672,-0.025391 C 30.70373,5.5314742 30.168315,4.5226426 29.193359,4.0488281 28.221432,3.5764858 26.972419,3.5282384 25.320312,3.5703125 Z M 13.935329,6.762441 c -0.896464,0 -1.617989,0.7215245 -1.617989,1.6179889 V 9.5695047 H 9.2100364 V 13.10934 H 12.31734 v 1.188558 c 0,0.896465 0.721525,1.618506 1.617989,1.618506 h 9.927043 c 0.896464,0 1.617989,-0.722041 1.617989,-1.618506 V 13.10934 9.5695047 8.3804299 c 0,-0.8964644 -0.721525,-1.6179889 -1.617989,-1.6179889 z"
                }
            />
        </SvgIcon>
    );
}
