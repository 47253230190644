import "./DeviceMarkerIcon.scss";

import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function MoistureSensorMarkerIcon(props) {
    return (
        <SvgIcon onClick={props.onClick} viewBox={"-4, -4, 32, 32"} {...props} className={"MarkerIcon"}>
            <path
                id={"path868"}
                d={
                    "m 23.99599,12.042984 c 0,3.309738 -1.340265,6.306441 -3.507666,8.476981 -5.738759,0.03256 -11.1701454,0.027 -16.9776666,9.9e-4 -2.1679842,-2.170618 -3.50865754,-5.16773 -3.50865754,-8.477973 0,-6.6257663 5.37123774,-11.99700348 11.99699514,-11.99700338 6.625757,0 11.996995,5.37123708 11.996995,11.99700338 z"
                }
                className={"MarkerIconRim"}
            />
            <path
                id={"path868"}
                d={
                    "m 23.99599,12.042984 c 0,3.309738 -1.340265,6.306441 -3.507666,8.476981 -5.738759,0.03256 -11.1701454,0.027 -16.9776666,9.9e-4 -2.1679842,-2.170618 -3.50865754,-5.16773 -3.50865754,-8.477973 0,-6.6257663 5.37123774,-11.99700348 11.99699514,-11.99700338 6.625757,0 11.996995,5.37123708 11.996995,11.99700338 z"
                }
                className={"MarkerIconOuterCircle"}
            />
            <path
                id={"path874"}
                style={{ fill: "#ffffff" }}
                d={
                    "m 11.999784,2.1434588 c -5.467619,1e-7 -9.8996536,4.4320348 -9.8996538,9.8996622 0,0.430489 0.030421,0.853695 0.083716,1.26969 2.0707196,-0.838953 4.3953363,-1.381015 6.7954508,-1.626257 1.0035968,-1.7717594 2.38339,-3.5140949 2.532662,-6.5830692 0.0083,-0.4953257 0.246737,-0.8027929 0.487825,-0.8356079 0.241088,-0.032815 0.484707,0.2091542 0.502812,0.8123534 0.1159,3.0629527 1.493482,4.8322023 2.503206,6.6094237 2.404276,0.24949 4.733042,0.798768 6.807336,1.647962 0.05539,-0.423878 0.08682,-0.855513 0.08682,-1.294495 0,-5.4676276 -4.432551,-9.8996624 -9.900171,-9.8996622 z m 0,5.1216512 c -0.235493,3.286429 -3.0483926,4.676622 -3.0483926,7.388188 0,3.797703 6.1166776,3.869002 6.0988526,0 -0.486259,-3.795438 -2.811567,-4.101281 -3.05046,-7.388188 z m 1.123446,4.495858 c 0.09497,0.368296 1.098651,1.886648 1.108976,2.771924 0,0.854296 -0.507868,1.691329 -1.018542,1.824694 -1.612231,0.421043 -1.357575,-1.09163 -0.623735,-1.788004 0.73384,-0.696374 0.702886,-1.586542 0.533301,-2.808614 z"
                }
                className={"MarkerIconInnerCircle"}
            />
            <ellipse id={"path824"} cx={"22"} cy={"2"} rx={"5"} ry={"5"} className={"AlertBubble"} />
        </SvgIcon>
    );
}
