/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import React from "react";
import { dayMs } from "verditypes/dist/timeConstants";

// imports it's own source code
import source from "!!raw-loader!./WarningHeaderDemo.jsx";

import readme from "./README.md";
import WarningHeader from "./WarningHeader";

function DemoComponent() {
    return (
        <div style={{ maxWidth: "80%" }}>
            <WarningHeader
                warnings={[
                    {
                        warningType: "offline",
                        warningSourceKey: "S1",
                        warningTitle: "Case-specific title",
                        warningDetails: "Some case-specific details",
                        warningLevel: 2,
                        warningTypePriority: 85,
                        timeSet: new Date(Date.now() - dayMs),
                        seen: false,
                        SMSSent: true,
                    },
                ]}
            />

            <br />

            <WarningHeader warnings={[]} />

            <br />

            <WarningHeader
                warnings={[
                    {
                        warningType: "low accuracy",
                        warningSourceKey: "S1",
                        warningTitle: "Case-specific title",
                        warningDetails: "Some case-specific details",
                        warningLevel: 2,
                        warningTypePriority: 55,
                        timeSet: new Date(Date.now() - dayMs),
                        seen: false,
                        SMSSent: true,
                    },
                    // higher priority errors trump lower priority ones
                    {
                        warningType: "offline",
                        warningSourceKey: "S1",
                        warningLevel: 2,
                        warningTypePriority: 95,
                    },
                ]}
            />

            <br />

            <WarningHeader
                warnings={[
                    // errors always trump warnings, regardless of type priority
                    {
                        warningType: "low accuracy",
                        warningSourceKey: "S1",
                        warningTitle: "Case-specific title",
                        warningDetails: "Some case-specific details",
                        warningLevel: 2,
                        warningTypePriority: 55,
                        timeSet: new Date(Date.now() - dayMs),
                        seen: false,
                        SMSSent: true,
                    },
                    {
                        warningType: "offline",
                        warningSourceKey: "S1",
                        warningLevel: 1,
                        warningTypePriority: 95,
                    },
                ]}
            />
        </div>
    );
}

const WarningHeaderDemo = {
    component: DemoComponent,
    name: "WarningHeader",
    readme: readme,
    source: source,
};

export default WarningHeaderDemo;
