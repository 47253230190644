import "../Navigation.css";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SessionHandler } from "verdiapi";

import FocusContext from "../../../services/mapManagement/FocusContext";

export function AccountSelect() {
    const { t } = useTranslation(["modules"], { keyPrefix: "navigation.drawer" });
    const [selectedAccount, setSelectedAccount] = useState("");
    return (
        <FormControl variant={"filled"} className={"nav-drawer-select"}>
            <InputLabel id={"admin-account-select"}>{t("account")}</InputLabel>
            <Select
                className={"nav-drawer-select"}
                labelId={"admin-account-select"}
                onChange={(e) => {
                    FocusContext.startLoadHandler("Loading Account", "sessionLoad");
                    SessionHandler.changeUser(e.target.value);
                    setSelectedAccount(e.target.value);
                }}
                value={selectedAccount}
            >
                <MenuItem value={""}>
                    <em>{t("selectAnAccount")}</em>
                </MenuItem>
                {SessionHandler.accounts &&
                    SessionHandler.accounts.map((a) => (
                        <MenuItem key={`NavBarMobileAcounts${a.id}`} value={a.id}>
                            {a.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
}
