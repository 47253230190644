import "./HTMLDynamicGraphCursor.scss";

import { Typography } from "@mui/material";
import { getTimeString, ShortMonthLookup } from "gardenspadejs/dist/dateHelpers";
import { GenerateUID } from "gardenspadejs/dist/general";
import React from "react";
import { EventHandler } from "verdiapi";

import { DynamicGraphContext } from "../DynamicGraphUtility";

export class HTMLDynamicGraphCursor extends React.Component {
    constructor(props) {
        super(props);
        this.uid = GenerateUID("HTMLDynamicCursor");
    }

    componentDidMount() {
        this.context.onCursorPositionChanged.addListener(() => {
            this.setState({});
        });
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
    }

    render() {
        return (
            <div className={"HTMLDynamicGraphCursor"} style={{ left: `max(15px, ${this.context.cursorPosition[0]}px` }}>
                <Typography
                    variant={"caption"}
                    className={"HTMLDynamicGraphCursor__text HTMLDynamicGraphCursor__text--top"}
                >
                    {ShortMonthLookup[this.context.cursorTime.getMonth()]} {this.context.cursorTime.getDate()}
                </Typography>
                <Typography
                    variant={"caption"}
                    className={"HTMLDynamicGraphCursor__text HTMLDynamicGraphCursor__text--bottom"}
                >
                    {getTimeString(this.context.cursorTime, "h:mm")}
                </Typography>
            </div>
        );
    }
}
HTMLDynamicGraphCursor.contextType = DynamicGraphContext;
