/* eslint-disable */
import "./TestBed.css";

import React from "react";

export default class TestBed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    moistureData;

    render() {
        new Date("Jul 20 2022 02:14:44 GMT-0700 (Pacific Daylight Time)");
        new Date(" Aug 8 2022 23:00:17 GMT-0700 (Pacific Daylight Time)");
        // let startTime = new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 5);
        // let endTime = new Date(Date.now());
        // let startTime = new Date(Date.now() - 1000 * 60 * 60 * 24 * 4);
        // let endTime = new Date(Date.now());

        return <div className={"rootContainer"} />;
    }
}
