import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import IconDatePicker from "../../../components/generic/IconDatePicker/IconDatePicker";
import { ModalLayout } from "../../../components/generic/ModalLayout";
import { useModal } from "../../../hooks/useModal";
import { createNewAdmin } from "../api";
import { AccountDetailsModalContent } from "./AccountDetailsModalContent";
import WidgetLoadingState from "./WidgetLoadingState";

export function AdminCreationWidet({ fetchAllAdmins }) {
    const [username, setUsername] = useState("");
    const defaultExpirationDate = new Date();
    defaultExpirationDate.setMonth(defaultExpirationDate.getMonth() + 3); // Defaults to 3 months from now in future
    const [expirationDate, setExpirationDate] = useState(defaultExpirationDate);
    const { enqueueSnackbar } = useSnackbar();
    const { openModal, closeModal } = useModal();
    const [loading, setLoading] = useState(false);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleCreateNewAdmin = async () => {
        try {
            closeModal();
            setLoading(true);

            const {
                username: newUsername,
                password: newPassword,
                passwordExpirationDate,
            } = await createNewAdmin({
                username: `admin-${username}`,
                passwordExpirationDate: expirationDate.toISOString(),
            });
            setLoading(false);
            openModal(
                <AccountDetailsModalContent
                    title={"New admin account created"}
                    accountDetails={[
                        {
                            label: "username",
                            value: newUsername,
                        },
                        {
                            label: "password",
                            value: newPassword,
                        },
                        {
                            label: "password expiration date ",
                            value: getTimeString(new Date(passwordExpirationDate), "MMMM dd, yyyy"),
                        },
                    ]}
                />,
            );
            // Reset form after submission
            setUsername("");

            // Refetch all admins to update table
            fetchAllAdmins();
        } catch (error) {
            enqueueSnackbar(`Error creating admin-${username}${error?.message && `: ${error.message}`} `, {
                variant: "error",
            });
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        openModal(
            <ModalLayout onClickClose={() => closeModal()}>
                <Typography variant={"h2"}>Confirm new admin</Typography>
                <Stack sx={{ px: 5, py: 1, alignItems: "center", justifyContent: "center" }} gap={2}>
                    <Typography variant={"h4"}>admin-{username}</Typography>
                    <Typography variant={"h4"}>Expiring: {getTimeString(expirationDate, "MMMM dd, yyyy")}</Typography>
                </Stack>

                <Typography variant={"body1"}>Are you sure you want to create this admin?</Typography>
                <Button variant={"contained"} color={"primary"} onClick={handleCreateNewAdmin}>
                    Create Admin
                </Button>
            </ModalLayout>,
        );
    };

    return (
        <Stack gap={3} sx={{ px: 6, py: 6, maxWidth: 575 }} border={1} borderRadius={5} borderColor={"GrayText"}>
            <Stack direction={"row"} alignItems={"center"}>
                <PersonAddIcon sx={{ fontSize: 30, mb: 1, mr: 1 }} color={"success"} />
                <Typography variant={"h2"}>Create a new admin</Typography>
            </Stack>
            <Typography variant={"p"}>
                Enter the new username and choose a password expiration date. You will be able to view the created
                user's password in the following modal popup after the user is successfully created.
            </Typography>

            <form onSubmit={handleSubmit}>
                <Stack gap={3}>
                    {loading ? (
                        <WidgetLoadingState text={"Creating..."} />
                    ) : (
                        <Stack direction={"row"} alignItems={"center"} gap={4} mt={4}>
                            <TextField
                                label={"Username"}
                                variant={"outlined"}
                                value={username}
                                onChange={handleUsernameChange}
                                sx={{ minWidth: 220 }}
                                InputProps={{
                                    startAdornment: <InputAdornment position={"start"}>admin-</InputAdornment>,
                                }}
                            />
                            <Stack>
                                <Typography variant={"p"}>Expiration date</Typography>
                                <IconDatePicker
                                    value={expirationDate}
                                    onChange={(value) => {
                                        setExpirationDate(value);
                                    }}
                                    includeYear
                                />
                            </Stack>
                        </Stack>
                    )}
                    <Typography variant={"p"}>
                        <b>Usernames:</b> All usernames will be prefixed automatically with "admin-".
                    </Typography>
                    <Typography variant={"p"}>
                        <b>Expiration date:</b> The date must be within the next 300 days.
                    </Typography>
                    <Button
                        disabled={!username || loading}
                        type={"submit"}
                        variant={"contained"}
                        color={"primary"}
                        sx={{ maxWidth: 220 }}
                    >
                        Create Admin
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
}
