import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SessionHandler } from "verdiapi";

import FocusContext from "../../../services/mapManagement/FocusContext";
import { FilledSelect } from "../../generic/FilledSelect";

export function AccountSelect() {
    const { t } = useTranslation("specializedComponents", { keyPrefix: "mapControlBar.accountSelect" });
    const defaultValue = t("selectAccount");
    const defaultState = useRef(defaultValue);

    const [selectedAccountId, setSelectedAccountId] = React.useState(defaultValue);
    const onSelectAccount = (e) => {
        const value = e.target.value;
        FocusContext.startLoadHandler("Loading Account", "sessionLoad");
        SessionHandler.changeUser(value);
        setSelectedAccountId(value);
    };

    const sortAccounts = () => {
        if (!SessionHandler.accounts) {
            return;
        }

        SessionHandler.accounts.sort((a, b) => {
            if (a.name && b.name) {
                return a.name.localeCompare(b.name);
            }
            if (a.name) {
                return 1;
            }
            return -1;
        });
    };

    // Reset the default state on a language change (if the default is still selected)
    useEffect(() => {
        if (selectedAccountId === defaultState.current) {
            setSelectedAccountId(defaultValue);
            defaultState.current = defaultValue;
        }
    }, [defaultValue]);

    // Load the listeners for account
    useEffect(() => {
        const accountChangeListener = () => {
            sortAccounts();

            if (SessionHandler.currentUserObject && SessionHandler.currentUserObject.id !== selectedAccountId) {
                setSelectedAccountId(SessionHandler.currentUserObject.id);
            }
        };

        SessionHandler.onSessionLoad.addListener(accountChangeListener);

        return () => {
            SessionHandler.onSessionLoad.removeListener(accountChangeListener);
        };
    }, []);

    return (
        <FilledSelect
            labelId={"AdminAccountSelectorInputLabel"}
            defaultOptionLabel={defaultValue}
            selectOptions={SessionHandler.accounts.map((account) => {
                const { name, id } = account;
                return {
                    id,
                    name,
                    value: id,
                };
            })}
            onSelect={onSelectAccount}
            value={selectedAccountId}
            // Since this component lives in the TopNavbar, we override the default white bg style
            backgroundColor={"unset"}
            inputLabelProps={{
                color: "dark",
            }}
            formControlProps={{
                sx: {
                    height: "var(--nav-bar-height, 55px)",
                },
            }}
            selectProps={{
                sx: {
                    height: "var(--nav-bar-height, 55px)",
                },
            }}
        />
    );
}
