import { useEffect, useRef } from "react";

interface UseIntervalProps {
    callback: () => void;
    delay: number | null;
}
/**
 * Hook that calls a callback at a given interval
 * @param callback - The callback to call
 * @param delay - The delay between calls. Set null to cancel
 */
export const useInterval = ({ callback, delay }: UseIntervalProps) => {
    const savedCallback = useRef<() => void>();
    const intervalId = useRef<NodeJS.Timeout>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function onTick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null && delay > 0) {
            intervalId.current = setInterval(onTick, delay);
        }
        return () => {
            clearInterval(intervalId.current);
        };
    }, [delay]);

    return {
        intervalId,
    };
};
