/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import React from "react";

// imports it's own source code
import source from "!!raw-loader!./TextFieldAlignmentOverrideDemo.jsx";

import readme from "./README.md";
import TextFieldAlignmentOverride from "./TextFieldAlignmentOverride";

function DemoComponent() {
    return (
        <div>
            <TextFieldAlignmentOverride label={"Right"} align={"right"} />
            <TextFieldAlignmentOverride label={"Left"} align={"left"} />
        </div>
    );
}

const TextFieldAlignmentOverrideDemo = {
    component: DemoComponent,
    name: "TextFieldAlignmentOverride",
    readme: readme,
    source: source,
};

export default TextFieldAlignmentOverrideDemo;
