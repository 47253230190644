import { useEffect, useState } from "react";

import { type Admin, getAllAdmins, getWhoAmI, type WhoAmIResponse } from "../api";

export function useAdminManager() {
    const [allAdmins, setAllAdmins] = useState<Admin[]>([]);
    const [currentUser, setCurrentUser] = useState<WhoAmIResponse>();

    const fetchAllAdmins = async () => {
        const res = await getAllAdmins();
        setAllAdmins(res);
    };

    const whoAmI = async () => {
        const res = await getWhoAmI();
        setCurrentUser(res);
    };

    useEffect(() => {
        fetchAllAdmins();
        whoAmI();
    }, []);

    return { allAdmins, fetchAllAdmins, currentUser };
}
