import "./TrendGraphChip.scss";
import "../../../../utils/ZoneOverviewUtility.css";

import TimelineIcon from "@mui/icons-material/Timeline";
import { Typography } from "@mui/material";
import { GenerateUID } from "gardenspadejs/dist/general";
import * as React from "react";
import { EventHandler } from "verdiapi";

import TypographyWithMaxWidth from "../../../generic/TypographyWithMaxWidth/TypographyWithMaxWidth";
import { DynamicGraphContext } from "../DynamicGraphUtility";

export default class TrendGraphChip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iconName: TimelineIcon,
            iconColor: this.props.sensor.color,
            label: "Trend",
        };
        this.uid = GenerateUID("trendgraphChip");
    }

    componentDidMount() {
        if (this.props.sensor.sensorType in this.context.staticGraphDictionary) {
            this.setState({
                unit: this.context.staticGraphDictionary[this.props.sensor.sensorType].unit,
                iconName: this.context.staticGraphDictionary[this.props.sensor.sensorType].iconName || TimelineIcon,
                iconColor: this.props.sensor.color, // staticGraphDictionary[this.props.sensor.sensorType].iconColor,
                label: this.context.staticGraphDictionary[this.props.sensor.sensorType].labelName,
                lastData: this.props.sensor.lastDataPoint,
            });
        }
        this.context.onFocusedLinesChanged.addListener(() => {
            this.forceUpdate();
        }, this.uid);
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
    }

    render() {
        const style = {};
        const rgbColor = hexToRgb(this.state.iconColor);
        style["--dataColor"] = `${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}`;
        let valueAtLastDatapoint;
        if (this.state.lastData) {
            valueAtLastDatapoint = this.state.lastData.value;
            valueAtLastDatapoint = (Math.round(valueAtLastDatapoint * 10) / 10).toString();
        }
        let focused = false;
        if (this.context.lines[this.props.lineID].focused) {
            focused = true;
        }
        const sensorName = this.props.sensor.name || "Probe";
        const sensorNameLength = sensorName.length;
        const fontSize = sensorNameLength > 7 ? 7 : 10;
        const IconName = this.state.iconName;
        return (
            <>
                <div
                    style={style}
                    className={`DynamicGraphDataChip DynamicGraphDataChip--${focused ? "focused" : "unfocused"}`}
                    onClick={(e) => {
                        let isDoubleClick = false;
                        if (e.detail % 2 === 0) {
                            isDoubleClick = true;
                            console.info("double click on trend graph chip!");
                        } else {
                            console.info("single click on trend graph chip!");
                        }
                        this.context.activeDataType = this.props.sensor.sensorType;
                        this.context.onActiveDataTypeChanged.trigger();
                        this.context.toggleLineFocus(this.props.lineID, isDoubleClick);
                    }}
                >
                    <div className={"ChipTitleWrapper"} title={this.props?.sensor?.name || "Probe"}>
                        <Typography variant={"button"} className={"ChipTitle"} style={{ fontSize: fontSize }}>
                            {this.props.sensor.name || "Probe"}
                        </Typography>
                    </div>
                    <div className={"DynamicGraphDataChip--Body"}>
                        <div className={"DynamicGraphDataChipIconAndCurValue"}>
                            <div className={"ChipIconWrapper"}>
                                <IconName slim={true} className={"DynamicGraphDataChipIcon"} />
                            </div>

                            <div className={"DynamicGraphDataChipCurValue"}>
                                {this.state.lastData && (
                                    <TypographyWithMaxWidth
                                        MaxWidth={55 / 2 - 3}
                                        variant={"button"}
                                        className={"ChipValue"}
                                    >
                                        {/* {valueAtLastDatapoint} */}
                                        {this.context.staticGraphDictionary[this.props.sensor.sensorType].labelForValue(
                                            valueAtLastDatapoint,
                                        )}
                                    </TypographyWithMaxWidth>
                                )}
                                {!this.state.lastData && (
                                    <TypographyWithMaxWidth
                                        MaxWidth={55 / 2 - 3}
                                        variant={"button"}
                                        className={"ChipValue"}
                                    >
                                        N/A
                                    </TypographyWithMaxWidth>
                                )}
                                <Typography variant={"button"} className={"ChipUnit"}>
                                    {this.state.unit || "N/A"}
                                </Typography>
                            </div>
                        </div>
                        <Typography variant={"button"} className={"ChipSensor"}>
                            {this.state.label || "None"}
                        </Typography>
                    </div>
                </div>
                {/* <Chip */}
                {/*    className={"zoneOverviewStatusChipStyle"} */}
                {/*    sx={{ */}
                {/*        backgroundColor: this.props.sensor.visible ? "rgba(0, 0, 0, 0.11)" : "#ffffff", */}
                {/*        "& .MuiChip-icon": { color: this.state.iconColor }, */}
                {/*    }} */}
                {/*    // disableRipple */}
                {/*    icon={<this.state.iconName />} */}
                {/*    label={this.state.label + ": " + (this.props.sensor.name || "Sensor")} */}
                {/*    size={"small"} */}
                {/*    variant={"outlined"} */}
                {/*    onClick={() => { */}
                {/*        this.context.activeDataType = this.props.sensor.sensorType; */}
                {/*        this.context.onActiveDataTypeChanged.trigger(); */}
                {/*    }} */}
                {/*    onDelete={this.props.onDelete} */}
                {/* /> */}
            </>
        );
    }
}

TrendGraphChip.contextType = DynamicGraphContext;
function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}
//
// TrendGraphChip.propTypes = {
//     lineID: PropTypes.string,
//     sensor: PropTypes.object,
//     onDelete: PropTypes.func,
// };
