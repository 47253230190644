export const NDVIColorMap = [
    [0.174694909590925, 0.0830199461673424, 0.550261217249588],
    [0.176123779639845, 0.087154325520354, 0.550438706404185],
    [0.177529618708746, 0.0911864408828895, 0.550615325465476],
    [0.178912855807259, 0.0951263199935551, 0.550791070004503],
    [0.180273899481867, 0.0989825792934506, 0.55096593559447],
    [0.181613138986639, 0.102762682201435, 0.551139917810644],
    [0.182930945366968, 0.106473140078058, 0.551313012230261],
    [0.184227672464001, 0.110119670633068, 0.551485214432431],
    [0.185503657846613, 0.113707324252743, 0.551656519998044],
    [0.186759223677144, 0.117240585813012, 0.551826924509671],
    [0.187994677516431, 0.120723457527384, 0.551996423551467],
    [0.189210313073182, 0.124159526956643, 0.552165012709082],
    [0.190406410902218, 0.127552023289139, 0.552332687569553],
    [0.191583239055695, 0.13090386426108, 0.552499443721219],
    [0.192741053691015, 0.13421769554221, 0.552665276753615],
    [0.193880099638806, 0.137495924007183, 0.552830182257379],
    [0.195000610934044, 0.140740746007966, 0.552994155824154],
    [0.196102811313095, 0.143954171530643, 0.55315719304649],
    [0.197186914679225, 0.147138044941813, 0.553319289517746],
    [0.19825312553889, 0.150294062891785, 0.55348044083199],
    [0.199301639410938, 0.153423789833927, 0.553640642583906],
    [0.200332643210638, 0.156528671534609, 0.553799890368689],
    [0.201346315610325, 0.159610046880877, 0.553958179781949],
    [0.202342827378278, 0.162669158239191, 0.554115506419612],
    [0.203322341697315, 0.165707160575359, 0.55427186587782],
    [0.204285014464486, 0.168725129510838, 0.554427253752831],
    [0.20523099457311, 0.171724068462153, 0.554581665640921],
    [0.206160424178318, 0.174704914986934, 0.55473509713828],
    [0.207073438947165, 0.177668546440969, 0.554887543840914],
    [0.207970168294292, 0.180615785034861, 0.555039001344546],
    [0.208850735604056, 0.183547402365817, 0.555189465244509],
    [0.209715258439941, 0.186464123489128, 0.555338931135652],
    [0.210563848742042, 0.189366630584792, 0.555487394612232],
    [0.211396613013336, 0.192255566267027, 0.555634851267816],
    [0.212213652495383, 0.195131536577932, 0.555781296695179],
    [0.213015063334094, 0.197995113701086, 0.555926726486197],
    [0.213800936736112, 0.200846838426166, 0.55607113623175],
    [0.214571359116346, 0.203687222391726, 0.556214521521613],
    [0.215326412237131, 0.206516750129842, 0.556356877944359],
    [0.216066173339487, 0.209335880933413, 0.55649820108725],
    [0.216790715266874, 0.2121450505644, 0.556638486536135],
    [0.217500106581844, 0.21494467281909, 0.556777729875344],
    [0.218194411675957, 0.21773514096463, 0.556915926687587],
    [0.218873690873284, 0.220516829059386, 0.557053072553845],
    [0.21953800052782, 0.223290093168316, 0.557189163053267],
    [0.22018739311509, 0.226055272483283, 0.557324193763062],
    [0.220821917318225, 0.228812690357134, 0.557458160258395],
    [0.221441618108746, 0.231562655259449, 0.55759105811228],
    [0.222046536822307, 0.234305461661021, 0.557722882895472],
    [0.222636711229594, 0.237041390853378, 0.557853630176362],
    [0.223212175602585, 0.239770711709023, 0.557983295520867],
    [0.223772960776371, 0.242493681387508, 0.558111874492325],
    [0.224319094206684, 0.245210545991908, 0.558239362651383],
    [0.224850600023318, 0.247921541179869, 0.558365755555891],
    [0.225367499079562, 0.250626892732943, 0.558491048760793],
    [0.225869808997811, 0.25332681708762, 0.558615237818015],
    [0.226357544211444, 0.256021521831112, 0.558738318276359],
    [0.226830716003123, 0.258711206164672, 0.55886028568139],
    [0.227289332539578, 0.261396061336984, 0.558981135575325],
    [0.227733398903007, 0.264076271049924, 0.559100863496924],
    [0.228162917119149, 0.266752011838782, 0.559219464981377],
    [0.228577886182133, 0.269423453428862, 0.559336935560192],
    [0.228978302076157, 0.272090759070211, 0.559453270761083],
    [0.229364157794061, 0.27475408585206, 0.559568466107858],
    [0.229735443352857, 0.277413584998458, 0.559682517120305],
    [0.230092145806253, 0.280069402146422, 0.559795419314078],
    [0.230434249254225, 0.282721677607842, 0.559907168200582],
    [0.230761734849651, 0.28537054661627, 0.560017759286861],
    [0.231074580802056, 0.288016139559622, 0.56012718807548],
    [0.231372762378472, 0.290658582199765, 0.560235450064413],
    [0.231656251901436, 0.293297995879857, 0.560342540746923],
    [0.231925018744133, 0.295934497720251, 0.560448455611446],
    [0.232179029322674, 0.298568200803727, 0.560553190141478],
    [0.232418247085531, 0.301199214350725, 0.560656739815453],
    [0.232642632500081, 0.303827643885241, 0.560759100106625],
    [0.232852143036274, 0.306453591391962, 0.560860266482955],
    [0.233046733147387, 0.309077155465192, 0.560960234406984],
    [0.233226354247829, 0.311698431450092, 0.561058999335719],
    [0.233390954687971, 0.314317511576696, 0.561156556720509],
    [0.233540479725955, 0.31693448508713, 0.56125290200693],
    [0.233674871496421, 0.319549438356477, 0.561348030634657],
    [0.23379406897612, 0.322162455007629, 0.561441938037346],
    [0.233898007946309, 0.32477361602051, 0.561534619642511],
    [0.233986620951903, 0.327382999835975, 0.561626070871399],
    [0.234059837257268, 0.329990682454717, 0.561716287138873],
    [0.234117582798583, 0.33259673753144, 0.561805263853277],
    [0.23415978013267, 0.335201236464601, 0.561892996416321],
    [0.234186348382204, 0.337804248481928, 0.561979480222952],
    [0.234197203177159, 0.34040584072199, 0.562064710661227],
    [0.234192256592393, 0.34300607831201, 0.562148683112187],
    [0.234171417081231, 0.345605024442131, 0.56223139294973],
    [0.2341345894049, 0.348202740436338, 0.562312835540485],
    [0.234081674557657, 0.350799285820201, 0.562393006243681],
    [0.234012569687461, 0.353394718385617, 0.562471900411018],
    [0.233927168011986, 0.355989094252706, 0.562549513386536],
    [0.233825358729802, 0.358582467929009, 0.562625840506491],
    [0.233707026926504, 0.361174892366127, 0.562700877099214],
    [0.233572053475582, 0.36376641901394, 0.562774618484986],
    [0.233420314933776, 0.366357097872512, 0.562847059975904],
    [0.233251683430675, 0.368946977541822, 0.562918196875747],
    [0.233066026552285, 0.371536105269404, 0.562988024479841],
    [0.232863207218268, 0.374124526996022, 0.563056538074926],
    [0.232643083552539, 0.376712287399467, 0.563123732939018],
    [0.232405508746895, 0.379299429936562, 0.563189604341279],
    [0.232150330917297, 0.381885996883469, 0.563254147541869],
    [0.231877392952439, 0.384472029374386, 0.56331735779182],
    [0.23158653235417, 0.387057567438691, 0.56337923033289],
    [0.231277581069342, 0.389642650036628, 0.563439760397424],
    [0.230950365312593, 0.392227315093585, 0.563498943208218],
    [0.230604705379556, 0.394811599533045, 0.563556773978375],
    [0.230240415449951, 0.397395539308259, 0.563613247911161],
    [0.229857303379952, 0.399979169432707, 0.563668360199868],
    [0.229455170483217, 0.402562524009397, 0.563722106027665],
    [0.22903381129986, 0.405145636259062, 0.563774480567459],
    [0.228593013352671, 0.407728538547295, 0.563825478981743],
    [0.22813255688975, 0.410311262410672, 0.563875096422457],
    [0.227652214612717, 0.412893838581914, 0.563923328030835],
    [0.227151751389585, 0.415476297014123, 0.563970168937261],
    [0.226630923951262, 0.418058666904132, 0.564015614261119],
    [0.22608948057064, 0.420640976715015, 0.564059659110642],
    [0.225527160723084, 0.423223254197781, 0.564102298582764],
    [0.224943694727062, 0.425805526412299, 0.564143527762965],
    [0.224338803363543, 0.428387819747476, 0.564183341725121],
    [0.223712197472692, 0.430970159940731, 0.564221735531349],
    [0.223063577526232, 0.433552572096777, 0.564258704231854],
    [0.222392633173734, 0.436135080705762, 0.56429424286477],
    [0.221699042760933, 0.438717709660775, 0.564328346456009],
    [0.220982472817983, 0.441300482274752, 0.564361010019096],
    [0.220242577515401, 0.443883421296814, 0.564392228555017],
    [0.219478998085234, 0.446466548928037, 0.564421997052056],
    [0.218691362204748, 0.449049886836707, 0.564450310485634],
    [0.2178792833397, 0.451633456173052, 0.564477163818149],
    [0.217042360043947, 0.454217277583494, 0.56450255199881],
    [0.216180175211884, 0.456801371224423, 0.564526469963476],
    [0.215292295279793, 0.459385756775524, 0.564548912634488],
    [0.214378269371853, 0.461970453452665, 0.564569874920506],
    [0.213437628386091, 0.464555480020372, 0.564589351716337],
    [0.212469884015111, 0.467140854803898, 0.564607337902771],
    [0.211474527695849, 0.469726595700905, 0.564623828346406],
    [0.210451029482047, 0.472312720192781, 0.564638817899482],
    [0.20939883683242, 0.474899245355584, 0.564652301399704],
    [0.208317373306744, 0.477486187870663, 0.564664273670072],
    [0.207206037161226, 0.480073564034921, 0.564674729518703],
    [0.206064199833529, 0.482661389770786, 0.56468366373866],
    [0.204891204306733, 0.485249680635847, 0.564691071107766],
    [0.203686363340246, 0.487838451832219, 0.564696946388435],
    [0.202448957554252, 0.490427718215596, 0.564701284327485],
    [0.201178233352662, 0.493017494304054, 0.564704079655959],
    [0.199873400667661, 0.495607794286567, 0.564705327088942],
    [0.198533630506808, 0.49819863203128, 0.564705021325378],
    [0.197158052281227, 0.500790021093528, 0.564703157047882],
    [0.195745750890558, 0.503381974723618, 0.564699728922556],
    [0.194295763537139, 0.505974505874385, 0.564694731598797],
    [0.192807076238063, 0.508567627208515, 0.564688159709113],
    [0.191278619999429, 0.51116135110567, 0.564680007868925],
    [0.189709266611992, 0.513755689669386, 0.564670270676377],
    [0.188097824021441, 0.516350654733789, 0.564658942712144],
    [0.186443031219613, 0.518946257870107, 0.564646018539233],
    [0.184743552594691, 0.521542510392997, 0.564631492702786],
    [0.182997971668771, 0.524139423366694, 0.564615359729884],
    [0.181204784139743, 0.526737007610986, 0.564597614129341],
    [0.17936239013071, 0.529335273707016, 0.564578250391508],
    [0.177469085533958, 0.531934232002927, 0.564557262988065],
    [0.175523052316884, 0.534533892619341, 0.564534646371817],
    [0.173522347633762, 0.537134265454699, 0.564510394976487],
    [0.171464891558729, 0.539735360190436, 0.564484503216507],
    [0.169348453220679, 0.542337186296028, 0.564456965486807],
    [0.167170635078363, 0.544939753033897, 0.564427776162602],
    [0.1649288550218, 0.547543069464175, 0.564396929599181],
    [0.162620325921638, 0.550147144449349, 0.564364420131689],
    [0.160242032167756, 0.552751986658776, 0.564330242074908],
    [0.157790702637855, 0.555357604573075, 0.564294389723041],
    [0.155262779409749, 0.557964006488403, 0.564256857349486],
    [0.152654381369911, 0.56057120052062, 0.564217639206617],
    [0.149961261663947, 0.563179194609341, 0.564176729525558],
    [0.147178757667821, 0.565787996521877, 0.564134122515952],
    [0.144301731810355, 0.568397613857085, 0.564089812365738],
    [0.141324501119301, 0.571008054049102, 0.564043793240915],
    [0.13824075275344, 0.573619324370992, 0.563996059285312],
    [0.135043441962787, 0.576231431938297, 0.563946604620349],
    [0.131724667801532, 0.578844383712495, 0.563895423344805],
    [0.128275520375845, 0.581458186504368, 0.563842509534574],
    [0.124685891248614, 0.584072846977287, 0.563787857242426],
    [0.120944235549371, 0.586688371650414, 0.563731460497762],
    [0.117037269886714, 0.589304766901816, 0.563673313306372],
    [0.112949583589502, 0.59192203897151, 0.563613409650178],
    [0.108663130890982, 0.594540193964425, 0.563551743486995],
    [0.10415655635383, 0.597159237853294, 0.563488308750269],
    [0.0994042815166884, 0.599779176481469, 0.563423099348827],
    [0.0943752409252618, 0.602400015565674, 0.563356109166619],
    [0.0890310881680509, 0.60502176069868, 0.563287332062458],
    [0.0833235731684575, 0.607644417351924, 0.563216761869757],
    [0.0771905706238348, 0.610267990878059, 0.563144392396265],
    [0.0705498047130181, 0.61289248651344, 0.563070217423802],
    [0.0632883994799154, 0.615517909380555, 0.562994230707985],
    [0.0552442799987929, 0.618144264490393, 0.562916425977961],
    [0.0552309430723153, 0.618148381836778, 0.562916302594632],
    [0.08024335881614, 0.620584852852642, 0.557021707128594],
    [0.0998067156915327, 0.623031402672697, 0.551104359933689],
    [0.116184630194833, 0.62548413324499, 0.545172816657715],
    [0.130421644129635, 0.627942967648297, 0.539226355799841],
    [0.143093629307645, 0.630407830046661, 0.533264220175175],
    [0.154557251702521, 0.632878645676225, 0.527285614611231],
    [0.165051215775813, 0.635355340832096, 0.521289703454279],
    [0.174744415627101, 0.637837842855231, 0.515275607866231],
    [0.183761472462188, 0.640326080119368, 0.509242402890349],
    [0.192197409023329, 0.642819982017997, 0.503189114261337],
    [0.200126618443288, 0.645319478951388, 0.497114714932322],
    [0.207608621607226, 0.647824502313672, 0.491018121287657],
    [0.214691913894399, 0.650334984479983, 0.484898189006388],
    [0.221416622395067, 0.652850858793675, 0.478753708536519],
    [0.227816393875621, 0.655372059553608, 0.472583400134724],
    [0.233919768969952, 0.657898522001507, 0.466385908419833],
    [0.239751203572108, 0.660430182309415, 0.460159796381005],
    [0.245331842049654, 0.662966977567223, 0.45390353877288],
    [0.250680112133032, 0.665508845770292, 0.447615514819887],
    [0.255812189246581, 0.668055725807168, 0.441294000139993],
    [0.260742363639149, 0.670607557447398, 0.434937157784122],
    [0.265483334054011, 0.673164281329441, 0.428543028270873],
    [0.270046445121882, 0.675725838948685, 0.422109518476394],
    [0.274441881107599, 0.678292172645564, 0.415634389215663],
    [0.278678825424673, 0.680863225593791, 0.409115241323176],
    [0.282765593024375, 0.683438941788692, 0.402549500006953],
    [0.286709741087082, 0.686019266035657, 0.395934397208619],
    [0.290518162205991, 0.688604143938703, 0.389266951652214],
    [0.294197163330112, 0.691193521889149, 0.382543946203231],
    [0.2977525330371, 0.693787347054414, 0.375761902084217],
    [0.301189599175915, 0.696385567366928, 0.368917049400384],
    [0.304513278511063, 0.698988131513159, 0.362005293313286],
    [0.30772811968339, 0.701594988922766, 0.355022175056145],
    [0.310838340554458, 0.704206089757869, 0.347962826802545],
    [0.31384786080598, 0.706821384902438, 0.34082191916934],
    [0.316760330510439, 0.709440825951812, 0.333593599839538],
    [0.319579155264767, 0.712064365202329, 0.326271421410397],
    [0.322307518378893, 0.714691955641086, 0.318848256077118],
    [0.324948400529944, 0.717323550935819, 0.311316194112712],
    [0.327504597226868, 0.719959105424909, 0.30366642224276],
    [0.329978734376154, 0.722598574107504, 0.295889076857889],
    [0.332373282194824, 0.72524191263377, 0.287973065438252],
    [0.334690567680011, 0.727889077295264, 0.279905847407933],
    [0.336932785813817, 0.730540025015426, 0.271673162631079],
    [0.339102009656578, 0.733194713340194, 0.263258691505829],
    [0.341200199460223, 0.735853100428744, 0.25464362448494],
    [0.343229210915352, 0.738515145044349, 0.245806109864793],
    [0.345190802630405, 0.741180806545355, 0.236720535227203],
    [0.347086642928366, 0.743850044876286, 0.227356577298092],
    [0.348918316035401, 0.746522820559056, 0.217677922561944],
    [0.350687327726445, 0.749199094684316, 0.207640508465512],
    [0.352395110484664, 0.751878828902901, 0.197190047126339],
    [0.354043028224819, 0.754561985417412, 0.186258440442037],
    [0.355632380624541, 0.757248526973898, 0.174758416857771],
    [0.357164407102385, 0.759938416853668, 0.162575184745985],
    [0.357611170609317, 0.760742692119818, 0.158781001355565],
    [0.361020499288086, 0.761438935164741, 0.159865493047864],
    [0.365845290165756, 0.762430668871691, 0.161407259695987],
    [0.370628139032711, 0.763421062153376, 0.162943556117225],
    [0.375370757593556, 0.764410114985045, 0.164474526480156],
    [0.380074756641506, 0.765397827340073, 0.166000309890985],
    [0.384741653992701, 0.766384199189948, 0.167521040629011],
    [0.389372881643934, 0.767369230504255, 0.169036848368411],
    [0.393969792244257, 0.76835292125066, 0.170547858387296],
    [0.398533664958724, 0.769335271394893, 0.172054191764904],
    [0.403065710792272, 0.770316280900737, 0.173555965567754],
    [0.407567077432943, 0.77129594973001, 0.175053293025483],
    [0.412038853666132, 0.772274277842551, 0.176546283697059],
    [0.416482073405171, 0.773251265196209, 0.178035043628005],
    [0.420897719377983, 0.774226911746821, 0.179519675499207],
    [0.42528672650481, 0.775201217448208, 0.181000278767853],
    [0.429649984997903, 0.776174182252155, 0.182476949800996],
    [0.433988343210456, 0.777145806108395, 0.183949782002208],
    [0.438302610259018, 0.778116088964605, 0.185418865931754],
    [0.442593558440864, 0.779085030766382, 0.186884289420673],
    [0.44686192546548, 0.780052631457238, 0.188346137679152],
    [0.451108416517215, 0.781018890978583, 0.189804493399522],
    [0.455333706164375, 0.781983809269713, 0.191259436854201],
    [0.459538440128397, 0.782947386267797, 0.192711045988884],
    [0.463723236925376, 0.783909621907868, 0.194159396511245],
    [0.467888689390935, 0.784870516122807, 0.195604561975432],
    [0.472035366098355, 0.785830068843329, 0.197046613862563],
    [0.476163812678899, 0.786788279997978, 0.198485621657481],
    [0.48027455305238, 0.78774514951311, 0.199921652921955],
    [0.484368090575299, 0.788700677312882, 0.201354773364534],
    [0.488444909113125, 0.789654863319242, 0.202785046907236],
    [0.492505474042727, 0.790607707451917, 0.20421253574924],
    [0.496550233190404, 0.7915592096284, 0.205637300427744],
    [0.50057961771046, 0.792509369763944, 0.207059399876152],
    [0.504594042908825, 0.793458187771544, 0.208478891479703],
    [0.508593909015845, 0.794405663561932, 0.20989583112871],
    [0.512579601912, 0.795351797043564, 0.211310273269504],
    [0.516551493809963, 0.796296588122609, 0.21272227095322],
    [0.520509943896164, 0.797240036702942, 0.214131875882526],
    [0.524455298934732, 0.798182142686128, 0.2155391384564],
    [0.528387893836438, 0.799122905971414, 0.216944107813054],
    [0.532308052195109, 0.800062326455725, 0.218346831871098],
    [0.536216086793685, 0.801000404033644, 0.219747357369028],
    [0.540112300082032, 0.801937138597409, 0.22114572990312],
    [0.543996984628353, 0.802872530036903, 0.222541993963808],
    [0.547870423545975, 0.803806578239639, 0.223936192970619],
    [0.551732890897108, 0.80473928309076, 0.225328369305737],
    [0.555584652075062, 0.805670644473018, 0.226718564346248],
    [0.559425964166309, 0.806600662266776, 0.228106818495147],
    [0.56325707629365, 0.807529336349991, 0.229493171211145],
    [0.567078229941684, 0.808456666598208, 0.230877661037345],
    [0.570889659265661, 0.80938265288455, 0.232260325628836],
    [0.574691591384732, 0.810307295079711, 0.233641201779244],
    [0.57848424666056, 0.811230593051947, 0.235020325446306],
    [0.582267838962146, 0.812152546667064, 0.236397731776487],
    [0.586042575917694, 0.813073155788412, 0.237773455128704],
    [0.58980865915429, 0.813992420276879, 0.239147529097187],
    [0.593566284526072, 0.814910339990877, 0.240519986533506],
    [0.597315642331586, 0.815826914786339, 0.241890859567818],
    [0.601056917520913, 0.816742144516705, 0.243260179629351],
    [0.604790289893171, 0.81765602903292, 0.244627977466176],
    [0.608515934284901, 0.818568568183424, 0.245994283164266],
    [0.612234020749864, 0.819479761814141, 0.247359126165915],
    [0.615944714730708, 0.820389609768473, 0.248722535287503],
    [0.619648177222941, 0.821298111887293, 0.25008453873666],
    [0.623344564931638, 0.822205268008939, 0.251445164128841],
    [0.627034030421251, 0.823111077969201, 0.252804438503345],
    [0.630716722258899, 0.824015541601318, 0.254162388338791],
    [0.63439278515147, 0.824918658735967, 0.25551903956808],
    [0.638062360076863, 0.825820429201259, 0.256874417592864],
    [0.641725584409659, 0.82672085282273, 0.25822854729753],
    [0.645382592041514, 0.827619929423332, 0.259581453062737],
    [0.649033513496543, 0.82851765882343, 0.260933158778509],
    [0.652678476041925, 0.82941404084079, 0.262283687856903],
    [0.656317603793994, 0.830309075290573, 0.263633063244275],
    [0.659951017820012, 0.831202761985332, 0.264981307433156],
    [0.663578836235853, 0.832095100734999, 0.266328442473749],
    [0.66720117429978, 0.832986091346883, 0.267674489985064],
    [0.670818144502518, 0.833875733625658, 0.269019471165713],
    [0.674429856653783, 0.834764027373363, 0.270363406804362],
    [0.678036417965442, 0.835650972389387, 0.271706317289863],
    [0.681637933131465, 0.83653656847047, 0.273048222621076],
    [0.685234504404807, 0.83742081541069, 0.274389142416393],
    [0.688826231671368, 0.838303713001461, 0.275729095922975],
    [0.69241321252117, 0.839185261031523, 0.277068102025706],
    [0.695995542316856, 0.840065459286938, 0.278406179255883],
    [0.699573314259658, 0.840944307551081, 0.279743345799652],
    [0.703146619452926, 0.841821805604637, 0.281079619506183],
    [0.706715546963339, 0.84269795322559, 0.28241501789562],
    [0.710280183879886, 0.843572750189221, 0.283749558166786],
    [0.713840615370728, 0.844446196268098, 0.285083257204673],
    [0.717396924738028, 0.845318291232073, 0.286416131587714],
    [0.720949193470827, 0.846189034848273, 0.287748197594841],
    [0.724497501296066, 0.847058426881095, 0.289079471212355],
    [0.728041926227811, 0.8479264670922, 0.290409968140585],
    [0.731582544614784, 0.848793155240507, 0.291739703800371],
    [0.735119431186239, 0.849658491082184, 0.293068693339362],
    [0.738652659096277, 0.850522474370648, 0.294396951638137],
    [0.742182299966652, 0.851385104856552, 0.29572449331616],
    [0.745708423928128, 0.852246382287784, 0.297051332737568],
    [0.749231099660458, 0.853106306409459, 0.2983774840168],
    [0.752750394431018, 0.853964876963911, 0.299702961024075],
    [0.756266374132179, 0.854822093690694, 0.301027777390723],
    [0.75977910331744, 0.855677956326566, 0.302351946514366],
    [0.763288645236384, 0.856532464605494, 0.303675481563967],
    [0.766795061868504, 0.857385618258637, 0.304998395484741],
    [0.770298413955941, 0.85823741701435, 0.306320701002938],
    [0.773798761035169, 0.859087860598172, 0.307642410630495],
    [0.777296161467677, 0.859936948732824, 0.308963536669573],
    [0.780790672469683, 0.8607846811382, 0.31028409121697],
    [0.784282350140916, 0.861631057531362, 0.311604086168422],
    [0.787771249492496, 0.862476077626538, 0.312923533222795],
    [0.791257424473959, 0.863319741135111, 0.314242443886162],
    [0.794740927999443, 0.864162047765616, 0.315560829475784],
    [0.798221811973078, 0.865002997223734, 0.316878701123986],
    [0.801700127313601, 0.865842589212287, 0.318196069781937],
    [0.805175923978228, 0.866680823431232, 0.319512946223329],
    [0.808649250985815, 0.867517699577654, 0.320829341047973],
    [0.81212015643932, 0.868353217345763, 0.322145264685297],
    [0.815588687547603, 0.869187376426885, 0.323460727397766],
    [0.819054890646584, 0.870020176509461, 0.324775739284205],
    [0.82251881121978, 0.870851617279036, 0.326090310283055],
    [0.825980493918244, 0.871681698418259, 0.327404450175539],
    [0.829439982579926, 0.872510419606874, 0.328718168588754],
    [0.83289732024848, 0.873337780521715, 0.330031474998695],
    [0.836352549191527, 0.8741637808367, 0.331344378733189],
    [0.839805710918403, 0.874988420222829, 0.332656888974781],
    [0.842195291906245, 0.875558555646015, 0.333565369815977],
    [0.842747918375584, 0.874185031677965, 0.332785205071463],
    [0.844526436167461, 0.869718773227357, 0.330250719817554],
    [0.846278080039958, 0.865249701901058, 0.327718278905379],
    [0.848003143908072, 0.860777739798246, 0.325187881666242],
    [0.849701915588765, 0.856302807237966, 0.322659527304212],
    [0.851374676980517, 0.851824822707455, 0.320133214891605],
    [0.853021704236145, 0.847343702808586, 0.317608943364296],
    [0.854643267929214, 0.84285936220236, 0.315086711516848],
    [0.856239633214287, 0.838371713551345, 0.31256651799746],
    [0.857811059981335, 0.833880667459993, 0.310048361302713],
    [0.859357803004541, 0.82938613241271, 0.307532239772113],
    [0.860880112085751, 0.824888014709609, 0.30501815158242],
    [0.862378232192805, 0.82038621839981, 0.302506094741751],
    [0.863852403592982, 0.815880645212195, 0.299996067083454],
    [0.865302861981748, 0.811371194483497, 0.297488066259729],
    [0.866729838607027, 0.806857763083583, 0.294982089735002],
    [0.86813356038918, 0.802340245337822, 0.292478134779016],
    [0.869514250036865, 0.797818532946378, 0.289976198459657],
    [0.870872126158958, 0.793292514900295, 0.287476277635474],
    [0.872207403372699, 0.788762077394211, 0.284978368947891],
    [0.873520292408215, 0.784227103735542, 0.282482468813098],
    [0.874811000209561, 0.779687474249956, 0.279988573413598],
    [0.876079730032445, 0.775143066182959, 0.277496678689404],
    [0.877326681538735, 0.770593753597397, 0.275006780328851],
    [0.878552050887907, 0.766039407266664, 0.272518873759021],
    [0.879756030825543, 0.761479894563403, 0.270032954135754],
    [0.88093881076899, 0.756915079343467, 0.267549016333227],
    [0.882100576890303, 0.752344821824887, 0.265067054933066],
    [0.883241512196569, 0.747768978461603, 0.262587064212992],
    [0.884361796607718, 0.74318740181166, 0.260109038134949],
    [0.885461607031913, 0.738599940399591, 0.257632970332707],
    [0.886541117438617, 0.73400643857267, 0.255158854098903],
    [0.887600498929414, 0.729406736350697, 0.252686682371492],
    [0.888639919806681, 0.724800669268968, 0.250216447719571],
    [0.889659545640191, 0.72018806821405, 0.247748142328556],
    [0.890659539331704, 0.715568759251955, 0.245281757984658],
    [0.891640061177648, 0.710942563448298, 0.242817286058631],
    [0.892601268929941, 0.706309296679962, 0.240354717488752],
    [0.893543317855026, 0.701668769437804, 0.23789404276298],
    [0.894466360791187, 0.697020786619864, 0.235435251900258],
    [0.895370548204202, 0.69236514731453, 0.232978334430899],
    [0.896256028241399, 0.687701644573057, 0.230523279376015],
    [0.897122946784166, 0.683030065170809, 0.228070075225915],
    [0.897971447498972, 0.678350189356535, 0.225618709917433],
    [0.898801671886952, 0.673661790588951, 0.223169170810101],
    [0.899613759332101, 0.668964635259847, 0.220721444661108],
    [0.900407847148129, 0.664258482402859, 0.218275517598981],
    [0.901184070624029, 0.659543083387031, 0.215831375095877],
    [0.901942563068388, 0.654818181594158, 0.213389001938442],
    [0.902683455852493, 0.650083512078897, 0.210948382197111],
    [0.903406878452274, 0.645338801210493, 0.20850949919377],
    [0.904112958489119, 0.640583766294925, 0.206072335467667],
    [0.904801821769595, 0.635818115176143, 0.203636872739464],
    [0.905473592324123, 0.631041545814998, 0.201203091873303],
    [0.906128392444627, 0.62625374584433, 0.198770972836752],
    [0.906766342721206, 0.621454392098559, 0.196340494658501],
    [0.907387562077848, 0.616643150115997, 0.193911635383639],
    [0.907992167807219, 0.611819673611937, 0.191484372026363],
    [0.908580275604571, 0.606983603920424, 0.189058680519924],
    [0.90915199960077, 0.602134569402416, 0.186634535663635],
    [0.909707452394505, 0.597272184817869, 0.184211911066711],
    [0.910246745083672, 0.59239605065904, 0.181790779088738],
    [0.910769987295989, 0.58750575244207, 0.179371110776502],
    [0.911277287218838, 0.582600859953656, 0.176952875796927],
    [0.911768751628379, 0.577680926449302, 0.174536042365831],
    [0.912244485917952, 0.572745487799343, 0.172120577172174],
    [0.912704594125783, 0.567794061578556, 0.169706445297464],
    [0.913149178962025, 0.562826146094787, 0.167293610129946],
    [0.913578341835149, 0.557841219351574, 0.164882033273165],
    [0.913992182877705, 0.552838737939249, 0.162471674448448],
    [0.914390800971472, 0.547818135848474, 0.160062491390836],
    [0.914774293772023, 0.542778823199514, 0.157654439737911],
    [0.915142757732705, 0.537720184879912, 0.155247472910947],
    [0.915496288128073, 0.532641579082424, 0.152841541987738],
    [0.915834979076776, 0.527542335734234, 0.15043659556639],
    [0.916158923563927, 0.522421754807495, 0.148032579619317],
    [0.916468213462955, 0.517279104500144, 0.145629437336561],
    [0.91676293955697, 0.512113619274725, 0.143227108957519],
    [0.917043191559648, 0.506924497741565, 0.140825531590003],
    [0.917309058135648, 0.501710900371052, 0.138424639015514],
    [0.917560626920581, 0.496471947018008, 0.136024361479416],
    [0.917797984540538, 0.491206714239109, 0.133624625464615],
    [0.91802121663119, 0.485914232381993, 0.13122535344716],
    [0.91823040785648, 0.480593482422045, 0.128826463632005],
    [0.918425641926906, 0.475243392519851, 0.126427869666976],
    [0.918607001617422, 0.469862834268805, 0.124029480332767],
    [0.91877456878495, 0.4644506185984, 0.121631199206506],
    [0.918928424385529, 0.459005491294083, 0.119232924296151],
    [0.919068648491106, 0.453526128089266, 0.116834547642648],
    [0.919195320305976, 0.448011129278871, 0.114435954886369],
    [0.919308518182885, 0.4424590137966, 0.112037024793942],
    [0.919408319638803, 0.436868212689735, 0.109637628741039],
    [0.91949480137038, 0.431237061915419, 0.107237630146147],
    [0.919568039269083, 0.425563794370836, 0.104836883849626],
    [0.919628108436043, 0.419846531056078, 0.102435235431629],
    [0.919675083196596, 0.41408327125238, 0.100032520461507],
    [0.919709037114544, 0.408271881579265, 0.0976285636703056],
    [0.919730043006137, 0.402410083771316, 0.0952231780367157],
    [0.919738172953784, 0.396495440987968, 0.0928161637754024],
    [0.919733498319498, 0.390525342436863, 0.0904073072149622],
    [0.919716089758093, 0.38449698605158, 0.0879963795507639],
    [0.919686017230121, 0.378407358916355, 0.0855831354555744],
    [0.919643350014576, 0.372253215071608, 0.0831673115281043],
    [0.919588156721355, 0.366031050261876, 0.0807486245562707],
    [0.919520505303495, 0.359737073098782, 0.0783267695680311],
    [0.919440463069183, 0.353367172001105, 0.0759014176378669],
    [0.919348096693554, 0.346916877136033, 0.0734722134112776],
    [0.91924347223027, 0.340381316412085, 0.071038772302691],
    [0.919126655122897, 0.333755164354388, 0.0686006773137574],
    [0.918997710216085, 0.327032582412444, 0.0661574754086636],
    [0.91885670176655, 0.320207148889627, 0.06370867337041],
    [0.918703693453865, 0.313271776215222, 0.0612537330463103],
    [0.918538748391066, 0.306218612666262, 0.0587920658714707],
    [0.918361929135088, 0.299038924834603, 0.056323026534606],
    [0.918173297697011, 0.291722956048905, 0.0538459056198431],
    [0.917972915552149, 0.284259754491993, 0.0513599210192125],
    [0.917760843649965, 0.27663696274083, 0.0488642078607802],
    [0.917537142423829, 0.268840557659148, 0.0463578066333285],
    [0.917301871800622, 0.26085452562782, 0.0438396491053156],
    [0.917055091210179, 0.252660452441534, 0.0413085415268928],
    [0.916796859594591, 0.244236998940985, 0.0387555409320031],
    [0.91652723541736, 0.235559221144371, 0.0362632304591169],
    [0.916246276672413, 0.226597674891931, 0.0338509572044397],
    [0.915954040892983, 0.217317215718153, 0.0315178716898391],
];
