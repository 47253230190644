import { ToggleButtonGroup } from "@mui/lab";
import { ToggleButton } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { useSnackbar } from "notistack";
import React from "react";
import { ThirdPartyDeviceBase } from "verdiapi/dist/Models/Devices/ThirdPartyDeviceBase";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { DataTypeSpecifier } from "verditypes";
import { dayMs, hourMs } from "verditypes/dist/timeConstants";

import { NumericFieldWithUnit } from "../../generic/NumericFieldWithUnit/NumericFieldWithUnit";
import { useMultiDepthDataProcessing } from "./MultiDepthDataProcessing";
import { SentekSoilMoistureGraphDataStream } from "./types";
import { useMultiDepthSoilMoistureGraphOptions } from "./useMultiDepthSoilMoistureGraphOptions";

const durationByTimeRangeOption = {
    month: dayMs * 30,
    week: dayMs * 7,
    day: dayMs,
    hour: hourMs,
} as const;

export function MultiDepthSoilMoistureGraph({
    device,
    moistureDataType = "moisture_drillAndDrop",
    salinityDataType = "salinity_drillAndDrop",
    temperatureDataType = "soilTemperature",
    graphSubtitle = "From Sentek Probe",
    graphTitle = "Multi Depth Probe Data",
}: {
    device: ThirdPartyDeviceBase;
    moistureDataType?: DataTypeSpecifier;
    salinityDataType?: DataTypeSpecifier;
    temperatureDataType?: DataTypeSpecifier;
    graphSubtitle?: string;
    graphTitle?: string;
}) {
    const relevantDataTypes: DataTypeSpecifier[] = [
        moistureDataType,
        salinityDataType,
        temperatureDataType,
        "moisture",
    ];
    const { enqueueSnackbar } = useSnackbar();
    const [oldestDateToGet, setOldestDataToGet] = React.useState(new Date(Date.now() - dayMs * 5));

    type TimeRangeOption = keyof typeof durationByTimeRangeOption;

    const [timeRangeOption, setTimeRangeOption] = React.useState<TimeRangeOption>("week");
    const [stackDepths, setStackDepths] = React.useState<boolean>(true);

    // means that when we say to go back 1 week or 1 day, we are doing that from a constant point to eliminate performance
    // problems trying to fetch an extra 3 minutes of data a week ago.
    const timeToCalculateTimeRangeFrom = React.useMemo(
        () => new Date(Date.now()),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [new Date(Date.now()).getHours()],
    );

    React.useEffect(() => {
        setOldestDataToGet(
            new Date(timeToCalculateTimeRangeFrom.valueOf() - durationByTimeRangeOption[timeRangeOption]),
        );
    }, [timeRangeOption, timeToCalculateTimeRangeFrom]);

    const [dataTypesToShow, setDataTypesToShow] = React.useState(relevantDataTypes);
    const [splitMode, setSplitMode] = React.useState("byDataType");
    const [numDatapoints, setNumDatapoints] = React.useState(0);

    const [onsetOfStress, setOnsetOfStress] = React.useState(
        device.deviceTypeSpecificInformation.onsetOfStress || undefined,
    );
    const [saturation, setSaturation] = React.useState(device.deviceTypeSpecificInformation.saturation || undefined);
    // const [refillPoint, setRefillPoint] = React.useState(device.deviceTypeSpecificInformation.refillPoint || undefined);

    const historicalDatabase = React.useMemo(
        () =>
            // @ts-ignore
            new HistoricalDataBase([device], relevantDataTypes, 1000, {
                worryAboutPosition: true,
                worryAboutRelevantZones: false,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [relevantDataTypes.toString()],
    );

    const oldestDataToGetInteger = oldestDateToGet.valueOf();

    const { allDataSeriesArray, dataSeriesByID } = useMultiDepthDataProcessing({
        numDatapoints,
        historicalDatabase,
    });

    // fetches data when needed
    React.useEffect(() => {
        const endTimeOfSample = new Date(Date.now());
        endTimeOfSample.setMinutes(60);
        historicalDatabase
            .getData(new Date(oldestDataToGetInteger), new Date(Date.now()))
            .then(() => {
                setNumDatapoints(historicalDatabase.data.value.length);
            })
            .catch((e) => {
                console.warn("graph error", e);
                enqueueSnackbar("failed to load data for graph", { variant: "error" });
            });
    }, [enqueueSnackbar, historicalDatabase, oldestDataToGetInteger]);

    // @ts-ignore
    const depths: number[] = [...new Set(allDataSeriesArray.map((ds) => ds.depth))].filter<number>(
        // @ts-ignore
        (depth) => !Number.isNaN(depth),
    );

    const { getXAxes, getXAxisIndexForDataStream, getYAxes, getYAxisIndexForDataStream, getGrids, numberOfGrids } =
        useMultiDepthSoilMoistureGraphOptions({
            seperateDataTypes: splitMode === "byDataType",
            seperateDepths: splitMode === "byDepth",
            useBoostToSeperateDepths: stackDepths,
            dataTypes: relevantDataTypes,
            depths: depths,
        });

    const xAxes = getXAxes();

    const summedDataSeries: undefined | SentekSoilMoistureGraphDataStream = allDataSeriesArray.find(
        (ds) => ds.dataType === "moisture",
    );

    /**
     *
     * @type {Array<never>}
     */
    const coloredAreaDataSeries: SentekSoilMoistureGraphDataStream[] = [];

    if (summedDataSeries && onsetOfStress > 0) {
        coloredAreaDataSeries.push({
            ...summedDataSeries,
            id: "onsetOfStressThreshold",
            data: [
                {
                    dateValue: oldestDateToGet.valueOf(),
                    value: [oldestDateToGet.valueOf(), onsetOfStress],
                },
                {
                    dateValue: Date.now(),
                    value: [Date.now(), onsetOfStress],
                },
            ],
            areaStyle: {
                color: "#E55651",
                origin: "start",
                opacity: 0.2,
            },
            lineStyle: {
                color: "#E55651",
                opacity: 0.4,
            },
        });
    }

    if (summedDataSeries && saturation > onsetOfStress && saturation > 0) {
        coloredAreaDataSeries.push({
            ...summedDataSeries,
            id: "saturationThreshold",
            data: [
                {
                    dateValue: oldestDateToGet.valueOf(),
                    value: [oldestDateToGet.valueOf(), saturation],
                },
                {
                    dateValue: Date.now(),
                    value: [Date.now(), saturation],
                },
            ],
            areaStyle: {
                color: "#76e551",
                origin: "end",
                opacity: 0.2,
            },
            lineStyle: {
                color: "#76e551",
                opacity: 0.4,
            },
        });
    }

    const options = {
        title: {
            text: graphTitle,
            subtext: graphSubtitle,
            left: "center",
        },
        tooltip: {
            trigger: "axis",
            valueFormatter: (value: number) => value.toFixed(2),
            axisPointer: {
                animation: false,
            },
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: "none",
                },
                restore: {},
                saveAsImage: {},
            },
        },
        axisPointer: {
            link: { xAxisIndex: "all" },
        },
        xAxis: xAxes,
        dataZoom: [
            {
                // The first dataZoom component
                xAxisIndex: xAxes.map((_v, i) => i),
                startValue: oldestDateToGet,
                end: 100,
                // controls the first and the third xAxis
            },
        ],
        grid: getGrids(),
        yAxis: getYAxes(dataSeriesByID),
        series: [...allDataSeriesArray, ...coloredAreaDataSeries]
            .filter((dataSeries) => dataTypesToShow.includes(dataSeries.dataType as unknown as DataTypeSpecifier))
            .map((ds) => {
                ds.yAxisIndex = getYAxisIndexForDataStream(ds);
                ds.xAxisIndex = getXAxisIndexForDataStream(ds);
                return ds;
            })
            .sort((a, b) => `${a.yAxisIndex} ${a.depth}`.localeCompare(`${b.yAxisIndex} ${b.depth}`)),
    };
    return (
        <div>
            <div
                className={"SentekChartControls"}
                style={{
                    paddingBottom: 20,
                }}
            >
                <ToggleButtonGroup
                    sx={{ padding: 2 }}
                    value={dataTypesToShow}
                    onChange={(_e, v) => {
                        setDataTypesToShow(v);
                    }}
                    aria-label={"text formatting"}
                >
                    <ToggleButton value={moistureDataType} aria-label={"bold"}>
                        Moisture
                    </ToggleButton>
                    <ToggleButton value={temperatureDataType} aria-label={"italic"}>
                        Temperature
                    </ToggleButton>
                    <ToggleButton value={salinityDataType} aria-label={"underlined"}>
                        Salinity
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    sx={{ padding: 2 }}
                    value={splitMode}
                    exclusive={true}
                    onChange={(_e, v) => {
                        setSplitMode(v);
                    }}
                >
                    <ToggleButton value={"byDepth"} aria-label={"bold"}>
                        Split By Depth
                    </ToggleButton>
                    <ToggleButton value={"byDataType"} aria-label={"italic"}>
                        Split By Data Type
                    </ToggleButton>
                    <ToggleButton value={"byNothing"} aria-label={"underlined"}>
                        Combine
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    sx={{ padding: 2 }}
                    value={timeRangeOption}
                    exclusive={true}
                    onChange={(_e, v: TimeRangeOption) => {
                        setTimeRangeOption(v ?? timeRangeOption);
                    }}
                >
                    {Object.keys(durationByTimeRangeOption).map((v) => (
                        <ToggleButton value={v} aria-label={"bold"}>
                            {v}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    sx={{ padding: 2 }}
                    value={stackDepths}
                    exclusive={true}
                    onChange={(_e, v: boolean) => {
                        setStackDepths(v);
                    }}
                >
                    <ToggleButton value={true} aria-label={"bold"}>
                        Stack Trends
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <ReactECharts
                notMerge={true}
                style={{ height: Math.min(2000, numberOfGrids * 400), width: "100%" }}
                option={options}
            />
            <div>
                {/* @ts-ignore */}
                <NumericFieldWithUnit
                    value={onsetOfStress ?? 0}
                    showUnits={true}
                    databaseUnit={"m"}
                    alternateUnits={["m", "mm", "in", "ft"]}
                    onChange={(e: { value: number }) => {
                        if (e.value === 0) {
                            setOnsetOfStress(undefined);
                            device.deviceTypeSpecificInformation.onsetOfStress = undefined;
                            return;
                        }
                        setOnsetOfStress(e.value);
                        device.deviceTypeSpecificInformation.onsetOfStress = e.value;

                        device.edit({
                            deviceTypeSpecificInformation: device.deviceTypeSpecificInformation,
                        });
                    }}
                    label={"Onset of stress"}
                    bufferUpdates={true}
                    isRange={false}
                    unitUsecase={"stressThresholds"}
                />
                {/* @ts-ignore */}
                <NumericFieldWithUnit
                    value={saturation ?? 0}
                    showUnits={true}
                    databaseUnit={"m"}
                    alternateUnits={["m", "mm", "in", "ft"]}
                    onChange={(e: { value: number }) => {
                        if (e.value === 0) {
                            setSaturation(undefined);
                            device.deviceTypeSpecificInformation.saturation = undefined;
                            return;
                        }
                        setSaturation(e.value);
                        device.deviceTypeSpecificInformation.saturation = e.value;

                        device.edit({
                            deviceTypeSpecificInformation: device.deviceTypeSpecificInformation,
                        });
                    }}
                    label={"Saturation"}
                    bufferUpdates={true}
                    isRange={false}
                    unitUsecase={"stressThresholds"}
                />
            </div>
        </div>
    );
}
