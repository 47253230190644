import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function TensiometerIcon(props) {
    let viewBox = "0, 0,  32, 32";
    if (props.slim) {
        viewBox = "7.558, 0, 16.882, 32";
    }
    return (
        <SvgIcon viewBox={viewBox} {...props}>
            {/* <path */}
            {/*    d="M10.624 5.047a.439.439 0 0 0-.175.118c-.023.036-.042.819-.042 1.74l-.001 1.675.822-.035c.452-.02 3.086-.036 5.853-.036h5.032l-.001-1.639c0-.902-.019-1.669-.042-1.704a.472.472 0 0 0-.192-.122c-.202-.077-11.061-.074-11.254.003m1.258 8.706.015 4.051.745 1.339.745 1.34v3.406c0 2.856.013 3.423.077 3.512.074.101.175.105 2.669.12 1.426.009 2.646.003 2.711-.013.301-.075.288.09.288-3.613v-3.412l.745-1.34.746-1.339.014-4.051.015-4.051h-.815l-.001 3.97-.001 3.97-.704 1.272-.703 1.273V9.702h-4.347l.019 5.257c.01 2.891.016 5.257.013 5.257-.004 0-.327-.581-.718-1.291l-.713-1.291V9.702h-.815l.015 4.051" */}
            {/*    fillRule="evenodd" */}
            {/* /> */}
            {/* <path */}
            {/*    style={{ */}
            {/*        fillOpacity: 1, */}
            {/*        strokeWidth: 0.2, */}
            {/*        strokeLinecap: "butt", */}
            {/*        strokeLinejoin: "round", */}
            {/*        strokeMiterlimit: 4, */}
            {/*        strokeDasharray: "none", */}
            {/*        strokeOpacity: 1, */}
            {/*    }} */}
            {/*    d="M10.624 5.047a.439.439 0 0 0-.175.118c-.023.036-.042.819-.042 1.74l-.001 1.675.822-.035c.452-.02 3.086-.036 5.853-.036h5.032l-.001-1.639c0-.902-.019-1.669-.042-1.704a.472.472 0 0 0-.192-.122c-.202-.077-11.061-.074-11.254.003m1.258 8.706.015 4.051.745 1.339.745 1.34v3.406c0 2.856.013 3.423.077 3.512.074.101.175.105 2.669.12 1.426.009 2.646.003 2.711-.013.301-.075.288.09.288-3.613v-3.412l.745-1.34.746-1.339.014-4.051.015-4.051h-.815l-.001 3.97-.001 3.97-.704 1.272-.703 1.273V9.702h-4.347l.019 5.257c.01 2.891.016 5.257.013 5.257-.004 0-.327-.581-.718-1.291l-.713-1.291V9.702h-.815l.015 4.051" */}
            {/*    fillRule="evenodd" */}
            {/* /> */}
            <path
                d={
                    "M8.908 1.5a.486.486 0 0 0-.217.135c-.094.103-.102.257-.116 2.3l-.016 2.19 7.685-.014 7.685-.014v-4.36l-.124-.123-.123-.124-7.329-.006c-4.031-.004-7.381.004-7.445.016m1.619 11.467.015 5.325.962 1.736.962 1.737v4.415c0 4.096.007 4.429.095 4.602.066.132.147.201.271.232.097.025 1.696.038 3.555.03 2.75-.012 3.395-.029 3.468-.089a.649.649 0 0 0 .143-.271c.035-.125.055-1.785.055-4.558l.001-4.361.962-1.737.962-1.736.014-5.325.014-5.325h-1.031v10.389l-.935 1.694-.935 1.693-.014-6.888-.013-6.888h-5.636l-.014 6.888-.014 6.888-.935-1.693-.935-1.694V7.642h-1.031l.014 5.325"
                }
                fillRule={"evenodd"}
            />
            {/* <path */}
            {/*    style={{ */}
            {/*        fillOpacity: 1, */}
            {/*        strokeWidth: 0.2, */}
            {/*        strokeLinecap: "butt", */}
            {/*        strokeLinejoin: "round", */}
            {/*        strokeMiterlimit: 4, */}
            {/*        strokeDasharray: "none", */}
            {/*        strokeOpacity: 1, */}
            {/*    }} */}
            {/*    d="M7.195 3.687v-.835h2.895v.831" */}
            {/*    transform="translate(-4.34 -1.43)" */}
            {/* /> */}
            {/* <path */}
            {/*    style={{ */}
            {/*        fill: "none", */}
            {/*        stroke: "#000", */}
            {/*        strokeWidth: 0.2, */}
            {/*        strokeLinecap: "butt", */}
            {/*        strokeLinejoin: "round", */}
            {/*        strokeMiterlimit: 4, */}
            {/*        strokeDasharray: "none", */}
            {/*        strokeOpacity: 1, */}
            {/*    }} */}
            {/*    d="M7.589 3.99v2.12l.394.713V8.61h1.32V6.823l.393-.714V3.99" */}
            {/*    transform="translate(-4.34 -1.43)" */}
            {/* /> */}
            {/* <path */}
            {/*    style={{ */}
            {/*        fillOpacity: 1, */}
            {/*        stroke: "none", */}
            {/*        strokeWidth: ".264583px", */}
            {/*        strokeLinecap: "butt", */}
            {/*        strokeLinejoin: "miter", */}
            {/*        strokeOpacity: 1, */}
            {/*    }} */}
            {/*    d="M7.983 6.823h.095V3.99h1.136v2.833h.089V8.61h-1.32V6.823" */}
            {/*    transform="translate(-4.34 -1.43)" */}
            {/* /> */}
        </SvgIcon>
    );
}
