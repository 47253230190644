import "./DepthProfile.css";

import { Typography } from "@mui/material";
import * as React from "react";

import ReactComponentBase from "../../../utils/ReactComponentBase";
import { getSoilType } from "../../../utils/SoilTypeCalculator";

const compById = {};

const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
        if (compById[entry.target.id]) {
            compById[entry.target.id].evaluateSize(entry);
        }
    });
});

export default class DepthProfile extends ReactComponentBase {
    constructor(props) {
        super(props);
        this.state = {
            tallEnoughForText: false,
            bumpSoilMoistureUp: false,
            individualTooltips: false,
        };
    }

    componentDidMount() {
        resizeObserver.observe(this.ownComponent);
        compById[this.uid] = this;
    }

    evaluateSize(entry) {
        const newState = {};
        let height;
        // not all devices support contentBoxSize. Specifically old versions of Safari.
        if (entry.contentBoxSize) {
            height = entry.contentBoxSize[0].blockSize;
        } else {
            height = entry?.target?.clientHeight;
        }
        newState.tallEnoughForText = height > 150;
        newState.individualTooltips = height > 60;
        if (this.props.moistureLevel) {
            const heightOfMoistureBar = height * this.getMoisturePercent();
            newState.bumpSoilMoistureUp = heightOfMoistureBar < 50;
        }

        this.setState(newState);
    }

    componentWillUnmount() {
        resizeObserver.unobserve(this.ownComponent);
        delete compById[this.uid];
    }

    get moistureReading() {
        return this.props.moistureLevel || this.props.soilProfile.lastMoistureReading;
    }

    getMoisturePercent() {
        if (!this.moistureReading) {
            return null;
        }
        const soilCapacity = this.props.soilProfile.soilCapacity || 1.0;

        return this.moistureReading / soilCapacity;
    }

    getMoisturePercentFormatted() {
        if (!this.moistureReading) {
            return null;
        }
        return `${Math.min(100, Math.round(this.getMoisturePercent() * 100))}%`;
    }

    render() {
        const relevantSoilProfile = this.props.soilProfile;
        const renderText = !this.props.noText && this.state.tallEnoughForText;

        const individualTooltips = this.state.individualTooltips;

        const percentOfSoilCapacity = this.getMoisturePercentFormatted();

        const getNiceSoilPercentage = (input) =>
            `${Math.round(input * 100)
                .toString()
                .padStart(2, " ")}%`;

        const percentagesOfHeight = {
            sand: Math.max(0.15, relevantSoilProfile.sand),
            silt: Math.max(0.15, relevantSoilProfile.silt),
            clay: Math.max(0.15, relevantSoilProfile.clay),
        };
        const soilTypes = Object.keys(percentagesOfHeight);
        const titleTexts = {};

        let totalPercentage = 0;
        Object.keys(percentagesOfHeight).forEach((type) => {
            totalPercentage += percentagesOfHeight[type];
        });

        Object.keys(percentagesOfHeight).forEach((type) => {
            percentagesOfHeight[type] = (percentagesOfHeight[type] / Math.max(totalPercentage, 0.1)) * 100;
        });

        const classNames = [`depthProfile ${this.props.className || ""} `];
        if (!renderText) {
            classNames.push("depthProfile--textHidden");
        }
        if (this.state.bumpSoilMoistureUp) {
            classNames.push("depthProfile--bumpSoilMoistureUp");
        }

        let profileWideTooltip;
        if (!this.props.hideTooltips && !individualTooltips) {
            profileWideTooltip = `${getSoilType(percentagesOfHeight.sand, percentagesOfHeight.clay)}`;
        } else if (!this.props.hideTooltips && individualTooltips) {
            soilTypes.forEach((st) => {
                titleTexts[st] = `${getNiceSoilPercentage(relevantSoilProfile[st])} ${st} @ ${
                    this.props.soilProfile.depth
                }"`;
            });
            titleTexts.moisture = `${percentOfSoilCapacity} percent soil capacity`;
        }

        const generateSoilProfile = (soilType) => (
            <div
                className={`soilBar soilBar--${soilType} `}
                style={{ height: `${percentagesOfHeight[soilType]}%` }}
                title={titleTexts[soilType] || null}
                key={`${this.uid}--${soilType}`}
            >
                <Typography variant={"body1"} className={"soilProfilePercentText"}>
                    {getNiceSoilPercentage(relevantSoilProfile[soilType])} {soilType}
                </Typography>
            </div>
        );
        return (
            <div className={classNames.join(" ")} id={this.uid}>
                <div className={"soilProfile"} title={profileWideTooltip}>
                    {soilTypes.map(generateSoilProfile)}
                </div>
                {percentOfSoilCapacity !== null && (
                    <div className={"moistureBar"} title={titleTexts.moisture}>
                        <div className={"moistureLevel"} style={{ height: percentOfSoilCapacity }}>
                            <Typography variant={"body1"} className={"soilProfileMoistureText"}>
                                {percentOfSoilCapacity}
                                <br />
                                <span className={"moistureLevelLabel"}>cap</span>
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
