import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { SnackbarKey, useSnackbar as notistackUseSnackbar } from "notistack";

export function SnackbarDismissButton(snackbarId: SnackbarKey) {
    const { closeSnackbar } = notistackUseSnackbar();
    return (
        <IconButton
            color={"info"}
            onClick={() => {
                closeSnackbar(snackbarId);
            }}
        >
            <Close />
        </IconButton>
    );
}
