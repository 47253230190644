import React from "react";

import IOSShaderUpdateMessage from "./IOSShaderUpdateMessage";
import ServiceDownMessage from "./ServiceDownMessage";
import WebGL2Message from "./WebGL2Message";

export default class AllOnLoadMessages extends React.Component {
    displayedMessages = {};

    messagesClearedThisLoad = [];

    mounted = false;

    constructor(props) {
        super(props);
        this.state = {};
        this.loadMessages();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    clearMessage(messageTitle, until = undefined) {
        if (!until) {
            until = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365);
        }
        this.displayedMessages[messageTitle] = until;
        localStorage.setItem("clearedMessages", JSON.stringify(this.displayedMessages));
        this.loadMessages();
    }

    loadMessages() {
        try {
            this.displayedMessages = JSON.parse(localStorage.getItem("clearedMessages"));
            if (!this.displayedMessages) {
                this.displayedMessages = {};
            }
        } catch (e) {
            console.warn("error loading which messages have been displayed from local storage: ", e);
        }
        if (this.mounted) {
            this.setState({});
        }
    }

    render() {
        const messageLookup = {
            WebGL2Message: WebGL2Message,
            IOSShaderUpdateMessageUpdate: IOSShaderUpdateMessage,
            ServiceDownMessage: ServiceDownMessage,
        };

        return (
            <>
                {Object.keys(messageLookup).map((messageName) => {
                    const Comp = messageLookup[messageName];
                    let isCleared = false;
                    if (this.displayedMessages[messageName]) {
                        const clearedUntil = new Date(this.displayedMessages[messageName].valueOf()).valueOf();
                        if (clearedUntil > Date.now()) {
                            console.info(`message ${messageName} cleared`);
                            isCleared = true;
                        }
                    }
                    if (this.messagesClearedThisLoad.includes(messageName)) {
                        isCleared = true;
                    }
                    return (
                        <Comp
                            cleared={isCleared}
                            key={messageName}
                            onClose={(clearFor) => {
                                this.messagesClearedThisLoad.push(messageName);
                                this.clearMessage(messageName, new Date(Date.now() + clearFor));
                            }}
                        />
                    );
                })}
            </>
        );
    }
}
