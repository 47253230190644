import "./AsyncTaskButton.scss";

import { Button } from "@mui/material";
import { BufferedAction } from "gardenspadejs/dist/general";
import { useSnackbar } from "notistack";
import React from "react";

import ConfirmChoiceButton from "../ConfirmChoiceButton/ConfirmChoiceButton";

const saveTextLookup = {
    unavailable: {
        button: "Save",
    },
    ready: {
        button: "Save",
        confirmationQuestion: "Are you sure you want to save?",
        confirmationAnswer: "Yes",
    },
    running: {
        button: "Saving...",
    },
    justSucceeded: {
        button: "Saved!",
    },
    justFailed: {
        button: "Failed!",
        snackbar: "Error: Failed to save!",
    },
};
const textLookupByButtonType = {
    save: saveTextLookup,
};

export default function AsyncTaskButton({
    className,
    buttonType = "save",
    variant = "contained",
    onStart = (e) => undefined,
    color = "primary",
    needsConfirmation = false,
    isActionAllowed = false,
    onFail = (error) => undefined,
    onSuccess = (result) => undefined,
    ...extraButtonProps
}) {
    const { enqueueSnackbar } = useSnackbar();

    const finalClassName = `${className || ""} AsyncTaskButton`;
    /**
     *
     * @type {[
     *  "ready" | "running" | "justSucceeded" |"justFailed",
     * React.Dispatch<React.SetStateAction<string>>]}
     */
    const [currentTaskState, setCurrentTaskState] = React.useState("ready");
    const workingOnTaskSemaphor = React.useRef(false);

    const isAvailableRef = React.useRef(isActionAllowed);
    // isAvailableRef stores the current availability of the button to clicks. If the button was just pushed,
    // it is not available, or if the isAvailable prop is set to false, it is not available.
    isAvailableRef.current =
        isActionAllowed &&
        !["justSucceeded", "justFailed"].includes(currentTaskState) &&
        !workingOnTaskSemaphor.current;

    const clearRecentResultAction = React.useMemo(
        () =>
            new BufferedAction(
                () => {
                    setCurrentTaskState((curTaskState) => {
                        // if it is in the just succeeded or just failed state, transition back to ready
                        if (["justSucceeded", "justFailed"].includes(curTaskState)) {
                            return "ready";
                        }
                        return curTaskState;
                    });
                },
                800,
                false,
                true,
            ),
        [],
    );

    const textLookup = textLookupByButtonType[buttonType];
    const buttonState = isActionAllowed ? currentTaskState : "unavailable";
    const textOptions = textLookup[buttonState];

    const _onSuccess = (r) => {
        workingOnTaskSemaphor.current = false;
        setCurrentTaskState("justSucceeded");
        clearRecentResultAction.trigger();
        if (textLookup?.justSucceeded?.snackbar) {
            enqueueSnackbar(textLookup?.justSucceeded?.snackbar, { variant: "success" });
        }
        try {
            onSuccess(r);
        } catch (error) {
            console.warn("Error in onsuccess handler of async task button:", error);
        }
    };
    const _onFail = (e) => {
        workingOnTaskSemaphor.current = false;
        setCurrentTaskState("justFailed");
        clearRecentResultAction.trigger();
        if (textLookup?.justFailed?.snackbar) {
            enqueueSnackbar(textLookup?.justFailed?.snackbar, { variant: "error" });
        }
        try {
            onFail(e);
        } catch (error) {
            console.warn("Error in onfail handler of async task button:", error);
        }
    };
    const promisifiedTask = async (...args) => onStart(...args);
    const onClick = async (e) => {
        if (currentTaskState === "ready" && !workingOnTaskSemaphor.current && isAvailableRef.current) {
            workingOnTaskSemaphor.current = true;
            // make sure that we only set the last task state if it hasn't already been set to "justFinished"
            // which is possible in the event of an instant fail, though not recorded ever happening
            setCurrentTaskState((lastTaskState) => (lastTaskState === "ready" ? "running" : lastTaskState));
            promisifiedTask(e)
                .then((r) => {
                    _onSuccess(r);
                })
                .catch((error) => {
                    _onFail(error);
                });
        }
    };

    const buttonProps = {
        disabled: !isActionAllowed,
        color: buttonState === "unavailable" ? "inherit" : color,
        className: finalClassName,
        variant: variant,
        onClick: onClick,
    };

    if (needsConfirmation) {
        return (
            <ConfirmChoiceButton
                disableOpen={!isAvailableRef.current}
                confirmationQuestion={textOptions.confirmationQuestion}
                confirmationText={textOptions.confirmationAnswer}
                {...buttonProps}
                {...extraButtonProps}
            >
                {textOptions.button}
            </ConfirmChoiceButton>
        );
    }

    return (
        <Button {...buttonProps} {...extraButtonProps}>
            {textOptions.button}
        </Button>
    );
}
