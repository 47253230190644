/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import React from "react";

import source from "!!raw-loader!./GraphLegendChipDemo.jsx";

import { GraphLegendChip } from "./GraphLegendChip";
import readme from "./README.md";

function DemoComponent({ ...props }) {
    return (
        <div>
            <GraphLegendChip label={"NDVI line"} variant={"Line"} colorSet={"NDVI"} />
            <GraphLegendChip
                label={"Scheduled Irrigation Square"}
                variant={"Square"}
                colorSet={"ScheduledIrrigation"}
            />
            <GraphLegendChip label={"Irrigation Dot"} variant={"Dot"} colorSet={"Irrigation"} />
            <GraphLegendChip label={"purple dot"} variant={"Dot"} colorSet={"Set"} />
            <GraphLegendChip label={"black line"} variant={"Line"} color={"#000000"} />
        </div>
    );
}
const GraphLegendChipDemo = {
    component: DemoComponent,
    name: "GraphLegendChip",
    readme: readme,
    source: source,
};

export default GraphLegendChipDemo;
