import { InputLabel, Select, SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

export enum SproutAutomatedValveValues {
    V1 = "v1",
    V2 = "v2",
    BOTH = "both",
    NONE = "none",
}

export const SproutAutomatedValveOptions = [
    {
        label: "V1 Only",
        value: SproutAutomatedValveValues.V1,
        asArray: [true, false],
    },
    {
        label: "V2 Only",
        value: SproutAutomatedValveValues.V2,
        asArray: [false, true],
    },
    {
        label: "Both Valves",
        value: SproutAutomatedValveValues.BOTH,
        asArray: [true, true],
    },
    {
        label: "None",
        value: SproutAutomatedValveValues.NONE,
        asArray: [false, false],
    },
];

/**
 * Hook to manage the select state of SproutAutomationValveSelection
 */
export const useSproutAutomationValveSelection = ({ defaultValueArr }: { defaultValueArr: [boolean, boolean] }) => {
    const defaultAsString = JSON.stringify(defaultValueArr);
    const defaultValue = SproutAutomatedValveOptions.find(
        (option) => JSON.stringify(option.asArray) === defaultAsString,
    )?.value;
    const [selectedValue, setSelectedValue] = useState(defaultValue ?? SproutAutomatedValveValues.BOTH);

    const onChange = (newValue: (typeof SproutAutomatedValveValues)[keyof typeof SproutAutomatedValveValues]) => {
        setSelectedValue(newValue);
    };
    return {
        onChangeValveValue: onChange,
        selectedValveOption:
            SproutAutomatedValveOptions.find((option) => option.value === selectedValue) ??
            SproutAutomatedValveOptions[0],
    };
};

/**
 * A component that allows you to change which valves will automate for a sprout device.
 */
interface SproutAutomationValveSelectionProps {
    className?: string;
    value: (typeof SproutAutomatedValveValues)[keyof typeof SproutAutomatedValveValues];
    onChange: (selectedOption: (typeof SproutAutomatedValveValues)[keyof typeof SproutAutomatedValveValues]) => void;
}
export function SproutAutomationValveSelection({ className, onChange, value }: SproutAutomationValveSelectionProps) {
    className += ` SproutAutomationValveSelection `;
    const uid = React.useId();

    const handleSelectOption = (e: SelectChangeEvent<SproutAutomatedValveValues>) => {
        onChange(e.target.value as SproutAutomatedValveValues);
    };

    return (
        <div className={className}>
            <FormControl fullWidth>
                <InputLabel id={`SproutAutomationValveSelectionLabel${uid}`}>Valve(s) To Automate</InputLabel>
                <Select
                    labelId={`SproutAutomationValveSelectionLabel${uid}`}
                    value={value}
                    label={"Valve(s) To Automate"}
                    onChange={handleSelectOption}
                >
                    {SproutAutomatedValveOptions.map((valveOption) => (
                        <MenuItem key={valveOption.value} value={valveOption.value}>
                            {valveOption.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText error> ^ Admin Only right now! ^ </FormHelperText>
            </FormControl>
        </div>
    );
}
