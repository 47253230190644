/**
 * Quick and dirty algorithim to get the center of a polygon
 * @param polygon
 * @return {number[]}
 */
export function getCenterOfPolygon(
    polygon: ({ lat: number; lng: number } | { lattitude: number; long: number } | [number, number])[],
): number[] {
    const CenterPoint = [0, 0];
    let divisor = 0;
    let corners = [];
    if (polygon && polygon[0]) {
        corners = polygon;
    } else {
        return [0, 0];
    }
    // @ts-ignore
    corners.forEach((v) => {
        // @ts-ignore
        const lat = v.lat || v[0] || v.lattitude;
        // @ts-ignore
        const lng = v.lng || v[1] || v.longitude;
        // @ts-ignore
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(lat) && !isNaN(lng)) {
            CenterPoint[0] += lat;
            CenterPoint[1] += lng;
            divisor++;
        } else {
            console.warn("NaN on zone vertices while finding center");
            console.warn(JSON.stringify(polygon));
        }
    });
    if (divisor === 0) {
        divisor = 1;
    }
    CenterPoint[0] /= divisor;
    CenterPoint[1] /= divisor;
    return CenterPoint;
}
