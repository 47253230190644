import LockResetIcon from "@mui/icons-material/LockReset";
import { Autocomplete, Button, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { ModalLayout } from "../../../components/generic/ModalLayout";
import { useModal } from "../../../hooks/useModal";
import { resetOwnPassword } from "../api";
import { AccountDetailsModalContent } from "./AccountDetailsModalContent";
import WidgetLoadingState from "./WidgetLoadingState";

interface ResetPasswordWidgetProps {
    title: string;
    bodyText: string;
    allAdmins?: string[];
    showUserSelect?: boolean;
    ownAdminUsername?: string;
}
export function ResetPasswordWidget({
    title,
    bodyText,
    allAdmins = [],
    showUserSelect = false,
    ownAdminUsername,
}: ResetPasswordWidgetProps) {
    const { openModal, closeModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    // Username from select if enabled via showUserSelect (otherwise use ownAdminUsername)
    const [username, setUsername] = useState("");

    const handleChange = (_: any, value: string | null) => setUsername(value ?? "");
    const [resetPending, setResetPending] = useState(false);

    const handleResetPassword = async () => {
        try {
            closeModal();
            setResetPending(true);

            const { password } = await resetOwnPassword();

            setResetPending(false);
            openModal(
                <AccountDetailsModalContent
                    title={`Password Successfully Reset for ${showUserSelect ? username : ownAdminUsername}`}
                    accountDetails={[
                        {
                            label: "password",
                            value: password,
                        },
                    ]}
                />,
            );
        } catch (error) {
            enqueueSnackbar(`Password reset failed`, { variant: "error" });
            setResetPending(false);
        }
        if (showUserSelect) {
            setUsername("");
        }
    };

    const onClickReset = () => {
        openModal(
            <ModalLayout onClickClose={() => closeModal()}>
                <Typography variant={"h2"}>Confirm password reset</Typography>
                <Stack sx={{ px: 5, py: 1, alignItems: "center", justifyContent: "center" }} gap={2}>
                    <Typography variant={"h4"}>{showUserSelect ? username : ownAdminUsername}</Typography>
                </Stack>

                <Typography variant={"body1"}>{bodyText} Are you sure you want to proceed?</Typography>
                <Button variant={"contained"} color={"primary"} onClick={handleResetPassword}>
                    Reset password
                </Button>
            </ModalLayout>,
        );
    };

    return (
        <Stack gap={3} sx={{ px: 6, py: 6, maxWidth: 575 }} border={1} borderRadius={5} borderColor={"GrayText"}>
            <Stack direction={"row"} alignItems={"center"}>
                <LockResetIcon sx={{ fontSize: 30, mb: 1, mr: 1 }} color={"primary"} />
                <Typography variant={"h2"}>{title}</Typography>
            </Stack>
            <Typography variant={"body1"}>{bodyText}</Typography>
            {resetPending ? (
                <WidgetLoadingState text={"Resetting..."} />
            ) : (
                <>
                    {showUserSelect && (
                        <Autocomplete
                            disablePortal
                            value={username}
                            onChange={handleChange}
                            id={"combo-box-demo"}
                            options={allAdmins}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label={"Username"} />}
                        />
                    )}
                </>
            )}

            <Button
                disabled={(showUserSelect && !username) || resetPending}
                onClick={onClickReset}
                variant={"contained"}
                color={"primary"}
                sx={{ maxWidth: 220, mt: 2 }}
            >
                Reset Password
            </Button>
        </Stack>
    );
}
