import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function InfoOutlineBlueIcon(props) {
    return (
        <SvgIcon viewBox={"0 0 24 24"} {...props}>
            <path
                fill={"#03a9f4"}
                d={
                    "M 11 9 H 13 V 7 H 11 M 12 20 C 7.59 20 4 16.41 4 12 C 4 7.59 7.59 4 12 4 C 16.41 4 20 7.59 20 12 C 20 16.41 16.41 20 12 20 M 12 2 A 10 10 0 0 0 2 12 A 10 10 0 0 0 12 22 A 10 10 0 0 0 22 12 A 10 10 0 0 0 12 2 M 11 17 H 13 V 11 H 11 V 17 Z"
                }
            />
        </SvgIcon>
    );
}
