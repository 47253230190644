import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion as MUIAccordion, AccordionDetails, AccordionSummary, SxProps, Typography } from "@mui/material";

interface AccordionProps {
    title: string | React.ReactNode;
    children: React.ReactNode;
    sx?: SxProps;
    accordionSummarySx?: SxProps;
    defaultExpanded?: boolean;
}
export function Accordion({ defaultExpanded = false, sx, title, children, accordionSummarySx }: AccordionProps) {
    return (
        <MUIAccordion
            elevation={0}
            disableGutters
            defaultExpanded={defaultExpanded}
            sx={{
                // Remove divider line
                "&:before": {
                    display: "none",
                },
                ...sx,
            }}
        >
            <AccordionSummary
                sx={{
                    mb: 6,
                    ...accordionSummarySx,
                }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls={"panel2-content"}
                id={"panel2-header"}
            >
                {typeof title === "string" ? <Typography variant={"h4"}>{title}</Typography> : title}
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </MUIAccordion>
    );
}
