import React from "react";
import SimpleBar from "simplebar-react";

import GlobalOptions from "../../../utils/GlobalOptions";
import ReactComponentBase from "../../../utils/ReactComponentBase";

export default class SimpleBarCompatability extends ReactComponentBase {
    render() {
        if (GlobalOptions.useSimpleBar) {
            return <SimpleBar {...this.props}>{this.props.children}</SimpleBar>;
        }
        const propsToPass = { ...this.props };
        const scrollableNodeProps = this.props.scrollableNodeProps;
        delete propsToPass.scrollableNodeProps;
        const forceVisible = this.props.forceVisible;
        delete propsToPass.forceVisible;

        if (!propsToPass.style) {
            propsToPass.style = {};
        }
        const newStyle = {};
        const originalStyle = propsToPass.style || {};
        newStyle.overflowX = "auto";
        newStyle.overflowY = "auto";
        if (forceVisible && forceVisible.includes("x")) {
            newStyle.overflowX = "scroll";
        }
        if (forceVisible && forceVisible.includes("y")) {
            newStyle.overflowY = "scroll";
        }
        newStyle.position = "relative";
        newStyle.height = "auto";
        Object.assign(propsToPass, scrollableNodeProps);
        Object.assign(newStyle, originalStyle);
        propsToPass.style = newStyle;
        if (this.props.classNames && this.props.classNames.compatability) {
            propsToPass.className = propsToPass.className || ` ${this.props.classNames.compatability}`;
        }
        return (
            <div {...propsToPass}>
                <div className={"simplebar-content"}>{this.props.children}</div>
            </div>
        );
    }
}
