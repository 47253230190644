import "./SensorRecentValuesPreview.scss";

import Typography from "@mui/material/Typography";
import { GenerateApproximateTime, getTimeString } from "gardenspadejs/dist/dateHelpers";
import { roundToDecimals } from "gardenspadejs/dist/general";
import React from "react";

export default class SensorRecentValuesPreview extends React.Component {
    getDataPointInfo(dataType) {
        const lastPoint = this.props.device?.recentData?.[dataType];
        if (lastPoint) {
            const now = new Date(Date.now());
            const lastGathered = new Date(lastPoint.date.valueOf());
            const lastValue = lastPoint.value;
            let timeSincePoint = GenerateApproximateTime(
                new Date(lastGathered.valueOf()),
                new Date(Date.now()),
                false,
                true,
                "seconds",
            );
            if (lastGathered.getDate() !== now.getDate()) {
                timeSincePoint = getTimeString(lastGathered, "MMM d h:mm nn");
            } else if (Math.abs(Date.now() - lastGathered.getDate()) > 1000 * 60 * 20) {
                timeSincePoint = getTimeString(lastGathered, "h:mm nn");
            }
            return {
                lastValue,
                timeSincePoint,
                lastGathered,
            };
        }
        return null;
    }

    render() {
        if (this.props.sensor.sensorType === "irrometer") {
            const dataPointInfo = this.getDataPointInfo("moisture_irrometer");
            if (dataPointInfo) {
                return <RenderTensiometer dataPointInfo={dataPointInfo} />;
            }
        } else if (this.props.sensor.sensorType === "watermark") {
            const dataPointInfo = this.getDataPointInfo("moisture_watermark");
            if (dataPointInfo) {
                return <RenderTensiometer dataPointInfo={dataPointInfo} />;
            }
        } else if (this.props.sensor.sensorType === "v1flowmeter") {
            const dataPointInfo = this.getDataPointInfo("v1flowmeter");
            if (dataPointInfo) {
                return <RenderFlowmeter dataPointInfo={dataPointInfo} />;
            }
        }
        return <div className={"SensorRecentValuesPreview SensorRecentValuesPreview--empty"} />;
    }
}

function RenderFlowmeter({ dataPointInfo }) {
    const { timeSincePoint, lastValue } = dataPointInfo;
    return (
        <div className={"SensorRecentValuesPreview"}>
            <Typography variant={"body2"} component={"div"}>
                {roundToDecimals(lastValue, 1)} L/h <i>{timeSincePoint}</i>
            </Typography>
        </div>
    );
}

function RenderTensiometer({ dataPointInfo }) {
    const { timeSincePoint, lastValue } = dataPointInfo;
    return (
        <div className={"SensorRecentValuesPreview"}>
            <Typography variant={"body2"} component={"div"}>
                {roundToDecimals(lastValue, 1)} KPa <i>{timeSincePoint}</i>
            </Typography>
        </div>
    );
}
