import "./ManualModeToggle.scss";

import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { secondMs } from "verdiapi/dist/HelperFunctions";

import { useTimeSince } from "../../../hooks/useTimeSince";
import { Stepper } from "../../generic/Stepper";
import { ManualModeTransitionStatuses, ManualOverrideInfo } from "./types";

// Assigns transition statuses to Stepper steps
export const ManualModeTransitionSteps: Record<ManualModeTransitionStatuses, number> = {
    sending: 0,
    confirming: 1,
    complete: 3, // index 3 since we want complete' step to be checked, not waiting at the complete step
} as const;

interface ManualModeExplanationProps {
    manualOverrideInfo: ManualOverrideInfo;
    manualModeTransitionStatus: ManualModeTransitionStatuses;
    valveActionMinutes: number | undefined;
    timeToCompleteMinutes: number | undefined;
    valveOperationText: string;
    targetState: string;
}
export function ManualModeExplanation({
    manualOverrideInfo,
    manualModeTransitionStatus,
    valveActionMinutes,
    timeToCompleteMinutes,
    valveOperationText,
    targetState,
}: ManualModeExplanationProps) {
    const { t } = useTranslation(["specializedComponents"], { keyPrefix: "manualModeToggle" });

    const { timeSinceString } = useTimeSince({
        startTime: manualOverrideInfo?.remoteOverrideSettingLastUpdated?.valueOf(),
        timerTickInterval: secondMs,
    });

    const explanationText = useMemo(
        () =>
            ({
                open: t("explanationText.currentlyOpening"),
                closed: t("explanationText.currentlyClosing"),
                none: t("explanationText.currentlySyncing"),
            })[targetState],
        [targetState, t],
    );
    return (
        <div className={"ManualModeToggle__Explanation"}>
            <Typography>{explanationText} </Typography>
            <Typography style={{ marginBottom: "12px" }} variant={"caption"}>
                {t("valveActionTimeEstimate", {
                    action: valveOperationText,
                    minutes: valveActionMinutes,
                })}
            </Typography>
            <br />
            <Typography variant={"caption"}>
                {t("explanationText.startedTimeAgo", {
                    minutes: timeSinceString,
                })}
            </Typography>
            <Stepper
                activeStep={ManualModeTransitionSteps[manualModeTransitionStatus]}
                steps={[
                    { label: t("explanationText.steps.sending") },
                    { label: t("explanationText.steps.confirming") },
                    {
                        label: t("explanationText.steps.complete"),
                        sublabel: t("explanationText.steps.withinMinutes", { minutes: timeToCompleteMinutes }),
                    },
                ]}
            />
        </div>
    );
}
